import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { TransaccionModel } from 'src/app/models/reto/transaccion.model';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';

@Injectable({
    providedIn: 'root'
})
export class TransaccionesApiService {

    public TransaccionesGET = new RequestHandler<TransaccionModel[]>();
    public TransaccionesGET_GENERIC = new RequestHandler<TransaccionModel[]>();
    public TransaccionesDELETE = new RequestHandler<TransaccionModel>();
    public TransaccionesPUT = new RequestHandler<TransaccionModel[]>();
    public TransaccionesPOST = new RequestHandler<TransaccionModel[]>();

    public sectoresGET = new RequestHandler<SectorModel[]>();
    public sectoresDELETE = new RequestHandler<SectorModel>();
    public sectoresPUT = new RequestHandler<SectorModel>();
    public sectoresPOST = new RequestHandler<SectorModel>();

    public parcelasGET = new RequestHandler<ParcelasModel[]>();
    public parcelasDELETE = new RequestHandler<ParcelasModel>();
    public parcelasPUT = new RequestHandler<ParcelasModel>();
    public parcelasPOST = new RequestHandler<ParcelasModel>();


    constructor(
        private syncService: SyncService<TransaccionModel[]>,
        private syncServiceDelete: SyncService<TransaccionModel>,
        private api: ApiRequestService<TransaccionModel[]>,
        private apiDelete: ApiRequestService<TransaccionModel>,
    ) {
        
        this.defineTransaccionesGET();
        this.defineTransaccionesPUT();
        this.defineTransaccionesPOST();
        this.defineTransaccionesDELETE();
    }

    private defineTransaccionesGET() {
        this.TransaccionesGET.fetch(HttpRequestType.GET, 'transacciones/get');
        this.TransaccionesGET_GENERIC.fetch(HttpRequestType.GET, 'generic/transacciones');
        
        this.api.registerRequest(this.TransaccionesGET);
        this.api.registerRequest( this.TransaccionesGET_GENERIC );

        this.syncService.register(this.syncService.baseSyncPolicy(this.TransaccionesGET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.TransaccionesGET_GENERIC));     
    }

    private defineTransaccionesPUT() {
        this.TransaccionesPUT
            .fetch(HttpRequestType.PUT, 'generic/transacciones');

        this.api
            .registerRequest(this.TransaccionesPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.TransaccionesPUT));
    }

    private defineTransaccionesPOST() {
        this.TransaccionesPOST
            .fetch(HttpRequestType.POST, 'generic/transacciones');
        
        this.api
            .registerRequest(this.TransaccionesPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.TransaccionesPOST));
    }

    private defineTransaccionesDELETE() {
        this.TransaccionesDELETE
            .fetch(HttpRequestType.DELETE, 'generic/transacciones');

        this.apiDelete
            .registerRequest(this.TransaccionesDELETE);

        this.syncServiceDelete
            .register(this.syncServiceDelete.baseSyncPolicy(this.TransaccionesDELETE));
    }
}
