import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { environment } from 'src/environments/environment';
import { build, buildParams, list, pool } from 'src/app/common/classes/request-builder';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import { ProductosApiService } from 'src/app/service/api/productos-api';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';

const fincaName = environment.features.fincaName;

@Component({
    selector: 'app-finca-form',
    templateUrl: './finca-form.component.html',
    styleUrls: ['./finca-form.component.scss']
})
export class FincaFormComponent extends BaseForm<FincasModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<FincasModel>();

    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public fincasNifAutocomplete = environment.features.fincasNifAutocomplete;
    public fincasNifRequired = environment.features.fincasNifRequired;
    public showTipoExplotacion = environment.features.showTipoExplotacion;
    public showFincasProductos = environment.features.showFincasProductos;
    public showFincasRazonSocial = environment.features.showFincasRazonSocial;
    public showFincasCodigo = environment.features.showFincasCodigo;


    public formRequest = new AppFormRequest<FincasModel>();
    public override model = {} as FincasModel;
    public error = '';

    public tipos = list(
        ['...', null], 
        ['Agricultura Ecológica', 'AE'], 
        ['Producción Integrada', 'PI'], 
        ['Certificacion Privada', 'CP'],
        ['Agrupacion de Tratamiento Integrado en Agricultura', 'Atrias'],
        ['Asistida de un asesor', 'AS'],
        ['Sin obligacion de aplicar la GIP', 'NO'],
    );

    public clientes = list();
    public productos = list();
    public propiedad = list(
        ['...', null], 
        ['Propias', 'Propias'], 
        ['Arrendadas', 'Arrendadas']
    );

    public formFields: ItemInterface<object>[] = [
        {
            field: 'id_cliente',
            label: 'Cliente',
            valuePrimaryKey: 'id',
            values: this.clientes,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            visible: this.hasClients
        },
        {
            field: 'razon_social',
            label: 'Razón social',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showFincasRazonSocial
        },        
        {
            field: 'nombre', 
            label: 'Nombre de ' + this.fincaName, 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: true
        },        
        {
            field: 'nif', 
            label: 'NIF', 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: this.fincasNifRequired
        },
        {
            field: 'codigo', 
            label: 'Código', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.showFincasCodigo
        },
        {
            field: 'registro_nacional', 
            label: 'Registro Nacional', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'registro_autonomico', 
            label: 'Registro Autonómico', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'direccion', 
            label: 'Dirección', 
            inputType: {type: InputType.EDIT_TEXT}
        },        
        {
            field: 'localidad', 
            label: 'Localidad', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'codigo_postal', 
            label: 'Código Postal', 
            inputType: {type: this.appName === 'yagoaznar' ? InputType.EDIT_TEXT : InputType.EDIT_NUMERIC}
        },
        {
            field: 'provincia', 
            label: 'Provincia', 
            inputType: {type: InputType.EDIT_TEXT}
        },        
        {
            field: 'telefono', 
            label: 'Teléfono', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'movil', 
            label: 'Móvil', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'email', 
            label: 'Email', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'superficie', 
            label: 'Superficie', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'tipo_explotacion',
            inputType: {type: InputType.DROPDOWN}, 
            label: 'Tipo de explotación',
            values: this.tipos,
            visible: this.showTipoExplotacion
        },
        {
            field: 'alquilado',
            label: 'Alquilado',
            inputType: { type: InputType.SWITCH },
            visible: this.appName === 'covidai' ? true : false
        },
        {
            field: 'ids_productos',
            label: 'Productos (Fertirrigación)',
            inputType: {type: InputType.MULTISELECT},
            valuePrimaryKey: 'id',
            multiSelect: true,
            values: this.productos,
            visible: this.showFincasProductos
        },
        {
            field: 'observaciones',
            label: 'Observaciones Cliente',
            inputType: {type: InputType.EDIT_TEXTAREA},
            visible: this.appName === 'gardengest'
        },
        {
            field: 'propiedad',
            label: 'Propiedad',
            inputType: {type: InputType.DROPDOWN},
            values: this.propiedad,
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'ccc',
            label: 'CCC',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'campanaragricola'
        }
    ];

    public formFieldsTitular: ItemInterface<object>[] = [
        {
            field: 'titular_nombre', 
            label: 'Titular Nombre', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'titular_nif', 
            label: 'Titular NIF', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'titular_direccion', 
            label: 'Titular Dirección', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'titular_localidad', 
            label: 'Titular Localidad', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'titular_codigo_postal', 
            label: 'Titular Código Postal', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'titular_provincia', 
            label: 'Titular Provincia', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'titular_telefono', 
            label: 'Titular Teléfono', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'titular_email', 
            label: 'Titular Email', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'titular_tipo', 
            label: 'Tipo Representación', 
            inputType: {type: InputType.EDIT_TEXT}
        },
    ];

    constructor(
        public override router: Router,
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public fincasApi: FincasApiService,
        public clientesApi: ClientesApiService,
        public productosApi: ProductosApiService
    ) {
        super(
            route,
            router,
            dashboard,
            fincasApi.fincasPUT,
            fincasApi.fincasPOST,
            'finca',
            'Editar ' + fincaName,
            'Crear ' + fincaName,
            'Duplicar ' + fincaName
        );
    }

    ngOnInit() {
        this.initFormData();
        this.expandFormRequest();

        if (this.fincasNifAutocomplete && (this.getType() === FormRequestTypes.CREATE)) {
            this.model.nif = (StorageManager.getClient() || {}).nif;
        }

        const fieldsToSend = (['id', 'id_usuario'])
            .concat(
                this.formFields.map(it => it.field ?? ''),
                this.formFieldsTitular.map(it => it.field ?? ''),
            );

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');   
        }

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.fincasApi.fincasGET)
            .setPostRequest(this.fincasApi.fincasPOST)
            .setPutRequest(this.fincasApi.fincasPUT)
            .setFormFields(this.formFields.concat(this.formFieldsTitular))
            .setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());
    }

    
    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        
        if (this.appName !== 'gardengest'){
            delete this.model.observaciones;
        }

        if (this.appName !== 'agropecuariaramirogruas'){
            delete this.model.propiedad;
        }
        
        if (this.appName !== 'campanaragricola'){
            delete this.model.ccc;
        }
        
        this.formRequest.send();
    }

    public formChanges() {
        this.requestButton.error = '';
    }
    
    private initFormData(): Promise<boolean> {
        const builds = [];

        if (this.hasClients) {
            builds.push(build(this.clientesApi.clientesGET, this.clientes, 'nombre', false));
        }

        if (this.showFincasProductos) {
            builds.push(buildParams(this.productosApi.productos.GET, {tipo: 'fertilizante'}, this.productos, 'nombre', false, true));
        }

        return pool(builds, () => {
            this.formRequest.update();
        });
    }

    private expandFormRequest() {
        this.formRequest.beforeSend(resolve => {
            if (this.model.superficie) {
                this.model.superficie = this.model.superficie.replace(',', '.');
            }

            if (this.hasClients) {
                this.model.id_usuario = environment.features.applicationType === 'cropgest' ? 
                    (StorageManager.getClient() || {}).id_usuario : 
                    (StorageManager.getClient() || {}).id_usuario_asignado || this.model.id_usuario;
            }

            if (this.appName === 'armentia' && StorageManager.getUser().rol === 'user' ) {
                this.model.id_cliente = StorageManager.getUser().id ;
            } else if (StorageManager.getUser().tipo === 'comunero') {
                this.model.id_cliente = StorageManager.getUser().id_cliente ?? '';
            }
            resolve(true);
        });
    }
}
