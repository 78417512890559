
<div id="product-container" class="two-columns-layout" *ngIf="images">
  <div *ngFor="let image of images">
    <div *ngIf="image && (image.visible ||  image.visible === undefined)">
      <div class="container py-3">
        <div class="card" style="padding: 10px; border: 0px !important; background-color: transparent !important;">
          <div class="reduce-row">
            <div class="row">
              <button (click)="goto(image.id)" *ngIf="image.id !== 'tratamientos-control'"
                class="menu-item d-flex align-items-end"
                [style.backgroundImage]="'url(' + image.src + ')'">
                <div style="width: 100%">
                  <p class="title-header">
                    {{image.title}}
                  </p>
                </div>
              </button>
              <button (click)="goto(image.id)" *ngIf=" image.id  === 'tratamientos-control'"
                class="menu-item d-flex align-items-end"
                [style.backgroundImage]="'url(' + image.src + ')'" style="background-size: 100% 100%;" >
                <div style="width: 100%">
                  <p class="title-header">
                    {{image.title}}
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="formacionUrl" class="mt-2 mb-5 d-flex justify-content-center">
  <a [href]="formacionUrl" target="_blank" class="btn btn-primary btn-lg">
    Formación
    <i class="fa fa-external-link" style="font-size: 18px;"></i>
  </a>
</div>
