
<p-panel>
    <h4>Tarea Fertilizantes 

    <!--- Spinner -->
        <div *ngIf="loadingData" style="
            display: inline-block;
            margin-right: 10px;
            position: relative;
            top: 3px;">
            <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s">
            </p-progressSpinner>
        </div>

    </h4>
    
    <!--- Fila 1 --->
    <div class="row">

        <!--- Datos Generales --->
        <div class="col-md-12">
            <app-form-builder
                    [fields]="form.formFieldsSectorLeft"
                    [distributionOrder]="this.appName === 'pyf' ? [6] : this.appName === 'donjardin' ? [] : distributionOrder"
                    [distributionColumns]="4"
                    (formChanges)="formChanges($event)"
                    (selectAllcheck)="selectAll()"
                    (mouseleave)="formChanges('')"
                    [model]="model">
            </app-form-builder>
        </div>
        <!--- Datos PAC -->
        <div class="col" *ngIf="applicationType === 'cropgest'">
            <br>
            
            <h4>
                Datos PAC
            </h4>
            
            <app-form-builder
                    [fields]="form.formFieldsPac"
                    [distributionOrder]="distributionOrderPac"
                    [distributionColumns]="5"
                    (formChanges)="formChanges($event)"
                    (selectAllcheck)="selectAll()"
                    [model]="model">
            </app-form-builder>
        </div>
        <p *ngIf="errorMessage" style="margin-left: 20px; color: red">{{errorMessage}}</p>
    </div>
    <!-- TODO  La funcionalidad de audio en la entrada de observaciones ya esta implementda solo deben añadir el parametro "Audio" con dato TRUE -->
    <!--- Fin Fila 1 --->
    <!-- <div class="row">
        <app-dictado *ngIf="showTareasFertiObservaciones" style="margin-left: 1%;"
            mensaje="Grabar Observaciones" 
            [observaciones]="model.orden_trabajo" 
            (sendObservaciones)="getOrdenTrabajo($event)">
        </app-dictado>
    </div>  
    <br>
    <br> -->

    <!--- Fila 2 --->
    <div class="row">
        <!--- [Obsoleto] Plan de Abonado --->
        <!--<div *ngIf="false">
            <div class="d-flex justify-content-start align-items-center">
                <h4>Plan de abonado</h4>
            </div>

            <br>
            
            <app-abonado *ngIf="alreadyLoaded"
                        [capacidad_cuba]="form.sectores.selected[0].caudal_cuba"
                        [caudal_inyectora]="form.sectores.selected[0].caudal_inyectora"
                        [numero_arboles]="form.sectores.selected[0].plantas"
                        [productos]="[
                            form.productos1.selected,
                            form.productos2.selected,
                            form.productos3.selected,
                            form.productos4.selected,
                            form.productos5.selected,
                            form.productos6.selected,
                            form.productos7.selected
                        ]"
                        [dosis]="[
                            model['dosis1'],
                            model['dosis2'],
                            model['dosis3'],
                            model['dosis4'],
                            model['dosis5'],
                            model['dosis6'],
                            model['dosis7']
                        ]"
                        [modelSup]="model"
                        [sectores]="form.sectores.selected"
                        [fecha_inicio]="model['fecha_inicio']"
                        [fecha_fin]="model['fecha_fin']"
                        [horas_dia]="model['horas_dia']"
                        [dias_semana]="model['numero_dias']"
                        [dosis_cuba]="model['dosis_cuba']"
                        [recalculateEvent]="recalculateEmmiter">
            </app-abonado>

            <br>

        </div>-->

        <!--- Información sobre los productos aplicados. --->
        <div class="col d-flex justify-content-between">  

            <!--- [Versión Prosanzcu] Bloque Productos Abonado --->
            <div class="col-8"
                *ngIf="(
                    appName ==='prosanzcu' || 
                    this.appName === 'abonosfolques' || 
                    this.appName === 'pyf'
                )">
                
                <h4>Productos abonado</h4>
                
                <app-form-builder
                        [fields]="form.formFieldsBottomLeft"
                        [distributionColumns]="showTareasFertiGramos ? (appName === 'pyf'? 4: 3 ): 2"
                        (formChanges)="formChanges($event)"
                        [model]="model">
                </app-form-builder>
            </div>
            
            <!--- 
                [Versión Estandar] Bloque Productos Abonado 
                -               
                ¿¿¿Que diferencia hay con el de arriba???
            --->
            <div 
                class="mx-4" 
                style="min-width: 650px;" 
                *ngIf="!(
                    appName === 'prosanzcu' || 
                    this.appName === 'abonosfolques' || 
                    this.appName === 'pyf'
                ) ">
                
                <h4>Productos abonado</h4>
                
                <app-form-builder
                        [fields]="form.formFieldsBottomLeft"
                        [distributionColumns]="showTareasFertiGramos ? (appName === 'pyf'? 4: 3 ): 2"
                        (formChanges)="formChanges($event)"
                        [model]="model">
                </app-form-builder>
            </div>

            <!-- BLOQUE KILOS POR SECTOR -->
            <div class="col-4"
                *ngIf="calculoSectores && mostrarBloqueSectores"
                style="
                    max-width: 400px; 
                    border: 1px solid lightgrey; 
                    margin-top: -25px">
    
                <p-tabView 
                    (onChange)="calculateKilosPorSector()" 
                    [(activeIndex)]="kgPorSectorTabIndex">
        
                    <ng-container *ngFor="let sector of form?.sectores?.selected">
            
                        <p-tabPanel [header]="sector.nombre">
                
                            <app-form-builder
                                [fields]="form.formFieldsBottomRight"
                                [distributionColumns]="1"
                                (formChanges)="formSectores()"
                                [model]="calculoSectores">
                            </app-form-builder>

                        </p-tabPanel>
                    </ng-container>
                </p-tabView>
            </div>
        
            <!-- BLOQUE N/P/K -->
            <div *ngIf="showFertilizantesNPK" class="mx-4 rounded px-0">
                
                <h4>
                    N / P / K 
                    <span *ngIf="showFertilizantesCa">/ Ca</span>
                    <span *ngIf="showFertilizantesS">/ S</span>
                    <span *ngIf="showFertilizantesMg">/ Mg</span>
                    Abonado
                </h4>
        
                <table class="table-style center-content mr-2" style="margin-top:20px;">    
                    <tr style="background-color:silver">
                        <td style="width: 80px;">N</td>
                        <td style="width: 80px;">P</td>
                        <td style="width: 80px;">K</td>
                        <td *ngIf="showFertilizantesCa" style="width: 80px;">Ca</td>
                        <td *ngIf="showFertilizantesS" style="width: 80px;">S</td>
                        <td *ngIf="showFertilizantesMg" style="width: 80px;">Mg</td>
                    </tr>
                    <tr>
                        <td>{{decimalFormat(totalN, 2) || '0,00'}}</td>
                        <td>{{decimalFormat(totalP, 2) || '0,00'}}</td>
                        <td>{{decimalFormat(totalK, 2) || '0,00'}}</td>
                        <td *ngIf="showFertilizantesCa">
                            {{decimalFormat(totalCa, 2) || '0,00'}}
                        </td>
                        <td *ngIf="showFertilizantesS">
                            {{decimalFormat(totalS, 2) || '0,00'}}
                        </td>
                        <td *ngIf="showFertilizantesMg">
                            {{decimalFormat(totalMg, 2) || '0,00'}}
                        </td>
                    </tr>
                </table>

                <!-- BLOQUE UNIDADES FERTILIZANTES -->
                <h4 style="margin-top:50px;" 
                    *ngIf="!(appName==='armentia')">
                        <div *ngIf="appName === 'torrepacheco'">
                                Uds Fertilizantes (Ha/Campaña)
                        </div> 

                        <div *ngIf="appName !== 'torrepacheco'">
                            Unidades fertilizantes
                        </div> 
                </h4>

                <!-- Si las unidades son generales de la tarea... -->
                <table *ngIf="form?.sectores?.selected 
                                && !udsByParcela" 
                        class="table-style center-content mr-2">
                    
                        <!-- Nombre de Columnas de la Tabla -->
                        <tr style="background-color:silver">
                            <td style="width: 100px;">{{sectorName}}</td>
                            <td style="width: 100px;">Superficie&nbsp;{{textoSuperficie}}</td>
                            <td style="width: 80px;">N</td>
                            <td style="width: 80px;">P</td>
                            <td style="width: 80px;">K</td>
                            <td *ngIf="showFertilizantesCa" style="width: 80px;">Ca</td>
                            <td *ngIf="showFertilizantesS" style="width: 80px;">S</td>
                            <td *ngIf="showFertilizantesMg" style="width: 80px;">Mg</td>
                        </tr>

                        <!-- Valores de las Columnas  -->
                        <ng-container *ngFor="
                            let sector of 
                            (form?.sectores?.selected || []); 
                            let i = index">
                            
                            <tr *ngIf="udFertN[i]">
                                <td>{{sector?.nombre}}</td>
                                <td>{{decimalFormat(superfSect[i], 2) || '0,00'}}</td>
                                <td>{{decimalFormat(udFertN[i], 2) || '0,00'}}</td>
                                <td>{{decimalFormat(udFertP[i], 2) || '0,00'}}</td>
                                <td>{{decimalFormat(udFertK[i], 2) || '0,00'}}</td>
                                <td *ngIf="showFertilizantesCa">{{decimalFormat(udFertCa[i], 2) || '0,00'}}</td>
                                <td *ngIf="showFertilizantesS">{{decimalFormat(udFertS[i], 2) || '0,00'}}</td>
                                <td *ngIf="showFertilizantesMg">{{decimalFormat(udFertMg[i], 2) || '0,00'}}</td>
                            </tr>

                            <tr *ngIf="!udFertN[i]">
                                <td>{{sector?.nombre}}</td>
                                <td>Sector sin superficie</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td *ngIf="showFertilizantesCa">-</td>
                                <td *ngIf="showFertilizantesS">-</td>
                                <td *ngIf="showFertilizantesMg">-</td>
                            </tr>
                        </ng-container>

                        <tr *ngIf=" 
                                ((form?.sectores?.selected || []).length > 1 
                                && appName !== 'pyf')">
                            <td>Total</td>
                            <td>{{decimalFormat(totalesTabla.superficie, 2) || '0,00'}}</td>
                            <td>{{decimalFormat(totalesTabla.n, 2) || '0,00'}}</td>
                            <td>{{decimalFormat(totalesTabla.p, 2) || '0,00'}}</td>
                            <td>{{decimalFormat(totalesTabla.k, 2) || '0,00'}}</td>
                            <td *ngIf="showFertilizantesCa">
                                {{decimalFormat(totalesTabla.ca, 2) || '0,00'}}
                            </td>
                            <td *ngIf="showFertilizantesS">
                                {{decimalFormat(totalesTabla.s, 2) || '0,00'}}
                            </td>
                            <td *ngIf="showFertilizantesMg">
                                {{decimalFormat(totalesTabla.mg, 2) || '0,00'}}
                            </td>
                        </tr>

                </table>
        
                <!-- Si unidad/parcela... -->
                <table *ngIf="
                    form?.parcelas?.selected 
                    && udsByParcela" 
                    class="table-style center-content mr-2">
            
                    <!-- Nombre de Columnas de la Tabla -->
                    <tr style="background-color:silver">
                        <td style="width: 100px;">Parcela</td>
                        <td style="width: 100px;">Superficie&nbsp;{{textoSuperficie}}</td>
                        <td style="width: 80px;">N</td>
                        <td style="width: 80px;">P</td>
                        <td style="width: 80px;">K</td>
                        <td *ngIf="showFertilizantesCa" style="width: 80px;">Ca</td>
                        <td *ngIf="showFertilizantesS" style="width: 80px;">S</td>
                        <td *ngIf="showFertilizantesMg" style="width: 80px;">Mg</td>
                    </tr>

                    <!-- Valores de las Columnas  -->
                    <ng-container *ngFor="
                        let parcela of 
                        (form?.parcelas?.selected || []); 
                        let i = index">
                        
                        <tr *ngIf="udFertN[i]">
                            <td>{{parcela?.nombre}}</td>
                            <td>{{parcela?.superficie_cultivada 
                                || '0,00'}}
                            </td>
                            <td>{{decimalFormat(udFertN[i], 2) 
                                || '0,00'}}
                            </td>
                            <td>{{decimalFormat(udFertP[i], 2) 
                                || '0,00'}}
                            </td>
                            <td>{{decimalFormat(udFertK[i], 2) 
                                || '0,00'}}
                            </td>
                            <td *ngIf="showFertilizantesCa">
                                {{decimalFormat(udFertCa[i], 2) 
                                || '0,00'}}</td>
                            <td *ngIf="showFertilizantesS">
                                {{decimalFormat(udFertS[i], 2) 
                                || '0,00'}}
                            </td>
                            <td *ngIf="showFertilizantesMg">
                                {{decimalFormat(udFertMg[i], 2) 
                                || '0,00'}}
                            </td>
                        </tr>

                        <tr *ngIf="!udFertN[i]">
                            <td>{{parcela?.nombre}}</td>
                            <td>Sector sin superficie</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td *ngIf="showFertilizantesCa">-</td>
                            <td *ngIf="showFertilizantesS">-</td>
                            <td *ngIf="showFertilizantesMg">-</td>
                        </tr>
                    </ng-container>
                </table>                
            </div>
            <!-- Fin del BLOQUE N/P/K -->
        </div>
        <!--- Fin Información sobre los productos aplicados. --->
    </div>
    <!--- Fin de la Fila 2 --->
        
    <br>

    <!--- Fila 3 --->
    <div *ngIf="applicationType === 'cropgest'" class="row">
        
        <!-- Plan de Abonado -->
        <div class="col">
            
            <h4>Plan Abonado</h4>
            
            <app-form-builder
                    [fields]="form.formFieldsAbonado"
                    [distributionColumns]="6"
                    (formChanges)="formChanges($event)"
                    (selectAllcheck)="selectAll()"
                    [model]="model">
            </app-form-builder>
        </div>
    </div>

    <br>
    
    <!-- Gardengest -->
    <div *ngIf="this.appName==='donjardin' || this.appName==='coliberica'">
        
        <br>
        
        <!-- Fila Orden de Trabajo -->
        <div class="row">
            <!-- Textarea -->
            <div class="col-lg-6 col-md-12">
                <app-form-builder 
                    [model]="model" 
                    (formChanges)="formChanges($event);" 
                    [distributionColumns]="1"
                    [fields]="form.formFieldsOrdenTrabajo">
                </app-form-builder>
                
                <app-dictado
                    mensaje="Grabar orden de trabajo" 
                    [observaciones]="model.orden_trabajo ?? ''" 
                    (sendObservaciones)="getOrdenTrabajo($event)">
                </app-dictado>
            </div>

            <!-- Subida de Imágenes -->
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="tareas-camara">
                    <app-upload-images
                    [model]="model"
                    folder="fertilizantes"
                    tipo="form"
                    (sendImageName)="updateImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>   
        <!-- Fin Fila Orden de Trabajo -->

        <!-- Fila Observaciones -->
        <div class="row">
            <!-- Textarea -->
            <div class="col-lg-6 col-md-12">
                <app-form-builder
                    [model]="model"
                    (formChanges)="formChanges($event)"
                    [distributionColumns]="1" 
                    [fields]="form.formFieldsObs">
                </app-form-builder> 
                
                <app-dictado
                    mensaje="Grabar observaciones" 
                    [observaciones]="model.observaciones ?? ''" 
                    (sendObservaciones)="getObservaciones($event)">
                </app-dictado>
            </div>

            <!-- Subida de Imágenes -->
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="tareas-camara">
                    <app-upload-images 
                    [model]="model" 
                    folder="fertilizantes"
                    nombre_imagen = 'image_after' 
                    tipo="form" 
                    (sendImageName)="updateAfterImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>
        <!-- Fin Fila Observaciones -->

        <br>
        
        <!-- Firma -->
        <div 
            class="distribution_camera_signature" 
            style="display: flex;
                    gap: 50px;
                    align-items: center;" 
            *ngIf="this.model.signature_img">
            
            <div>
                <div *ngIf="this.model.signature_img" style="display: flex; flex-direction: column;">
                    
                    <p>Firma</p>
                    
                    <img 
                        *ngIf="!this.model.signature_img.includes('base64')" 
                        [src]="serverUrl + 
                                'images/fertilizantes/' +
                                this.model.signature_img" 
                        class="info-firma-img" alt="Firma">
                    
                    <img 
                        *ngIf="this.model.signature_img.includes('base64')" 
                        [src]="this.model.signature_img" 
                        class="info-firma-img" alt="Firma">
                </div>
            </div>

            <app-signature 
                (sendFile)="getSignature($event)" 
                (sendTempImg)="updateFirma($event);">
            </app-signature>
        </div>
        <!-- Fin Firma -->

        <div 
            class="distribution_camera_signature"  
            *ngIf="!this.model.signature_img">
            
            <app-signature 
                (sendFile)="getSignature($event)" 
                (sendTempImg)="updateFirma($event);">
            </app-signature>
        </div>
    </div>
    <!-- Fin Gardengest -->

    <!-- Botones del Formulario -->
    <div class="d-flex justify-content-center">
        <!-- Volver -->
        <div>
            <button 
                class="btn btn-dark mx-2" 
                (click)="goBack()">
                Volver
            </button>
        </div>

        <!-- Enviar -->
        <app-request-button
                (click)="submit()"
                [warning]="errorMessage"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
    <!-- Fin Botones del Formulario -->

</p-panel>

<br>
