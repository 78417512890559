<p-panel>
    <div class="d-flex justify-content-left align-content-center">
      <div>
        <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
        <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
      </div>
    </div>
    <div class="row">
      <div class="mb-5 col-sm-12">
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          (selectAllcheck)="selectAll()"
          [fields]="formFieldsPlaces"
          (formChanges)="formChanges()">
        </app-form-builder>
      </div>
    </div>
    <div class="row">
      <div class="mb-5 col-sm-12">
        <h5>Cubiertas Vegetales Espontáneas o Sembrada</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="4"
          (selectAllcheck)="selectAll()"
          [fields]="formFields"
          (formChanges)="formChanges()">
        </app-form-builder>
      </div>
    </div>
    <div class="row">
      <div class="mb-5 col-sm-12">
        <h5>Cubiertas Inertes (Restos Poda)</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          (selectAllcheck)="selectAll()"
          [fields]="formFieldsCubiertasInertes"
          (formChanges)="formChanges()">
        </app-form-builder>
      </div>
    </div>
    <div *ngIf="appName === 'sequiador' || appName === 'SIEX'" class="row">
      <div class="mb-5 col-sm-12">
        <h5>Arroz</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          (selectAllcheck)="selectAll()"
          [fields]="formFieldsArroz"
          (formChanges)="formChanges()">
        </app-form-builder>
        <app-dictado
        mensaje="Grabar observaciones" 
        [observaciones]="model.observaciones ?? ''" 
        (sendObservaciones)="getObservaciones($event)"
        ></app-dictado>
      </div>
    </div>
    
    <div class="d-flex justify-content-center">
      <div>
        <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
      </div>
      <app-request-button
              (click)="submit()"
              [lockOnSuccess]="true"
              [mode]="requestButtonType"
              [request]="request">
      </app-request-button>
    </div>
  </p-panel>