<br>

<div style="width: auto; overflow: auto">
  <app-common-list-table
          [request]="getRequest"
          [cols]="cols"
          [globalConditionalStyle]="globalConditionalStyle"
          [globalVisualTransform]="globalVisualTransform"
          [filtering]="filter"
          [actionButtonText]="canAddVentas ? btnAnadir : ''"
          (actionButtonClick)="add()"
          (emitSearchText)="updateFooter()">

          <ng-template #header>
            <div style="display: flex;">
              <div style="margin-left: 20px;">
                <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white;">
                    <i class="fa fa-download"></i>
                    Exportar listado
                </button>
              </div>
            </div>
            </ng-template>
    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
                [hideEdit]="this.appName === 'salf' && esUsuario"
                [showDuplicate]="true"
                (duplicateRowButtonEvent)="duplicate(data)"       
                (editRowButtonEvent)="edit(data)"
                (deleteRowButtonEvent)="show('delete', data)"
                (showRowButtonEvent)="show('info', data)">
        </app-action-buttons>
      </td>
    </ng-template>

    <ng-template #footer let-col>
        <div style="font-size: 0.75em; text-align: left" *ngIf="(['finca'].includes(col.field))">
            TOTAL:
        </div>
        <div style="font-size: 0.75em; text-align: right; font-weight: bold; padding-right: 34px" *ngIf="(['cantidad'].includes(col.field))">
            {{col.footer}}
        </div>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
  *ngIf="canShow['info']"
  header="Detalle del registro"
  [(visible)]="canShow['info']"
  (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3"
        [fields]="formFields">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>

<p-dialog
  *ngIf="canShow['delete']"
  header="Eliminar registro"
  [(visible)]="canShow['delete']"
  (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <app-form-builder
      [model]="model"
      [distributionOrder]="distributionOrder"
      [distributionColumns]="3"
      [fields]="formFields">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>
