import {ProductosModel} from '../../../../../models/productos/productos.model';
import {Filtering} from '../../../../../service/filtering/filtering';
import {ProductosApiService} from '../../../../../service/api/productos-api';
import {ClientesApiService} from '../../../../../service/api/clientes-api.service';
import {StorageManager} from '../../../../../common/storage-manager.class';
import {Utils} from '../../../../../common/utils';
import { environment } from '../../../../../../environments/environment';
import { ClienteModel } from 'src/app/models/cliente.model';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { list } from 'src/app/common/classes/request-builder';

export class TratamientosFormInitData {

    public showTratamientosAllProductos = environment.features.showTratamientosAllProductos;

    public clientes: TypeAheadInterface<ClienteModel> = list();
    public productos: TypeAheadInterface<ProductosModel> = list();
    public productosApi: ProductosApiService | undefined;
    public clientesApi: ClientesApiService | undefined;

    constructor() {}

    public start(
        clientes: TypeAheadInterface<ClienteModel>,
        productos: TypeAheadInterface<ProductosModel>,
        clientesApi: ClientesApiService | undefined,
        productosApi: ProductosApiService | undefined
    ): Promise<boolean> {
        this.clientes = clientes;
        this.productos = productos;
        this.clientesApi = clientesApi;
        this.productosApi = productosApi;

        return new Promise((resolve) => {
            this.getClientes().then(this.getProductos.bind(this)).then(resolve).catch(e => {
                console.log('catch en getClientes: ' + e);
            }
            );
        });
    }

    private getClientes(): Promise<boolean> {
        return new Promise((resolve) => {
            this.clientesApi?.clientesGET.response((value: ClienteModel[]) => {
                const user = StorageManager.getUser() as UserModel;

                this.clientes.selected = (value instanceof Array ? value : [])
                    .filter(it => it)
                    .find(it => it.id === (StorageManager.getClient() || {}).id) as ClienteModel;

                if (value) {
                    if ( environment.features.showTareasMto ) {
                        this.clientes.filtered = value
                            .filter(it => it)
                            .sort((a, b) => a.nombre.localeCompare(b.nombre))
                            .map(it => ({label: it.nombre || (it.razon_social ?? ''), value: it}));

                        resolve(true);
                    } else {
                        if (environment.appName === 'sequiador' && user.rol === 'admin') {
                            this.clientes.filtered = value
                                .map(it => ({label: it.nombre || (it.razon_social ?? ''), value: it}));
                        } else {
                            this.clientes.filtered = value
                                .filter(it => it)
                                .filter(it => [it.id_usuario_asignado, it.id_usuario_asignado2].includes(user.id))
                                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                                .map(it => ({label: it.nombre || (it.razon_social ?? ''), value: it}));
                        }

                        resolve(true);
                    }
                }
            });
            this.clientesApi?.clientesGET.safePerform();
        });
    }

    private getProductos(): Promise<boolean> {
        return new Promise((resolve) => {
            this.productosApi?.productos.GET.response((value: ProductosModel[]) => {
                if (value) {
                    this.productos.values = value
                        .filter(it =>
                            this.showTratamientosAllProductos ? it :  
                                (it.tipo === 'fitosanitario'  || it.tipo === 'fertilizante')
                        )
                        .map(it => {
                            it.nombre = (it.nombre || '').trim().toUpperCase();
                            return it;
                        })
                        .filter(
                            Filtering.distinctBy.bind({field: 'nombre'})
                        )
                        .map(it => {
                            const level = Utils.checkIfProductIsValid(it['fecha_caducidad'], it.tipo ?? '');
                            return ({label: level + ' ' + it.nombre, value: it});
                        });

                    this.productos.filtered = this.productos.values;

                    resolve(true);
                }
            });
            this.productosApi?.productos.GET.safePerform();
        });
    }
}
