import {TareasApiService} from '../../../../service/api/tareas-api.service';
import { Component  } from '@angular/core';
import { Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import moment, { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import {StorageManager} from '../../../../common/storage-manager.class';
import { createCsv } from 'src/app/common/createCsv';

import type { AfterViewInit, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaCespedModel } from 'src/app/models/tareas/tarea-cesped.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

const TRUTHY_VALUES = [1, '1', true, 'true'];
@Component({
    selector: 'app-tareas-mto-cesped',
    templateUrl: './tareas-mto-cesped.component.html',
    styleUrls: ['./tareas-mto-cesped.component.scss']
})
export class TareasMtoCespedComponent extends BaseView<TareaCespedModel> implements OnInit, AfterViewInit {

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;

    public appName = environment.appName;
    public serverUrl = environment.serverUrl;
    public fincaName = environment.features.fincaName; 
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
  (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));     

    public getRequest: RequestHandler<TareaCespedModel[]> = this.tareasApi.mtoCesped.GET;
    public deleteRequest: RequestHandler<TareaCespedModel> = this.tareasApi.mtoCesped.DELETE;
    public pathToForm = 'cesped-cavado';  
    public menuItemId = 'cesped-cavado';  

    public override filtering: Filtering<TareaCespedModel> = new Filtering<TareaCespedModel>();
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public override canShow: Record<string, boolean> = {};
    public override model: TareaCespedModel = {};
  
    public distributionOrder = [3];
    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'notas_cliente',
                label: 'Notas cliente',
                inputType: {type: InputType.TEXTAREA},
                visible: environment.features.showTareasMto ? true : false
            },
            {
                field: 'cliente_nombre', 
                name: 'Cliente', 
                label: 'Cliente', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha', 
                name: 'Fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.TEXT}, 
                required: true
            },
            {
                field: 'finca',
                name: this.fincaName,
                label: this.fincaName,
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'hora_inicio', 
                name: 'Hora Inicio', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TEXT} 
            },
            {
                field: 'hora_fin', 
                name: 'Hora Fin', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TEXT} 
            },
            {
                field: 'horas_totales', 
                name: 'Horas', 
                label: 'Horas', 
                inputType: {type: InputType.TEXT} 
            },
            {
                field: 'mantenimiento', 
                name: 'Mantenimiento/Creación', 
                label: 'Mantenimiento', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'prioridad', 
                name: 'Prioridad/Realizada', 
                label: 'Prioridad/Realizada', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'corte_desbroce', 
                name: 'Corte/Desbroce', 
                label: 'Corte/Desbroce', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },        
            {
                field: 'escarificado', 
                name: 'Escarificado', 
                label: 'Escarificado', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },            
            {
                field: 'aireado_pinchado', 
                name: 'Aireado/Pinchado', 
                label: 'Aireado/Pinchado', 
                inputType: {type: InputType.RO_MD_SWITCH}
            }, 
            {
                field: 'soplado', 
                name: 'Soplado', 
                label: 'Soplado', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },        
            {
                field: 'escarda_hierba', 
                name: 'Escarda hierba', 
                label: 'Escarda hierba', 
                inputType: {type: InputType.RO_MD_SWITCH}
            }
        ];        
    public formFieldsCavados: ItemInterface<object>[] = 
        [
            {
                field: 'parterres', 
                name: 'Parterres', 
                label: 'Parterres', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'arriates', 
                name: 'Arriates', 
                label: 'Arriates', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'jardineras', 
                name: 'Jardineras', 
                label: 'Jardineras', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
        ];

    public formFieldsRecebado: ItemInterface<object>[] = 
        [ 
            {
                field: 'sacas_una_tonelada', 
                name: 'Sacas (1 tonelada)', 
                label: 'Sacas ( 1 tonelada )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sacas_media_tonelada', 
                name: 'Sacas( 1/2 tonelada)', 
                label: 'Sacas ( 1/2 tonelada )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sacos_venticinco', 
                name: 'Sacas( 1/2 tonelada)', 
                label: 'Sacos ( 25Kg )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'kilos_granel', 
                name: 'Kilos a granel', 
                label: 'Kilos a granel', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sacos_ciemhus_litros', 
                name: 'Sacos Ciemhus ( 50l )', 
                label: 'Sacos Ciemhus ( 50l )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'recebado_portes', 
                name: 'Portes', 
                label: 'Portes', 
                inputType: {type: InputType.TEXT}
            }
        ]; 
    public formFieldsTVegetal: ItemInterface<object>[] = 
        [
            {
                field: 'vegetal_sacas_una_tonelada', 
                name: '[Vegetal] Sacas (1 tonelada)', 
                label: 'Sacas ( 1 tonelada )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'vegetal_sacas_media_tonelada', 
                name: '[Vegetal] Sacas (1/2 tonelada)', 
                label: 'Sacas ( 1/2 tonelada )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'vegetal_sacos_venticinco', 
                name: '[Vegetal] Sacos (25 kg)', 
                label: 'Sacos ( 25Kg )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'vegetal_kilos_granel', 
                name: '[Vegetal] Kilos a Granel', 
                label: 'Kilos a granel', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'vegetal_sacos_ciemhus_litros', 
                name: '[Vegetal] Sacos Ciemhus ( 50l )', 
                label: 'Sacos Ciemhus ( 50l )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'vegtal_portes', 
                name: '[Vegetal] Portes', 
                label: 'Portes', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTPreparada: ItemInterface<object>[] = 
        [
            {
                field: 'preparada_sacas_una_tonelada', 
                name: '[Preparada] Sacas ( 1 tonelada )', 
                label: 'Sacas ( 1 tonelada )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'preparada_sacas_media_tonelada', 
                name: '[Preparada] Sacas ( 1/2 tonelada)', 
                label: 'Sacas ( 1/2 tonelada )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'preparada_sacos_venticinco', 
                name: '[Preparada] Sacos ( 25kg)', 
                label: 'Sacos ( 25Kg )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'preparada_kilos_granel', 
                name: '[Preparada] Kilos a granel', 
                label: 'Kilos a granel', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'preparada_sacos_ciemhus_litros', 
                name: '[Preparada] Sacos Ciemhus ( 50l )', 
                label: 'Sacos Ciemhus ( 50l )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'preparada_portes', 
                name: '[Preparada] Portes', 
                label: 'Portes', 
                inputType: {type: InputType.TEXT},
            },
        ];

    public formFieldsTGravillada: ItemInterface<object>[] = 
        [            
            {
                field: 'garvillada_sacas_una_tonelada', 
                name: '[Garvillada] Sacas ( 1 tonelada )', 
                label: 'Sacas ( 1 tonelada )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'garvillada_sacas_media_tonelada', 
                name: '[Garvillada] Sacas ( 1/2 tonelada)', 
                label: 'Sacas ( 1/2 tonelada )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'garvillada_sacos_venticinco', 
                name: '[Garvillada] Sacos ( 25kg )', 
                label: 'Sacos ( 25Kg )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'garvillada_kilos_granel', 
                name: '[Garvillada] Kilos a granel', 
                label: 'Kilos a granel', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'garvillada_sacos_ciemhus_litros', 
                name: '[Garvillada] Sacos Ciemhus ( 50 l )', 
                label: 'Sacos Ciemhus ( 50l )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'garvillada_portes', 
                name: '[Garvillada] Portes', 
                label: 'Portes', 
                inputType: {type: InputType.TEXT} 
            },
        ];

    public formFieldsSiembraCesped: ItemInterface<object>[] = 
        [
            {
                field: 'siembra_cesped_kg', 
                name: '[Siembra] Cesped (Kg)', 
                label: 'Kg', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'siembra_cesped_gramos', 
                name: '[Siembra] Cesped (Gramos)', 
                label: 'Gramos', 
                inputType: {type: InputType.TEXT}
            }
        ];

    public formFieldsSiembraGrana: ItemInterface<object>[] =
        [
            {
                field: 'siembra_grana_kg', 
                name: '[Siembra] Grana (Kg)', 
                label: 'Kg', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'siembra_grana_gramos', 
                name: '[Siembra] Grana (Gramos)', 
                label: 'Gramos', 
                inputType: {type: InputType.TEXT}
            }
        ];

    public formFieldsSiembraDichondra: ItemInterface<object>[] = 
        [            
            {
                field: 'siembra_dichondra_kg', 
                name: '[Siembra] Dichondra - (Kg)', 
                label: 'Kg', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'siembra_dichondra_gramos', 
                name: '[Siembra] Dichondra (Gramos)', 
                label: 'Gramos', 
                inputType: {type: InputType.TEXT}
            }
        ];

    public formFieldsSustrato: ItemInterface<object>[] = 
        [            
            {
                field: 'sustrato_sacos_venticinco', 
                name: '[Sustrato] Sacos ( 25Kg )', 
                label: 'Sacos ( 25Kg )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sustrato_femabon', 
                name: '[Sustrato] Sacos Femabon ( 25l )', 
                label: 'Sacos Femabon ( 25l )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sustrato_ciemhus', 
                name: '[Sustrato] Sacos Ciemhus ( 70l )', 
                label: 'Sacos Ciemhus ( 70l )', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sustrato_acido', 
                name: '[Sustrato] Sacos sustrato ácido ( 20l )', 
                label: 'Sacos sustrato ácido ( 20l )', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'trabajadores_nombres', 
            name: 'Profesional(es)', 
            label: 'Profesional(es)', 
            inputType: {type: InputType.TEXTAREA}
        }, 
    ];
  
    public formFieldsOrdenTrabajo: ItemInterface<object>[] = 
        [  
            {
                field: 'orden_trabajo', 
                name: 'Orden de trabajo', 
                label: 'Orden de trabajo', 
                inputType: {type: InputType.TEXTAREA}
            }
        ];

    public formFieldsObs: ItemInterface<object>[] = 
        [  
            {
                field: 'observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];

    public cols: ColInterface[] = [
        {field: 'cliente_nombre', header: 'Cliente', filter: ''},
        {field: 'trabajadores_nombres', header: 'Empleado', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'fecha', header: 'Fecha', filter: ''},
        {field: 'hora_inicio', header: 'Hora Inicio', filter: ''},
        {field: 'hora_fin', header: 'Hora Fin', filter: ''},
        {field: 'horas_totales', header: 'Horas', filter: '', footer: ''},
        {field: 'prioridad', header: 'Prioridad/Realizada', filter: ''},
        {field: 'observaciones', header: 'Observaciones', filter: ''}
    ];  

    public prioridad = {
        selected: ''
    };

    public totalHoras: Moment | string = '00:00';
    public auxTotalHoras: Moment | string = '00:00';

    fecha: {inicio: string; fin: string} = {
        inicio: '',
        fin: '',
    };

    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.mtoCesped.GET, tareasApi.mtoCesped.DELETE);

    }

    ngOnInit() {

        if (environment.features.showTareasMto){
            if (this.rolUser !== 'admin'){
                this.hiddenButtonDelete = true;
            }
            this.prioridad.selected = 'No-Realizada';
        }

        this.dashboard.setSubmenuItem(this.menuItemId);
    
        this.filtering.addFilter((copy: TreeNode<TareaCespedModel>[] | void) => (copy as TreeNode<TareaCespedModel>[]).filter(row => {
        //console.log('row', row);
            switch (this.prioridad.selected) {
            case 'Realizada':
                return ['Realizada'].includes(row.data?.prioridad ?? '');
            case 'No-Realizada':
                return !['Realizada'].includes(row.data?.prioridad ?? '');
            default:
                return true;
            }
        }));
  
 
 
        /*** 
   *  En el caso de que el usuario no se admin,
   * filtrará por su id de usuario que coincide,
   *  con el id trabajador   
  */
        if ( StorageManager.getUser().rol !== 'admin' ){ 
            this.filtering.addFilter((copy: TreeNode<TareaCespedModel>[] | void) => (copy as TreeNode<TareaCespedModel>[]).filter(row => {
        
                if (row.data?.ids_trabajadores != null ) {
          
                    const idTrabajador = StorageManager.getUser().id;
                    const listaTrabajadores = (row.data?.ids_trabajadores as string).split(';');
                    if ( listaTrabajadores.includes(idTrabajador)) {
                        return true;
                    }
          
                }
                return false;
            }));

        }

        /*** 
   *  Fin de filtrar por id_usuario = trabajador,
   *   
  */
        this.filtering.addFilter((copy: TreeNode<TareaCespedModel>[] | void) => (copy as TreeNode<TareaCespedModel>[]).filter(row => {
      
            if ( this.fecha.inicio && this.fecha.fin && row.data?.fecha) {
          
                const arrayFecha = row.data.fecha.toString().split('/');
                const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];

                if (
                    (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux ) <= 0) && 
                    (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux ) >= 0)
                ) {
                    return true;
                }  else {
                    return false;
                }
            } else {
                return true;
            }
         
        }));
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-' + this.pathToForm]);
        }
    }
  
    public edit(data: TareaCespedModel) {
        //console.log('data',data);
      
        this.router.navigate(['dashboard', 'editar-cesped-cavado', data.id]);
    }
  
    public duplicate(data: TareaCespedModel) {
        this.router.navigate(['dashboard', 'duplicar-cesped-cavado', data.id]);
    }  

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }  

    public override show(modal: string, data: TareaCespedModel) {
        let x;
        this.formFields.forEach( form => {
            if ( form.field === 'mantenimiento' ){
                if (data.mantenimiento) {
                    form.label = 'Creación';
                } else {
                    form.label = 'Mantenimiento';
                }
            }
        });
        const trueFields = [
            'mantenimiento',
            'corte_desbroce',
            'escarificado',
            'aireado_pinchado',
            'soplado',
            'escarda_hierba',
            'parterres',
            'arriates',
            'jardineras',
        ];
    
        trueFields.forEach( f => {
            data[f] = TRUTHY_VALUES.includes(data[f]?.toString() ?? '');
        });
        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }  

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if ( col.field === 'horas_totales') {
            return {
                textAlign: 'center'
            };
        }
        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242',
                    }, 
                    textAlign: 'center'
                };
            }
            else if (value === 'Realizada'){
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },
                    textAlign: 'center'  
                };
            }
        }

        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        return {};
    }
  
    public globalVisualTransform(value: string, col: ColInterface) {
        const camposSiNo = [
            'corte_desbroce',
            'escarificado',
            'aireado_pinchado',
            'soplado',
            'escarda_hierba'
        ];

        if (camposSiNo.includes(col.field) ) {
            if ( TRUTHY_VALUES.includes(value) ) {
                return 'Sí';
            } else {
                return 'No';
            }
        }
        return value;
    }
  
    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }  
  
    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'prioridad') {
            this.prioridad.selected = this.prioridad.selected === value ? '' : value;
        }

        this.filtering.filter();
        this.updateFooter();
    }

    public override sum() {
        this.totalHoras = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHoras = moment(new Date('October 1, 2014 00:00:00'));
        let contador = 0;
        (this.filtering.value || []).forEach((tareas, index ) => {

            if ( tareas.data?.horas_totales != null ) {
   
                if (tareas.data.horas_totales.length > 0) {
                    contador++;
                    const horaMinuto = (tareas.data.horas_totales as string).split(':');
                    this.totalHoras = moment(this.totalHoras).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }

            if ( index + 1 >= (this.filtering.value as TareaCespedModel[]).length ){
                let minutos = moment(this.totalHoras).format('mm');
      
      
                let differencia = moment(this.totalHoras).diff(this.auxTotalHoras);
      
                if ( minutos.toString().length === 0) {
                    minutos = '00';
                }

                if ( minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }

                differencia = differencia / (1000 * 60 * 60);

                differencia = Math.abs(Math.floor(differencia));
     

                this.totalHoras = differencia + ':' + minutos;
      
                if ( contador === 0){
                    this.totalHoras = '00:00';
                }

            }
  
    
        });

        if ( contador === 0){
            this.totalHoras = '00:00';
        }
        this.filtering.filter();
    }

    public override updateFooter() {
        this.sum();
    }

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields,
            this.formFieldsCavados,
            this.formFieldsRecebado,
            this.formFieldsTVegetal,
            this.formFieldsTPreparada,
            this.formFieldsTGravillada,
            this.formFieldsSiembraCesped,
            this.formFieldsSiembraGrana,
            this.formFieldsSiembraDichondra,
            this.formFieldsSustrato,
            this.formFieldsTrabajadores,
            this.formFieldsOrdenTrabajo,
            this.formFieldsObs,
        ];

        const csvContent = createCsv(arrayFormFields, this.filtering.value, 'observaciones');

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Cesped cavado.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();


    }



    getFechaInicio( event: string) {
        this.fecha.inicio = event;
        this.filtering.filter();
        this.updateFooter();
  
    }
    getFechaFin( event: string) {
        this.fecha.fin = event;
        this.filtering.filter();
        this.updateFooter();
    }


    ngAfterViewInit() {
        this.tareasApi.mtoCesped.GET.response(() => {
            this.updateFooter();
            this.sum();
        });
    }

}
