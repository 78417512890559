import { environment } from './../../../../../environments/environment';
import {Component} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import type { OnInit } from '@angular/core';
@Component({
    selector: 'app-mapa-satelite',
    templateUrl: './mapa-satelite.component.html',
    styleUrls: ['./mapa-satelite.component.scss']
})
export class MapaSateliteComponent implements OnInit {

    public showCargando = false;
    public token = '';
    public thebluedotsURL: string | SafeResourceUrl = '';
    
    constructor(private http: HttpClient, private dom: DomSanitizer) { }

    async ngOnInit() { 

        if (environment.appName === 'agricar' || environment.appName === 'manezylozano' || environment.features.showMapaGraniot === true ) {
            //let token = await this.getIframeLogged('https://g8a078u1g6.execute-api.eu-west-1.amazonaws.com/app/api/log_in/');
            //console.log('token', token);
            
            const baseUrl = 'https://app.graniot.com/';
            this.thebluedotsURL = this.dom.bypassSecurityTrustResourceUrl(baseUrl);
        }
        else {
            const baseUrl = 'https://staging.thebluedots.io/embedded?lang=es&token=';
            this.token = await  this.cargarToken();
            this.thebluedotsURL = this.dom.bypassSecurityTrustResourceUrl(baseUrl + this.token);
        }

    }

    cargarToken(): Promise<string>{
        const urlApi = 'https://locatec.es/proyectos/fitogest/ws/mapas/get_token.php';

        return new  Promise ( ( resolve) => {
            this.http.get<{token: string}>(urlApi).subscribe((data: {token: string}) => {
                resolve(data.token);            
            });
        });
    }

    getIframeLogged(url: string){
        return new  Promise ( ( resolve) => {  
            const body = {
                'username': 'locatec',
                'password': 'lcgraniot2022'
            };
            const headers = new HttpHeaders();
            headers.append('Content-Type:' , 'application/json');
            this.http.post(url, body, {headers}).subscribe((data) => 
            {
                resolve(data);            
            });
        });
    }
}