import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { Utils } from 'src/app/common/utils';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { ControlSanitarioFormDefinition } from './form-control/form-definition';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { build, stack } from 'src/app/common/classes/request-builder';

import type { OnDestroy, OnInit } from '@angular/core';
import { ControlSanitarioModel } from 'src/app/models/tareas/control-sanitario.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';

@Component({
    selector: 'app-control-sanitario-form',
    templateUrl: './control-sanitario-form.component.html',
    styleUrls: ['./control-sanitario-form.component.scss']
})
export class ControlSanitarioFormComponent extends BaseForm<ControlSanitarioModel> implements OnInit, OnDestroy {

    public form: ControlSanitarioFormDefinition = new ControlSanitarioFormDefinition();
    public formRequest = new AppFormRequest<ControlSanitarioModel>();
    public override model: ControlSanitarioModel = {};

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public tareasApi: TareasApiService,
        public fincasApi: FincasApiService,              
        public override router: Router) {
        super(
            route,
            router,
            dashboard,
            tareasApi.controlSanitario.PUT,
            tareasApi.controlSanitario.POST,
            'control-sanitario',
            'Actualizar Control Sanitario',
            'Realizar Control Sanitario',
            'Duplicar Control Sanitario'
        );
    }



    ngOnInit() {
  
        this.formRequest.update();
        this.expandFormRequest();
        const fieldsToSend = (['id', 'id_cliente', 'id_usuario']).concat( this.form.formFields.map(it => it.field ?? ''));    

        this.formRequest
            .setType(this.getType())
            .isGeneric(true)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.tareasApi.controlSanitario.POST)
            .setPutRequest(this.tareasApi.controlSanitario.PUT)
            .setGetRequest(this.tareasApi.controlSanitario.GET)
            .setFormFields(this.form.formFields)
            .setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());
        this.form.requestType = this.getType();
        this.form.model = this.model;
    }  

    public formChanges() {
        this.formRequest.update();
    }

    public submit() {
        this.formRequest.send();
        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    public override goBack() {
        this.router.navigate(['dashboard', this.pathToGoBack]);
    }

    private initFormData(): Promise<unknown> {
        if (  this.getType() === 0 ) {
            this.fincasApi.fincasGET.toPromise().then( (resp_fincas: FincasModel[]) => {
     
                if (resp_fincas.length === 1 ) {
                    this.model.id_finca = resp_fincas[0]?.id ?? '';
                }
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
        return stack([
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true)
        ]).then(() => {
            setTimeout(() => {

                this.formRequest.update();
            }, 100);
            this.formRequest.update();
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }


    private expandFormRequest() {

        this.formRequest.beforeLoad(resolve => {
            this.initFormData().then(() => {
                resolve(true);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        });

        this.formRequest.beforeSend(resolve => {
            this.model.fecha_inicio = this.model.fecha_inicio
                ? Utils.formatDate(this.model.fecha_inicio)
                : this.model.fecha_inicio ?? '';

            this.model.fecha_fin = this.model.fecha_fin
                ? Utils.formatDate(this.model.fecha_fin)
                : this.model.fecha_fin ?? '';

            this.model.fecha_plazo = this.model.fecha_plazo
                ? Utils.formatDate(this.model.fecha_plazo)
                : this.model.fecha_plazo ?? '';

            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = StorageManager.getUser().id;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';            

            resolve(true);
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }  
}
