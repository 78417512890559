import { ActivatedRoute, Params } from '@angular/router';
import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import type { OnInit } from '@angular/core';

export const windOptions = ['off', 'strong', 'soft', 'dark', 'fast'];

@Component({
    selector: 'app-mapa-ventusky',
    templateUrl: './mapa-ventusky.component.html',
    styleUrls: ['./mapa-ventusky.component.scss']
})
export class MapaVentuskyComponent implements OnInit {

    public thebluedotsURL: string | SafeResourceUrl = '';


    constructor(
        private dom: DomSanitizer,
        private route: ActivatedRoute
    ) { }

    async ngOnInit() {
        this.route.queryParams.subscribe( params => {
           
            
            let coord = this.checkParams(params);
            if (coord == null || coord.length < 2 ) {
                coord = '?p=39.2;1.3;5&l=temperature-2m';
            }
            
            const baseUrl = 'https://www.ventusky.com/';

            this.thebluedotsURL = this.dom.bypassSecurityTrustResourceUrl(baseUrl + coord);
        });


    }

    checkParams(params: Params) {
        if (params['p'] != null) {
            const coord = params['p'].split(';');
            const w = this.windAnimation(params);
            if (coord.length === 3) {

                return '?p=' + params['p'] + w;
            }
            if (coord.length === 2) {
                return '?p=' + params['p'] + w;
            }
            return '';
        }
        return '';
    }

    windAnimation(params: Params) {
        if (params['w'] == null) {
            return '';
        } else {
            if (windOptions.includes(params['w'])) {
                return '&w=' + params['w'];
            }
            return '';
        }
    }

}