<span [ngSwitch]="showFormatHourMinute">
<p *ngSwitchCase="true">
    <app-fichajes-hours-minutes></app-fichajes-hours-minutes>
</p>
<p *ngSwitchCase="false">
    <app-fichajes-decimal></app-fichajes-decimal>
</p>

<p *ngSwitchDefault>

</p>
</span>