import { Component, Output, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';
import { ImageService } from 'src/app/service/images/images.service';

import type { AfterViewInit } from '@angular/core';

declare const $: any;

@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements AfterViewInit {

    @Output() sendCameraFile: EventEmitter<File> = new EventEmitter<File>();

    public showPhoto = false;
    public theStream: MediaStream = new MediaStream();
    public saveStatus = '';
    public visibleCamera = false;
    public loadingCamera = false;

    constructor(
        private router: Router,
        private imageService: ImageService
    ) {
    }


    ngAfterViewInit() {
        const intervalMedia = setInterval(() => {
            if ( document.querySelector('video') != null )
            {
                clearInterval(intervalMedia);
                this.getStream();
                this.loadingCamera = true;
            }
        }, 1000);
    }

    goBack() {
    // if (!RealizaVisitaComponent.ImagesToSend) {
        this.saveStatus = 'Guardando...';
        this.takePhoto().then(() => {
            this.router.navigate(['dashboard', 'realiza-visita']);
            return;
        }).catch((err: string) => alert('Error: ' + err));
    // } else {
    //   this.router.navigate(['dashboard', 'realiza-visita']);
    // }
    }

    goto(id: string) {
        this.router.navigate(['dashboard', id]);
    }

    getUserMedia(
        options: { video: { facingMode: string; }; 
        audio: boolean; 
    }, successCallBack: (stream: any) => void, failureCallback: (err: any) => void) {
        const api = (navigator as any).getUserMedia || (navigator as any).webkitGetUserMedia ||
      (navigator as any).mozGetUserMedia || (navigator as any).msGetUserMedia;
        if (api) {
            return api.bind(navigator)(options, successCallBack, failureCallback);
        }
    }

    getStream() {
        this.showPhoto = false;

        if (!(navigator as any).getUserMedia && !(navigator as any).webkitGetUserMedia &&
      !(navigator as any).mozGetUserMedia && !(navigator as any).msGetUserMedia) {
            alert('User Media API not supported.');
            return;
        }

        const constraints = {
            video: {
                facingMode: 'environment'
            },
            audio: false
        };

        this.getUserMedia(constraints, (stream: any) => {
            const mediaControl = document.querySelector('video');
            if (mediaControl && 'srcObject' in mediaControl) {
                mediaControl.srcObject = stream;
                try {
                    mediaControl.src = (window.URL || (window as any).webkitURL).createObjectURL(stream);
                } catch (e) {
                    console.log('No mediaControl.src');
                }

            } else if ((navigator as any).mozGetUserMedia) {
                (navigator as any).mozSrcObject = stream;
            }
            this.theStream = stream;
        }, function (err: string) {
            alert('Error: ' + err);
        });
    }

    takePhoto(): Promise<boolean> {
        return new Promise((resolve) => {
            this.showPhoto = true;
            if (!('ImageCapture' in window)) {
                alert('ImageCapture is not available');
                return;
            }

            if (!this.theStream) {
                alert('Grab the video stream first!');
                return;
            }

            const theImageCapturer = new (window as any).ImageCapture(this.theStream.getVideoTracks()[0]);

            theImageCapturer.takePhoto()
                .then((blob: Blob) => {
                    const theImageTag: any = (document.getElementById('imageTag'));
                    theImageTag.src = URL.createObjectURL(blob);

                    const reader = new FileReader();

                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        this.resizeBase64Img(reader.result, 800, 600)
                            .then((result: string) => {
                                const file = this.imageService.dataURLtoFile(result, 'photo.png');
                                this.sendCameraFile.emit(file);
                                resolve(true);
                                return;
                            }).catch((err: string) => alert('Error: ' + err));
                    };
                    return;
                })
                .catch((err: string) => alert('Error: ' + err));
        });
    }

    public scrollToTop() {
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }

    private resizeBase64Img(base64: string | ArrayBuffer | null, width: number, height: number) {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
        const deferred = $.Deferred();

        $('<img/>').attr('src', base64).load(function () {
            // @ts-expect-error La directiva this se refiere a otro contexto.
            context.scale(width / this.width, height / this.height);
            // @ts-expect-error La directiva this se refiere a otro contexto.
            context.drawImage(this, 0, 0);
            deferred.resolve(canvas.toDataURL());
        });

        return deferred.promise();
    }
}






