import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import {  list, stack } from '../../../../../common/classes/request-builder';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { Utils } from '../../../../../common/utils';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import { EntidadesApiService } from '../../../../../service/api/entidades-api.service';
import { ProductosApiService } from '../../../../../service/api/productos-api';
import { TransaccionesApiService } from '../../../../../service/api/transacciones-api.service';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { AppFormRequest } from '../../../../../view/app-common/form-request/app-form-request';
import { BaseForm } from '../../../../../view/base-form';
import { environment } from '../../../../../../environments/environment';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { TratamientosFormInitData } from '../../../7_tratamientos/tratamientos-form/form-control/form-initdata';
import { fillTipoTransaccion } from './tipo_transaccion';
import { build } from '../../../../../common/classes/request-builder';
import moment from 'moment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { TransaccionModel } from 'src/app/models/reto/transaccion.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { ClienteModel } from 'src/app/models/cliente.model';
import { EntidadModel } from 'src/app/models/reto/entidad.model';
import { ItemValuesInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { ProductosModel } from 'src/app/models/productos/productos.model';

@Component({
    selector: 'app-transacciones-form',
    templateUrl: './transacciones-form.component.html',
    styleUrls: ['./transacciones-form.component.scss']
})
export class TransaccionesFormComponent extends BaseForm<TransaccionModel> implements OnInit, OnDestroy {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<TransaccionModel>();


    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showReto = environment.features.showReto;

    public data: TratamientosFormInitData = new TratamientosFormInitData();


    public formRequest = new AppFormRequest<TransaccionModel>();
    public override model = {fecha: ''} as TransaccionModel;
    public error = '';

    public lista_productos: TypeAheadInterface<string> = list();
    public lista_entidades_origen: TypeAheadInterface<string> = list();
    public lista_ropo_origen = list();
    public lista_entidades_destino: TypeAheadInterface<ClienteModel | string> = list();
    public lista_clientes: TypeAheadInterface<ClienteModel> = list();
    public lista_tipo_transaccion = fillTipoTransaccion();
    public lista_ropo_destino = list();
    public formFields: ItemInterface<object>[] = [
        { 
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.CALENDAR}, 
            required: true 
        },
        {
            field: 'id_entidad_origen',
            label: 'Entidad responsable ',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.lista_entidades_origen,
            required: true

        },
        {
            field: 'ropo_origen',
            label: 'ROPO responsable',
            inputType: {type: InputType.DROPDOWN},
            values: this.lista_ropo_origen,
            required: true
        },
        {
            field: 'tipo_transaccion',
            label: 'Tipo transacción ',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.lista_tipo_transaccion,
            required: true
        },
        {
            field: 'id_entidad_destino',
            label: 'Entidad destino',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.lista_entidades_destino,
        },
        {   
            field: 'ropo_destino', 
            label: 'ROPO destino', 
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.lista_ropo_destino,
        },
       
       
    ];
    public formAlmacenFields: ItemInterface<object>[] = [
        { 
            field: 'descuadre', 
            label: 'Descuadre', 
            inputType: {type: InputType.SWITCH}
        },        
        { 
            field: 'almacen_origen', 
            label: 'Nº almacén responsable', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        { 
            field: 'almacen_destino', 
            label: 'Nº almacén de transacción', 
            inputType: {type: InputType.EDIT_NUMERIC}
        }
    ];

    public formProductoFields: ItemInterface<object>[] = [
        {
            field: 'id_producto',
            label: 'Producto ' ,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.lista_productos,
            required: true
        },
        { 
            field: 'num_envases', 
            label: 'Nº envases', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            required: true 
        },
        { 
            field: 'capacidad_envase', 
            label: 'Capacidad de envase', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            required: true 
        },
        { 
            field: 'volumen_total', 
            label: 'Volumen total', 
            inputType: {type: InputType.ONLY_TEXT}
        },
        { 
            field: 'lote', 
            label: 'Lote', 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: true 
        },
        { 
            field: 'importacion_paralela', 
            label: 'Importación paralela', 
            inputType: {type: InputType.SWITCH}
        },
    ];

    public formCultivoFields: ItemInterface<object>[] = [
        { 
            field: 'cultivo_tratamiento', 
            label: 'Objeto de tratamiento', 
            inputType: {type: InputType.EDIT_TEXT}
        },
    ];

    public formNIMAFields: ItemInterface<object>[] = [
        { 
            field: 'nima', 
            label: 'NIMA', 
            inputType: {type: InputType.EDIT_TEXT}
        }
    ];


    public lista_aux_productos: ProductosModel[] = [];
    public lista_aux_entidades_origen: EntidadModel[] = [];
    
    public showAlmacen = false;
    public showNIMA = false;
    public getRequest = (this.getType() === FormRequestTypes.DUPLICATE)
        ? this.transaccionesApi.TransaccionesGET_GENERIC
        : this.transaccionesApi.TransaccionesGET;
    public intervaloTipoTransacion: string | number | NodeJS.Timeout | undefined;
    
    constructor(
        public override router: Router,
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public transaccionesApi: TransaccionesApiService,
        private productosApi: ProductosApiService,
        public entidadesApi: EntidadesApiService,
        public clientesApi: ClientesApiService        

    ) {
        super(
            route,
            router,
            dashboard,
            transaccionesApi.TransaccionesPUT,
            transaccionesApi.TransaccionesPOST,
            'transacciones',
            'Editar ',
            'Crear ',
            'Duplicar '
        );
        
    }

    async ngOnInit() {
        this.initFormData();
        this.lista_aux_productos = await this.productosApi.productos.GET.toPromise() as ProductosModel[];
        this.lista_aux_entidades_origen = await this.entidadesApi.EntidadesGET.toPromise();
        this.fillProductos();
        this.fillEntidades();
        
        
        const fieldsToSend = (['id', 'id_usuario'] as string[])
            .concat(
                this.formFields.map(it => it.field ?? ''),
                this.formCultivoFields.map(it => it.field ?? ''),
                this.formAlmacenFields.map(it => it.field ?? ''),
                this.formProductoFields.map(it => it.field ?? '')
            );

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        this.expandFormFields();

        this.formRequest
            .setType(this.getType())
            .isGeneric(this.getType() === FormRequestTypes.DUPLICATE)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.getRequest)
            .setPostRequest(this.transaccionesApi.TransaccionesPOST)
            .setPutRequest(this.transaccionesApi.TransaccionesPUT)
            .setFormFields(this.formFields.concat(this.formCultivoFields).concat(this.formAlmacenFields).concat(this.formProductoFields))
            .setFieldsToSend(fieldsToSend);
        this.formRequest.afterLoad(resolve => {
            this.fillRopoOrigen();
            this.fillRopoDestino();
            this.checkAlmacen();
            this.checkProductAndFillUnits();
            resolve(true);
            if (this.showReto) {
              
                if (this.model.importacion_paralela !== '1' ) {
                    this.model.importacion_paralela = '';
                }
                
                this.model.importacion_paralela = this.model.importacion_paralela === '1';
                
            }
            if ( (this.getType() === FormRequestTypes.DUPLICATE) ) {
               
                if (this.appName === 'ctl') {
                    delete this.model.almacen_destino;
                    delete this.model.id;
                    delete this.model.almacen_origen;
                    delete this.model.cultivo_tratamiento;
                    delete this.model.id_usuario;
                    this.model.tipo_transaccion = null;
                    this.model.id_entidad_destino = null;
                    this.model.ropo_destino = null;
                    this.model.fecha = '';
                  
                } else {
                    delete this.model.almacen_destino;
                    delete this.model.id;
                    delete this.model.almacen_origen;
                    delete this.model.cultivo_tratamiento;  
                    delete this.model.id_usuario;
                    delete this.model.importacion_paralela;
                    delete this.model.unidades;
                    
                }
            }
            
        });
        this.formRequest.load();

        this.softInit(this.getType());
    }

    override ngOnDestroy(): void {
        clearInterval(this.intervaloTipoTransacion);   
    }

    public submit() {
        

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        const fechaFs =  moment(Utils.toDate(this.model.fecha ?? '')).format('YYYY-MM-DD').split('-');
        const diff2 = moment(
            new Date(2022, 9, 1)
        ).diff(new Date(parseInt(fechaFs[0] ?? '', 10), parseInt(fechaFs[1] ?? '', 10), parseInt(fechaFs[2] ?? '', 10)), 'days'); 
        if (diff2 > 0 && parseInt(this.model.tipo_transaccion as string, 10) < 3 )  {
            this.requestButton.error = 'Tipo de transacción obsoleta';
        }    
        this.formRequest.send();
    }

    public formChanges(tag: string) {

        if (tag === 'descuadre'){
            if (this.model.descuadre === true) {
                this.model.almacen_origen = '';
                this.model.almacen_destino = '';
            } 
        }

        if ( tag === 'almacen_origen') {
            if (this.model.almacen_origen !== '') {
                this.model.descuadre = false;
            }
        }
        if ( tag === 'almacen_destino') {
            if (this.model.almacen_destino !== '') {
                this.model.descuadre = false;
            }
        }
 
        if ( tag === 'id_entidad_origen') {
            this.model.ropo_origen = '';
            this.fillRopoOrigen();
        }

        if ( tag === 'id_entidad_destino') {
            this.model.ropo_destino = '';           
            this.fillRopoDestino();
        }
        
        if ( tag === 'tipo_transaccion') {
            this.model.ropo_destino = '';
            if ( this.model.tipo_transaccion !== 8 ) {

                delete this.model.cultivo_tratamiento;
                this.formFields.forEach( data => {
                    
                    if (data.field === 'ropo_destino') {
                        data.required = true;
                    }

                    if (data.field === 'id_entidad_destino') {
                        this.fillEntidades();
                        data.values = this.lista_entidades_destino;
                        delete data.valuePrimaryKey;                        
                    }                    
                }) ;
            }

            if ( this.model.tipo_transaccion === 8 ) {
                this.formFields.forEach( data => {
                    if (data.field === 'ropo_destino') {
                        data.required = false;
                    }

                    if (data.field === 'id_entidad_destino') {
                        if (environment.features.hasClients) {        
                            data.values = this.lista_clientes;
                            data.valuePrimaryKey = 'id';                       
                        }else{
                            this.fillEntidades();                        
                            data.values = this.lista_entidades_destino;
                        }
                    }
                });
            }

        }
        if ( tag === 'id_entidad_origen' || 
            tag === 'ropo_origen' || 
            tag === 'tipo_transaccion' || 
            tag === 'id_entidad_destino' || 
            tag === 'ropo_destino') {
            this.checkAlmacen();
        } 
        if ( tag === 'num_envases' ||  tag === 'capacidad_envase' ) {
            this.calculateVolumenTotal();
        } 
       
        if  (tag === 'id_producto') {
            this.checkProductAndFillUnits();
        }
        
       
    }

    public override goBack() {
        this.request.reset();
        this.router.navigate(['dashboard', 'reto-transacciones']);
    }

    public fillProductos() {
        for (const producto of this.lista_aux_productos) {
            if ( producto.tipo === 'fitosanitario') {
                this.lista_productos.filtered.push({ label: producto.nombre ?? '', 
                    value: producto.id ?? '', 
                    unidades: producto.unidades ? +producto.unidades : 0 });

            }

        }
    }

    public fillEntidades() {
        //this.lista_entidades_origen = list();
        this.lista_entidades_destino = list();
        const rol = StorageManager.getUser().rol ? StorageManager.getUser().rol : '';
        const idUser = StorageManager.getUser().id ? StorageManager.getUser().id : '';
        const numItems = this.lista_entidades_origen.filtered.length;
        for (const entidad of this.lista_aux_entidades_origen) {
            if ( rol === 'admin') {
                if ( numItems  < 2 ) {
                    this.lista_entidades_origen.filtered.push({ 
                        label: entidad.nombre, 
                        value: entidad.id ?? '' 
                    });
                }
                this.lista_entidades_destino.filtered.push({ 
                    label: entidad.nombre, 
                    value: entidad.id ?? '' 
                });
            } else {
                if ( idUser === entidad.id_usuario ) {
                    if ( numItems  < 2 ) {
                        this.lista_entidades_origen.filtered.push({ 
                            label: entidad.nombre, 
                            value: entidad.id ?? '' 
                        });
                    }
                    this.lista_entidades_destino.filtered.push({ 
                        label: entidad.nombre, 
                        value: entidad.id ?? ''
                    });
                }
            }
           
        }
    }

    public fillRopoOrigen() {
        this.lista_ropo_origen.filtered.splice(1, (this.lista_ropo_origen.filtered.length - 1));
       
        if (this.model.id_entidad_origen != null ) {

            for (const entidad of this.lista_aux_entidades_origen) {
                    
                if ( parseInt(entidad.id ?? '', 10) === parseInt( this.model.id_entidad_origen, 10) ) {

                    // Filtramos los que no tienen ropo, no añadimos líneas vacias 
                    if ( 
                        entidad.roposs != null && 
                        entidad.roposs.length > 1 
                    ){
                        this.lista_ropo_origen.filtered.push({ 
                            label: entidad.roposs, 
                            value: entidad.roposs 
                        });
                    }  
                    if ( 
                        entidad.ropost != null && 
                        entidad.ropost.length > 1 
                    ){
                        this.lista_ropo_origen.filtered.push({ 
                            label: entidad.ropost, 
                            value: entidad.ropost 
                        });
                    }
                    if ( 
                        entidad.roposu != null && 
                        entidad.roposu.length > 1 
                    ){
                        this.lista_ropo_origen.filtered.push({ 
                            label: entidad.roposu, 
                            value: entidad.roposu 
                        });
                    }
                          
                }                      
            }               
            // Si solo hay un valor en la lista, selecciónalo automáticamente.
            if (this.lista_ropo_origen.filtered.length === 2) {
                    
                this.model.ropo_origen = this.lista_ropo_origen.filtered[1].value;
            }                  
        }  
    }

    public fillRopoDestino() {     
        this.lista_ropo_destino.filtered.splice(1, (this.lista_ropo_destino.filtered.length - 1));
        if (this.model.id_entidad_destino != null ) {        
            if (this.model.tipo_transaccion !== 8 ){
                for (const entidad of this.lista_aux_entidades_origen) {
                    if ( 
                        parseInt(entidad.id ?? '', 10) === parseInt( this.model.id_entidad_destino , 10) 
                    ) {
                        if ( 
                            entidad.roposs != null && 
                            entidad.roposs.length > 1 
                        ){
                            this.lista_ropo_destino.filtered.push({ 
                                label: entidad.roposs, 
                                value: entidad.roposs 
                            });
                        }  
                        if ( 
                            entidad.ropost != null && 
                            entidad.ropost.length > 1 
                        ){
                            this.lista_ropo_destino.filtered.push({ 
                                label: entidad.ropost, 
                                value: entidad.ropost 
                            });
                        }
                        if ( 
                            entidad.roposu != null && 
                            entidad.roposu.length > 1 
                        ){
                            this.lista_ropo_destino.filtered.push({ 
                                label: entidad.roposu, 
                                value: entidad.roposu 
                            });
                        }
                    }
                }
                //this.lista_entidades_destino
                // Rellenamos tipo transacion cuando 
                if ( this.model.tipo_transaccion !== 8 ) {

                    delete this.model.cultivo_tratamiento;
                    this.formFields.forEach( data => {
                        
                        if (data.field === 'ropo_destino') {
                            data.required = true;
                        }
    
                        if (data.field === 'id_entidad_destino') {
                            this.fillEntidades();
                            data.values = this.lista_entidades_destino;
                            delete data.valuePrimaryKey;                        
                        }                    
                    });
                }                         
            } else  {                        
                if (environment.features.hasClients) {

                    this.lista_clientes.filtered.forEach ( cliente => {
                        if ( cliente.value != null) {
                            // console.log('cliente.value', cliente.value.id  );
                            if (cliente.value.id === this.model.id_entidad_destino) {
                                this.lista_ropo_destino.filtered.push(
                                    { 
                                        label: (cliente.value as ClienteModel).ropo, 
                                        value: (cliente.value as ClienteModel).ropo 
                                    }
                                );
                                this.lista_ropo_destino.selected = cliente.value;
                                
                            }
                        }
                        
                    });
                }else {                    
                    for (const entidad of this.lista_aux_entidades_origen) {
                        if ( 
                            parseInt(entidad.id ?? '', 10) === parseInt( this.model.id_entidad_destino , 10) 
                        ) {
                            if ( 
                                entidad.roposs != null && 
                                entidad.roposs.length > 1 
                            ){
                                this.lista_ropo_destino.filtered.push({ 
                                    label: entidad.roposs, 
                                    value: entidad.roposs 
                                });
                            }  
                            if ( 
                                entidad.ropost != null && 
                                entidad.ropost.length > 1 
                            ){
                                this.lista_ropo_destino.filtered.push({ 
                                    label: entidad.ropost, 
                                    value: entidad.ropost 
                                });
                            }
                            if ( 
                                entidad.roposu != null && 
                                entidad.roposu.length > 1 
                            ){
                                this.lista_ropo_destino.filtered.push({ 
                                    label: entidad.roposu, 
                                    value: entidad.roposu 
                                });
                            }
                        }
                    }            
                }     
            }
            // Si solo hay un valor en la lista, selecciónalo automáticamente.
            if (this.lista_ropo_destino.filtered.length === 2) {        
                this.model.ropo_destino = this.lista_ropo_destino.filtered[1].value;
            }     
        }
    }

    public checkAlmacen() {
        /**
         id_entidad_origen
         ropo_origen
         tipo_transaccion
         id_entidad_destino
         ropo_destino
         */
        if ( 
            this.model.tipo_transaccion != null &&  
            (this.model.tipo_transaccion === '11' || this.model.tipo_transaccion === '12') 
        ) {
            if ( this.model.id_entidad_origen != null && this.model.id_entidad_destino != null ) {
                if ( this.model.ropo_origen != null && this.model.ropo_destino != null ) {
                    if (  
                        (this.model.id_entidad_origen  === this.model.id_entidad_destino )  && 
                        (this.model.ropo_origen  === this.model.ropo_destino ) 
                    ) {
                        this.showAlmacen = true;
                    } else {
                        this.deleteAlmacen();
                        this.showAlmacen = false;
                    }
                } else {
                    this.deleteAlmacen();
                    this.showAlmacen = false;
                }
            } else {
                this.deleteAlmacen();
                this.showAlmacen = false;
            }
        } else {
            this.deleteAlmacen();
            this.showAlmacen = false;
        }

        this.showNIMA = (this.model.tipo_transaccion === '9' || this.model.tipo_transaccion === '10') ? true : false;



    }

    public deleteAlmacen() {
        delete this.model.almacen_origen;
        delete this.model.almacen_destino;
    }

    public checkProductAndFillUnits() {
       
        
        if ( 
            (this.lista_productos.selected != null && 
                Object.keys(this.lista_productos.selected).length > 0 ) || this.model.id_producto != null 
        ) {
            const prod = this.lista_productos.filtered.find( (producto) => {
                if (this.model.id_producto === producto.value ){
                    return producto;
                }
                return false;
            });
          
            this.model.unidades = prod?.unidades?.toString() ?? '';
        }
       
        // console.log('this.model', this.lista_productos.filtered );
        
    }

    public calculateVolumenTotal() {
        /**
         num_envases
         capacidad_envase
         */
        if ( this.model.num_envases != null && this.model.capacidad_envase != null)
        {
            const numEnvases = parseFloat( this.model.num_envases);
            const capacidadEnvase = parseFloat( this.model.capacidad_envase );

            const resultado = (numEnvases * capacidadEnvase).toFixed(2);
            this.model.volumen_total = resultado;

        } else {
            this.model.volumen_total = '';
        }
    }

    private expandFormFields() {
        this.formRequest.beforeSend(resolve => {
            this.model.fecha = this.model.fecha
                ? Utils.formatDate(this.model.fecha)
                : this.model.fecha ?? '';
            resolve(true);
        });
    }
    private initFormData(): Promise<boolean> {
       

        
        this.formRequest.update();
        return stack([
            build(this.clientesApi.clientesGET, this.lista_clientes, 'nombre')
        ]).then(() => {

            if ( (this.getType() === FormRequestTypes.EDIT) ) {
       
                this.intervaloTipoTransacion = setInterval( () => {
                    if ( this.model.id != null ) {
                        if ( this.model.tipo_transaccion === 8 ) {
                            (this.lista_entidades_destino as TypeAheadInterface<ClienteModel>) = {...this.lista_clientes};
                            this.formFields.forEach( data => {
                                if (data.field === 'ropo_destino') {
                                    data.required = false;
                                }
                                if (data.field === 'id_entidad_destino') {
                                    this.fillEntidades();
                                    data.values = this.lista_entidades_destino;
                                    data.valuePrimaryKey = 'id';
                                    (data.values as ItemValuesInterface<object>).data = 
                                        (data.values as ItemValuesInterface<object>).filtered;

                                    (data.values as ItemValuesInterface<[{value: string}]>).filtered.forEach((element) => {
                                        if ((element as {value: string}).value === this.model.id_entidad_destino) {
                                            (data.values as ItemValuesInterface<string>).selected = (element as {value: string}).value;
                                        }
                                    });
                                    this.fillRopoDestino();
                                }
                            });
                        } 
                        clearInterval(this.intervaloTipoTransacion);  
                        this.formRequest.update();                            
                    }
                }, 1000);     
            }  
            return true;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
            return false;
        }
        );
    }
}