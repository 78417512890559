import { ItemValuesInterface } from 'src/app/common/components/form-builder/form-builder.component';
import {AppFormRequest} from '../app-form-request';

export class AppFormRequestCreate<T> {

    private form: AppFormRequest<T>;

    constructor(form: AppFormRequest<T>) {
        this.form = form;
        this.init();
    }

    private init() {
        
        this.form.onUpdate(resolve => {
            (this.form.model as Record<string, number>)['@_update_@'] = 0;

            setTimeout(() => {
                delete (this.form.model as Record<string,number>)['@_update_@'];
            }, 10);

            resolve(true);
        });

        this.form.beforeSend(resolve => {
            delete (this.form.model as Record<string,string>)['id'];
            resolve(true);
        });

        this.form.beforeSend(resolve => {
            if (this.form.fieldsToSend) {
                for (const x in this.form.model) {
                    if (this.form.model[x]) {
                        let founded = false;

                        this.form.fieldsToSend.forEach(field => {
                            if (field === x) {
                                founded = true;
                            }
                        });

                        if (!founded) {
                            delete this.form.model[x];
                        }
                    }
                }
            }

            resolve(true);
        });

        this.form.onSend(resolve => {
            this.form.requestPOST.safePerform(this.form.model as T[]);
            this.form.requestPOST.response(() => {
                resolve(true);
            });
        });

        this.form.afterSend(resolve => {
            if (!this.form.requestPOST.hasError) {
                for (const x in this.form.model) {
                    if (this.form.model[x] && !this.form.fieldsToRetain.includes(x)) {
                        delete this.form.model[x];
                    }
                }

                (this.form.formFields || []).forEach(field => {
                    if (field.values && !this.form.fieldsToRetain.includes(field.field ?? '')) {
                        (field.values as ItemValuesInterface<object>).selected = null;
                    }
                });
            }

            resolve(true);
        });

        this.form.onFinish(resolve => {
            setTimeout(() => {
                this.form.requestPOST.unsuscribe();
            }, 1000);
            resolve(true);
        });

    }
}
