import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../../base-form';
import {VisitasApiService} from '../../../../../service/api/visitas-api.service';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {build, list, pool} from '../../../../../common/classes/request-builder';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { VisitasSemanaModel } from 'src/app/models/visitas/visitas-semana.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { UserModel } from 'src/app/models/usuarios/user.model';

@Component({
    selector: 'app-visitas-semana-form',
    templateUrl: './visitas-semana-form.component.html',
    styleUrls: ['./visitas-semana-form.component.scss']
})
export class VisitasSemanaFormComponent extends BaseForm<VisitasSemanaModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: false, static: false })
        requestButton = new RequestButtonComponent<VisitasSemanaModel>();

    public override model: VisitasSemanaModel = {semana: 0};
    public formRequest = new AppFormRequest<VisitasSemanaModel>();

    public fincas = list(['...', null]);
    public parcelas = list(['...', null]);
    public semanas: TypeAheadInterface<number> = list(['...', null],
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
        37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54
    );
    public anos: TypeAheadInterface<number> = list(['...', null],
        2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030
    );
    public estados = list(['...', null], ...[
        'Araña roja', 'Trip', 'Empoasca', 'Pulgón', 'Pulgón de la madera', 'Cotonet', 'Mosca blanca',
        'Piojo san José', 'Típula', 'Otiorrinco', 'Caparreta', 'Mosquito del ciruelo', 'Acaro blanco',
        'Eriófidos', 'Orugas', 'Ceratitits', 'Escarabajo flor', 'Escarabajo como flor', 'Barrenillo del almendro',
        'Sciara', 'Arañuelo', 'Minador', 'Cabezudo o Psila', 'Caracol/Babosa', 'Anarsia', 'Molesta',
        'Fauna auxiliar', 'Oidio', 'Roya', 'Botritis', 'Repilo', 'Bolladura', 'Apomyelois', 'Cryptoblabes', 'Scatella'
    ].sort().map(it => [it, it]));
    public umbral = list(
        ['...', null],
        '< 3 Ind/brote',
        '> 3 Ind/brote',
        '% Elevado árboles afectados',
        '% Medio árboles afectados',
        '% Bajo árboles afectados',
        'Fruta atacada'
    );

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Agrupación',
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            required: true
        },
        {
            label: 'Subparcela',
            field: 'id_parcela',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            valuePrimaryKey: 'id',
            filter: (it: { value: ParcelasModel; }) => {
                const parcela = it.value;
                if (this.fincas.selected && parcela) {
                    return this.fincas.selected.id === parcela.id_finca;
                }
                return true;
            },
            values: this.parcelas,
            required: true
        },
        {
            field: 'semana',
            label: 'Semana',
            inputType: {type: InputType.DROPDOWN},
            values: this.semanas,
        },
        {
            field: 'ano',
            label: 'Año',
            inputType: {type: InputType.DROPDOWN},
            values: this.anos,
        },
        {
            label: 'Plaga #1',
            field: 'plaga1',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.estados))
        },
        {
            label: 'Umbral #1',
            field: 'umbral1',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.umbral))
        },
        {
            label: 'Cantidad #1',
            field: 'cantidad1',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Observaciones #1',
            field: 'observaciones1',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
    
        {
            label: 'Plaga #2',
            field: 'plaga2',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.estados))
        },
        {
            label: 'Umbral #2',
            field: 'umbral2',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.umbral))
        },
        {
            label: 'Cantidad #2',
            field: 'cantidad2',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Observaciones #2',
            field: 'observaciones2',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
    
        {
            label: 'Plaga #3',
            field: 'plaga3',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.estados))
        },
        {
            label: 'Umbral #3',
            field: 'umbral3',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.umbral))
        },
        {
            label: 'Cantidad #3',
            field: 'cantidad3',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Observaciones #3',
            field: 'observaciones3',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
    
        {
            label: 'Plaga #4',
            field: 'plaga4',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.estados))
        },
        {
            label: 'Umbral #4',
            field: 'umbral4',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.umbral))
        },
        {
            label: 'Cantidad #4',
            field: 'cantidad4',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Observaciones #4',
            field: 'observaciones4',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
        {
            label: 'Plaga #5',
            field: 'plaga5',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.estados))
        },
        {
            label: 'Umbral #5',
            field: 'umbral5',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.umbral))
        },
        {
            label: 'Cantidad #5',
            field: 'cantidad5',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Observaciones #5',
            field: 'observaciones5',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
        {
            label: 'Plaga #6',
            field: 'plaga6',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.estados))
        },
        {
            label: 'Umbral #6',
            field: 'umbral6',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.umbral))
        },
        {
            label: 'Cantidad #6',
            field: 'cantidad6',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Observaciones #6',
            field: 'observaciones6',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
    ];


    constructor(
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public visitasApi: VisitasApiService,
        public fincasApi: FincasApiService,
        public formApi: FormCommonApiService,
        public override router: Router
    ) {
        super(
            route,
            router,
            dashboard,
            visitasApi.visitasSemanaPUT,
            visitasApi.visitasSemanaPOST,
            'visitas-semana',
            'Actualizar Visita',
            'Crear Visita'
        );
    }

    ngOnInit() {
        this.initFormData();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(true)
            .setGetRequest(this.visitasApi.visitasSemanaGET)
            .setPostRequest(this.visitasApi.visitasSemanaPOST)
            .setPutRequest(this.visitasApi.visitasSemanaPUT)
            .setFormFields(this.formFields)
            .setFieldsToSend((['id'] as string[])
                .concat(
                    this.formFields.map(it => it.field ?? '')
                )
            );

        this.formRequest.afterLoad(resolve => {
            if (this.model.semana) {
                this.model.semana = parseInt(this.model.semana.toString(), 10);
            }
            resolve(true);
        });
        this.formRequest.beforeSend(resolve => {
            const clientId = (StorageManager.getClient() as ClienteModel).id;
            const clientIdFromFinca = (this.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model['id_cliente'];

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {} as UserModel).id;
            const userIdFromFinca = (this.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model['id_usuario'];

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            resolve(true);
        });

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public formChanges(tag: string) {
        if (tag === 'id_finca') {
            this.model.id_parcela = null;
        }

        this.requestButton.error = '';
    }


    private initFormData() {
        pool([
            build(this.fincasApi.fincasGET, this.fincas, 'nombre'),
            build(this.fincasApi.parcelasGET, this.parcelas, 'nombre', true)
        ], () => {
            this.formRequest.update();
        });
    }

}
