export class MenuItemModel {

    id = '';
    icon = '';
    text = '';
    visible?: boolean;
    isSelected = false;
    expandable?: ExpandableItem[];
    
}

export class ExpandableItem {
    id = '';
    text = '';
    subtitulo?: string;
    fatherId = '';
    father?: MenuItemModel;
    isSelected = false;
    visible?: boolean;
}