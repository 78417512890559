import { environment } from './../../../../../environments/environment';
import { FincasModel } from './../../../../models/form-common/fincas.module';
import { SectorModel } from './../../../../models/form-common/sector.model';
import { ParcelasModel } from './../../../../models/form-common/parcelas.model';
import { FullMapModel } from './../../../../models/form-common/full-map.model';
import { SigPACModel } from './../../../../models/form-common/sigpac.model';
import { FincasApiService } from './../../../../service/api/fincas-api.service';
import { CamposApiService } from './../../../../service/api/campos-api.service';
import { MapaParcelasApiService } from './../../../../service/api/mapa-parcelas-api.service';
import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { ViewChild, OnDestroy } from '@angular/core';
import { Filtering } from '../../../../service/filtering/filtering';
import { FormCommonApiService } from '../../../../service/api/formcommon-api.service';
// @ts-ignore
import google from '@google/maps';
import { StorageManager } from '../../../../common/storage-manager.class';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import type { OnInit } from '@angular/core';
import type { Observer } from 'rxjs';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';

declare const google: any;

@Component({
    selector: 'app-mapa-rutas',
    templateUrl: './mapa-rutas.component.html',
    styleUrls: ['./mapa-rutas.component.scss']
})
export class MapaRutasComponent implements OnInit, OnDestroy {

    @ViewChild('gmap', {read: ElementRef, static: true}) gmapElement: ElementRef | undefined;
    @ViewChild('divSelectFincas', {read: ElementRef, static: true}) divSelectFincas: ElementRef | undefined;
    @ViewChild('divSearchMap', {read: ElementRef, static: true}) divSearchMap: ElementRef | undefined;
    @ViewChild('searchMap', {read: ElementRef, static: true}) searchMap: ElementRef | undefined;  
    @ViewChild('divNuevaParcela', {read: ElementRef, static: true}) divNuevaParcela: ElementRef | undefined;
    @ViewChild('divGuardarCambios', {read: false, static: false}) divGuardarCambios: ElementRef | undefined;
    @ViewChild('divCancelarEdicion', {read: false, static: false}) divCancelarEdicion: ElementRef | undefined;
    @ViewChild('divCancelaParcela', {read: false, static: false}) divCancelaParcela: ElementRef | undefined;

    @Input() origin = '';
    @Input() finca = '';
    @Input() localidad = '';
    @Input() cultivo = '';
    @Input() variedad = '';
    @Input() parcelaNombre = '';

    @Output() fromMapa = new EventEmitter<any>();

    map: google.maps.Map = null;

    geocoder: google.maps.Geocoder = null;

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public parcelaName = environment.features.parcelaName;
    public showMeteoBtn = environment.features.showMeteoBtn;
    public hasClients = environment.features.hasClients;

    public arrayFincas: any[] = [];
    public arraySectores: SectorModel[] = [];
    public arrayParcelas: ParcelasModel[] = [];
    public arrayCultivos: any[] = [];
    public arrayCoord = [];
    public parcelaSelectedid = '';

    public showMapaLabels = environment.features.showMapaLabels;
    public markerLabels: any = [];


    public obs1: Observable<any> | undefined;
    public obs2: Observable<any> | undefined;
    public obs3: Observable<any> | undefined;

    public arrayVariedades: VisitasVariedadMesModel[] = [];

    public arrayMapaParcelas: {
        id: string;
        id_parcela: string;
        id_finca: string;
        coordenadas: string;
        color: string;
        id_usuario: string;
    }[] = [];

    public sigPAC: SigPACModel = new SigPACModel();

    public strFinca = '';
    public fincaSelected = '';
    public fincaSelectedId = '';

    public strCultivos = '';
    public cultivoSelected = '';
    public cultivoSelectedId = '';

    public strVariedades = '';
    public variedadSelected = '';
    public variedadSelectedId = '';

    //public fincasSelect: SafeHtml;
    public showCargando = false;
    public contentString = '';
    public infowindow: any = null;
    public infoWindOpened = false;
    public markerInfoWindOpened = false;
    public numPlantas = '';

    public oldmap: any = null;
    public arrayMarkers: any[] = [];
    public markerInfowindow: any = null;
    public markerClicked: any = null;

    //Estilos para los poligonos
    public estilo_hover = { fillColor: 'blue', strokeWeight: 3, strokeColor: 'black' };
    public estilo_poligono_clic = { fillColor: '#ff8000', strokeWeight: 5, strokeColor: '#282828' };

    public poly_btn = null;

    //Zoom a partir del que se cargan las parcelas de catastro
    public zoomCatastro = 16;

    //Lista de poligonos cargados en el mapa
    public idsPoligonos = [];

    //Lista de poligonos que se han hecho clic dentro de la misma sesión
    public idsPoligonosClic: any[] = [];

    public arrayFincasClicked: any[] = [''];

    public markers: any = [];
    public bounds: any = new google.maps.LatLngBounds();

    public coordenadas_locatec = '';

    public miJSON: any = {};
    public parcelaObj: any = [];
    public selectedShape: any;
    public drawingManager: google.maps.drawing.DrawingManager;

    public showSigpac = false;
    public creatingParcel = false;

    public loadCount = 0;
    public arrayFullMapObj: FullMapModel[] = [];

    public lastFinca = '';
    public lastLatLng = '';
    public lastColor = '';
    public lastZoom = 0;
    public user = '';
    public showWeather = false;
    // public showMeteoBtn: boolean = (environment.appName == 'manezylozano' || environment.appName == 'fitogest') ? true : false;
    public routes: any = [];

    constructor(private router: Router,
        private dashboardEvent: DashboardService,
        private formApi: FormCommonApiService,
        private fincasApi: FincasApiService,
        private camposApi: CamposApiService,
        private mapaParcelasApi: MapaParcelasApiService) { }

    /**
  * Styles an element as a button
  */
    public buttonize = (
        btn: { 
            type: string; 
            setAttribute: (arg0: string, arg1: string) => void; 
            innerHTML: string; 
            addEventListener: (arg0: string, arg1: { (): void; (): void; }) => void; 
            style: { background: string; }; 
        }) => {
    // Set CSS for the control border.
        btn.type = 'button';
        btn.setAttribute('style', `
      background-color: white;
      cursor: pointer;
      padding: 0px 8px;
      margin: 5px;
      margin-left: -5px;
      border-radius: 2px;
      color:black;
      font-size: 2.2em;
      border:none;
    `);
        btn.innerHTML = '+';

        btn.addEventListener('mouseover', () => {
            btn.style.background = 'grey';
        });
        btn.addEventListener('mouseout', () => {
            btn.style.background = 'white';
        });
    };

    ngOnInit() {


        if (this.router && this.router.url === '/dashboard/mapas-rutas') {
            this.dashboardEvent.setMenuItem('mapas-rutas');
        }

        this.lastFinca = StorageManager.getLastFincaMap();
        this.lastLatLng = StorageManager.getLastLatLng();
        this.lastColor = StorageManager.getLastColorMap();
        this.lastZoom = StorageManager.getLastZoom();
        this.user = StorageManager.getUser().nombre;

        if (this.origin !== 'parcelas-form') { this.finca = this.lastFinca; this.fincaSelected = this.finca; }

        this.showCargando = true;

        if (this.origin === 'parcelas-form') {
            this.cultivoSelected = this.cultivo;
            this.variedadSelected = this.variedad;
            this.fincaSelected = this.finca;
        }

        //Try to get GPS device position
        this.getPosition();

        Promise.all([
            this.getFincas(),
            this.getParcelas(),
            this.getCultivos(),
            this.getVariedades(),
        ]).then(() => this.getMapaParcelas()).then(() => {
            this.getFullMapObject(false);
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

        this.initializeMap();
    }


    ngOnDestroy() {
        this.fincasApi.fincasGET.unsuscribe();
        this.fincasApi.sectoresGET.unsuscribe();
        this.fincasApi.parcelasGET.unsuscribe();
        this.formApi.cultivosGET.unsuscribe();
        this.formApi.variedadesGET.unsuscribe();
        this.mapaParcelasApi.mapaParcelasGET.unsuscribe();
    }


    initializeMap() {

        let lat: number;
        let lng: number;
        try {
            lat = parseFloat(this.lastLatLng.split(',')[0] ?? '');
            lng = parseFloat(this.lastLatLng.split(',')[1] ?? '');
        }
        catch (err) {
            console.log(err);
            lat = 38.991071;
            lng = -0.792838;
            this.lastZoom = 6;
            this.showCargando = false;
        }

        //this.getWeather(lat.toString(),lng.toString());

        this.map = new google.maps.Map(this.gmapElement?.nativeElement, {
            zoom: this.lastZoom,
            center: new google.maps.LatLng(lat, lng),
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            mapTypeId: google.maps.MapTypeId.HYBRID,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'hybrid'],
                position: google.maps.ControlPosition.TOP_RIGHT,
                labels: true
            },
            disableDefaultUI: false,
            fullscreenControl: false,
            streetViewControl: false,
            tilt: 0 // para que no se muestre el mapa con 45º
        });


        //Bloque para añadir mapa de forma manual

        //Boton para activar edicion de mapas
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.divSelectFincas?.nativeElement);

        this.cargaMapListeners();


        ///// BUSCADOR DE LOCALIDADES ///////
        // Create the search box and link it to the UI element.
        const divInput = this.divSearchMap?.nativeElement;
        const input = this.searchMap?.nativeElement;
        const searchBox = new google.maps.places.SearchBox(input);
        const autocomplete = new google.maps.places.Autocomplete(input);
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(divInput);
        autocomplete.setComponentRestrictions({ 'country': ['es'] });

        const map = this.map;

        searchBox.addListener('places_changed', function () {
            searchBox.set('map', null);
            const places = searchBox.getPlaces();
            if (places.length === 0) { return; }
            const boundss = new google.maps.LatLngBounds();

            places.forEach(function (place: { geometry: { viewport: string; location: string; }; }) {
                if (!place.geometry) {
                    console.log('Returned place contains no geometry');
                    return;
                }

                if (place.geometry.viewport) { boundss.union(place.geometry.viewport); }
                else { boundss.extend(place.geometry.location); }
            });

            try { map.fitBounds(boundss); }
            catch (e) { console.log('catch en fitbounds: ' + e); }

            lat = boundss.getCenter().lat();
            lng = boundss.getCenter().lng();
            StorageManager.saveLastLatLng(lat + ',' + lng);
        });

        autocomplete.addListener('place_changed', function () {
            searchBox.set('map', null);
            const place = autocomplete.getPlace();
            const boundss = new google.maps.LatLngBounds();

            if (!place.geometry) {
                console.log('Returned place contains no geometry');
                return;
            }

            if (place.geometry.viewport) { boundss.union(place.geometry.viewport); }
            else { boundss.extend(place.geometry.location); }

            try { map.fitBounds(boundss); }
            catch (e) { console.log('catch en fitbounds: ' + e); }

            lat = boundss.getCenter().lat();
            lng = boundss.getCenter().lng();
            StorageManager.saveLastLatLng(lat + ',' + lng);
        });

        //BOTON NUEVA PARCELA
        const divNueva = this.divNuevaParcela?.nativeElement;
        if (this.appName !== 'innovia') {this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(divNueva);}



        //Evento click del MAPA
        google.maps.event.addListener(map, 'click', (event: { latLng: any; }) => {

            const marker = new google.maps.Marker({
                position: event.latLng,        
                map: map
            });
      
            google.maps.event.addListener(marker, 'click', () => {

                //Comprueba si infowindow abierto
                try {
                    if (this.markerInfowindow.getMap() == null) { this.markerInfoWindOpened = false; }
                    this.markerInfowindow.close();
                }
                catch (err) { console.log(err); }

                const contentwindow = '<div><input type=\'button\' id=\'elimMarker\' class=\'btn btn-danger\' value=\'Eliminar\'></div>';
                this.markerInfowindow = new google.maps.InfoWindow({
                    content: contentwindow
                });
        
                //EVENTO CLICK DE ELIMINAR MARKER
                this.markerInfowindow.addListener('domready', () => {

                    this.markerClicked = marker;

                    document.getElementById('elimMarker')?.addEventListener('click', () => {
                        let i = 0;

                        this.arrayMarkers.forEach(it => {
                            if (this.markerClicked === it) {
                                this.arrayMarkers.splice(i,1);
                                marker.setMap(null);

                            }
                            i++;
                        });
                    });

                });
                this.markerInfowindow.open(this.map, marker);
                this.markerInfoWindOpened = true;

            });

            this.arrayMarkers.push(marker);   
        });
    }


    eliminarMarker(){
        console.log('Elimina marker...');
    }


    cargaMapListeners() {

        //Eventos al entrar y salir el puntero de un polígono
        this.map.data.addListener('mouseover', (event: { feature: { h: { id: string; }; i: { id: string; }; }; }) => {   
            try{
                if (!this.arrayFincasClicked.includes(event.feature.h.id)) {this.map.data.overrideStyle(event.feature, this.estilo_hover);}
            }
            catch (error) {
                console.log(error);
                if (!this.arrayFincasClicked.includes(event.feature.i.id)) {this.map.data.overrideStyle(event.feature, this.estilo_hover);}
            }
        });
    
        this.map.data.addListener('mouseout', (event: { feature: { h: { id: string; }; i: { id: string; }; }; }) => {
            try{
                if (!this.arrayFincasClicked.includes(event.feature.h.id)) {this.map.data.revertStyle(event.feature);}        
            }
            catch (error) {
                console.log(error);
                if (!this.arrayFincasClicked.includes(event.feature.i.id)) {this.map.data.revertStyle(event.feature);}        
            }

        });
        google.maps.event.addListener(this.map, 'dragend', () => {
            this.overlay();
            const lat = this.map.getCenter().lat();
            const lng = this.map.getCenter().lng();
            this.lastLatLng = lat + ',' + lng;
            StorageManager.saveLastLatLng(this.lastLatLng);
        });
        google.maps.event.addListener(this.map, 'zoom_changed', () => {
            this.overlay();
            this.lastZoom = this.map.getZoom();
            StorageManager.saveLastZoom(this.lastZoom);
        });

        //Sólo me interesa tenerlo cuando se carga el mapa por primera vez
        google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
            ///// SELECCIONA FINCA DESDE PARCELAS FORM /////
            const obj = this.divSelectFincas?.nativeElement.firstChild;
            obj.value = this.finca;

            if (this.origin === 'parcelas-form') {
                obj.disabled = true;
                if (this.arrayFincas.length > 0) {this.getFincaSelected(this.finca);}
                else {setTimeout(() => { this.getFincaSelected(this.finca); }, 1000);}
            }
            this.overlay();
        });



    //Evento click --> Abre Infowindow con opciones de guardado. TIPO 1
    //this.map.data.addListener('click', (event) => { this.abreInfoWindow(event, 1); });
    }


    montaInfoWindowContent(tipo?: number) {

        let lockCultivoVariedad = '';
        let elimButton = '';
        let guardaButton = '';
        let editButton = '';
        let title = '';

        //Comprueba si infowindow abierto
        try {
            if (this.infowindow.getMap() == null) { this.infoWindOpened = false; }
            this.infowindow.close();
        }
        catch (err) { console.log(err); }

        if (tipo === 3) { // Editando parcela. Ya está añadida a alguna finca
            elimButton = '<button id="elimina" type="button" class="btn btn-danger" style="margin-left: 6px">Eliminar</button>';
            editButton = '<button id="edita" type="button" class="btn btn-success" style="margin-left: 6px">Editar</button>';
        }
        else {
            guardaButton = '<button id="guarda" type="button" class="btn btn-success" style="margin-left: 10px">Guardar</button>';
        }

        //SI ESTAMOS EN ORIGEN PARCELAS NO DEBE DEJAR EDITAR
        if (tipo === 3 && this.origin === 'parcelas-form') {
            elimButton = ''; editButton = ''; guardaButton = '';
        }

        if (this.origin === 'parcelas-form' || tipo === 3) {
            this.strCultivos = this.strCultivos.replace(' selected', '');
            this.strVariedades = this.strVariedades.replace(' selected', '');
            this.strCultivos = this.strCultivos.replace('\'' + this.cultivoSelected + '\'', '\'' + this.cultivoSelected + '\' selected');
            this.strVariedades = this.strVariedades.replace(this.variedadSelected + '\'', '\'' + this.variedadSelected + '\' selected');
            if (tipo !== 3) {lockCultivoVariedad = 'disabled';}
        }

        //String SIGPAC para el infowindow
        let str3 = '';
        str3 = '<div class=\'row\' style=\'margin-left:4px;\'>';
        str3 += '<div class=\'col-md-2 form-group\'>';
        str3 += '  <label>SigPAC</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4 form-group\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.referencia.trim() + '\' type=\'text\'>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-2 form-group\'>';
        str3 += '  <label>Superficie</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4 form-group\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.superficie.trim() + '\' id=\'superficie\' type=\'text\'>';
        str3 += '</div>';
        str3 += '</div>';

        str3 += '<div class=\'row\' style=\'margin-left:4px;\'>';
        str3 += '<div class=\'col-md-2 form-group\'>';
        str3 += '  <label>Provincia</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4 form-group\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.provincia.trim() + '\' id=\'provincia\' type=\'text\'>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-2 form-group\'>';
        str3 += '  <label>Municipio</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4 form-group\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.municipio.trim() + '\' id=\'municipio\' type=\'text\'>';
        str3 += '</div>';
        str3 += '</div>';

        str3 += '<div class=\'row\' style=\'margin-left:4px;\'>';
        str3 += '<div class=\'col-md-2 form-group\'>';
        str3 += '  <label>Polígono</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4 form-group\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.poligono.trim() + '\' id=\'poligono\' type=\'text\'>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-2 form-group\'>';
        str3 += '  <label>Parcela</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4 form-group\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.parcela.trim() + '\' id=\'parcela\' type=\'text\'>';
        str3 += '</div>';
        str3 += '</div>';

        str3 += '<div class=\'row\' style=\'margin-left:4px;\'>';
        str3 += '<div class=\'col-md-2 form-group\'>';
        str3 += '  <label>Recinto</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4 form-group\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.recinto.trim() + '\' id=\'recinto\' type=\'text\'>';
        str3 += '</div>';
        str3 += '</div>';
        str3 += '';

        if (environment.appName === 'manezylozano') {title = 'Campo <br>' + this.fincaSelected;}
        else {
            if (tipo !== 3) {title = 'Crear Parcela<br>' + this.fincaSelected;}
            else {
                if (this.numPlantas !== '' && this.appName !== 'covidai') {
                    title = 'Parcela ' + this.parcelaNombre + ' (' + this.numPlantas + ' árboles)' + '<br>' + this.fincaSelected;
                }
                else if (this.numPlantas !== '' && this.appName === 'covidai') {
                    title = 'Parcela ' + this.parcelaNombre + ' (' + this.numPlantas + ' plantas)' + '<br>' + this.fincaSelected;
                }
                else {title = 'Parcela ' + this.parcelaNombre + '<br>' + this.fincaSelected;}
            }
        }


        this.contentString =
      '<div id="contentIW" style="min-width:300px; max-width:400px; overflow:hidden; font-weight:400;">' +
      '<div class="form-group row" style="margin-left:10px; margin-top:20px; padding:10px; background:#aaaaff">' +
      '<label style="font-size: 1.6em; text-align: center; margin: auto">' + title + '</label>' +
      '</div>' +

      str3 +

      '<div class="row"  style="margin-left:4px;">' +
      '<div class="col-md-2 form-group">' +
      '<label>Cultivo</label>' +
      '</div>' +
      '<div class="col-md-4 form-group">' +
      '<select ' + lockCultivoVariedad + 
        ' class="form-control iwinput" name="cultivos" id="cultivos" style="font-size: 1em; max-width: 120px; padding: 2px">' +
        this.strCultivos +
      '</select>' +
      '</div>' +

      '<div class="col-md-2 form-group">' +
      '<label>Variedad</label>' +
      '</div>' +
      '<div class="col-md-4 form-group" id="divVariedades">' +
      '<select ' + lockCultivoVariedad + 
        ' class="form-control iwinput" name="variedades" id="variedades" style="font-size: 1em; max-width: 120px; padding: 2px">' +
        this.strVariedades +
      '</select>' +
      '</div>' +
      '</div>' +
      '</div>' +

      '<div class="row">' +
      '<div id="color-palette" style="margin:0 auto; text-align: center"></div>' +
      '</div>' +

      '<div class="form-group row" style="display: block; padding: 16px; margin: auto; text-align: center">' +
      '<button id="cancela" type="button" class="btn btn-basic" style="margin-left: 6px;">Cerrar</button>' +
      editButton + elimButton + guardaButton +
      '</div>' +
      '</div>' +
      '</div>';

    }


    setSigPAC(refpar: string) {
    //Comprueba que la referencia es de parcela rustica y extraemos valores
        this.sigPAC.referencia = refpar;

        if (!isNaN(+refpar.substr(6, 3)) && !isNaN(+refpar.substr(9, 5))) {
            this.sigPAC.provincia = refpar.substr(0, 2);
            this.sigPAC.municipio = refpar.substr(2, 3);
            this.sigPAC.poligono = refpar.substr(6, 3);
            this.sigPAC.parcela = refpar.substr(9, 5);
            this.sigPAC.recinto = '0';
        }
        else {
            console.log('Parcela Urbana');
        }
    }


    setCoordLocatec(
        path: { 
            forEach: (arg0: (it: { lat: () => string; lng: () => string; }) => void) => void; 
            forEachLatLng: (arg0: (it: { lat: () => string; lng: () => string; }) => void) => void; }, t: number): any {
    //Extraemos las coordenadas de la parcela seleccionada en formato Locatec
        let latInicial = '';
        let lngInicial = '';
        const bounds = new google.maps.LatLngBounds();
        let coordenadasLct = '';
        const pathParcel: any = [];

        if (t === 1) {
            path.forEach((it: { lat: () => string; lng: () => string; }) => {
                coordenadasLct += '(' + it.lat() + ',' + it.lng() + ');';
                //Guardamos las coordenadas iniciales para poder cerrar el polígono
                if (latInicial === '') { latInicial = it.lat(); lngInicial = it.lng(); }

                pathParcel.push(new google.maps.LatLng(it.lat(), it.lng()));
                bounds.extend(it);
            });
        }
        else {
            path.forEachLatLng((it: { lat: () => string; lng: () => string; }) => {
                coordenadasLct += '(' + it.lat() + ',' + it.lng() + ');';
                //Guardamos las coordenadas iniciales para poder cerrar el polígono
                if (latInicial === '') { latInicial = it.lat(); lngInicial = it.lng(); }

                pathParcel.push(new google.maps.LatLng(it.lat(), it.lng()));
                bounds.extend(it);
            });
        }

        //Cerramos el polígono
        this.coordenadas_locatec = coordenadasLct;
        this.coordenadas_locatec += '(' + latInicial + ',' + lngInicial + ')';

        this.sigPAC.superficie = '';

        return bounds;
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////                    FUNCION ABRE INFO WINDOW UNICA PARA TODOS LOS CASOS                            //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    abreInfoWindow(
        event: { 
            feature: { 
                getProperty: (arg0: string) => string; 
                getGeometry: () => any; }; 
                latLng: { 
                    lat: () => string; 
                    lng: () => string; 
                };
            }, 
        tipo: number, 
        id?: number, 
        bounds?: { getCenter: () => string; } | undefined) {

        this.parcelaSelectedid = id?.toString() ?? '';

        //Extrae propiedades de polígono y parcela
        let refpar = '';

        ///////// Parcela NUEVA con SIGPAC SI (tipo 1) /////////
        if (tipo === 1) {
            refpar = event.feature.getProperty('id');

            //Controlamos si el elemento ya ha sido cliclado en la misma sesión, para no añadirlo 2 veces
            if (!this.idsPoligonosClic.includes(refpar)) {
                this.setSigPAC(refpar);
                const path = event.feature.getGeometry();
                this.setCoordLocatec(path, 2);
            }
        }

        ////////// Parcela NUEVA con SIGPAC NO (tipo 2) ///////////
        else if (tipo === 2) {

        }

        ////////// Parcela que editamos (tipo 3) ///////////
        else if (tipo === 3) {
            if (environment.appName === 'manezylozano') {this.fincaSelected = this.arrayFullMapObj[id ?? 0]?.parcela_nombre ?? '';}
            else {this.fincaSelected = this.arrayFullMapObj[id ?? 0]?.finca ?? '';}
            this.sigPAC.provincia = this.arrayFullMapObj[id ?? 0]?.cod_provincia ?? '';
            this.sigPAC.municipio = this.arrayFullMapObj[id ?? 0]?.municipio ?? '';
            this.sigPAC.poligono = this.arrayFullMapObj[id ?? 0]?.poligono ?? '';
            this.sigPAC.parcela = this.arrayFullMapObj[id ?? 0]?.parcela ?? '';
            this.sigPAC.recinto = this.arrayFullMapObj[id ?? 0]?.recinto ?? '';
            this.sigPAC.superficie = this.arrayFullMapObj[id ?? 0]?.superficie ?? '';

            this.cultivoSelected = this.arrayFullMapObj[id ?? 0]?.cultivo ?? '';
            this.variedadSelected = this.arrayFullMapObj[id ?? 0]?.variedad ?? '';

            this.sigPAC.referencia = (
                this.arrayFullMapObj[id ?? 0]?.sigpac !== undefined || this.arrayFullMapObj[id ?? 0]?.sigpac !== '') ?        
                this.arrayFullMapObj[id ?? 0]?.sigpac ?? '' : '';

            this.parcelaNombre = this.arrayFullMapObj[id ?? 0]?.parcela_nombre ?? '';

            const parcela = this.arrayParcelas.find(el => el.id === this.arrayFullMapObj[id ?? 0]?.id_parcela);
            if (parcela !== undefined) {this.numPlantas = (parcela.plantas) || '';}
        }


        //Comprueba si infowindow abierto
        try {
            if (this.infowindow.getMap() == null) { this.infoWindOpened = false; }
            this.infowindow.close();
            this.parcelaObj.forEach((element: { setEditable: (arg0: boolean) => void; }) => {
                element.setEditable(false);
            });
        }
        catch (err) { console.log(err); }

        this.montaInfoWindowContent(tipo);

        this.infowindow = new google.maps.InfoWindow({
            content: this.contentString
        });


        //ABRE EL INFOWINDOW
        this.infowindow.setContent(this.contentString);
        if (tipo === 2) {this.infowindow.setPosition(bounds?.getCenter());}
        else {this.infowindow.setPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });}
        this.infowindow.open(this.map);
        this.infoWindOpened = true;

        this.infowindow.addListener('domready', () => {

            //Parcela nueva creada
            if (tipo !== 3) {
                document.getElementById('guarda')?.addEventListener('click', () => {
                    const objVariedades = document.getElementById('variedades') as HTMLSelectElement;
                    let variedadSel = '';
                    for (const optionVariedades of Array.from(objVariedades.options)) {
                        if (optionVariedades.selected) { variedadSel = optionVariedades.value; }
                    }

                    this.cultivoSelected = variedadSel.split('|')[0]?.trim() ?? '';
                    this.variedadSelected = variedadSel.split('|')[1]?.trim() ?? '';
                    if (this.origin === 'parcelas-form') {
                        this.cultivoSelected = this.cultivo;
                        this.variedadSelected = this.variedad;
                    }
                    this.guardarIW(event, this.sigPAC.superficie, tipo);
                });
            }

            //Parcela ya existente
            else if (tipo === 3 && this.origin !== 'parcelas-form') {

                document.getElementById('edita')?.addEventListener('click', () => {

                    // GUARDAMOS EL PATH DE LA PARCELA SELECCIONADA
                    this.arrayCoord = this.parcelaObj[id ?? 0].getPath();

                    if (this.map.controls[google.maps.ControlPosition.LEFT_TOP].length === 4) {
                        this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
                        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.divGuardarCambios?.nativeElement);
                        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.divCancelarEdicion?.nativeElement);
                    }

                    document.getElementById('divCancelarEdicion')?.addEventListener('click', () => {            
                        this.parcelaObj[id ?? 0].setEditable(false);
                    });

                    this.parcelaObj[id ?? 0].setEditable(true);
                    this.infowindow.close();
                });
            }

            document.getElementById('cancela')?.addEventListener('click', () => {
                this.cancelarIW();
            });

            document.getElementById('cultivos')?.addEventListener('change', () => {
                //this.recargaVariedades(event.srcElement);
                const objCultivos = document.getElementById('cultivos') as HTMLSelectElement;
                let objVariedades = document.getElementById('variedades');
                const divVariedades = document.getElementById('divVariedades');
                let cultivoSel = '';

                for (const cultivoOptions of Array.from(objCultivos.options)) {
                    if (cultivoOptions.selected) { cultivoSel = cultivoOptions.value; }
                }

                this.cultivoSelected = cultivoSel;

                objVariedades?.remove();
                
                if (divVariedades) {
                    divVariedades.innerHTML = 
                    '<select class="form-control iwinput"' +
                      'name="variedades" id="variedades" style="font-size: 1em; max-width: 120px; padding: 2px">';
                }

                objVariedades = document.getElementById('variedades');

                let opt = '';
                for (const variedad of this.arrayVariedades) {
                    if (variedad.cultivo === cultivoSel) {
                        opt += '<option value=\'' + cultivoSel + ' | ' + 
                          variedad.variedad + '\'>' + variedad.variedad + '</option>';
                    }
                }

                if (objVariedades) {
                    objVariedades.innerHTML = opt;
                }
            });
        });
    }


    cancelEditPolygon(id: number) {
    /* Al cerrar el InfoWindow si la parcela tiene editable = true lo cambiamos a false */
        const cerrar = document.getElementsByClassName('gm-ui-hover-effect')[0];
    
        if (cerrar) {
            cerrar.addEventListener('click', () => {
                this.parcelaObj[id].setEditable(false);
            });
        }
    }

    actualizarParcela() {
        this.guardarCambios(this.arrayCoord, +this.parcelaSelectedid);    
        this.parcelaObj[this.parcelaSelectedid].setEditable(false);
    }

    pintaPArcelas() {

        let coords: any[] = [];
        let coord: any = [];
        let aux = '';
        let k = 0;
        let j = 0;
        let color = '';
        let bounds = new google.maps.LatLngBounds();
        let nomParcela = '';

        const noIcon = {
            path: 'M 125,5 125,5',
            fillOpacity: 0.1,
            scale: 0.1,
        };


        this.arrayFullMapObj.forEach(el => {

            coord = [];

            coord = (el.coordenadas || '').split(';');
            for (j = 0; j < coord.length; j++) {
                aux = coord[j] ?? '';
                aux = aux.replace('(', '');
                aux = aux.replace(')', '');
                coords.push(new google.maps.LatLng((aux.split(',')[0] || '').trim(), (aux.split(',')[1] || '').trim()));
                bounds.extend(coords[j]);
            }

            if (el.finca === '') { color = '222222'; }
            else { color = '009900'; } //'00D500';

            color = (el.color || '').replace('#', '');

            try { 
                if (this.applicationType === 'cropgest') {
                    nomParcela = this.arrayFincas.find(it => it.id === el.id_finca).cliente_nombre + ' > \n' || '';
                    nomParcela += this.arrayParcelas.find(it => it.id === el.id_parcela)?.nombre || ''; 
                } else {
                    nomParcela = this.arrayParcelas.find(it => it.id === el.id_parcela)?.nombre || ''; 
                }
            }
            catch (e) { nomParcela = ''; /*console.log("el: ",el);*/ }

            //nomParcela = ((this.arrayParcelas.find(it => it.id == el.id_parcela)).nombre) || '';

            // COMPROBAMOS SI YA SE HA PINTADO UNA PARCELA CON EL MISMO NÚMERO DE REFERENCIA
            if (true) {
                // Construct the polygon.
                this.parcelaObj[k] = new google.maps.Polygon({
                    paths: coords,        
                    strokeColor: '#' + color,
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: '#' + color,
                    fillOpacity: 0.5, // 0.35
                    id: k,
                    idParcela: el.id,
                    idFinca: el.id_finca
                });


                const parcel = this.parcelaObj[k];

                google.maps.event.addListener(parcel, 'click', (event: { latLng: any; }) => {
                    //Extrae propiedades de polígono y parcela              
                    //this.abreInfoWindow(event, 3, parcel.id);

                    const marker = new google.maps.Marker({
                        position: event.latLng,        
                        map: this.map
                    });
          
                    google.maps.event.addListener(marker, 'click', () => {
    
                        //Comprueba si infowindow abierto
                        try {
                            if (this.markerInfowindow.getMap() == null) { this.markerInfoWindOpened = false; }
                            this.markerInfowindow.close();
                        }
                        catch (err) { /*console.log(err);*/ }
    
                        const contentwindow = 
                          '<div><input type=\'button\' id=\'elimMarker\' class=\'btn btn-danger\' value=\'Eliminar\'></div>';
                        this.markerInfowindow = new google.maps.InfoWindow({
                            content: contentwindow
                        });
            
                        //EVENTO CLICK DE ELIMINAR MARKER
                        this.markerInfowindow.addListener('domready', () => {
    
                            this.markerClicked = marker;
    
                            document.getElementById('elimMarker')?.addEventListener('click', () => {
                                let i = 0;
    
                                this.arrayMarkers.forEach(it => {
                                    if (this.markerClicked === it) {
                                        this.arrayMarkers.splice(i,1);
                                        marker.setMap(null);
    
                                    }
                                    i++;
                                });
                            });
    
                        });
                        this.markerInfowindow.open(this.map, marker);
                        this.markerInfoWindOpened = true;
    
                    });
    
                    this.arrayMarkers.push(marker);   
    

                });

                //Añade label nombre parcela. Solo para Vginer y demo
                if (this.appName === 'vginer' || this.appName === 'amoros' || this.appName === 'fitogest' || this.appName === 'agrogest') {

                    const marker = new google.maps.Marker({
                        position: bounds.getCenter(),
                        label: nomParcela,
                        icon: noIcon,
                        map: this.map,
                    });
                    google.maps.event.addListener(marker, 'click', (event: any) => {
                        //Extrae propiedades de polígono y parcela                
                        this.abreInfoWindow(event, 3, parcel.id);
                    });

                    bounds = new google.maps.LatLngBounds();
                    marker.setMap(this.map);
                }

                parcel.setMap(this.map);
                //refparAnterior = refpar;

                if (this.showMapaLabels) {

                    const marker = new google.maps.Marker({
                        position: bounds.getCenter(),
                        label: nomParcela,
                        icon: noIcon,
                        idFinca: el.id_finca,
                        map: this.map
                    });

                    this.markerLabels.push(marker);

                    bounds = new google.maps.LatLngBounds();
                    marker.setMap(this.map);
                }
            }
            coords = [];
            k++;
        });
        window.scrollTo(0, 0);
    }


    montaGeoJSON() {

        let coord = '';
        let coords: string[];
        let c = '';
        let miJS: number[] = [];
        let misJS: number[][] = [];
        const misJS2: any[][] = [];
        let lat: number;
        let lng: number;

        this.arrayMapaParcelas.forEach(el => {
            coord = el.coordenadas;
            coords = coord.split(';');
            coords.forEach(co => {
                c = co.replace('("', '');
                c = c.replace('")', '');
                c = c.replace('(', '');
                c = c.replace(')', '');

                lat = parseFloat(c.split(',')[0]?.toString() ?? '');
                lng = parseFloat(c.split(',')[1]?.toString() ?? '');

                miJS.push(lat);
                miJS.push(lng);
                misJS.push(miJS);
                miJS = [];
            });
            misJS2.push(misJS);
            misJS = [];
            //miJSON.features[0].geometry.coordinates.push();
        });

        this.miJSON = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: misJS2
                    }
                }
            ]
        };

    }

    getIWObjects(): {
        superficie: string,
        provincia: string,
        municipio: string,
        poligono: string,
        parcela: string,
        recinto: string
        } {
        const objSuperficie = document.getElementById('superficie') as HTMLInputElement;
        const objProvincia = document.getElementById('provincia') as HTMLInputElement;
        const objMunicipio = document.getElementById('municipio') as HTMLInputElement;
        const objPoligono = document.getElementById('poligono') as HTMLInputElement;
        const objParcela = document.getElementById('parcela') as HTMLInputElement;
        const objRecinto = document.getElementById('recinto') as HTMLInputElement;

        const objs = {
            superficie: objSuperficie.value,
            provincia: objProvincia.value,
            municipio: objMunicipio.value,
            poligono: objPoligono.value,
            parcela: objParcela.value,
            recinto: objRecinto.value,
        };

        return objs;
    }


    guardarIW(event: { feature: { getProperty: (arg0: string) => any; }; }, ha: string, tipo?: number, refparcel?: undefined) {

        if (this.fincaSelected === '' || this.fincaSelected == null) {
            alert('Debe seleccionar una finca primero...');
            return;
        }

        const style = { fillColor: 'green', strokeWeight: 5, strokeColor: 'black', fillOpacity: 0.8 };
        let refpar = '';
        let provincia = ''; 
        let municipio = ''; 
        let poligono = ''; 
        let parcela = ''; 
        let recinto = '';
        let operacion = '';

        if (tipo === 2) {
            //this.arrayFincasClicked.push(refparcel);
            this.map.data.overrideStyle(event.feature, style);
            refpar = refparcel ?? '';
        }
        else {
            if (tipo === 3) {operacion = 'editar';}
            //this.arrayFincasClicked.push(event.feature.g.id); //l
            this.map.data.overrideStyle(event.feature, style);
            refpar = event.feature.getProperty('id');
        }

        //this.setSigPAC(refpar, tipo);

        const objs: any = this.getIWObjects();

        if (objs != null && objs !== undefined) {
            provincia = objs.provincia;
            municipio = objs.municipio;
            poligono = objs.poligono;
            parcela = objs.parcela;
            recinto = objs.recinto;
        }

        this.infoWindOpened = false;
        this.infowindow.close();

        const color = '#88dd66';

        this.arrayFincas.forEach(el => {
            if (el.nombre === this.fincaSelected) { this.fincaSelectedId = el.id; }
        });

        let encontrado = false;
        this.arrayCultivos.forEach(el => {
            if (el.cultivo === this.cultivoSelected && !encontrado) {
                this.cultivoSelectedId = el.id;
                encontrado = true;
            }
            if (el.variedad === this.variedadSelected) { this.variedadSelectedId = el.id; }
        });

        let url2 = '';
        url2 += 'finca=' + this.fincaSelectedId + '&provincia=' + objs.provincia + '&municipio=' + objs.municipio;
        url2 += '&poligono=' + objs.poligono + '&parcela=' + objs.parcela + '&recinto=' + objs.recinto;
        url2 += '&cultivo=' + this.cultivoSelectedId + '&variedad=' + this.variedadSelectedId;
        url2 += '&coords=' + this.coordenadas_locatec + '&color=' + color;
        url2 += '&refpar=' + refpar + '&ha=' + ha;

        const usuario = (StorageManager.getUser() || {} as any).id;
        let cliente = (StorageManager.getClient() || {} as any).id;
        const finca = this.fincaSelectedId;

        if (cliente === 0 || cliente == null) {
            this.arrayFincas.forEach(el => {
                if (el.id === finca) { cliente = el.id_cliente; }
            });
        }

        const cultivo = this.cultivoSelectedId;
        const variedad = this.variedadSelectedId;
        const coord = this.coordenadas_locatec;
        const origin = this.origin;
        const appName = environment.appName;

        const datos = JSON.stringify({ 
            finca, 
            provincia, 
            municipio, 
            poligono, 
            parcela, 
            recinto, 
            cultivo, 
            variedad, 
            coord, 
            color, 
            cliente, 
            usuario, 
            refpar, 
            ha, 
            operacion, 
            origin, 
            appName 
        });

        if (this.origin !== 'parcelas-form') {
        }

        //req.unsubscribe();
        if (this.origin === 'parcelas-form') { this.fromMapa.emit(datos); }
    }

    cancelarIW() {
        this.idsPoligonosClic.pop();
        this.infoWindOpened = false;
        this.infowindow.close();
    }

    recargaMapa() {
        this.showCargando = true;

        while (this.arrayParcelas.length > 0) {
            this.arrayParcelas.pop();
        }
        while (this.arrayMapaParcelas.length > 0) {
            this.arrayMapaParcelas.pop();
        }
        while (this.arrayFullMapObj.length > 0) {
            this.arrayFullMapObj.pop();
        }

        this.getParcelas();
        this.getMapaParcelas();
        setTimeout(() => { this.getFullMapObject(true); }, 3000);

    //this.initializeMap();  
    }



    //Esta función introduce la imagen del Catastro en el mapa
    overlay() {
        if (!this.showSigpac) { return; }

        this.bounds = this.map.getBounds() || null;

        if (this.oldmap != null) {
            this.oldmap.setMap(null);  //'Despinta' la imagen anterior para que no se solapen mucho
            this.oldmap = null;
        }


    }



    getFincaSelected(finca: any) {
        finca = typeof(finca) === 'string' ? finca : finca.value;
        let numParcelas = 0;
        const bounds = new google.maps.LatLngBounds();
        let c, lat, lng = '';
        let localidad, cp = '';

        StorageManager.saveLastFincaMap(finca);

        try {
            this.fincaSelected = finca;

            // SACAMOS EL ID DE LA FINCA
            this.arrayFincas.forEach(f => {
                if (f.nombre === this.fincaSelected) {
                    this.fincaSelectedId = f.id;
                    localidad = f.localidad;
                    cp = f.codigo_postal;
                }
            });

            // SACAMOS LA PRIMERA PARCELA Y EL PRIMER PAR DE COORDENADAS PARA CENTRAR EL MAPA
            this.arrayFullMapObj.forEach(parcela => {
                if (environment.appName === 'manezylozano') {
                    if (parcela.id_parcela === this.fincaSelectedId) {
                        numParcelas++;
                        c = parcela.coordenadas.split(';')[0];
                        lat = c?.split(',')[0]?.replace('(', '');
                        lng = c?.split(',')[1]?.replace(')', '') ?? '';
                        bounds.extend(new google.maps.LatLng(lat, lng));
                    }
                }
                else {
                    if (parcela.id_finca === this.fincaSelectedId) {
                        numParcelas++;
                        c = parcela.coordenadas.split(';')[0];
                        lat = c?.split(',')[0]?.replace('(', '');
                        lng = c?.split(',')[1]?.replace(')', '') ?? '';
                        bounds.extend(new google.maps.LatLng(lat, lng));
                    }
                }
            });

            if (numParcelas > 0) {
                this.map.fitBounds(bounds);
                this.map.panToBounds(bounds);
                this.map.setZoom(17);
                this.lastLatLng = bounds.getCenter().lat() + ',' + bounds.getCenter().lng();
                StorageManager.saveLastLatLng(this.lastLatLng);
            }
            else {
                if (environment.appName !== 'innovia') {
                    if (environment.appName !== 'manezylozano') {
                        alert('No hay parcelas registradas todavía para esta finca');
                        if (cp !== '' && cp != null) {this.codeAddressObs(cp).subscribe();}
                        else if (localidad !== '' && localidad != null) {this.codeAddressObs(localidad).subscribe();}
                    }
                    else {alert('Este campo aun no está creado en mapas!');}
                }
            }
        }
        catch (e) { console.log(e); }

        //DESTACAR EN COLOR LA FINCA SELECCIONADA
        if (environment.appName !== 'innovia') {
            this.parcelaObj.forEach((it: { idFinca: string; setOptions: (arg0: { fillColor: string; }) => void; }) => {
                if (it.idFinca === this.fincaSelectedId) {it.setOptions({ fillColor: '#1111aa' });}      
                else {it.setOptions({ fillColor: '#009900' });}
            });
        }
        //PARA INNOVIA MOSTRAMOS SOLO LA RUTA SELECCIONADA
        else {
            this.routes.forEach((it: { id_cliente: string; setOptions: (arg0: { strokeOpacity: number; }) => void; }) => {
                if (it.id_cliente === this.fincaSelectedId) {it.setOptions({ strokeOpacity: 1 });}      
                else {it.setOptions({ strokeOpacity: 0 });}
            });
            this.markers.forEach((it: { id_cliente: string; setVisible: (arg0: boolean) => void; }) => {
                if (it.id_cliente === this.fincaSelectedId) {it.setVisible(true);}
                else {it.setVisible(false);}
            });

        }
    }

    codeAddressObs(address: string): Observable<google.maps.GeocoderResult[]> {
        return Observable.create((observer: Observer<google.maps.GeocoderResult[]>) => {
            // Invokes geocode method of Google Maps API geocoding.
            this.geocoder = new google.maps.Geocoder();
            this.geocoder.geocode({ address: address, region: 'es' }, (
                (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        this.map.setCenter(results[0].geometry.location, 10);
                        observer.next(results[0].geometry.location);
                        observer.complete();

                        this.lastLatLng = results[0].geometry.location.lat() + ',' + results[0].geometry.location.lng();
                        StorageManager.saveLastLatLng(this.lastLatLng);
                    } else {
                        console.log('geocode was not successful for the following reason: ' + status);
                        observer.error(status);
                    }
                })
            );
        });
    }



    //////////////////////////////////////////////////////////////////////////////
    /////////////////////  FUNCIONES DE CARGA DE DATOS  //////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    getFincas() {

        const clientSelected = (StorageManager.getClient() || {}).id;
        const idUsuario = StorageManager.getUser().id;
        const rolUsuario = StorageManager.getUser().rol;

        if (environment.appName === 'manezylozano') {
            return new Promise<void>((resolve) => {
                this.camposApi.campos.GET.safePerform();
                this.camposApi.campos.GET.response((value: ParcelasModel[]) => {
                    if (value) {
            
                        if (rolUsuario !== 'admin') {
                            this.arrayFincas = value.filter(it => it.id_usuario === idUsuario).sort(Filtering.sort('nombre', 1));
                        } else {
                            this.arrayFincas = value.filter(it => it).sort(Filtering.sort('nombre', 1));
                        }
                        if (this.origin === 'parcelas-form') {this.arrayFincas.push({ nombre: this.finca });}
                        this.arrayFincas.forEach(it => {
                            if (it.id_usuario === idUsuario) {
                                this.strFinca += '<option value=\'' + it.nombre + '\'>' + it.nombre + '</option>';
                            }
                        });
                        this.strFinca = this.strFinca.replace('\'' + this.fincaSelected + '\'', '\'' + this.fincaSelected + '\' selected');
                        this.loadCount++;
                    }
                    resolve();
                });
            });
        }
        else {
            return new Promise<void>((resolve) => {

                this.fincasApi.fincasGET.safePerform();
                this.fincasApi.fincasGET.response((value: FincasModel[]) => {
        
                    if (value) {
                        this.arrayFincas = value.filter(it => it).sort(Filtering.sort('nombre', 1));

                        this.arrayFincas.forEach(it => {
                            if (it.id_cliente === clientSelected) {
                                this.strFinca += 
                                  '<option value=\'' + it.nombre + '\'>' + it.nombre + '(' + it.cliente_nombre + ')' + '</option>';
                                this.strFinca = 
                                  this.strFinca.replace('\'' + this.fincaSelected + '\'', '\'' + this.fincaSelected + '\' selected');
                                this.loadCount++;
                            }
                        });
                    }
                    resolve();
                });
            });
        }
    }

    getSectores() {
        return new Promise<void>((resolve) => {
            this.fincasApi.sectoresGET.safePerform();
            this.fincasApi.sectoresGET.response((value: SectorModel[]) => {
                this.arraySectores = value;
                this.loadCount++;
                resolve();
            });
        });
    }

    getParcelas() {
        const idUsuario = StorageManager.getUser().id;
        const rolUsuario = StorageManager.getUser().rol;

        if (environment.appName === 'manezylozano') {
            return new Promise<void>((resolve) => {
                this.camposApi.campos.GET.safePerform();
                this.camposApi.campos.GET.response((value: ParcelasModel[]) => {
                    if (value) {
                        if (rolUsuario !== 'admin') {
                            this.arrayParcelas = value.filter(it => it.id_usuario === idUsuario).sort(Filtering.sort('nombre', 1));
                        }
                        else {this.arrayParcelas = value.filter(it => it).sort(Filtering.sort('nombre', 1));}
                        this.loadCount++;
                    }
                    resolve();
                });
            });
        }
        else {
            return new Promise<void>((resolve) => {
                this.fincasApi.parcelasGET.safePerform();
                this.fincasApi.parcelasGET.response((value: ParcelasModel[]) => {
                    this.arrayParcelas = value;
                    this.loadCount++;
                    resolve();
                });
            });
        }
    }


    getCultivos() {
        return new Promise<void>((resolve) => {
            const arrayAux: string[] = [''];

            this.strCultivos = '<option value=\'\'>Selecciona...</option>';
            this.formApi.cultivosGET.safePerform();
            this.formApi.cultivosGET.response((value: VisitasVariedadMesModel[]) => {
                if (value) {
                    this.arrayCultivos = value.filter(it => it).sort(Filtering.sort('cultivo', 1));
                    this.arrayCultivos.forEach(it => {
                        if (!arrayAux.includes(it.cultivo)) {
                            arrayAux.push(it.cultivo);
                            this.strCultivos += '<option value=\'' + it.cultivo + '\'>' + it.cultivo + '</option>';
                        }
                    });

                    this.strCultivos = 
                      this.strCultivos.replace('\'' + this.cultivoSelected + '\'', '\'' + this.cultivoSelected + '\' selected');
                    this.loadCount++;
                }
                resolve();
            });
        });
    }

    getVariedades() {
        return new Promise<void>((resolve) => {
            this.formApi.variedadesGET.safePerform();
            this.formApi.variedadesGET.response((value: VisitasVariedadMesModel[]) => {
                if (value) {
                    this.arrayVariedades = value.filter(it => it).sort(Filtering.sort('variedad', 1));
                    this.arrayVariedades.forEach(it => {
                        this.strVariedades += '<option value=\'' + it.cultivo + ' | ' + it.variedad + '\'>' + it.variedad + '</option>';
                    });
                    this.strVariedades = 
                      this.strVariedades.replace('\'' + this.variedadSelected + '\'', '\'' + this.variedadSelected + '\' selected');
                    this.loadCount++;
                }
                resolve();
            });
        });
    }


    getMapaParcelas() {
        const clientSelected = (StorageManager.getClient() || {}).id;
        const idUsuario = StorageManager.getUser().id;
        const rolUsuario = StorageManager.getUser().rol;

        return new Promise<void>(resolve => {
            this.mapaParcelasApi.mapaParcelasGET.safePerform();
            this.mapaParcelasApi.mapaParcelasGET.response((value: FullMapModel[]) => {
                if (value) {
                    if (this.appName === 'manezylozano' && rolUsuario !== 'admin') {
                        this.arrayMapaParcelas = value.filter(it => it.id_usuario === idUsuario);
                    }
                    else {this.arrayMapaParcelas = value;}
                    this.loadCount++;
                }
                else {
                    this.showCargando = false;
                }
                if (clientSelected !== undefined) {this.arrayMapaParcelas = value.filter(it => it.id_cliente === clientSelected);}
                resolve();
            });
        });

    }


    getFullMapObject(eliminado: boolean) {

        let idCultivo = '';
        let idVariedad = '';
        let idx = 0;
        let obj: any = {};

        if (this.arrayMapaParcelas.length > 0) {
            this.arrayMapaParcelas.forEach(el => {
                obj = {};

                obj.id = el.id;
                obj.id_parcela = el.id_parcela;
                obj.id_finca = el.id_finca;
                obj.coordenadas = el.coordenadas;
                obj.color = el.color;

                //EXTRA DATA
                try { obj.finca = this.arrayFincas.find(el2 => el2.id === el.id_finca).nombre || ''; }
                catch (err) { obj.finca = ''; }

                idx = this.arrayParcelas.findIndex(el2 => el2.id === el.id_parcela);

                if (idx === -1) {
                    obj.id = '';
                    obj.parcela_nombre = '';
                    obj.cod_provincia = '';
                    obj.municipio = '';
                    obj.poligono = '';
                    obj.parcela = '';
                    obj.recinto = '';
                    obj.superficie = '';
                    obj.sigpac = '';
                    obj.cultivo = '';
                    obj.variedad = '';
                }
                else {
                    obj.parcela_id = this.arrayParcelas[idx]?.id;
                    obj.parcela_nombre = this.arrayParcelas[idx]?.nombre;
                    obj.cod_provincia = this.arrayParcelas[idx]?.cod_provincia || '';
                    obj.municipio = this.arrayParcelas[idx]?.municipio || '';
                    obj.poligono = this.arrayParcelas[idx]?.poligono || '';
                    obj.parcela = this.arrayParcelas[idx]?.numero || '';
                    obj.recinto = this.arrayParcelas[idx]?.recinto || '';
                    if (environment.appName === 'manezylozano') {obj.superficie = this.arrayParcelas[idx]?.superficie_sigpac || '';}
                    else {obj.superficie = this.arrayParcelas[idx]?.superficie_sigpac || '';}
                    obj.sigpac = this.arrayParcelas[idx]?.sigpac || '';

                    idCultivo = this.arrayParcelas[idx]?.id_cultivo || '';
                    idVariedad = this.arrayParcelas[idx]?.id_variedad || '';

                    if (this.arrayVariedades.find(el2 => el2.id === idCultivo) !== undefined &&
            this.arrayVariedades.find(el2 => el2.id === idVariedad) !== undefined) {
                        obj.cultivo = this.arrayVariedades.find(el2 => el2.id === idCultivo)?.cultivo || '';
                        obj.variedad = this.arrayVariedades.find(el2 => el2.id === idVariedad)?.variedad || '';
                    }
                }
                this.arrayFullMapObj.push(obj);
            });
            if (!eliminado) {this.pintaPArcelas();}
        }
        this.showCargando = false;
    }


    getPosition() {
        if (navigator.geolocation) {
            try {
                this.getGPSPosition().then(pos => {
                    console.log(`Positon: ${pos.lng} ${pos.lat}`);
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            }
            catch (e) { console.log('catch en getPosition: ' + e); }
        }
        else {
            console.log('Get GPS Position not supported!');
        }
    }

    getGPSPosition(): Promise<any> {
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(resp => {
                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
            },
            err => {
                if (err.code === 0) {
                    // Unknown error
                }
                if (err.code === 1) {
                    // Access denied by user
                }
                if (err.code === 2) {
                    // Position unavailable
                }
                if (err.code === 3) {
                    // Timed out
                }
                //reject(err);
            });
        });
    }




    //////////////////////////////////////////////////////////////////////////////
    ///////////////////////  FUNCIONES DE DATOS SIGPAC  //////////////////////////
    //////////////////////////////////////////////////////////////////////////////  

    cancelaParcela() {
        if (this.drawingManager) {
            this.drawingManager.setDrawingMode(null);
        }
        this.creatingParcel = false;
        const el = this.divNuevaParcela?.nativeElement;
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(el);
    }


    enviarRuta() {
        let url = '';

        this.arrayMarkers.forEach(marker => {
            if (url !== '') {url += '/';}
            url += marker.getPosition().lat() + ',' +marker.getPosition().lng();
        });

        url = 'https://www.google.co.ls/maps/dir/' + url;
        console.log('',url);

        new google.maps.InfoWindow({
            position: this.map.getCenter(),
            content: '<div><a href="' + url + '" target="_blank">Ver ruta</a></div>'
        }).open(this.map);
    
    }



    public guardarCambios(arrayCoord: any, id: number): void {

        let coordenadasLct = '';
        let latInicial = '';
        let lngInicial = '';
        const pathParcel: any = [];
        let i = 0;

        arrayCoord.forEach(function (path: { lat: () => string; lng: () => string; }) {
            if (i === 0) {
                latInicial = path.lat();
                lngInicial = path.lng();
                coordenadasLct = '(' + latInicial + ',' + lngInicial + ');';
            }
            i++;
            pathParcel.push(new google.maps.LatLng(path.lat(), path.lng()));
            coordenadasLct += '(' + path.lat() + ',' + path.lng() + ');';
        });

        coordenadasLct = coordenadasLct.slice(0, -1);
        this.cancelEditPolygon(id);
    }



}


