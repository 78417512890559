<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="getRequest"
            [cols]="cols"
            [filtering]="filtering"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            [showExportCsv]="showCostesTareasFertiCsv"
            (emitSearchText)="updateFooter()">

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons
                        [hideEdit]="true"
                        [hideDelete]="true"
                        (deleteRowButtonEvent)="show('delete', data)"
                        (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        </ng-template>

        <ng-template #footer let-col>
            <div style="font-size: 0.75em; text-align: left" *ngIf="(['apellidos'].includes(col.field))">
                TOTAL:
            </div>
            <div style="font-size: 0.75em; text-align: right; font-weight: bold"
                 *ngIf="(['coste'].includes(col.field))">
                {{moneyFormat(col.footer)}} €
            </div>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog
        *ngIf="canShow['info']"
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="4"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <div *ngIf="appName !== 'ava'">
        <div class="d-flex justify-content-center">
            <h4>Composición del Tratamiento</h4>
        </div>

        <div class="d-flex justify-content-center">
            <table class="table-style">
                <tr>
                    <th style="width: 15px; border: none"></th>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                </tr>
                <tr *ngFor="let item of model?.tratamiento?.linea; let i = index">
                    <td style="border: none">{{i + 1}}</td>
                    <td>{{item?.producto?.nombre}}</td>
                    <td>{{cuantityFormat(item.cantidad_real ?? 0)}}</td>
                    <td>{{moneyFormat(item.producto?.precio ?? 0)}}</td>
                </tr>
            </table>
        </div>
        </div>
       
        <div *ngIf="appName === 'ava'">
            <div class="d-flex justify-content-center">
                <h4>Composición del Tratamiento</h4>
            </div>
    
            <div class="d-flex justify-content-center">
                <table class="table-style">
                    <tr>
                        <th style="width: 15px; border: none"></th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Total</th>
                    </tr>
                    <tr *ngFor="let item of model?.tratamiento?.linea; let i = index">
                        <td style="border: none">{{i + 1}}</td>
                        <td>{{item?.nombre}}</td>
                        <td style="text-align: right;">{{ cuantityFormat(+(this.model['dosis'+(i+1)] || 0)) }}</td>
                        <td style="text-align: right;">{{ moneyFormat(item?.precio ?? 0)}} €</td>
                        <td style="text-align: right;">{{ moneyFormat((item?.precio || 0) * 
                        (this.model['dosis' + (i + 1)] !== undefined ? 0 : 0))}} €</td>
                    </tr>
                    <tr >
                        <td style="border: none" ></td>
                        <td style="border: none" ></td>
                        <td style="border: none" ></td>
                        <td style="border: none" ></td>
                        <td style="color:white; background-color: black; text-align: right;" >{{moneyFormat(calcularTratamiento(model.tratamiento?.linea ?? []))}} €</td>
                    </tr>
                </table>
            </div>
            </div>
            <div class="d-flex justify-content-center" style="margin-top: 40px">
                <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
            </div>
    </div>
    <br>

</p-dialog>