import {StorageManager} from './storage-manager.class';



export function checkIfRolAdmin(){
    try {
        if ( StorageManager.getUser().rol === 'admin'){
            return false;
        }
        return true;
    } catch (error) {
        console.log(error);
        return true;
    }
}