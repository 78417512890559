import {TareasApiService} from '../../../../service/api/tareas-api.service';
import {Component} from '@angular/core';
import { Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import {StorageManager} from '../../../../common/storage-manager.class';
import { createCsv } from 'src/app/common/createCsv';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareasBioModel } from 'src/app/models/tareas/tareas-bio.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-tareas-bio',
    templateUrl: './tareas_bio.component.html',
    styleUrls: ['./tareas_bio.component.scss']
})
export class TareasBioComponent extends BaseView<TareasBioModel> implements OnInit {
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public rolUser = StorageManager.getUser().rol;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public parcelaName = environment.features.parcelaName;
    public showBioMotivo = environment.features.showBioMotivo;
    public hasClients = environment.features.hasClients;
    public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;
    public showBioOrden = environment.features.showBioOrden;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));

    public getRequest: RequestHandler<TareasBioModel[]> = this.tareasApi.bio.GET;
    public deleteRequest: RequestHandler<TareasBioModel> = this.tareasApi.bio.DELETE;
    public pathToForm = 'crear-bio';
    public menuItemId = 'bio';

    public override filtering: Filtering<TareasBioModel> = new Filtering<TareasBioModel>();
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: TareasBioModel = {};

    public distributionOrder = [3, 2, 4, 3, 3];

    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'finca',
                name: this.fincaName,
                label: this.fincaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sector',
                name: this.sectorName,
                label: this.sectorName, 
                inputType: {type: InputType.TEXT}, 
                visible: this.appName !== 'hernandorena' 
            },
            {
                field: 'parcela',
                name: this.parcelaName,
                label: this.parcelaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha_receta',
                name: this.appName === 'hernandorena' ? 'Fecha' : 'Fecha Orden', 
                label: this.appName === 'hernandorena' ? 'Fecha' : 'Fecha Orden', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha_aplicacion', 
                name: 'F. Apl.',
                label: 'F. Apl.', 
                visible: environment.appName !== 'hernandorena', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'hora_inicio', 
                name: 'Hora Inicio',
                label: 'Hora Inicio', 
                inputType: {type: InputType.TEXT},
                visible: environment.features.timeStartStopTareas
            },
            {
                field: 'hora_fin',
                name: 'Hora Fin',
                label: 'Hora Fin', 
                inputType: {type: InputType.TEXT},
                visible: environment.features.timeStartStopTareas
            },    
            {
                field: 'horas_totales', 
                name: 'Horas',
                label: 'Horas', 
                inputType: {type: InputType.TEXT}, 
                visible: environment.features.tareasHorasTotales 
            },
            {
                field: 'facturado',
                name: 'Facturado',  
                label: 'Facturado', 
                inputType: {type: InputType.RO_SWITCH}, 
                visible: this.appName === 'agrosalvi'
            },
            {
                field: 'factura_albaran',
                name: 'Factura/Albarán',
                label: 'Factura/Albarán', 
                inputType: {type: InputType.TEXT}, 
                visible: this.appName === 'agrosalvi'
            },
            {
                field: 'trabajadores_nombre',
                label: 'Trabajadores',
                inputType: { type: InputType.TEXT }
            },
            {
                field: 'producto',
                name: 'Producto',
                label: 'Producto', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tipo_plaga', 
                label: 'Tipo de plaga', 
                inputType: { type: InputType.TEXT}
            },
            {
                field: 'dosis',
                name: 'Dosis',
                label: 'Dosis', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'modo_accion',
                name: 'Modo de acción', 
                label: 'Modo de acción', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'forma_aplicacion', 
                name: 'Forma de aplicación (intensidad)',
                label: 'Forma de aplicación (intensidad)', 
                inputType: {type: InputType.TEXT}
            },
            { 
                field: 'suelta_m2', 
                label: 'Suelta m²', 
                inputType: {type: InputType.EDIT_NUMERIC },
                visible: this.appName === 'hernandorena' ? false : true
            },
            {
                field: 'motivo',
                name: 'Motivo',
                label: 'Motivo', 
                inputType: {type: InputType.TEXTAREA }, 
                visible: this.showBioMotivo 
            },
            {
                field: 'estado_tarea',
                name: 'Estado de la Tarea', 
                label: 'Estado de la Tarea', 
                inputType: {type: InputType.TEXT}, 
                visible: this.appName === 'onda' || this.appName === 'lacooperativa'
            },
            {
                field: 'empresa_pagadora',
                name: 'Empresa Pagadora',
                label: 'Empresa Pagadora', 
                inputType: {type: InputType.TEXT}, 
                visible: this.appName === 'onda' || this.appName === 'lacooperativa'
            }
        ];
    public formFieldsHernandorena: ItemInterface<object>[] = 
        [
            {
                field: 'fecha_receta', 
                label: 'F. Receta', 
                visible: environment.appName !== 'hernandorena', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha_receta', 
                label: 'Fecha', 
                visible: environment.appName === 'hernandorena', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha_aplicacion', 
                label: 'F. Apl.', 
                visible: environment.appName !== 'hernandorena', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'finca', 
                label: this.fincaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sector', 
                label: this.sectorName, 
                inputType: {type: InputType.TEXT},  
                visible: this.appName !== 'hernandorena' 
            },
            {
                field: 'parcela', 
                label: this.parcelaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'producto', 
                label: 'Producto', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'modo_accion', 
                label: this.appName === 'hernandorena' ? 'Tipo medida' : 'Modo de\xa0acción', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'forma_aplicacion', 
                label: 'Forma de aplicación', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'dosis', 
                label: 'Dosis', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'motivo', 
                label: 'Motivo', 
                inputType: {type: InputType.TEXTAREA }, 
                visible: this.showBioMotivo 
            },
            {
                field: 'tipo_plaga', 
                label: 'Tipo de plaga', 
                inputType: { type: InputType.TEXT}
            },
            {
                field: 'gasto', 
                label: 'Gasto', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'm2', 
                label: 'm²', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cantidad_total_producto', 
                label: 'Cantidad total producto', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'orden',
                label: 'Orden',
                inputType: {type: InputType.TEXT},
                visible: this.showBioOrden
            },
        ];

    public cols: ColInterface[] = [
        {field: 'id', header: 'Nº\xa0Ficha', filter: ''},
        {field: 'fecha_receta', header: 'F.\xa0Receta', filter: ''},
        {field: 'fecha_aplicacion', header: 'F.\xa0Apl.', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'sector', header: this.sectorName, filter: ''},
        {field: 'parcela', header: this.parcelaName, filter: ''},
        {field: 'producto', header: 'Producto', filter: ''},
        {field: 'modo_accion', header: this.appName === 'hernandorena' ? 'Tipo medida' : 'Modo de\xa0acción', filter: ''},
        {field: 'forma_aplicacion', header: 'Forma de\xa0aplicación', filter: ''},
        {field: 'dosis', header: 'Dosis', filter: ''},
        {field: 'facturado', header: 'Facturado', filter: '', visible: this.appName === 'agrosalvi'}
    ];
    public colsHernandorena: ColInterface[] = [
        {field: 'id', header: 'Nº\xa0Ficha', filter: ''},
        {field: 'fecha_receta', header: 'Fecha', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'sector', header: this.sectorName, filter: ''},
        {field: 'parcela', header: this.parcelaName, filter: ''},
        {field: 'producto', header: 'Producto', filter: ''},
        {field: 'modo_accion', header: this.appName === 'hernandorena' ? 'Tipo medida' : 'Modo de\xa0acción', filter: ''},
        {field: 'forma_aplicacion', header: 'Forma de\xa0aplicación', filter: ''},
        {field: 'dosis', header: 'Dosis', filter: ''},
        {field: 'tipo_plaga', header: 'Tipo de plaga', filter: ''},
        // {field: 'suelta_m2', header: 'Suelta m²', filter: ''},
        {field: 'gasto', header: 'Gasto', filter: ''},
        {field: 'm2', header: 'm²', filter: ''},
        {field: 'cantidad_total_producto', header: 'Cantidad total producto', filter: ''}
    ];


    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.bio.GET, tareasApi.bio.DELETE);

        
    }

    ngOnInit() {
        
        if (environment.appName === 'hernandorena'){
            this.cols = this.colsHernandorena;
            this.formFields = this.formFieldsHernandorena;
        }
        this.dashboard.setSubmenuItem(this.menuItemId);
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', this.pathToForm]);
        }
    }

    public edit(data: TareasBioModel) {
        this.router.navigate(['dashboard', 'editar-bio', data.id, false]);
    }

    public duplicate(data: TareasBioModel) {
        this.router.navigate(['dashboard', 'duplicar-bio', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: TareasBioModel) {
        let x;
        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields
        ];

        const csvContent = createCsv(arrayFormFields, this.filtering.value, 'empresa_pagadora');
      
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Bio.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
      
    }

    public globalConditionalStyle(_value: string, col: ColInterface): ColStylesInterface {
        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (col.field === 'parcela') {
            return (value.length > 50) ? value.slice(0, 50 - 1) + '…' : value;
        }

        if (col.field === 'facturado') {
            switch (value) {
            case '0':
                return 'No';
            case '1':
                return 'Sí';
            default:
                return 'No';
            }
        }
        return value;
    }

    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }
}
