import {Injectable} from '@angular/core';
import {RequestHandler} from 'src/app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { VentaModel } from 'src/app/models/ventas/venta.model';
import { Compradores } from 'src/app/models/ventas/compradores.model';
import { AlbaranModel } from 'src/app/models/ventas/albaran.model';

@Injectable({
    providedIn: 'root'
})
export class VentasApiService {

    public ventasGET = new RequestHandler<VentaModel[]>();
    public ventasPUT = new RequestHandler<VentaModel[]>();
    public ventasPOST = new RequestHandler<VentaModel[]>();
    public ventasDELETE = new RequestHandler<VentaModel>();

    public compradoresGET = new RequestHandler<Compradores[]>();
    public compradoresPUT = new RequestHandler<Compradores[]>();
    public compradoresPOST = new RequestHandler<Compradores[]>();
    public compradoresDELETE = new RequestHandler<Compradores>();

    public albaranesGET = new RequestHandler<AlbaranModel[]>();
    public albaranesPUT = new RequestHandler<AlbaranModel[]>();
    public albaranesPOST = new RequestHandler<AlbaranModel[]>();
    public albaranesDELETE = new RequestHandler<AlbaranModel>();

    constructor(
        private syncServiceVenta: SyncService<VentaModel[]>,
        private syncServiceVentaDelete: SyncService<VentaModel>,
        private syncServiceComprador: SyncService<Compradores[]>,
        private syncServiceCompradorDelete: SyncService<Compradores>,
        private apiVenta: ApiRequestService<VentaModel[]>,
        private apiVentaDelete: ApiRequestService<VentaModel>,
        private apiComprador: ApiRequestService<Compradores[]>,
        private apiCompradorDelete: ApiRequestService<Compradores>) {

        this.defineVentasGET();
        this.defineVentasPUT();
        this.defineVentasPOST();
        this.defineVentasDELETE();

        this.defineCompradoresGET();
        this.defineCompradoresPUT();
        this.defineCompradoresPOST();
        this.defineCompradoresDELETE();
    }

    private defineVentasGET() {
        this.ventasGET
            .fetch(HttpRequestType.GET, 'ventas/ventas');

        this.apiVenta
            .registerRequest(this.ventasGET);

        this.syncServiceVenta
            .register(this.syncServiceVenta.baseSyncPolicy(this.ventasGET));
    }

    private defineVentasPUT() {
        this.ventasPUT
            .fetch(HttpRequestType.PUT, 'generic/ventas');

        this.apiVenta
            .registerRequest(this.ventasPUT);

        this.syncServiceVenta
            .register(this.syncServiceVenta.baseSyncPolicy(this.ventasPUT));
    }

    private defineVentasPOST() {
        this.ventasPOST
            .fetch(HttpRequestType.POST, 'generic/ventas');

        this.apiVenta
            .registerRequest(this.ventasPOST);

        this.syncServiceVenta
            .register(this.syncServiceVenta.baseSyncPolicy(this.ventasPOST));
    }

    private defineVentasDELETE() {
        this.ventasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/ventas');

        this.apiVentaDelete
            .registerRequest(this.ventasDELETE);

        this.syncServiceVentaDelete
            .register(this.syncServiceVentaDelete.baseSyncPolicy(this.ventasDELETE));
    }

    private defineCompradoresGET() {
        this.compradoresGET
            .fetch(HttpRequestType.GET, 'ventas/compradores');

        this.apiComprador
            .registerRequest(this.compradoresGET);

        this.syncServiceComprador
            .register(this.syncServiceComprador.baseSyncPolicy(this.compradoresGET));
    }

    private defineCompradoresPUT() {
        this.compradoresPUT
            .fetch(HttpRequestType.PUT, 'generic/comercios');

        this.apiComprador
            .registerRequest(this.compradoresPUT);

        this.syncServiceComprador
            .register(this.syncServiceComprador.baseSyncPolicy(this.compradoresPUT));
    }

    private defineCompradoresPOST() {
        this.compradoresPOST
            .fetch(HttpRequestType.POST, 'generic/comercios');

        this.apiComprador
            .registerRequest(this.compradoresPOST);

        this.syncServiceComprador
            .register(this.syncServiceComprador.baseSyncPolicy(this.compradoresPOST));
    }

    private defineCompradoresDELETE() {
        this.compradoresDELETE
            .fetch(HttpRequestType.DELETE, 'generic/comercios');

        this.apiCompradorDelete
            .registerRequest(this.compradoresDELETE);

        this.syncServiceCompradorDelete
            .register(this.syncServiceCompradorDelete.baseSyncPolicy(this.compradoresDELETE));
    }
}


