import { BaseApi } from './base-api.class';
import { UserModel } from '../../models/usuarios/user.model';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseResponseModel } from 'src/app/models/base-response.model';
import { ParamsInterface } from '../OffService/params.interface';

@Injectable({
    providedIn: 'root'
})
export class UserApiService extends BaseApi<UserModel> {

    constructor(_: HttpClient) {
        super(_);
    }

    public postUser(user: ParamsInterface): Promise<UserModel> {
        return this.post('user/register', user)
            .then<UserModel>(this.getEntityData.bind(this));
    }

    public sendEmailConfirmation(data: UserModel): Promise<BaseResponseModel<UserModel>> {
        return this.post('user/sendEmailConfirmation', {
            id: data.id,
            usuario: data.usuario ?? '',
            email: data.email ?? '',
            estado: data.estado ?? ''
        });
    }
}
