import { DashboardService } from '../../../../../service/events/dashboard.service';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormCommonApiService } from '../../../../../service/api/formcommon-api.service';
import { BaseForm } from '../../../../base-form';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { build, list, pool } from '../../../../../common/classes/request-builder';
import { AppFormRequest } from '../../../../app-common/form-request/app-form-request';
import { Filtering } from '../../../../../service/filtering/filtering';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { environment } from '../../../../../../environments/environment';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { MapaParcelasApiService } from '../../../../../service/api/mapa-parcelas-api.service';
import { VisitasVariedadMesApiService } from '../../../../../service/api/visitas_variedad_mes-api.service';
import { DialogSigPacComponent } from './dialog/dialog.sigpac.component';
import { VentasApiService } from '../../../../../service/api/ventas-api.service';
import { SiexApiService } from '../../../../../service/api/siex-api.service';
import { UsuariosApiService } from '../../../../../service/api/usuarios-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface, TypeAheadInterface } from '../../../../../common/components/form-builder/form-builder.component';
import type { ReferenciaCatastral } from './dialog/dialog.sigpac.component';

import { NgZone } from '@angular/core';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { FullMapModel } from 'src/app/models/form-common/full-map.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { Compradores } from 'src/app/models/ventas/compradores.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';


const parcelaName = environment.features.parcelaName;
const TRUTHY_VALUES = [1, '1', true, 'true'];

@Component({
    selector: 'app-parcelas-form',
    templateUrl: './parcelas-form.component.html',
    styleUrls: ['./parcelas-form.component.scss']
})
export class ParcelasFormComponent extends BaseForm<ParcelasModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<ParcelasModel>();
    @ViewChild('sigpac', { read: DialogSigPacComponent, static: true }) sigpacDialog: DialogSigPacComponent | undefined;

    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public showMapas = environment.features.showMapas;
    public showSigPacParcela = environment.features.showSigPacParcela;
    public showSectores = environment.features.showSectores;
    public variedadCanBeVarios = environment.features.variedadCanBeVarios;
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;
    public showDatosMedioambientales = environment.features.showDatosMedioambientales;
    public showParcelasEstacion = environment.features.showParcelasEstacion;
    public showParcelasCodigoTrazabilidad = environment.features.showParcelasCodigoTrazabilidad;
    public customMapColorsList: Record<string,string> = environment.features.customMapColorsList;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public otros = environment.features.showParcelasPartida;
    public showParcelasComercioDropdown = environment.features.showParcelasComercioDropdown;
    public showParcelasTurbo = environment.features.showParcelasTurbo;
    public showParcelasLote = environment.features.showParcelasLote;
    public textoSuperficie = environment.features.textoSuperficie;
    public activateApiSIEX = environment.features.activateSIEX;
    public hasPlantas = environment.features.hasPlantas;

    public override model: ParcelasModel = {
        activo: '1',
        turbo: '1',
        mapa: [{} as FullMapModel] 
    } as ParcelasModel;
    public mapaAux = {} as FullMapModel;
    public variedadVariosCreated = false;
    public formRequest = new AppFormRequest<ParcelasModel>();

    public fincas: TypeAheadInterface<FincasModel> = list();
    public sectores: TypeAheadInterface<SectorModel> = list();
    public cultivos: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public variedades: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public cultivosLegacy: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public variedadesLegacy: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public comercios: TypeAheadInterface<Compradores> = list();
    public aplicadores: TypeAheadInterface<MantenimientoModel> = list();

    public secanoRegadio: TypeAheadInterface<string> = list(
        ['...', null],
        ['Secano', 'S'],
        ['Regadío', 'R']
    );
    public sistemasRiego: TypeAheadInterface<string> = list();

    public usoSigpac: TypeAheadInterface<string> = list(
        ['...', null],
        ['Corrientes y superficies de agua (AG)', 'AG'],
        ['Viales (CA)', 'CA'],
        ['Cítricos-Frutal (CF)', 'CF'],
        ['Cítricos (CI)', 'CI'],
        ['Cítricos-Frutal de cáscara (CS)', 'CS'],
        ['Cítricos-Viñedo (CV)', 'CV'],
        ['Edificaciones (ED)', 'ED'],
        ['Elemento del Paisaje (EP)', 'EP'],
        ['Frutal de Cáscara-Frutal (FF)', 'FF'],
        ['Frutal de Cáscara-Olivar (FL)', 'FL'],
        ['Forestal (FO)', 'FO'],
        ['Frutal de Cáscara (FS)', 'FS'],
        ['Frutal de Cáscara-Viñedo (FV)', 'FV'],
        ['Frutal (FY)', 'FY'],
        ['Improductivo (IM)', 'IM'],
        ['Invernaderos y cultivos bajo plástico (IV)', 'IV'],
        ['Olivar-Cítricos (OC)', 'OC'],
        ['Olivar-Frutal (OF)', 'OF'],
        ['Olivar (OV)', 'OV'],
        ['Pasto arbolado (PA)', 'PA'],
        ['Pasto arbustivo (PR)', 'PR'],
        ['Pastizal (PS)', 'PS'],
        ['Tierra arable (TA)', 'TA'],
        ['Huerta (TH)', 'TH'],
        ['Frutal-Viñedo (VF)', 'VF'],
        ['Viñedo (VI)', 'VI'],
        ['Olivar-Viñedo (VO)', 'VO'],
        ['Zona concentrada (ZC)', 'ZC'],
        ['Zona urbana (ZU)', 'ZU'],
        ['Zona censurada (ZV)', 'ZV']
    );

    public patron: TypeAheadInterface<string> = list(
        ['...', null]
    );

    public patronAguacate: TypeAheadInterface<string> = list(
        ['...', null],
        'Ashdot 17',
        'Clon Atkinson',
        'Clon Bounty',
        'Clon Duke-7',
        'Clon Dusa',
        'Clon Thomas',
        'Clon Toro Canyon',
        'Criollo Antillano',
        'Criollo Mexicano',
        'Dgania 117',
        'Duke-7 (Semilla)',
        'Lula',
        'Maoz',
        'Mexicola',
        'Nachar',
        'Topa-Topa',
        'Water-Hole',
        'Zutano'
    );

    public regimenTenencia: TypeAheadInterface<string> = list(
        ['...', null],
        'Propiedad',
        'Arrendamiento',
        'Aparcería',
        'Otros'
    );
    public regimenTenenciaProdalbar: TypeAheadInterface<string> = list(
        ['...', null],
        'Propiedad',
        'Arrendamiento'
    );

    public patronAlmendro: TypeAheadInterface<string> = list(
        ['...', null],
        'Franco de semilla'
    );
    public patronManzano: TypeAheadInterface<string> = list(
        ['...', null],
        // Fuji
        'Kiku',
        'Fubrax',
        'Zhen Aztec',
        'San',
        'September Wonder',
        // Gala
        'Brookfield',
        'Scniga',
        'Vinus',
        'Schnicored',
        // Golden'
        'Delicious',
        'Smoothee',
        'Reinders',
        'Parsi',
        // Granny Smith
        'Challenger',
        // Red Delicious
        'Starking',
        'Golden Rose',
        'Jeromine',
        'Scarlet Spur',
        // Reineta
        'Blanca',
        'Parda',
        // Otros patrones
        'M12',
        'M7',
        'M9',
        'MM106'
    );
    public patronMelocotoneroParaguayo: TypeAheadInterface<string> = list(
        ['...', null],
        'Franco',
        'GF677',
        'Garmen',
        'Rootpac 40',
        'Rootpac 60'
    );

    public patronMango: TypeAheadInterface<string> = list(
        ['...', null],
        '13-1',
        'Banilejo',
        'Criollo',
        'Gomera-1',
        'Gomera-3'
    );
    public patronMelocotonero: TypeAheadInterface<string> = list(
        ['...', null],
        'GF-677', 'GxN Garnem', 'Monclart'
    );
    public patronNogal: TypeAheadInterface<string> = list(
        ['...', null],
        'Juglans regia', 'Mj209 x Ra'
    );
    public patronCerezo: TypeAheadInterface<string> = list(
        ['...', null],
        'Adara', 'Avium', 'Cab6', 'Col', 'Marina-Adara', 'Maxma', 'Santa Lucía', 'Santa Lucía 64'
    );
    public patronCiruelo: TypeAheadInterface<string> = list(
        ['...', null],
        'Mariana', 'Melocotonero', 'Mirobolán C'
    );
    public patronCitricos: TypeAheadInterface<string> = list(
        ['...', null],
        'C-35', 'Carrizo', 'Citrumelo', 'Citrumelo CPB 4475', 'Cleopatra', 'Forner Alcaide',
        'Macrophylla', 'Naranjo Amargo', 'Naranjo Borde', 'Troyer', 'Volkameriana',
    );
    public patronPera: TypeAheadInterface<string> = list(
        ['...', null],
        'BA-29', 'EM-C', 'Franco', 'Membrillero'
    );
    public patronVina: TypeAheadInterface<{codigo: string;}> = list( // Portainjerto
    );

    public cama: TypeAheadInterface<string> = list( // CartagenaFresh
        ['...', null],
        ['1.80', '1.80'], ['90', '90'], ['60', '60']
    );

    public formatoMomparler: TypeAheadInterface<string> = list(
        ['...', null]
        , '104'
        , '102'
        , '40 '
        , '252'
        , '160'
        , '15 '
        , '77 '
        , '51 '
    );

    public sistemaAsesoramiento: TypeAheadInterface<string> = list(
        ['...', null],
        ['Agricultura Ecológica', 'AE'],
        ['Producción Integrada', 'PI'],
        ['Certificación Privada', 'CP'],
        ['Agrupación de Tratamiento Integrado en Agricultura', 'ATRIAS'],
        ['Asistida de un Asesor', 'AS'],
        ['Agrupación Oficial Reconocida', 'AO'],
        ['Guías de cultivo', 'GC'],
        ['Asesor individual', 'AI'],
        ['Sin obligación de aplicar la GIP', 'NO']
    );

    public sistemasCultivo: TypeAheadInterface<string> = list(
        ['...', null],
        ['Aire libre', '1'],
        ['Malla', '2'],
        ['Cubierta no accesible', '3'],
        ['Invernadero (cubierta accesible)', '4'],
        ['Forestales en rotación corta', '5'],
        ['Micorrizado', '6'],
        ['Acolchado (SG ACE)', '7'],
        ['Enarenado (SG ACE)', '8'],
        ['Bodega Sacos (Setas/champiñones)', '9'],
        ['Bodega Bandejas (Setas/champiñones)', '10'],
        ['Bodega Botellas (Setas/champiñones)', '11'],
        ['Bodega Otros (Setas/champiñones)', '12'],
        ['Nave Sacos (Setas/champiñones)', '13'],
        ['Nave Bandejas (Setas/champiñones)', '14'],
        ['Nave Botellas (Setas/champiñones)', '15'],
        ['Nave Otros (Setas/champiñones)', '16'],
        ['Hoyos (Viñedo Lanzarote)', '17'],
        ['Zanjas (Viñedo Lanzarote)', '18'],
        ['Invernadero (cubierta accesible)+ enarenado', '19'],
    );

    public observacionesForestales: TypeAheadInterface<string> = list(
        ['...', null],
        ['EUCALIPTO', '225'],
        ['PAULONIA', '200'],
        ['CHOPO', '116'],
        ['SAUCE', '227'],
        ['ROBINIA', '230'],
        ['MIMBRERA', '442'],
    );
    public uds_bandeja: TypeAheadInterface<string> = list(
        ['...', ''],
        ['84', '84'],
        ['144', '144'],
        ['240', '240'],
        ['Semillero', 'Semillero']
    );

    public tipoSiembra: TypeAheadInterface<string> = list(
        ['...', null],
        ['Siembra Directa', 'SD'],
        ['Trasplante', 'TR']
    );

    public filtroCultivosYagoAznar = ['CEBADA', 'AVENA', 'OLIVO', 'VIÑA', 'ALMENDRO', 'BARBECHO TRADICIONAL'];


    public formFieldsDatosParcela: ItemInterface<object>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: { type: InputType.DROPDOWN_SEARCH },
            required: true,
            values: this.fincas,
            valuePrimaryKey: 'id'
        },
        {
            field: 'id_sector',
            label: this.sectorName,
            inputType: { type: InputType.DROPDOWN },
            values: this.sectores,
            valuePrimaryKey: 'id',
            filter: (it: { value: SectorModel }) => it.value.id_finca === (this.fincas.selected || {}).id,
            visible: this.showSectores
        },
        {
            field: 'numero_parcela_agricola',
            label: 'Número de Parcela Agrícola',
            inputType: { type: InputType.EDIT_NUMERIC },
            visible: this.appName === 'agropecuariaramirogruas' || this.appName === 'prodalbar'
        },
        {
            field: 'pendiente',
            label: '% Pendiente',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'id_cultivo',
            label: this.appName === 'momparler' ?
                'Género/Especie SIEX' :
                environment.features.showTareasMto ?
                    'Especie SIEX' :
                    'Cultivo SIEX',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.cultivos,
            valuePrimaryKey: 'id',
            filter: (it: { value: { cultivo: string; }; }) => {
                if (this.appName === 'yagoaznar') {
                    const cultivoEncontrado = this.filtroCultivosYagoAznar.find((cultivo) => cultivo === it.value.cultivo);

                    if (cultivoEncontrado) {
                        return it;
                    }
                } else {
                    return it;
                }
                return false;
            },
            visible: this.activateApiSIEX,
            required: !environment.features.showTareasMto && this.appName !== 'agrosalvi'
        },
        {
            field: 'id_variedad',
            label: 'Variedad SIEX',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.variedades,
            valuePrimaryKey: 'id',
            filter: (it: { value: VisitasVariedadMesModel; }) => it.value.codigo_cultivo === (this.cultivos.selected || {}).codigo,
            visible: this.activateApiSIEX,
            required: (!this.variedadCanBeVarios && !environment.features.showTareasMto) && this.appName !== 'agrosalvi'
        },
        {
            field: 'id_cultivo_legacy',
            label: this.appName === 'momparler' ? 'Género/Especie' : environment.features.showTareasMto ? 'Especie' : 'Cultivo',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.cultivosLegacy,
            valuePrimaryKey: 'id',
            filter: Filtering.distinctBy.bind({ field: 'label' }),
            required: !environment.features.showTareasMto && this.appName !== 'agrosalvi',
            visible: this.hasPlantas || !this.activateApiSIEX,
        },
        {
            field: 'id_variedad_legacy',
            label: 'Variedad',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.variedadesLegacy,
            valuePrimaryKey: 'id',
            filter: (it: { value: VisitasVariedadMesModel }) => {
                const match = it.value.cultivo === (this.cultivosLegacy.selected || {}).cultivo;

                if (match) {
                    this.cultivosLegacy.selected = this.cultivosLegacy.filtered
                        .filter(c => c && c.value)
                        .map(c => c.value)
                        .find(cultivo => cultivo?.cultivo === it.value.cultivo) as VisitasVariedadMesModel;
                }

                return match;
            },
            required: (!this.variedadCanBeVarios && !environment.features.showTareasMto) && this.appName !== 'agrosalvi',
            visible: this.hasPlantas || !this.activateApiSIEX
        },
        {
            field: 'marco',
            label: this.appName === 'cartagenafresh' ? 'Nº Cintas Riego' : 'Marco Plantación (Filas x Plantas)',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.features.showMarcoPlantacion
        },
        {
            field: 'pies',
            label: 'Pies',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.appName === 'yagoaznar'
        },
        {
            field: 'anyo_plantacion',
            label: this.appName === 'cartagenafresh' ? 'Semana Plantación' : 'Año Plantación',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.features.showAnyoPlantacion
        },
        {
            field: 'plantas',
            label: this.appName === 'cartagenafresh' ? 'Kg/Ha' : 'Nº Árboles/Plantas',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.features.showPlantas
        },
        {
            field: 'patron',
            label: (this.cultivos.selected || {}).cultivo === 'VIÑA' ?
                'Portainjertos' :
                this.appName === 'momparler' ?
                    'Formato' :
                    this.appName === 'cartagenafresh' ?
                        'Cama' :
                        'Patrón',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.appName === 'momparler' ? this.formatoMomparler : this.appName === 'cartagenafresh' ? this.cama : this.patron,
            visible: environment.features.showPatron
        },
        {
            field: 'diametro_copa',
            label: 'Diámetro de Copa',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.appName === 'agufert'
        },
        {
            field: 'captacion_agua',
            label: 'Captación Agua',
            inputType: { type: InputType.SWITCH },
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'zonas_especificas',
            label: 'Zonas Específicas',
            inputType: { type: InputType.SWITCH },
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'zona_vulnerable',
            label: 'Zona Vulnerable (ha)',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'herbaceo_lenyoso',
            label: 'Herbáceo / Leñoso',
            inputType: { type: InputType.SWITCH },
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'producto',
            label: 'Producto',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.appName === 'bravosol'
        },
        {
            field: 'prevision_produccion',
            label: 'Previsión Producción',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.appName === 'icod'
        },
        {
            field: 'codigo_trazabilidad',
            label: 'Código de trazabilidad',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.showParcelasCodigoTrazabilidad
        },
        {
            field: 'lote',
            label: 'Nº lote',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.showParcelasLote
        },
        {
            field: 'pozo',
            label: 'Pozo',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.appName === 'pyf'
        },
        {
            field: 'rios',
            label: 'Rios',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.appName === 'pyf'
        },
        {
            field: 'estacion_control',
            label: 'Estación de control',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.appName === 'pyf'
        },


        {
            field: 'regimen_tenencia',
            label: 'Régimen tenencia',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.appName === 'prodalbar' ? this.regimenTenenciaProdalbar : this.regimenTenencia,
            visible: environment.appName === 'pyf' || this.appName === 'prodalbar'
        },
        {
            field: 'produccion_ecologica',
            label: 'Producción Ecológica',
            inputType: { type: InputType.SWITCH },
            visible: this.appName === 'prodalbar'
        },
        {
            field: 'uds_bandeja',
            label: 'Uds. Bandeja',
            inputType: { type: InputType.DROPDOWN },
            values: this.uds_bandeja,
            visible: this.appName === 'viverospereira'
        },
        {
            field: 'distancia_menor_50',
            label: 'DISTANCIA MENOR A 50m',
            inputType: { type: InputType.SWITCH },
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'tipo_siembra',
            label: 'Tipo de Siembra',
            inputType: { type: InputType.DROPDOWN },
            values: this.tipoSiembra,
            visible: this.appName === 'pedrosaez'
        },
        {
            field: 'aplicadores',
            label: 'Aplicadores',
            inputType: { type: InputType.MULTISELECT },
            valuePrimaryKey: 'id',
            values: this.aplicadores,
            multiSelect: true,
            visible: this.appName === 'sequiador' &&
                (StorageManager.getUser().tipo === 'comunero' ||
                    StorageManager.getUser().rol === 'admin'),
            filter: (it: {selected: MantenimientoModel[]; value: MantenimientoModel }) => {
                if (it.value.rol === 'responsable') {
                    if (it.value.nombre === 'ROTORSUN') {

                        if (it.selected.indexOf(it.value) < 0) {
                            it.selected.push(it.value);
                        }
                    }
                    return true;
                }
                return false;
            }
        },

        { 
            label: 'Activo', 
            field: 'activo', 
            inputType: { type: InputType.SWITCH }, 
            visible: this.showAplicadoresActivo 
        },
        {
            label: 'Procedencia Agua/Origen',
            field: 'procedencia_agua',
            inputType: { type: InputType.DROPDOWN },
            values: list('Regeneración', 'Subterránea', 'Superficial'),
            visible: this.applicationType === 'cropgest',
        },
        {
            label: 'Linde',
            field: 'linde',
            inputType: { type: InputType.SWITCH },
            visible: this.applicationType === 'cropgest',
        },
        {
            label: 'Notas',
            field: 'notas',
            inputType: { type: InputType.EDIT_TEXTAREA },
            visible: this.appName === 'agroleza'
        }
    ];

    public formFieldsNumMetros: ItemInterface<object>[] = [
        {
            field: 'numero_metros',
            label: 'Número de Metros',
            inputType: { type: InputType.EDIT_TEXT }
        },

    ];

    public formFieldsDatosParcelaOtros: ItemInterface<object>[] = [
        { 
            field: 'partida', 
            label: 'Partida', 
            inputType: { type: InputType.EDIT_TEXT }, 
            visible: environment.features.showParcelasPartida 
        },
        {
            field: 'comercio',
            label: 'Comercio',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.features.showParcelasComercio && !this.showParcelasComercioDropdown
        },
        {
            field: 'comercio',
            label: 'Comercio',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.comercios,
            valuePrimaryKey: 'id',
            visible: this.showParcelasComercioDropdown
        },
        { 
            field: 'pozos', 
            label: 'Pozos', 
            inputType: { type: InputType.EDIT_TEXT }, 
            visible: environment.features.showParcelasPozos 
        },
        { 
            field: 'turbo', 
            label: 'Turbo', 
            inputType: { type: InputType.SWITCH }, 
            visible: environment.features.showParcelasTurbo 
        },
    ];

    public formFieldsDatosSigpac: ItemInterface<object>[] = [
        {
            field: 'nombre',
            label: `Nombre ${this.appName === 'gardengest' ? 'del' : 'de la'} ${this.parcelaName}`,
            inputType: { type: InputType.EDIT_TEXT },
            required: true
        },
        {
            field: 'uso_sigpac',
            label: 'Uso SigPAC',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.usoSigpac,
        },
        { 
            field: 'superficie_sigpac', 
            label: 'Superficie SigPAC (' + this.textoSuperficie + ')', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        {
            field: 'superficie_cultivada',
            label: 'Superficie Cultivada (' + this.textoSuperficie + ')',
            inputType: { type: InputType.EDIT_TEXT }
        },
        { 
            field: 'cod_provincia', 
            label: 'Cod. Provincia', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'municipio', 
            label: 'Municipio', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'poligono', 
            label: 'Polígono', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'numero', 
            label: 'Nº Parcela', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'recinto', 
            label: 'Recinto', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'agregado', 
            label: 'Agregado', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'orden', 
            label: 'Zona', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'sigpac', 
            label: 'SigPAC', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'coordenadas', 
            label: 'Coordenadas', 
            inputType: { type: InputType.ONLY_TEXT }, 
            visible: this.appName === 'agrosalvi' 
        }
    ];

    public formFieldsDatosGenerales: ItemInterface<object>[] = [
        { 
            field: 'codigo_secano_regadio', 
            label: 'Secano / Regadío', 
            inputType: { type: InputType.DROPDOWN }, 
            values: this.secanoRegadio 
        },
        {
            field: 'codigo_sistema_riego',
            label: '',
            inputType: { type: InputType.DROPDOWN },
            values: this.sistemasRiego,
            valuePrimaryKey: 'codigo',
            filter: () => this.secanoRegadio.selected === 'R',
            style: { 'display': 'none' }
        },
        {
            field: 'codigo_sistema_cultivo',
            label: 'Sistema de Cultivo',
            inputType: { type: InputType.DROPDOWN },
            values: this.sistemasCultivo
        },
        {
            field: 'codigo_observaciones_forestales',
            label: '',
            inputType: { type: InputType.DROPDOWN },
            values: [],
            style: { 'display': 'none' }
        },
        {
            field: 'sistema_asesoramiento',
            label: 'Sistema de asesoramiento',
            inputType: { type: InputType.DROPDOWN },
            values: this.sistemaAsesoramiento
        },
        {
            field: 'estacion',
            label: 'Estación',
            inputType: { type: InputType.DROPDOWN },
            values: list(['...', null], ['I - Invierno', 'I'], ['P - Primavera', 'P']),
            visible: this.showParcelasEstacion && this.appName !== 'pyf'
        },

    ];

    public formFieldsDatosMedioambientales: ItemInterface<object>[] = [
        { 
            field: 'incluido_en_parcela', 
            label: 'Incluido en la parcela', 
            inputType: { type: InputType.SWITCH } 
        },
        { 
            field: 'distancia', 
            label: 'Distancia (m3)', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'coordenadas_utm', 
            label: 'Coordenadas UTM', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'denominacion', 
            label: 'Denominación', 
            inputType: { type: InputType.EDIT_TEXT } 
        }
    ];

    public formFieldsZonasEspecificas: ItemInterface<object>[] = [
        { field: 'zona_total', label: 'Totalmente', inputType: { type: InputType.SWITCH } },
        { field: 'zona_parcial', label: 'Parcialmente', inputType: { type: InputType.SWITCH } }
    ];

    public mapaAbierto = false;
    public origin = 'parcelas-form';
    public finca = 'finca';
    public localidad = 'localidad';
    public cultivo = 'cultivo';
    public variedad = 'variedad';
    public parcelaNombre = 'nombre parcela';
    public mapaOpened = false;
    public viewNumeroMetros = false;

    /**
     * Variables que hara uso el cliente 'Momparler' para asignar el nombre de la parcela dependiendo del
     * cultivo, variedad y patron que haya escogido.
     */
    public parcelaNombreCultivo = '';
    public parcelaNombreVariedad = '';
    public parcelaNombrePatron = '';

    constructor(
        public override route: ActivatedRoute,
        public override router: Router,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public mapaParcelasApi: MapaParcelasApiService,
        public override dashboard: DashboardService,
        public visitasVariedadMesApi: VisitasVariedadMesApiService,
        private ventasApi: VentasApiService,
        public siexApi: SiexApiService,
        public usuariosAPI: UsuariosApiService,
        private zone: NgZone,
    ) {
        super(
            route,
            router,
            dashboard,
            fincasApi.parcelasPUT,
            fincasApi.parcelasPOST,
            'parcelas',
            'Editar ' + parcelaName,
            'Crear ' + parcelaName
        );
    }

    ngOnInit() {
        if (this.appName === 'agrosalvi') {
            this.model.coordenadas = this.model.mapa?.[0]?.coordenadas ?? '';
        }

        // HACK TEMPORAL
        this.fincasApi.parcelasGET.response(res => {

            if (this.formRequest.type === FormRequestTypes.EDIT && (res.length > 1)) {
                res.map((element: ParcelasModel) => {
                    if (element.id.toString() === this.formRequest.registerId.toString()) {
                        this.formRequest.afterLoad(() => {
                            this.model = element;
                        });
                    }
                });
            }
        });

        this.fincasApi.parcelasPOST.response(res => {
            if (res && res[0] && this.mapaAux.coordenadas) {
                this.mapaAux.id_parcela = res[0].id;
                if (this.hasClients) {
                    this.mapaAux.id_cliente = res[0].id_cliente ?? '';
                }
                this.mapaParcelasApi.mapaParcelasPOST.toPromise([this.mapaAux]);
            }
        });

        this.fincasApi.parcelasPUT.response(res => {
            if (res && res[0] && this.mapaAux.coordenadas) {
                this.mapaAux.id_parcela = res[0].id;
                if (this.hasClients) {
                    this.mapaAux.id_cliente = res[0].id_cliente ?? '';
                }
                if (this.mapaAux.id) {
                    this.mapaParcelasApi.mapaParcelasPUT.toPromise([this.mapaAux]);
                } else {
                    this.mapaParcelasApi.mapaParcelasPOST.toPromise([this.mapaAux]);
                }
            }
        });

        this.initFormData();
        if (this.appName === 'ava' && this.getType() === 0) {
            this.fincasApi.fincasGET.toPromise().then((resp_fincas: FincasModel[]) => {

                if (resp_fincas.length > 0) {
                    this.model.id_finca = resp_fincas[0]?.id ?? '';
                }
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
        this.expandFormRequest();

        let fieldsToSend = (['id', 'id_usuario', 'cultivo', 'variedad'])
            .concat(
                this.formFieldsDatosParcela.map(it => it.field ?? ''),
                this.formFieldsDatosParcelaOtros.map(it => it.field ?? ''),
                this.formFieldsDatosSigpac.map(it => it.field ?? ''),
                this.formFieldsDatosGenerales.map(it => it.field ?? '')
            );
        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }
        if (this.formFieldsDatosMedioambientales) {
            fieldsToSend = fieldsToSend.concat(
                this.formFieldsDatosMedioambientales.map(it => it.field ?? ''),
                this.formFieldsZonasEspecificas.map(it => it.field ?? '')
            );
        }
        if (this.appName === 'agropecuariaramirogruas') {
            fieldsToSend = fieldsToSend.concat(this.formFieldsNumMetros.map(it => it.field ?? ''));
        }
        this.formRequest
            .setType(this.getType())
            .isGeneric(false)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.fincasApi.parcelasGET)
            .setPostRequest(this.fincasApi.parcelasPOST)
            .setPutRequest(this.fincasApi.parcelasPUT)
            .setFormFields(Array.prototype
                .concat(
                    this.formFieldsDatosParcela,
                    this.formFieldsDatosParcelaOtros,
                    this.formFieldsDatosSigpac,
                    this.formFieldsDatosGenerales
                )
            )
            .setFieldsToRetain(['id_finca'])
            .setFieldsToSend(fieldsToSend);


        this.formRequest.load();

        this.softInit(this.getType());

    }

    openSigpac() {
        this.sigpacDialog?.openDialog();
    }

    getSigPac(event: object) {
        const rc = event as ReferenciaCatastral;
        this.model.cod_provincia = rc.provincia ?? '';
        this.model.municipio = rc.municipio ?? '';
        this.model.poligono = rc.poligono ?? '';
        this.model.numero = rc.parcela ?? '';
    }

    public submit() {
        this.model.cultivo = (
            (this.hasPlantas || !this.activateApiSIEX) ?
                this.cultivosLegacy.selected?.cultivo :
                this.cultivos.selected?.cultivo) ?? '';
        this.model.variedad = (
            (this.hasPlantas || !this.activateApiSIEX) ?
                this.variedadesLegacy.selected?.variedad :
                this.variedades.selected?.variedad) ?? '';

        if (!this.model.cultivo) {
            this.model.id_cultivo = '';
            this.model.id_variedad = '';
        }

        if (this.showSectores) {
            if (this.sectores.selected == null) {
                this.model.id_sector = null;
            }
        }

        if (this.appName !== 'agrosalvi') {
            delete this.model.coordenadas;
        }
        if (this.appName !== 'agropecuariaramirogruas') {
            delete this.model.pendiente;
            delete this.model.captacion_agua;
            delete this.model.zonas_especificas;
            delete this.model.distancia_menor_50;
            delete this.model.numero_metros;
            delete this.model.zona_vulnerable;
            delete this.model.herbaceo_lenyoso;
        }

        if (this.appName !== 'agropecuariaramirogruas' && this.appName !== 'prodalbar') {
            delete this.model.numero_parcela_agricola;
        }

        if (this.appName !== 'prodalbar') {
            delete this.model.produccion_ecologica;
        }

        if (this.appName !== 'viverospereira') {
            delete this.model.uds_bandeja;
        }

        if (this.appName !== 'pedrosaez') {
            delete this.model.tipo_siembra;
        }

        if (this.appName !== 'yagoaznar') {
            delete this.model.pies;
        }

        if (this.applicationType !== 'cropgest') {
            delete this.model.linde;
        }

        if (this.appName !== 'agroleza') {
            delete this.model.notas;
        }

        delete this.model.id_parcela;

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        this.formRequest.send();
    }


    public reloadComponent() {
        this.mapaOpened = false;
        window.location.reload();
    }


    public formChanges(tag: string) {
        this.requestButton.error = '';



        if (tag === 'codigo_sistema_cultivo') {
            const idxOF = this.formFieldsDatosGenerales.map(it => it.field).indexOf('codigo_observaciones_forestales');
            const obsForestales = this.formFieldsDatosGenerales[idxOF];
            if (obsForestales) {
                if (this.model.codigo_sistema_cultivo === '5') {
                    obsForestales.values = this.observacionesForestales;
                    obsForestales.label = 'Observaciones de Forestales';
                    obsForestales.style = { 'display': 'block' };
                } else {
                    obsForestales.label = '';
                    obsForestales.style = { 'display': 'none' };
                }
            }
        }

        if (tag === 'codigo_secano_regadio') {
            const idxSistemaRiego = this.formFieldsDatosGenerales.map(it => it.field).indexOf('codigo_sistema_riego');
            const sistemaRiego = this.formFieldsDatosGenerales[idxSistemaRiego];
            if (sistemaRiego) {
                if (this.model.codigo_secano_regadio === 'R') {
                    sistemaRiego.label = 'Sistema de Riego';
                    sistemaRiego.style = { 'display': 'block' };
                } else {
                    sistemaRiego.label = '';
                    sistemaRiego.style = { 'display': 'none' };
                }
            }
        }

        if (this.showParcelasTurbo && tag === 'turbo') {

            this.formFieldsDatosParcelaOtros.forEach((parcela, index) => {
                const turboParcela = this.formFieldsDatosParcelaOtros[index];
                if (parcela.field === 'turbo' && turboParcela) {
                    const turbo = TRUTHY_VALUES.includes(this.model.turbo ?? '');
                    if (turbo === true) {
                        turboParcela.label = 'Turbo Sí';
                    } else {
                        turboParcela.label = 'Turbo No';
                    }
                }
            });
        }

        if (tag === 'id_finca') {
            this.model.id_parcela = null;
        }

        if (tag === 'id_cultivo' && this.appName !== 'cartagenafresh') {
            this.model.id_variedad = null;
            const idxPatron = this.formFieldsDatosParcela.map(it => it.field).indexOf('patron');
            const patronCultivo = this.formFieldsDatosParcela[idxPatron];
            let cultivo: string;

            if (this.appName === 'momparler') {
                cultivo = this.cultivos.selected?.cultivo ?? '';
            } else {
                cultivo = this.cultivos.selected?.cultivo.toLowerCase() ?? '';
            }

            if (patronCultivo) {
                patronCultivo.values = this.getPatron(cultivo);

                if (cultivo === 'viña') {
                    patronCultivo.label = 'Portainjertos';
                } else {
                    if (this.appName === 'momparler') {
                        patronCultivo.label = 'Formato';
                        this.parcelaNombreCultivo = cultivo;
                    } else {
                        patronCultivo.label = 'Patrón';
                    }
                }
            }
        }

        if (tag === 'patron' && this.patronVina.selected) {
            const idxPatron = this.formFieldsDatosParcela.map(it => it.field).indexOf('patron');
            const patronCultivo = this.formFieldsDatosParcela[idxPatron];
            if (patronCultivo) {
                (patronCultivo.values as TypeAheadInterface<{codigo: string}>).selected =
                    this.patronVina.filtered.find(patron => patron.value && patron.value.codigo === this.model.patron)?.value as 
                    {codigo: string};
                this.model.patron = this.patronVina.selected.codigo;
            }
        }

        if (tag === 'distancia_menor_50') {
            this.showNumMetros();
        }

        // if(this.appName === 'momparler'){

        //     if(tag === 'patron') {
        //         this.parcelaNombrePatron = this.model.patron;
        //     }

        //     if(tag === 'id_variedad'){
        //         const idxVariedad = this.formFieldsDatosParcela.map(it => it.field ).indexOf('id_variedad');
        //         let variedad: string;
        //         if (this.formFieldsDatosParcela[idxVariedad].values.selected) {
        //             variedad = (this.formFieldsDatosParcela[idxVariedad].values.selected.variedad || '');
        //             this.parcelaNombreVariedad = variedad;
        //         }
        //     }
        //     if ( this.parcelaNombreCultivo == null ){
        //         this.parcelaNombreCultivo = '';
        //     }

        //     if ( this.parcelaNombreVariedad == null ){
        //         this.parcelaNombreVariedad = '';
        //     }

        //     if ( this.parcelaNombrePatron == null ){
        //         this.parcelaNombrePatron = '';
        //     }


        //     this.model.nombre = this.parcelaNombreCultivo + "-" + this.parcelaNombreVariedad + "-" + this.parcelaNombrePatron;    
        // }
    }

    public getPatron(cultivo: string) {
        switch (cultivo) {
        case 'aguacate': return this.patronAguacate;
        case 'almendro': return this.patronAlmendro;
        case 'cerezo': return this.patronCerezo;
        case 'ciruelo': return this.patronCiruelo;
        case 'cítrico': return this.patronCitricos;
        case 'cítrico mandarino': return this.patronCitricos;
        case 'clementina': return this.patronCitricos;
        case 'lima': return this.patronCitricos;
        case 'limoneros': return this.patronCitricos;
        case 'mandarinos': return this.patronCitricos;
        case 'mandarino': return this.patronCitricos;
        case 'mandarino híbrido': return this.patronCitricos;
        case 'mango': return this.patronMango;
        case 'manzano': return this.patronManzano;
        case 'melocotonero': return this.appName === 'agropedbel' ?
            this.patronMelocotoneroParaguayo :
            this.patronMelocotonero;
        case 'naranjos': return this.patronCitricos;
        case 'naranjo': return this.patronCitricos;
        case 'nogal': return this.patronNogal;
        case 'pera': return this.patronPera;
        case 'peral': return this.patronPera;
        case 'pomelo': return this.patronCitricos;
        case 'pomelos': return this.patronCitricos;
        case 'viña': return this.patronVina;
        case 'paraguayo': return this.patronMelocotoneroParaguayo;
        default: if (this.appName === 'momparler') { return this.formatoMomparler; } else { return this.patron; };
        }
    }

    public abreMapa() {
        this.mapaOpened = true;

        if (!this.model['id_finca']) {
            alert('Debe seleccionar una Finca!');
            return;
        }
        if (!this.model[this.activateApiSIEX ? 'id_cultivo' : 'id_cultivo_legacy']) {
            alert('Debe seleccionar un Cultivo!');
            return;
        }
        if (!this.model[this.activateApiSIEX ? 'id_variedad' : 'id_variedad_legacy'] && !this.variedadCanBeVarios) {
            alert('Debe seleccionar una Variedad!');
            return;
        }

        this.mapaAbierto = !this.mapaAbierto;
        this.finca = (this.formFieldsDatosParcela[0]?.values as TypeAheadInterface<FincasModel>).selected?.nombre || '';
        this.localidad = (this.formFieldsDatosParcela[0]?.values as TypeAheadInterface<FincasModel>).selected?.localidad || '';

        this.cultivo = this.cultivos.selected?.cultivo ?? '';

        if (this.variedadCanBeVarios && !this.model.id_variedad) {
            this.variedad = 'Varios';
        } else {
            this.variedad = this.variedades.selected?.variedad ?? '';
        }
        this.parcelaNombre = this.model.nombre || '';
    }

    public getFromMapa(datos: string) {
        this.zone.run(() => {
            const data = JSON.parse(datos);
    
            if (this.appName !== 'amoros') {
                this.model.cod_provincia = data.provincia;
                this.model.municipio = data.municipio;
                this.model.poligono = data.poligono;
                this.model.numero = data.parcela;
                this.model.recinto = data.recinto;
                this.model.superficie_sigpac = data.ha;
                this.model.sigpac = data.refpar;
                this.model.uso_sigpac = data.usoSigpac;
            }
    
            if (!this.model.mapa) {
                this.model.mapa = [{} as FullMapModel];
            }
    
            if (this.model.mapa[0]) {
                this.model.mapa[0].id_finca = this.model.id_finca ?? '';
                this.model.mapa[0].id_sector = this.model.id_sector ?? '';
                this.model.mapa[0].id_cultivo = (this.activateApiSIEX ? this.model.id_cultivo_legacy : this.model.id_cultivo) ?? '';
                this.model.mapa[0].id_usuario = StorageManager.getUser().id;
                this.model.mapa[0].coordenadas = data.coord;
                this.model.mapa[0].color = data.color;

                console.log('Coordenadas del FORM: ', this.model.mapa[0].coordenadas);
        
                if (data.nombreParcela !== '') {
                    this.model.nombre = data.nombreParcela;
                } else if (data.parcela !== '' && data.parcela != null) {
                    this.model.nombre = `${this.parcelaName} ${data.parcela}`;
                } else {
                    this.model.nombre = this.parcelaNombre;
                }
            
            }    
            this.mapaAbierto = false;
        });
    }
    

    public getMapColor(mapa: FullMapModel) {
        const defaultColor = '#88dd66';
        if (this.cultivos.selected && Object.keys(this.customMapColorsList).includes(this.cultivos.selected.cultivo)) {
            mapa.color = this.customMapColorsList[this.cultivos.selected.cultivo] ?? '';
        } else {
            mapa.color = defaultColor;
        }
        return mapa;
    }

    public showNumMetros() {

        if (this.appName === 'agropecuariaramirogruas') {

            if (this.model['distancia_menor_50'] != null) {

                if (TRUTHY_VALUES.includes(this.model['distancia_menor_50'])) {
                    this.viewNumeroMetros = true;
                } else {
                    delete this.model['numero_metros'];
                    this.viewNumeroMetros = false;
                }
            }

        }
    }

    private initFormData(): Promise<boolean> {
        const builds = [
            build(this.fincasApi.fincasGET, this.fincas, ['nombre']),
            build(this.fincasApi.sectoresGET, this.sectores, ['nombre'], true),
            build(this.siexApi.portainjertosGET, this.patronVina, ['sinonimo']),
            build(this.siexApi.sistemasRiegoGET, this.sistemasRiego, ['sistema'], true),
            build(this.fincasApi.sectoresGET, this.sectores, ['nombre'], true),
            build(this.ventasApi.compradoresGET, this.comercios, 'razon_social'),
            build(this.usuariosAPI.usuariosGET, this.aplicadores,
                (it: MantenimientoModel) => it.nombre + ' ' + (it.apellidos || ''), true),
        ];

        if (this.hasPlantas || !this.activateApiSIEX) {
            builds.push(
                build(this.formApi.cultivosGET, this.cultivosLegacy, ['cultivo'], true),
                build(this.formApi.variedadesGET, this.variedadesLegacy, ['variedad'], true)
            );
        }

        if (this.activateApiSIEX) {
            builds.push(
                build(this.siexApi.cultivosGET, this.cultivos, ['cultivo'], true),
                build(this.siexApi.variedadesGET, this.variedades, ['variedad'], true)
            );
        }

        return pool(builds, () => {
            setTimeout(() => {

                if (this.model.id_cultivo) {
                    const idxPatron = this.formFieldsDatosParcela.map(it => it.field).indexOf('patron');
                    const patronCultivo = this.formFieldsDatosParcela[idxPatron];
                    const cultivo = this.cultivos.selected;

                    if (patronCultivo) {
                        if (this.appName === 'cartagenafresh') {
                            patronCultivo.values = this.cama;
                        } else {
                            patronCultivo.values = this.getPatron(cultivo?.cultivo ?? '');

                            if (cultivo?.cultivo === 'viña') {
                                patronCultivo.label = 'Portainjertos';

                                (patronCultivo.values as TypeAheadInterface<{codigo: string}>).selected =
                                    this.patronVina.filtered.find(
                                        patron => patron.value && patron.value.codigo === this.model.patron
                                    )?.value as {codigo: string};

                                this.patronVina.selected = (
                                    patronCultivo.values  as TypeAheadInterface<{codigo: string}>
                                ).selected;
                            }
                        }
                    }
                    this.formRequest.update();
                }
            }, 600);
            // this.formRequest.update();

            if (this.fincas.filtered.length === 2 && this.getType() === 0) {
                this.model.id_finca = this.fincas.filtered[1]?.value?.id ?? '';
            }
        });
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            this.model.activo = this.model.activo === '1';
            this.model.linde = this.model.linde === '1';
            this.model.produccion_ecologica = this.model.produccion_ecologica === '1';

            if (this.showDatosMedioambientales) {
                this.model.incluido_en_parcela = this.model.incluido_en_parcela === '1';
                this.model.zona_total = this.model.zona_total === '1';
                this.model.zona_parcial = this.model.zona_parcial === '1';
            }

            if (this.showParcelasTurbo) {
                this.model.turbo = this.model.turbo === '1';
                this.formChanges('turbo');
            }

            this.showNumMetros();

            if (this.appName === 'agrosalvi') {
                this.model.coordenadas = this.model.mapa?.[0]?.coordenadas ?? '';
            }

            setTimeout(() => {

                // Por algún motio en duplicar no llega a cargar bien los cultivos y variedades.
                // Con este código se busca forzar esa carga.
                if (this.formRequest.type === FormRequestTypes.DUPLICATE) {
                    const idxCultivo = this.formFieldsDatosParcela.map(it => it.field).indexOf('id_cultivo');
                    const idxVariedad = this.formFieldsDatosParcela.map(it => it.field).indexOf('id_variedad');
                    const cultivo = this.formFieldsDatosParcela[idxCultivo];
                    const variedad = this.formFieldsDatosParcela[idxVariedad];
                    if (cultivo) {
                        cultivo.values = this.cultivos;
                        this.cultivos.filtered = this.cultivos.values;
                        (cultivo.values as TypeAheadInterface<VisitasVariedadMesModel>).selected =
                            this.cultivos.filtered.find(it => it.value && it.value.id === this.model.id_cultivo)?.value as
                                VisitasVariedadMesModel;
                    }

                    if (variedad) {
                        variedad.values = this.variedades;
                        this.variedades.filtered = this.variedades.values;

                        (variedad.values as TypeAheadInterface<VisitasVariedadMesModel>).selected =
                            this.variedades.filtered.find(it => it.value && it.value.id === this.model.id_variedad)?.value as 
                            VisitasVariedadMesModel;
                    }
                }
            }, 1000);
            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            if (this.model.mapa) {
                this.mapaAux = this.model.mapa[0] as FullMapModel;
                this.mapaAux = this.getMapColor(this.mapaAux);
            }

            if (this.hasClients) {
                const clientId = (StorageManager.getClient() || {}).id;
                const clientIdFromFinca = (this.fincas.selected || {}).id_cliente;
                const clientIdFromForm = this.model.id_cliente;

                this.model.id_cliente =
                    clientIdFromFinca ? clientIdFromFinca :
                        clientIdFromForm ? clientIdFromForm :
                            clientId ? clientId : ''; // DEFAULT
            }

            const userId = StorageManager.getUser().id;
            const userIdFromFinca = (this.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            if (this.hasClients) {
                this.model.id_usuario = this.applicationType === 'cropgest' ?
                    (StorageManager.getClient() || {}).id_usuario :
                    (StorageManager.getClient() || {}).id_usuario_asignado || this.model?.id_usuario;
            };

            if (this.variedadCanBeVarios) {
                const varios = this.variedades.filtered.find(it => it.value && it.value.variedad === 'Varios');
                if (varios) {
                    this.model.id_variedad = varios.value?.id ?? '';
                    resolve(true);
                } else {
                    const idxCultivo = this.formFieldsDatosParcela.map(it => it.field).indexOf('id_cultivo');
                    const idxCultivoLegacy = this.formFieldsDatosParcela.map(it => it.field).indexOf('id_cultivo_legacy');

                    const posCampoCultivo = (this.hasPlantas || !this.activateApiSIEX) ? idxCultivoLegacy : idxCultivo;
                    const campoCultivo = this.formFieldsDatosParcela[posCampoCultivo];

                    if (campoCultivo) {
                        this.visitasVariedadMesApi.visitasVariedadMes.POST.toPromise({
                            cultivo: (campoCultivo.values as TypeAheadInterface<VisitasVariedadMesModel>).selected?.cultivo ?? '',
                            variedad: 'Varios'
                        } as never).then(res => {
                            this.variedadVariosCreated = true;
                            if (res && res[0]) {
                                this.model.id_variedad = res[0].id ?? '';
                            }
                            return;
                        }).then(() => {
                            resolve(true);
                            return;
                        }).catch (e => {
                            console.log('catch en getPosition: ' + e);
                        }
                        );
    
                    }                
                }
            } else {
                resolve(true);
            }
        });


        this.formRequest.afterSend(resolve => {
            if (this.variedadVariosCreated) {
                window.location.reload();
            }
            resolve(true);
        });
    }
}