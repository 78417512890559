import { Component, ViewChild } from '@angular/core';
import { BaseForm } from '../../../../base-form';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { TareasApiService } from '../../../../../service/api/tareas-api.service';
import { ProductosApiService } from '../../../../../service/api/productos-api';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import { build, stack } from 'src/app/common/classes/request-builder';
import { FitoLocalesFormDefinition } from './form-control/form-definition';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { Utils } from 'src/app/common/utils';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { FormCommonApiService } from '../../../../../service/api/formcommon-api.service';
import { environment } from 'src/environments/environment';
import { InputType, TypeAheadInterface, ValuesInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';

import type { OnInit } from '@angular/core';
import { FitoLocalesModel } from 'src/app/models/tareas/fito-locales.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';

@Component({
    selector: 'app-fito-locales-form',
    templateUrl: './fito-locales-form.component.html',
    styleUrls: ['./fito-locales-form.component.scss'],
})
export class FitoLocalesFormComponent extends BaseForm<FitoLocalesModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: false, static: false })
        requestButton = new RequestButtonComponent<FitoLocalesModel>();

    public hasClients = environment.features.hasClients;
    public clienteName = environment.features.clienteName;
    public userCanSeeClientes = environment.features.userCanSeeClientes
        ? true
        : (StorageManager.getUser().rol === 'admin');  
    public formRequest = new AppFormRequest<FitoLocalesModel>();
    public form: FitoLocalesFormDefinition = new FitoLocalesFormDefinition();  
    public override model: FitoLocalesModel = {};  


    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public clientesApi: ClientesApiService,
        public fincasApi: FincasApiService,
        public productosApi: ProductosApiService,
        public tareasApi: TareasApiService,
        public override router: Router) {
        super(
            route,
            router,
            dashboard,
            tareasApi.fitoLocales.PUT,
            tareasApi.fitoLocales.POST,
            'fito-locales',
            'Editar Tarea Fitosanitaria en locales',
            'Crear Tarea Fitosanitaria en locales',
            'Duplicar Tarea Fitosanitaria en locales'
        );
    }

    ngOnInit() {
  
        this.expandFormFields();
        this.expandFormRequest();

        const fieldsToSend = (['id', 'id_cliente', 'id_usuario']).concat(
            this.form.formFields.map(it => it.field ?? '')
        );  

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.tareasApi.fitoLocales.POST)
            .setPutRequest(this.tareasApi.fitoLocales.PUT)
            .setGetRequest(this.tareasApi.fitoLocales.GET)
            .setFormFields(this.form.formFields)
            .setFieldsToSend(fieldsToSend); 

        this.formRequest.load();
        this.softInit(this.getType());  
    }

    public submit() {

        this.formRequest.beforeSend(resolve => {
            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.form.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
          clientIdFromFinca ? clientIdFromFinca :
              clientIdFromForm ? clientIdFromForm :
                  clientId ? clientId : ''; // DEFAULT

            const userId = StorageManager.getUser().id;
            const userIdFromFinca = (this.form.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
          userIdFromFinca ? userIdFromFinca :
              userIdFromForm ? userIdFromForm :
                  userId ? userId : '';

            this.model.fecha = this.model.fecha ?
                Utils.formatDate(this.model.fecha)
                : this.model.fecha ?? '';

            resolve(true);
        });

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send(); 

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }


    public fiterProductFitosanitarios( productos: TypeAheadInterface<ProductosModel> ): Promise<ValuesInterface<ProductosModel>[]> {
    
        return new Promise( ( resolve) => {
            if ( productos == null) {
                resolve(productos);
            }
            const listaProductos: ValuesInterface<ProductosModel>[] = [];
            listaProductos.push({label: '...', value: null});
            for (let index = 0; index < productos.filtered.length; index++) {
                const prod = productos.filtered[index];
           
                if (  prod?.value != null && prod.value.tipo != null && prod.value.tipo === 'fitosanitario' ) {
                    listaProductos.push(prod);
                }
                if ( ( index + 1 ) >= productos.filtered.length ) {
                    resolve( listaProductos );
                }
            }

        });
    }

    private expandFormFields() {
        if (this.hasClients && this.userCanSeeClientes && (this.getType() === FormRequestTypes.DUPLICATE)) {
            this.form.formFields.unshift({
                field: 'id_cliente',
                label: this.clienteName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.form.clientes,
                valuePrimaryKey: 'id',
                required: true
            });
        } 
    }

    private expandFormRequest() {
        if (  this.getType() === 0 ) {
            this.fincasApi.fincasGET.toPromise().then( (resp_fincas: FincasModel[]) => {
         
                if (resp_fincas.length === 1 ) {
                    this.model.id_finca = resp_fincas[0]?.id ?? '';
                }
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
        this.formRequest.beforeLoad(resolve => {
            this.initFormData().then(() => {
                resolve(true);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });
    }

    private initFormData(): Promise<unknown> {
        return stack([
            build(this.clientesApi.clientesGET, this.form.clientes, 'nombre'),
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.productosApi.productos.GET, this.form.productos, 'nombre')
        ]).then(() => {
            setTimeout(async () => {
                this.form.productos.filtered = await this.fiterProductFitosanitarios(this.form.productos);
                this.formRequest.update();
            }, 100);
            this.formRequest.update();
            return;
        }).catch (e => {
            console.log('catch en pool: ' + e);
        }
        );
    }

}