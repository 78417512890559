import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../../service/events/dashboard.service';
import { TratamientosApiService } from '../../../service/api/tratamientos-api.service';
import { RequestButtonTypes } from '../../../common/components/request-button/request-button.component';
import { RequestHandler } from '../../../service/OffService/request-handler';
import { InputType } from '../../../common/components/form-builder/form-builder.component';
import { BaseView } from '../../base-view';
import { Filtering } from '../../../service/filtering/filtering';
import { StorageManager } from '../../../common/storage-manager.class';
import { Utils } from '../../../common/utils';
import moment from 'moment';
import { environment } from '../../../../environments/environment';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../common/components/form-builder/form-builder.component';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TratamientoLineaModel } from 'src/app/models/tratamientos/tratamiento-linea.model';
import { TreeNode } from 'primeng/api';
@Component({
    selector: 'app-tratamientos',
    templateUrl: './tratamientos.component.html',
    styleUrls: ['./tratamientos.component.scss']
})
export class TratamientosComponent extends BaseView<TratamientosModel> implements OnInit, OnDestroy {

    public appName = environment.appName;
    public showUdsCuaderno = environment.features.showUdsCuaderno;
    public enableLegalMode = environment.features.enableLegalMode;
    public showTareasFitosanitarias = environment.features.showTareasFitosanitarias;
    public showTratamientosMenu = environment.features.showTratamientosMenu;
    public showTratamientosExportCsv = environment.features.showTratamientosExportCsv;
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;
    public canAddTratamientos = environment.features.canAddTratamientos;
    public canDuplicateTratamientos = environment.features.canDuplicateTratamientos;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));

    public getRequest: RequestHandler<TratamientosModel[]> = this.tratamientosApi.tratamientosList;
    public deleteRequest: RequestHandler<TratamientosModel> = this.tratamientosApi.tratamientosDELETE;
    public filter: Filtering<TratamientosModel> = new Filtering<TratamientosModel>();
    public showAllTratamientos = false;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};

    public override model: TratamientosModel = {
        nombre: '', 
        producto: {nombre: '', materia_activa: ''}, 
        cantidad: '', 
        fecha_act: '', 
        finca: '', 
        activo: false
    };
    public rol = StorageManager.getUser().rol;

    public btnAnadir = 'Añadir Tratamiento';

    public formFields: ItemInterface<object>[] = [
        { 
            field: 'nombre', 
            label: 'Nombre', 
            inputType: {type: InputType.TEXT}
        },
        { 
            label: 'Activo', 
            field: 'activo', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.showAplicadoresActivo 
        },
    ];
    public hiddeDosisAplicada = false;
    public hiddeDosisCuaderno = false;

    public cols: ColInterface[] = [];


    constructor(public tratamientosApi: TratamientosApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(dashboardEvents, tratamientosApi.tratamientosList, tratamientosApi.tratamientosDELETE);
    }

    ngOnInit() {
        this.hiddeDosisAplicada = StorageManager.checkHiddenDosisAplicada();
        this.hiddeDosisCuaderno = StorageManager.checkHiddenDosisCuaderno();
        this.cols = [
            { field: 'tecnico_nombre', header: 'Técnico', filter: '', width: '80px' },
            { field: 'nombre', header: 'Nombre', filter: '' },
            { field: 'productos', header: 'Productos', filter: '' },
            { field: 'usos', header: 'Usos', filter: '' },
            { field: 'agentes', header: 'Agentes', filter: '' },
            { field: 'fecha_caducidad', header: 'Caducidad', filter: '' },
            {
                field: 'cantidades_cuaderno',
                header: (this.enableLegalMode ? 'Cantidad\xa01' : 'Cuaderno'),
                filter: '',
                width: '80px',
                visible: !this.hiddeDosisCuaderno
            },
            {
                field: 'cantidades_real',
                header: (this.enableLegalMode ? 'Cantidad\xa02' : 'Aplicada'),
                filter: '',
                width: '80px',
                visible: !this.hiddeDosisAplicada
            },
            { field: 'activo', header: 'Activo', width: '60px' },
        ];
        this.showAllTratamientos = Utils.initShowAll('showAlltratamientos');
        if (this.showTratamientosMenu) {
            this.dashboardEvents.setMenuItem('tratamientos');
        }
        this.dashboardEvents.setHeaderTitle('Tratamientos');
        this.initFilter();
    }

    public changeShowAll() {
        sessionStorage.setItem('showAlltratamientos', this.showAllTratamientos === true ? 'true' : 'false');
    }

    public filterTratamientos() {
        this.filter.filter();
    }

    public add() {
        if (environment.features.showTareasMto) {
            this.router.navigate(['dashboard', 'crear-tratamiento']);
            return;
        }
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboardEvents.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-tratamiento']);
        }
    }

    public edit(data: TratamientosModel) {
        this.router.navigate(['dashboard', 'editar-tratamiento', data.id]);
    }

    public duplicate(data: TratamientosModel) {
        this.router.navigate(['dashboard', 'duplicar-tratamiento', data.id]);
    }

    public globalConditionalStyle(
        _value: string, 
        col: ColInterface, 
        _colGroup: ColInterface[], 
        row: TreeNode<TratamientosModel>): ColStylesInterface {
        const tratamiento = row as TratamientosModel;
        if (col.field === 'activo') {
            return {
                textAlign: 'center'
            };
        }

        let estiloVademecum: ColStylesInterface = {};
        if (col.field === 'fecha_caducidad') {
            estiloVademecum = {
                rowStyle: {
                    color: 'black',
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid #dddddd'
                },
                textAlign: 'center'
            };

            const diffInsc = moment(new Date()).diff(Utils.toDate(tratamiento.fecha_inscripcion ?? ''), 'months');

            if (diffInsc < 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#cbdecb',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffffff',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            }

            const diff = moment(new Date()).diff(Utils.toDate(tratamiento.fecha_caducidad ?? ''), 'months');
            const diff2 = moment(new Date()).diff(Utils.toDate(tratamiento.fecha_caducidad ?? ''), 'days');

            if (diff >= 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'white',
                        backgroundColor: '#222222',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else if (diff >= 6) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ff7f7f',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else if (diff >= 0) {
                if ((diff === 0) && (diff2 < 0)) {
                    estiloVademecum = {
                        rowStyle: {
                            color: 'black',
                            backgroundColor: '#ff9a02',
                            borderBottom: '1px solid #dddddd'
                        },
                        textAlign: 'center'
                    };
                } else {
                    estiloVademecum = {
                        rowStyle: {
                            color: 'black',
                            backgroundColor: '#ffcf78',
                            borderBottom: '1px solid #dddddd'
                        },
                        textAlign: 'center'
                    };
                }
            }
        }

        return estiloVademecum;
    }

    public globalVisualTransform(value: string, col: ColInterface): string {
        if (col.field === 'activo') {
            return ([1, '1', true, 'true', 'Sí'].includes(value)) ? 'Sí' : 'No';
        }
        if (Utils.isNumber(value)) {
            return Utils.decimalFormat(value, 2, '.', ',', 3);
        }
        return value;
    }

    public override show(model: string, data: TratamientosModel) {
        super.show(model, data);
        this.model.activo = [1, '1', true, 'true', 'Sí'].includes(this.model.activo ?? false);

        this.model.linea?.forEach((it: TratamientoLineaModel) => {
            if (it.producto) {
                const diff = moment(new Date()).diff(Utils.toDate(it.producto.fecha_caducidad ?? ''), 'months');
                const diff2 = moment(new Date()).diff(Utils.toDate(it.producto.fecha_caducidad ?? ''), 'days');
    
                if (diff >= 12) {
                    it.producto.caducado = '#222222';
                } else if (diff >= 6) {
                    it.producto.caducado = '#ff7f7f';
                } else if (diff >= 0) {
                    if ((diff === 0) && (diff2 < 0)) {
                        it.producto.caducado = '#ff9a02';
                    } else {
                        it.producto.caducado = '#ffcf78';
                    }
                } else {
                    it.producto.caducado = '#f8f9fa';
                }
            }
        });
    }

    public createNavigation(route: string) {
        this.router.navigate(['dashboard', route]);
    }

    private initFilter() {
        this.filter.addFilter((data: TreeNode<TratamientosModel>[] | void) => (data || [])
            .filter((it) => it)
            .filter((it: TreeNode<TratamientosModel>) => {
                if ((it as TratamientosModel).fecha_caducidad === '//') {
                    (it as TratamientosModel).fecha_caducidad = '-';
                }
                return it;
            })
        );
    }
}
