import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { environment } from '../../../environments/environment';
import { ClienteModel } from 'src/app/models/cliente.model';

@Injectable({
    providedIn: 'root'
})
export class ClientesApiService {
    public clientesGET = new RequestHandler<ClienteModel[]>();
    public clientesPOST = new RequestHandler<ClienteModel[]>();
    public clientesPUT = new RequestHandler<ClienteModel[]>();
    public clientesDELETE = new RequestHandler<ClienteModel>();

    public cooperativasGET = new RequestHandler<ClienteModel[]>();

    constructor(
        private syncService: SyncService<ClienteModel[]>,
        private syncServiceDelete: SyncService<ClienteModel>,
        private api: ApiRequestService<ClienteModel[]>,
        private apiDelete: ApiRequestService<ClienteModel>,
    ) {

        this.defineClientesGET();
        this.defineClientesPOST();
        this.defineClientesPUT();
        this.defineClientesDELETE();

        this.defineCooperativasGET();
    }

    private defineClientesGET() {
        this.clientesGET
            .fetch(HttpRequestType.GET, 'clientes/get');

        this.api
            .registerRequest(this.clientesGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.clientesGET));
    }

    private defineClientesPOST() {
        this.clientesPOST
            .fetch(HttpRequestType.POST, environment.features.applicationType !== 'cropgest' ? 'generic/clientes' : 'clientes/post');

        this.api
            .registerRequest(this.clientesPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.clientesPOST));
    }

    private defineClientesPUT() {
        this.clientesPUT
            .fetch(HttpRequestType.PUT, 'generic/clientes');

        this.api
            .registerRequest(this.clientesPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.clientesPUT));
    }

    private defineClientesDELETE() {
        this.clientesDELETE
            .fetch(HttpRequestType.DELETE, 'generic/clientes');

        this.apiDelete
            .registerRequest(this.clientesDELETE);

        this.syncServiceDelete
            .register(this.syncServiceDelete.baseSyncPolicy(this.clientesDELETE));
    }

    private defineCooperativasGET() {
        this.cooperativasGET.fetch(HttpRequestType.GET, 'clientes/cooperativas_get');
        this.api.registerRequest(this.cooperativasGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.cooperativasGET));
    }
}
