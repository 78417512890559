<div id="leyenda">
    <div *ngIf="tareasFitosanitarias" class="wrapper"><div class="square red"></div>Tareas Fito</div>
    <div *ngIf="tareasBio" class="wrapper"><div class="square blue"></div>Tareas Bio</div>
    <div *ngIf="tareasFertilizantes" class="wrapper"><div class="square orange"></div>Tareas Ferti</div>
    <div *ngIf="tareasSiembra" class="wrapper"><div class="square green"></div>Tareas Siembra</div>
    <div *ngIf="tareasGenericas" class="wrapper"><div class="square" style="background-color: #d900ff;"></div>Tareas Genéricas</div>
</div>

<div id="gotodate-div">
    <p>Ir a fecha:</p>
    <p-select id="month-dropdown" [options]="months" [(ngModel)]="selectedMonth" placeholder="Mes" 
    optionLabel="name" optionValue="value" [showClear]="true" (onChange)="showSelectedDate(calendar)"></p-select>
    <p-select id="year-dropdown" [options]="years" [(ngModel)]="selectedYear" placeholder="Año" 
    optionLabel="name" optionValue="value" [showClear]="true" (onChange)="showSelectedDate(calendar)"></p-select>
    <p-select *ngIf="this.hasClients && this.appName === 'agrosalvi'" id="type-dropdown" [options]="tipos" [(ngModel)]="selectedTipo" placeholder="Tipo"
    optionLabel="name" optionValue="value" [showClear]="true" (onChange)="filtrarPorTipoCliente()"></p-select>
</div>

<div class="row" style="width: auto; overflow: auto">
    <div class="col-12">
        <full-calendar #calendar
                [events]="tareasArrayFiltered"
                [deepChangeDetection]="true"
                [options]="options">
        </full-calendar>
    </div>
</div>