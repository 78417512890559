<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <div class="d-flex flex-wrap justify-content-between">
    <div style="flex: 2" class="mr-4">
      <app-form-builder
            [model]="model"
            [distributionColumns]="3"
            [fields]="formFields"
            (formChanges)="formChanges($event)">
      </app-form-builder>
    </div>
    <div style="flex: 1">
      <h4 class="mb-3">Productos</h4>

      <div class="d-flex justify-content-between mb-2">
        <div style="flex: 1;">
        </div>
        <div class="mr-2 font-weight-bold" style="flex: 4;">
          <span>Nombre</span>
        </div>
        <div class="font-weight-bold" style="flex: 2;">
          <span>Dosis&nbsp;(%)</span>
        </div>
        <div class="ml-2 font-weight-bold" style="flex: 2;">
          <span>Unidades</span>
        </div>
      </div>
      <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]" class="d-flex justify-content-between mb-3">
        <div style="flex: 1">
          {{i}}
        </div>
        <div class="mr-2" style="flex: 4;">
          <p-select
            *ngIf="productosAll"
            [filter]="true"
            [(ngModel)]="model['producto' + i]"
            [options]="productosAll.filtered"
            (onChange)="formChanges('id_producto')">
          </p-select>
        </div>
        <div style="flex: 2">
          <input [id]="'dosis' + i" type="number"
            [(ngModel)]="model['dosis' + i]"
            (keyup)="formChanges('dosis' + i)" />
        </div>
        <div class="ml-2" style="flex: 2">
          <span *ngIf="model?.productos_uds">
            {{model.productos_uds?.[i - 1]}}
          </span>
        </div>
      </div>

    </div>
  </div>

  <div>
    <div class="my-4 text-center">
      <table class="registro-table table-bordered">
        <tr>
          <td class="font-weight-bold" style="width: 160px;">PRESIÓN (BAR)</td>
          <td *ngFor="let i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
            {{i}}
          </td>
        </tr>
        <tr class="font-weight-bold">
          <td>Caudal (l/min)</td>
          <td *ngFor="let i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
            {{totalCaudales[i] || 0.00}}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <br>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            (click)="submit()"
            [lockOnSuccess]="true"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
