<div style="max-width: auto; overflow: auto;">
    <div #passport>
    </div>
    <app-common-list-table
        [request]="getRequest" 
        [cols]="cols" 
        [filtering]="filtering"
        [globalConditionalStyle]="globalConditionalStyle" 
        [globalVisualTransform]="globalVisualTransform"
        [actionButtonText]="btnAnadir"
        (actionButtonClick)="add()">

        <ng-template #header>
            <div class="d-flex justify-content-start">
                <div style="margin-left: 20px;">
                    <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white; width: 8rem">
                        <i class="fa fa-download"></i>
                        Exportar listado
                    </button>
                </div>                
            </div>
        </ng-template>


        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons [hideDelete]="hiddenButtonDelete" [showDuplicate]="canDuplicateAlbaranes"
                    (duplicateRowButtonEvent)="duplicate(data)" (editRowButtonEvent)="edit(data)" (deleteRowButtonEvent)="show('delete', data)"
                    (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog 
    *ngIf="canShow['info']"
    header="Detalle del registro" 
    [(visible)]="canShow['info']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 80vw !important">
        <div class="mb-5 col-12">
            
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="5"
                    [fields]="formFieldsCliente"
                    >
            </app-form-builder>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsConceptos">
            </app-form-builder>
        </div>

       <div class="d-flex justify-content-center" style="margin-top: 40px">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>
</p-dialog>

<p-dialog 
    *ngIf="canShow['delete']"
    header="Eliminar registro" 
    [(visible)]="canShow['delete']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 80vw !important">
        <div class="mb-5 col-12">
            
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="5"
                    [fields]="formFieldsCliente"
                    >
            </app-form-builder>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsConceptos">
            </app-form-builder>
        </div>
    </div>

</p-dialog>
