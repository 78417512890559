import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import type { UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ShowVisitasVariedadMesGuardService {
    constructor(public router: Router) {}

    canActivate(): boolean | UrlTree {
        if (!environment.features.showVisitas || !environment.features.showVisitasVariedadMes) {
            return this.router.parseUrl('/login');
        }
        return true;
    }
}
