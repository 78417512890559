import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {environment} from '../../../../../../../environments/environment';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { AlbaranModel } from 'src/app/models/ventas/albaran.model';
export class AlbaranesFormDefinition {
    
    public appName = environment.appName;
    public lastFieldChanged = '';

    public requestType: FormRequestTypes = 0;
    public model: AlbaranModel = {};

    public formFieldsCliente: ItemInterface<object>[] = [
        {
            field: 'albaran',
            label: 'Albarán',
            inputType: {type: InputType.ONLY_TEXT},
            required: true
        },
        {
            field: 'chofer',
            label: 'Chofer',
            inputType: {type: InputType.EDIT_TEXT},
            required: true,
        },
        {
            field: 'fecha',
            label: 'Fecha',
            inputType: {type: InputType.CALENDAR},
            required: true
        },
        {
            field: 'cliente',
            label: 'Cliente',
            inputType: {type: InputType.EDIT_TEXT},
            required: true,
        },
        {
            field: 'direccion',
            label: 'Dirección',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'cif' ,
            label: 'C.I.F.',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'poblacion' ,
            label: 'Población',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'cp' ,
            label: 'C.P.',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'telefono' ,
            label: 'Teléfono',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'correo' ,
            label: 'Correo',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'obra' ,
            label: 'Obra',
            inputType: {type: InputType.EDIT_TEXT},
        }
    ];

    public formFieldsConceptos: ItemInterface<object>[] = [
        {
            field: 'cantidad1',
            label: 'Cantidad',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'concepto1',
            label: 'Concepto',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'precio1',
            label: 'Precio',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'total1',
            label: 'Total',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'cantidad2',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'concepto2',
            label: '',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'precio2',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'total2',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'cantidad3',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'concepto3',
            label: '',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'precio3',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'total3',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'cantidad4',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'concepto4',
            label: '',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'precio4',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'total4',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'cantidad5',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'concepto5',
            label: '',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'precio5',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'total5',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'cantidad6',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'concepto6',
            label: '',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'precio6',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'total6',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'cantidad7',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'concepto7',
            label: '',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            field: 'precio7',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'total7',
            label: '',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'trabajos_realizados',
            label: 'Trabajos Realizados',
            inputType: {type: InputType.TEXTAREA},
        },
        {
            field: 'conforme',
            label: 'Conforme',
            inputType: {type: InputType.TEXTAREA},
        } 
    ];
    
    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }
}