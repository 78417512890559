<div class="d-flex justify-content-center align-items-center">
    <div [style.height]="showNotification ? '18px' : 'initial'">
        <button *ngIf="!hideInfo"
            tooltipPosition="top" pTooltip="Más información"
            class="d-flex justify-content-center align-items-center"
            (click)="showRowButtonEvent.emit()"
            style="
                height: 20px;
                width: 20px;
                border: none;
                border-radius: 3px;
                background-color: #007bff;
                margin-right: 10px;
                color: white;
                cursor: pointer;">
            <i class="fa fa-info"></i>
        </button>
        <div *ngIf="showInfoCambios && showNotification"
            class="d-flex justify-content-center align-items-center"
            [class.bg-danger]="notificationSeverity === 'danger'"
            [class.bg-info]="notificationSeverity === 'info'"
            [class.bg-primary]="notificationSeverity === 'primary'"
            style="
                height: 13px;
                width: 13px;
                position: relative;
                top: -27px;
                left: 13px;
                border: 1px inset white;
                border-radius: 50%;
                color: white;
                font-size: xx-small;">
            <span>{{notificationCount || '!'}}</span>
        </div>
    </div>

    <div style="height: initial;">
        <button *ngIf="!hideEdit"
            tooltipPosition="top" pTooltip="Editar"
            class="d-flex justify-content-center align-items-center"
                (click)="editRowButtonEvent.emit()"
                style="
                height: 20px;
                width: 20px;
                border: none;
                border-radius: 3px;
                background-color: #ffc107;
                margin-right: 10px;
                cursor: pointer;">
            <i class="fa fa-pencil"></i>
        </button>
    </div>

    <div style="height: initial;" *ngIf="appName !=='covidai' ">
        <button *ngIf="!hideDelete"
            tooltipPosition="top" pTooltip="Eliminar"
            class="d-flex justify-content-center align-items-center"
            (click)="deleteRowButtonEvent.emit()"
            style="
                height: 20px;
                width: 20px;
                border: none;
                border-radius: 3px;
                background-color: #dc3545;
                margin-right: 10px;
                color: white;
                cursor: pointer;">
            <i class="fa fa-times"></i>
        </button>
    </div>
    <div style="height: initial;" *ngIf="appName ==='covidai'">
        <button *ngIf="userRol === 'admin' && !hideDelete"
            tooltipPosition="top" pTooltip="Eliminar"
            class="d-flex justify-content-center align-items-center"
            (click)="deleteRowButtonEvent.emit()"
            style="
                height: 20px;
                width: 20px;
                border: none;
                border-radius: 3px;
                background-color: #dc3545;
                margin-right: 10px;
                color: white;
                cursor: pointer;">
            <i class="fa fa-times"></i>
        </button>
    </div>
    
    <div style="height: initial;">
        <button *ngIf="showDuplicate"
            tooltipPosition="top" pTooltip="Duplicar"
            class="d-flex justify-content-center align-items-center"
            (click)="duplicateRowButtonEvent.emit()"
            style="
                height: 20px;
                width: 20px;
                border: none;
                border-radius: 3px;
                background-color: #19923b;
                color: white;
                cursor: pointer;">
            <i class="fa fa-copy"></i>
        </button>
    </div>
    
</div>