import {Component} from '@angular/core';
import {DashboardService} from '../../../service/events/dashboard.service';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { StorageManager } from 'src/app/common/storage-manager.class';

import type { OnInit } from '@angular/core';
import { ImageMenuInterface } from './image-menu.interface';

@Component({
    selector: 'app-home',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
    public formacionUrl = environment.features.formacionUrl;
    public userCanSeeClientes = environment.features.userCanSeeClientes ? 
        true : (StorageManager.getUser().rol === 'admin' || StorageManager.getUser().rol === 'responsable');
    public userCanSeeCostes = environment.features.userCanSeeCostes ? true : (StorageManager.getUser().rol === 'admin');
    public images: ImageMenuInterface[] = [
        {id: 'usuarios', title: 'Usuarios', src: 'assets/images/home_usuarios.jpg',
            visible: environment.features.showUsuarios && (StorageManager.getUser().rol === 'admin')},
        {id: 'clientes', title: environment.features.clientesName, src: 'assets/images/home_clientes.jpg',
            visible: environment.features.hasClients && this.userCanSeeClientes},

        {id: 'finca', title: environment.features.fincasName, src: 'assets/images/home_fincas.jpg',
            visible: environment.features.hasFincas},
        {id: 'campos', title: 'Campos', src: 'assets/images/home_fincas.jpg',
            visible: environment.features.showCampos},

        {id: 'mapas', title: 'Mapas', src: 'assets/images/home_mapas.jpg',
            visible: environment.features.showMapas},

        {id: 'ventas', title: environment.features.ventasName, src: 'assets/images/home_ventas.jpg',
            visible: environment.features.showVentas},

        {id: 'mantenimiento', title: environment.features.aplicadoresName, src: 'assets/images/home_trabajadores.jpg',
            visible: environment.features.hasAplicadores && environment.features.showTrabajadores},
        {id: 'maquinaria', title: 'Maquinaria', src: 'assets/images/home_maquinaria.jpg',
            visible: environment.features.hasAplicadores && !environment.features.showTrabajadores},

        {id: 'productos-fitosanitarios', title: 'Productos', src: 'assets/images/home_productos.jpg',
            visible: environment.features.hasProductos},
        {id: 'productos-s', title: 'Productos', src: 'assets/images/home_productos.jpg',
            visible: environment.features.showProductosSimple},
        {id: 'consultas', title: 'Vademecum', src: 'assets/images/home_productos.jpg',
            visible: environment.features.showVademecumMenu},

        {id: 'tratamientos', title: 'Tratamientos', src: 'assets/images/home_tratamientos.jpg', 
            visible: environment.features.showTratamientosMenu},
        {id: 'tratamientos-manez', title: 'Tratamientos', src: 'assets/images/home_tratamientos.jpg', 
            visible: environment.features.showTratamientosManezLozano},    
        {id: 'tratamientos-control', title: 'Control de tratamientos', src: 'assets/images/calendario.jpg', 
            visible: environment.features.showTratamientosControl},
        {id: 'tareas', title: 'Tareas', src: 'assets/images/home_tareas.jpg',
            visible: environment.features.showTareasFitosanitarias},

        {id: 'visitas', title: 'Visitas', src: 'assets/images/home_visitas.jpg',
            visible: environment.features.showVisitas},
        {id: 'visitas-semana', title: 'Visitas S.', src: 'assets/images/home_visitas.jpg',
            visible: environment.features.showVisitasPorSemana},

        {id: 'costes-fitosanitarios', title: 'Costes', src: 'assets/images/home_costos.jpg',
            visible: environment.features.showCostes && this.userCanSeeCostes},

        {id: 'stock', title: 'Stock', src: 'assets/images/home_stock.jpg',
            visible: environment.features.showStock},

        {id: 'albaranes', title: 'Albaranes', src: '',
            visible: environment.features.showAlbaranes}
    ];

    constructor(private dashboard: DashboardService,
        private router: Router) {
    }

    ngOnInit() {
        this.dashboard.setMenuItem('inicio');

        let onlyItem = {} as ImageMenuInterface;
        let countVisible = 0;
        this.images.forEach(item => {
            if (item && item.visible) {
                countVisible++;
                onlyItem = item;
            }
        });

        if (countVisible === 1) {
            this.router.navigate(['dashboard', onlyItem.id]);
        }

    }

    goto(id: string) {
        this.router.navigate(['dashboard', id]);
    }

}
