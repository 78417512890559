<p-datePicker
  [(ngModel)]="fecha"
  [locale]="es"
  dateFormat="dd/mm/yy"
  placeholder="..."
  appendTo="body"
  (onSelect)="markFieldAsChanged()"
  (ngModelChange)="markFieldAsChanged()"
>
</p-datePicker>
