import { createCsv } from 'src/app/common/createCsv';
import {environment} from '../../../../../environments/environment';
import {TratamientosApiService} from '../../../../service/api/tratamientos-api.service';
import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {Filtering} from '../../../../service/filtering/filtering';
import {Utils} from '../../../../common/utils';
import {FormCommonApiService} from '../../../../service/api/formcommon-api.service';
import {BaseView} from '../../../base-view';
import {StorageManager} from '../../../../common/storage-manager.class';
import moment from 'moment';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { AlbaranModel } from 'src/app/models/ventas/albaran.model';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { VentasApiService } from 'src/app/service/api/ventas-api.service';

@Component({
    selector: 'app-albaranes',
    templateUrl: './albaranes.component.html',
    styleUrls: ['./albaranes.component.scss']
})

export class AlbaranesComponent extends BaseView<AlbaranModel> implements OnInit, OnDestroy {
    public rolUser = StorageManager.getUser().rol;

    public appName: string = environment.appName;
    public serverUrl = environment.serverUrl;
    public logoUrl: string = environment.logoUrl;
    public pathToForm = 'crear-albaran';
    public menuItemId = 'albaranes';
    public hiddenButtonDelete = false;
    public canDuplicateAlbaranes = true;

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: AlbaranModel = {};

    public currentUser = StorageManager.getUser();

    public override filtering: Filtering<AlbaranModel> = new Filtering<AlbaranModel>();
    
    public btnAnadir = 'Añadir Albarán';

    public sm = localStorage.getItem('sm') ? true : false;
    public getRequest = new RequestHandler<AlbaranModel[]>();
    public deleteRequest = new RequestHandler<AlbaranModel>();

    public formFieldsCliente: ItemInterface<object>[] = [
        {
            field: 'albaran',
            label: 'Albarán',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'chofer',
            label: 'Chofer',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha',
            label: 'Fecha',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'cliente',
            label: 'Cliente',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'direccion',
            label: 'Dirección',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'cif' ,
            label: 'C.I.F.',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'poblacion' ,
            label: 'Población',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'cp' ,
            label: 'C.P.',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'telefono' ,
            label: 'Teléfono',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'correo' ,
            label: 'Correo',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'obra' ,
            label: 'Obra',
            inputType: {type: InputType.TEXT},
        }
    ];

    public formFieldsConceptos: ItemInterface<object>[] = [
        {
            field: 'cantidad1',
            label: 'Cantidad',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'concepto1',
            label: 'Concepto',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'precio1',
            label: 'Precio',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'total1',
            label: 'Total',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'cantidad2',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'concepto2',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'precio2',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'total2',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'cantidad3',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'concepto3',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'precio3',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'total3',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'cantidad4',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'concepto4',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'precio4',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'total4',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'cantidad5',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'concepto5',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'precio5',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'total5',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'cantidad6',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'concepto6',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'precio6',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'total6',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'cantidad7',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'concepto7',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'precio7',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'total7',
            label: '',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'trabajos_realizados',
            label: 'Trabajos Realizados',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'conforme',
            label: 'Conforme',
            inputType: {type: InputType.TEXT},
        } 
    ];

    public cols: ColInterface[] = [];

    constructor(public ventasApi: VentasApiService,
        public tratamientosApi: TratamientosApiService,
        private formApi: FormCommonApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, ventasApi.albaranesGET);

    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
        this.expandFormFields();

        this.appName = environment.appName;
    }


    public add() {
        this.router.navigate(['dashboard', this.pathToForm]);
    }

    public edit(data: AlbaranModel) {
        this.router.navigate(['dashboard', 'editar-albaran', data.id]);
    }

    public duplicate(data: AlbaranModel) {
        this.router.navigate(['dashboard', 'duplicar-albaran', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: AlbaranModel) {
        super.show(modal, data);
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public override ngOnDestroy() {
        Utils.unsuscribe([
            this.ventasApi.albaranesGET,
        ]);
        Utils.unsuscribe([
            this.ventasApi.albaranesDELETE,
        ]);
        Utils.unsuscribe([
            this.tratamientosApi.tratamientosGET,
        ]);
        Utils.unsuscribe([
            this.formApi.productos
        ]);
    }


    
    public globalConditionalStyle(_value: string, col: ColInterface): ColStylesInterface {
        
        const estiloVademecum: ColStylesInterface = {};

        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }

        
        if (['id', 'fecha_inicio', 'fecha_fin'].includes(col.field)) {
            return {textAlign: 'center'};
        }
        

        return estiloVademecum;
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (Utils.isNumber(value) && (col.field !== 'id')) {
            return Utils.decimalFormat((value || '').replace(',', '.'), 2, '.', ',');
        }

        return value;
    }

    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }

    public createNavigation(route: string) {
        this.router.navigate(['dashboard', route]);
    }


    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFieldsCliente,
            this.formFieldsConceptos,
        ];

        const csvContent = createCsv(arrayFormFields, this.filtering.value, 'factura_albaran');
      
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

        csvLink.download = 'Albaranes.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
      
    }
      

    private expandFormFields() {
    }
}
