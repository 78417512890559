<br>

<!-- FORM FIELDS -->
<p-panel>
    <div style="display: flex;">
        <h4>Datos de edificio</h4>
      
    </div>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="2" 
            [fields]="form.formFields">
        </app-form-builder>
        <h4>Jardin</h4>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsJardin">
        </app-form-builder>
        <h4>Piscina</h4>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsPiscinaHoras">
        </app-form-builder>
        <h5>Tareas</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsPiscinaTareas01">
        </app-form-builder>
        <h6 class="edificios">Comprobar Parámetros</h6>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsPiscinaTareas02">
        </app-form-builder>
        <h6 class="edificios">Otras Tareas</h6>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsPiscinaTareas03">
        </app-form-builder>
        <h5>Productos</h5>
        <h6 class="edificios">Cloro</h6>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsPiscinaProductos01">
        </app-form-builder>
        <h6 class="edificios">Floculante</h6>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3"
            [fields]="form.formFieldsPiscinaProductos02">
        </app-form-builder>
        <h6 class="edificios">Otros Productos</h6>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsPiscinaProductos03">
        </app-form-builder>
        <h5>Recambios</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsPiscinaRecambios">
        </app-form-builder>
        <h4>Limpieza</h4>              
        <h5>Limpieza General</h5> 
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsLimpiezaGeneral">
        </app-form-builder>
        <h4>Recambios</h4>
        <h5>Luces</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsRecambiosLuces">
        </app-form-builder>
        <h5>Papel Higiénico</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsRecambiosPapelHigienico">
        </app-form-builder>
        <h5>Rejillas</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsRecambiosRejillas">
        </app-form-builder>
        <h5>Productos Limpieza</h5>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [fields]="form.formFieldsRecambiosProductosLimpieza">
        </app-form-builder>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="2" 
            [fields]="form.formFieldsTrabajadores">
        </app-form-builder>
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <app-form-builder 
                    [model]="model" 
                    (formChanges)="form.formChanges($event);" 
                    [distributionColumns]="1"
                    [fields]="form.formFieldsOrdenTrabajo">
                </app-form-builder>
                <app-dictado
                 mensaje="Grabar orden de trabajo" 
                 [observaciones]="model.orden_trabajo ?? ''" 
                 (sendObservaciones)="getOrdenTrabajo($event)"></app-dictado>
            </div>
            <div class="col-lg-1 col-md-1">
                <div class="tareas-camara">
                    <app-upload-images
                    [model]="model"
                    folder="edificios"
                    tipo="form"
                    (sendImageName)="updateImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>                
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <app-form-builder
                  [model]="model"
                  (formChanges)="form.formChanges($event); formChanges($event)"
                  [distributionColumns]="1" 
                  [fields]="form.formFieldsObservaciones">
                </app-form-builder> 
                <app-dictado
                mensaje="Grabar observaciones" 
                [observaciones]="model.observaciones ?? ''" 
                (sendObservaciones)="getObservaciones($event)"
                ></app-dictado>
            </div>
            <div class="col-lg-1 col-md-1">
                <div class="tareas-camara">
                    <app-upload-images 
                      [model]="model" 
                      folder="edificios"
                      nombre_imagen = 'image_after' 
                      tipo="form" 
                      (sendImageName)="updateAfterImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>
  
        <div style="margin: 50px 0;">
            <div class="distribution_camera_signature" style="display: flex;
            gap: 50px;
            align-items: center;" *ngIf="this.model.signature_img">
                <div>
                    <div *ngIf="this.model.signature_img" style="display: flex; flex-direction: column;">
                        <p>Firma</p>
                        <img *ngIf="!this.model.signature_img.includes('base64')" [src]="serverUrl + 'images/edificios/'+this.model.signature_img " class="info-firma-img" alt="Firma">
                        <img *ngIf="this.model.signature_img.includes('base64')" [src]="this.model.signature_img " class="info-firma-img" alt="Firma">
                    </div>
                </div>
                <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
            </div>
            <div class="distribution_camera_signature"  *ngIf="!this.model['signature_img']">
            
                <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
            </div>
        </div>
    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>