<br>

<!-- FORM FIELDS -->
<p-panel>
  <div style="display: flex; max-width: 20%; position: absolute; right: 2rem;" *ngIf="showTareasOtrosImagen">
    <input type="file" #adjuntarFoto (change)="fileChange($event)" style="display: none;" accept="image/*"/>
    <button *ngIf="model?.adjunto" class="btn btn-link" (click)="verAdjunto()">Ver</button>
    <input type="button" class="btn btn-success"
      style='border-radius: 5px;
        font-size: 1em;
        padding: 6px 12px;
        font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;'
      value="Añadir foto" (click)="readUrl(adjuntarFoto)" />
  </div>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  
  <div class="row">
    <div class="md-12 col-12">
      <app-form-builder
              [model]="model"
              [distributionOrder]="distributionOrder"
              [undoEnabled]="false"
              [distributionColumns]="3"
              [fields]="form.formFields"
              (selectAllcheck)="selectAll()"
              (formChanges)="formChanges($event)"
              (mouseleave)="formChanges('')">
      </app-form-builder>
    </div>

  </div>

  <div class="row">
    <div class="col-md-12">      
      <app-form-builder [model]="model"
                    [distributionColumns]="2"
                    [fields]="form.FormFieldsObs"
                    (formChanges)="formChanges($event)">
      </app-form-builder>
    </div>

    <div class="col-md-6">
      <app-dictado *ngIf="this.appName === 'ava'"
      mensaje="Grabar observaciones" 
      [observaciones]="model.observaciones ?? ''" 
      (sendObservaciones)="getObservaciones($event)"
      ></app-dictado>
    </div>
   
  </div>
  <br>
  <p>TRABAJADORES SELECCIONADOS: {{seleccionados}}</p>

  <!-- ACTION BUTTONS -->
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            (click)="submit()"
            [mode]="requestButtonType"
            [lockOnSuccess]="true"
            [request]="request">
    </app-request-button>
  </div>
  <br>
</p-panel>
<br>
<p-toast styleClass="custom-toast" [style]="{'zIndex': '1010'}"></p-toast>