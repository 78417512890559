
import { UserModel } from '../models/usuarios/user.model';
import { environment } from '../../environments/environment';
import { isNull } from 'util';
import { ProductosModel } from '../models/productos/productos.model';
import { ClienteModel } from '../models/cliente.model';

export class StorageManager {
    private static readonly USER_TAG: string = environment.appName + '_' + 'user_tag' + '_' + environment.v;
    private static readonly CLIENT_TAG: string = environment.appName + '_' + 'client_tag' + '_' + environment.v;
    private static readonly CALENDAR_FILTER_TAG: string = environment.appName + '_' + 'calendar_filter' + '_' + environment.v;
    private static readonly VERSION_INFO_TAG: string = environment.appName + '_' + 'info_version_tag' + '_' + environment.v;
    private static readonly LISTADO_TAG: string = environment.appName + '_' + 'listado_tag' + '_' + environment.v;
    private static readonly MANTENIMIENTO_FIRST_TIME_TAG: string = 
        environment.appName + '_' + 'mantenimiento_first_time_tag' + '_' + environment.v;
    private static readonly MANTENIMIENTO_LAST_TIME_TAG: string = 
        environment.appName + '_' + 'mantenimiento_last_time_tag' + '_' + environment.v;
    private static readonly MANTENIMIENTO_ADDRESS_TAG: string = 
        environment.appName + '_' + 'mantenimiento_address_tag' + '_' + environment.v;

    //MAPS VARIABLES
    private static readonly LAST_FINCA_TAG: string = environment.appName + '_' + 'finca_tag' + '_' + environment.v;
    private static readonly LAST_SECTOR_TAG: string = environment.appName + '_' + 'sector_tag' + '_' + environment.v;
    private static readonly LAST_CULTIVO_TAG: string = environment.appName + '_' + 'cultivo_tag' + '_' + environment.v;
    private static readonly LAST_LATLNG_TAG: string = environment.appName + '_' + 'latlng_tag' + '_' + environment.v;
    private static readonly LAST_COLOR_TAG: string = environment.appName + '_' + 'color_tag' + '_' + environment.v;
    private static readonly LAST_ZOOM_TAG: string = environment.appName + '_' + 'zoom_tag' + '_' + environment.v;

    private static readonly hasClients: boolean = environment.features.hasClients;

    public static saveUser(user: UserModel) {
        localStorage.setItem(this.USER_TAG, JSON.stringify(user));
    }

    public static getUser(): UserModel {
        const user = localStorage.getItem(this.USER_TAG);
        return user ? JSON.parse(user) : null;
    }

    public static deleteUser() {
        localStorage.removeItem('sm');
        localStorage.setItem(this.USER_TAG, '');
    }

    public static saveClient(client: ClienteModel | null) {
        if (client && (Object.prototype.hasOwnProperty.call(client, 'nombre') &&Object.prototype.hasOwnProperty.call(client, 'apellidos') &&
            Object.prototype.hasOwnProperty.call(client, 'telefono')) || client === null) {
            sessionStorage.setItem(this.CLIENT_TAG, JSON.stringify(client));
        }
    }

    public static saveCalendarFilter(filtro: string) {
        if (filtro) {
            sessionStorage.setItem(this.CALENDAR_FILTER_TAG, JSON.stringify(filtro));
        }
    }

    public static deleteClient() {
        sessionStorage.setItem(this.CLIENT_TAG, '');
    }

    public static deleteCalendarFilter() {
        sessionStorage.setItem(this.CALENDAR_FILTER_TAG, '');
    }

    
    public static getClient() {
        if (this.hasClients) {
            const client = sessionStorage.getItem(this.CLIENT_TAG);
            return client ? JSON.parse(client) : null;
        } else {
            return null;
        }
    }

    public static getCalendarFilter() {
        if (this.hasClients) {
            return JSON.parse(sessionStorage.getItem(this.CALENDAR_FILTER_TAG) ?? '{}');
        } else {
            return null;
        }
    }

    public static getVersionInfo() {
        return JSON.parse(localStorage.getItem(this.VERSION_INFO_TAG) || '{"version": 0}');
    }

    public static deleteVersionInfo() {
        localStorage.setItem(this.VERSION_INFO_TAG, '');
    }

    public static setVersionInfo(data: string) {
        localStorage.setItem(this.VERSION_INFO_TAG, JSON.stringify(data));
    }


    ////////////////    MAPS STORAGE    //////////////////
    public static saveLastFincaMap(finca: string) {
        localStorage.setItem(this.LAST_FINCA_TAG, JSON.stringify(finca));
    }

    public static saveLastSectorMap(sector: string) {
        localStorage.setItem(this.LAST_SECTOR_TAG, JSON.stringify(sector));
    }

    public static saveLastCultivoMap(cultivo: string) {
        localStorage.setItem(this.LAST_CULTIVO_TAG, JSON.stringify(cultivo));
    }

    public static getLastFincaMap(): string {
        return JSON.parse(localStorage.getItem(this.LAST_FINCA_TAG) ?? '{}');
    }

    public static getLastSectorMap(): string {
        return JSON.parse(localStorage.getItem(this.LAST_SECTOR_TAG) ?? '{}');
    }

    public static getLastCultivoMap(): string {
        return JSON.parse(localStorage.getItem(this.LAST_CULTIVO_TAG) ?? '{}');
    }

    public static deleteLastFincaMap() {
        localStorage.setItem(this.LAST_FINCA_TAG, '');
    }

    public static deleteLastSectorMap() {
        localStorage.setItem(this.LAST_SECTOR_TAG, '');
    }

    public static deleteLastCultivoMap() {
        localStorage.setItem(this.LAST_CULTIVO_TAG, '');
    }

    public static saveLastLatLng(latlng: string) {
        localStorage.setItem(this.LAST_LATLNG_TAG, JSON.stringify(latlng));
    }

    public static getLastLatLng(): string {
        return JSON.parse(localStorage.getItem(this.LAST_LATLNG_TAG) ?? '{}');
    }

    public static deleteLastLatLng() {
        localStorage.setItem(this.LAST_LATLNG_TAG, '');
    }

    public static saveLastColorMap(color: string) {
        localStorage.setItem(this.LAST_COLOR_TAG, JSON.stringify(color));
    }

    public static getLastColorMap(): string {
        return JSON.parse(localStorage.getItem(this.LAST_COLOR_TAG) ?? '{}');
    }

    public static deleteLastColorMap() {
        localStorage.setItem(this.LAST_COLOR_TAG, '');
    }

    public static saveLastZoom(zoom: number) {
        localStorage.setItem(this.LAST_ZOOM_TAG, JSON.stringify(zoom));
    }

    public static getLastZoom(): number {
        return JSON.parse(localStorage.getItem(this.LAST_ZOOM_TAG) ?? '{}');
    }

    public static deleteLastZoom() {
        localStorage.setItem(this.LAST_ZOOM_TAG, '');
    }

    // VADEMECUM: Listado de Productos
    public static saveListadoProductosCSV(productos: ProductosModel[]) {
        localStorage.setItem(this.LISTADO_TAG, JSON.stringify(productos));
    }

    public static getListadoProductosCSV(): ProductosModel[] {
        return JSON.parse(localStorage.getItem(this.LISTADO_TAG) || '[]');
    }

    public static deleteListadoProductosCSV() {
        localStorage.setItem(this.LISTADO_TAG, '');
    }
    /**
 * 
 || (( id == '2') && environment.appName == 'fitogest')
 || (( id == '2') && environment.appName == 'fitogest')
 */
    public static checkHiddenDosisAplicada() {
        try {
            const sm = localStorage.getItem('sm') ? localStorage.getItem('sm') : '0';
            if ( (sm === '1' )) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }

    }

    public static checkHiddenDosisCuaderno() {
        try {
            const nombre = StorageManager.getUser().nombre;
            if ((environment.appName === 'torrepacheco' && nombre === 'prueba')) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }

    }

    /*********************************************************
     * @autor David Fernández Fuster.
     * 
     * @resumen Guarda los valores de los campos Hora Inicio y Hora Fin
     *          en las tareas de mantenimiento de Don Jardin.
     * @motivo Estos valores sirven de valores por defecto de sus respectivos
     *         campos a la hora de insertar un nuevo registro durante 24 horas.
     * ------------------------------------------------------
     * @param horaInicio value: Hora en formato 00:00, expiry: tiempo de expiración (24h)
     * @param horaFinal value: Hora en formato 00:00, expiry: tiempo de expiración (24h)
     ********************************************************/
    public static saveSessionDataDonJardin(direccion: string, horaInicio: string, horaFinal: string){
        const hora: string|null = this.checkExpiricyTime(this.MANTENIMIENTO_FIRST_TIME_TAG);

        if (isNull(hora)){
            const direccionExpirable = this.establishExpiricyTime(direccion, 86400000);
            const horaInicialExpirable = this.establishExpiricyTime(horaInicio, 86400000);
            const horaFinalExpirable = this.establishExpiricyTime(horaFinal, 86400000);
            
            sessionStorage.setItem(this.MANTENIMIENTO_ADDRESS_TAG, JSON.stringify(direccionExpirable));
            sessionStorage.setItem(this.MANTENIMIENTO_FIRST_TIME_TAG, JSON.stringify(horaInicialExpirable));
            sessionStorage.setItem(this.MANTENIMIENTO_LAST_TIME_TAG, JSON.stringify(horaFinalExpirable));
        }// if();
    }// ();
    /********************************************************************** */
    /********************************************************************** */
    
    /*****************************************************
     * @autor David Fernández Fuster
     * 
     * ---------------------------------------------------
     * @returns Una lista con la hora de inicio y la final.
     *****************************************************/
    public static getSessionDataDonJardin(){
        const direccion: string|null = this.checkExpiricyTime(this.MANTENIMIENTO_ADDRESS_TAG);
        const horaInicio: string|null = this.checkExpiricyTime(this.MANTENIMIENTO_FIRST_TIME_TAG);
        const horaFinal: string|null = this.checkExpiricyTime(this.MANTENIMIENTO_LAST_TIME_TAG);
        /************************************************* 
        * Comprobamos si el rol del usuarios es admin, 
        * de ser así vaciamos hora inicio y hora fin
        *************************************************/
        if ( StorageManager.getUser().rol === 'admin' ){
            return [direccion, null, null];
        } else {
            return [direccion, horaInicio, horaFinal];
        }
    }// ();
    /********************************************************************** */
    /********************************************************************** */

    /*****************************************************************************
     * @autor David Fernández Fuster
     * 
     * @resumen Establece un tiempo de caducidad para el valor almacenado.
     * @motivo Por el momento, se emplea en la hora de inicio y final de las tareas de mantenimiento.
     * --------------------------------------------------------------------------
     * @param value Valor almacenado.
     * @param expiryTime Tiempo de caducidad en milisegundos del valor almacenado.
     * @returns El valor con su fecha de caducidad.
     ****************************************************************************/
    public static establishExpiricyTime(value: string, expiryTime: number){
        const fechaActual = new Date();
        const item: object = {
            value: value,
            expiry: fechaActual.getTime() + expiryTime
        };

        return item;
    }
    /********************************************************************** */
    /********************************************************************** */

    /**********************************************************************
     * @autor David Fernández Fuster
     * 
     * @resumen Comprueba, de existir, si un valor està caducado.
     *          De estarlo, lo saca del almacén.
     * @motivo Comprobar el estado de los valores almacenados con fecha de caducidad.
     *         Sirve tanto para comprobar si el valor existe como si está caducado.
     * --------------------------------------------------------------------
     * @param key Clave que identifica el valor del que queremos comprobar su estado.
     * @returns valor nulo en caso de que esté caducado o no existe.
     *          valor almacenado en caso de que exista y no esté caducado.
     *********************************************************************/
    public static checkExpiricyTime(key: string){
        const itemStr: string = sessionStorage.getItem(key) ?? '';

        if (!itemStr) {
            // console.log("No existe.")
            return null;
        }// if();

        const item = JSON.parse(itemStr);
        const fechaActual: Date = new Date();

        if (fechaActual.getTime() > item.expiry) {
            sessionStorage.removeItem(key);
            return null;
        }// if();

        return item.value;
    }// ();
    /********************************************************************** */
    /********************************************************************** */
}
