import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {ProductosApiService} from '../../../../service/api/productos-api';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Utils} from '../../../../common/utils';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {BaseView} from '../../../base-view';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { Filtering } from 'src/app/service/filtering/filtering';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-productos-simple',
    templateUrl: './productos-simple.component.html',
    styleUrls: ['./productos-simple.component.scss']
})
export class ProductosSimpleComponent extends BaseView<ProductosModel> implements OnInit, OnDestroy {

    public showProductosMostrarTodos = environment.features.showProductosMostrarTodos;
    public currentUser = StorageManager.getUser();
    public filter: Filtering<ProductosModel> = new Filtering<ProductosModel>();
    public showAll = false;

    public getRequest: RequestHandler<ProductosModel[]> = this.productosApi.productos.GET;
    public deleteRequest: RequestHandler<ProductosModel> = this.productosApi.productos.DELETE;
    public pathToForm = 'productos-form';
    public menuItemId = 'fitosanitarios';
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: ProductosModel = {nombre: '', materia_activa: ''};

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Referencia', 
            field: 'num_registro', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Nombre', 
            field: 'nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Materia Activa', 
            field: 'materia_activa', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Unidades de medida', 
            field: 'uds_medida', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public cols: ColInterface[] = [
        {field: 'num_registro', header: 'N˚\xa0Registro', width: '120px', filter: ''},
        {field: 'nombre', header: 'Nombre', filter: ''},
        {field: 'materia_activa', header: 'Materia\xa0activa', filter: ''},
        {field: 'uds_medida', header: 'Uds.\xa0medida', width: '140px', filter: ''},
        {header: 'Usuario', field: 'usuario_nombre', visible: this.showProductosMostrarTodos && (this.currentUser.rol === 'admin')},
    ];

    constructor(public productosApi: ProductosApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(dashboardEvents, productosApi.productos.GET);
    }

    ngOnInit() {
        this.showAll = Utils.initShowAll('showAllproductos_simple');
        this.initFilter();
        this.dashboardEvents.setMenuItem('productos-s');
    }

    public changeShowAll(){
        sessionStorage.setItem('showAllproductos_simple', this.showAll === true ? 'true' : 'false' );
    }    

    public addProducto() {
        this.router.navigate(['dashboard', 'crear-producto-s']);
    }

    public edit(data: ProductosModel) {
        this.router.navigate(['dashboard', 'editar-producto-s', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform({tipo: 'fitosanitario'} as never);
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public showDetalle(data: ProductosModel) {
        this.show('detalle', data, false);
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        setTimeout(() => {
            if (elements[0]) {
                elements[0].scrollTop = 0;
            }
        }, 100);

    }

    public override show(modal: string, data: ProductosModel, closePreviousModal: boolean = true) {
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x] && closePreviousModal) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.canShow[modal] = false;
        if (modal !== 'detalle') {
            this.model = {nombre: '', materia_activa: ''};
            this.deleteRequest.reset();
        }
    }

    public globalConditionalStyle(value: string, col: ColInterface, _colGroup: ColInterface[], row: TreeNode<ProductosModel>): ColStylesInterface {
        const producto = row as ProductosModel;
        if (Utils.isNumber(value) && col.field !== 'num_registro') {
            if (col.field === 'ps') { return { textAlign: 'right', paddingRight: '40px' }; }
            else { 
                return { textAlign: 'right' };
            }
        }
        if (col.field === 'ps') { return { textAlign: 'right', paddingRight: '40px' }; }

        let estiloVademecum: ColStylesInterface = {};
        if (col.field === 'fecha_caducidad') {
            estiloVademecum = {
                rowStyle: {
                    color: 'black',
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid #dddddd'
                },
                textAlign: 'center'
            };

            const diffInsc = moment(new Date()).diff(Utils.toDate(producto.fecha_inscripcion ?? ''), 'months');

            if (diffInsc < 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#cbdecb',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffffff',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            }

            const diff = moment(new Date()).diff(Utils.toDate(producto.fecha_caducidad ?? ''), 'months');

            if (diff > 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'white',
                        backgroundColor: '#222222',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else if (diff > 6) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ff7f7f',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else if (diff >= 0) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffcf78',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            }
        }


        return estiloVademecum;
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (Utils.isNumber(value)){
            if (col.field === 'ps' || col.field === 'envase') { return Utils.decimalFormat(value, 0); }
            else if (col.field !== 'num_registro') { return Utils.decimalFormat(value, 2, '.', ',', 3); }
        } 
        return value;
    }

    private initFilter() {
        this.filter.addFilter((data: TreeNode<ProductosModel>[] | void) => (data || [])
            .filter((it: TreeNode<ProductosModel>) => it)
            .filter((it: TreeNode<ProductosModel>) => !this.showAll
                ? [it.data?.id_usuario].includes(this.currentUser.id)
                : true)
        );
    }    
}

