<button [class]="'sidenav-item ' + status" (click)="sidenavClicked()">
  <div>
    <div class="d-flex justify-content-center">
      <i [class]="icon + ' iconText'"></i>
    </div>

    <div class="d-flex justify-content-center">
      <p class="itemText">{{text}}</p>
    </div>
  </div>

  <div *ngIf="expand">
    <div *ngIf="isSelected">
      <div class="expand-arrow d-flex justify-content-center">
        <i class="fa fa-chevron-up expand"></i>
      </div>
    </div>

    <div *ngIf="!isSelected">
      <div class="expand-arrow d-flex justify-content-center">
        <i class="fa fa-chevron-down expand"></i>
      </div>
    </div>

    <div class="expand-container" [style.height]="submenuOpen">
      <div *ngFor="let data of expand; let i = index" [attr.data-index]="i">
        <ng-container
                *ngIf="data !== undefined && data !== null && (data.visible === undefined || data.visible === true)">

          <button *ngIf="!data.isSelected"
                #subMenuButton
               (click)="subSidenavClicked(data.id)"
               [ngClass]="{'expand-item-active': data.isSelected, 'expand-item-passive': !data.isSelected}"
               class="d-flex justify-content-center expand-item-passive">
               <p class="expand-text">{{ data.subtitulo ? data.subtitulo : data.text }}</p>
          </button>

          <button *ngIf="data.isSelected && i > 0"
               (click)="subSidenavClicked(data.id)"
               [ngClass]="{'expand-item-active': data.isSelected, 'expand-item-passive': !data.isSelected}"
               class="d-flex justify-content-center expand-item-active">

               <p class="expand-text">{{ data.subtitulo ? data.subtitulo : data.text }}</p>
          </button>
          <button *ngIf="data.isSelected && i === 0"
               (click)="subSidenavClicked(data.id)"
               [ngClass]="{'expand-item-active': data.isSelected, 'expand-item-passive': !data.isSelected}"
               class="d-flex justify-content-center expand-item-active"
               style="
              -webkit-box-shadow: inset 0px 9px 10px -5px rgba(0, 0, 0, 0.40);
              -moz-box-shadow: inset 0px 9px 10px -5px rgba(0, 0, 0, 0.40);
              box-shadow: inset 0px 9px 10px -5px rgba(0, 0, 0, 0.40);">

          <p class="expand-text">{{ data.subtitulo ? data.subtitulo : data.text }}</p>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</button>
