<div class="resp-container" style="margin: -19px -21px 0px -35px;">

    <div class="resp-iframe" style="width:100%; height:100%;">
        <iframe 
            style="width: 100%; height: 92%; background-color: aliceblue;"
            [src]="thebluedotsURL">
        </iframe>
    </div>

    <ng-container *ngIf="showCargando">
        <img style="   
      width: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -75px;
      margin-left: -75px;" src="./assets/icon/load.gif" 
      alt="Cargando"/>
    </ng-container>

</div>