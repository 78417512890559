import {Component} from '@angular/core';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '../../../../base-form';
import {buildParams, list, stack} from '../../../../../common/classes/request-builder';
import {VisitasApiService} from '../../../../../service/api/visitas-api.service';
import {CitasModel} from '../../../../../models/citas/citas.model';
import {CitasApiService} from '../../../../../service/api/citas-api.service';
import {environment} from '../../../../../../environments/environment';
import { Filtering } from '../../../../../service/filtering/filtering';
import moment from 'moment';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { SiexApiService } from '../../../../../service/api/siex-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { VisitasModel } from 'src/app/models/visitas/visitas.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';

@Component({
    selector: 'app-visitas-form',
    templateUrl: './visitas-form.component.html',
    styleUrls: ['./visitas-form.component.scss']
})
export class VisitasFormComponent extends BaseForm<VisitasModel> implements OnInit {

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public hasClients = environment.features.hasClients;
    public showVisitasPlagas = environment.features.showVisitasPlagas;
    public showVisitaCarenciasMultiple = environment.features.showVisitaCarenciasMultiple;
    public showVisitasPascualCabedo = environment.features.showVisitasPascualCabedo;
    public showMoviendo = environment.features.showMoviendo;
    public showCosecha = environment.features.showCosecha;
    public showNumTallos = environment.features.showNumTallos;
    public showVisitasFisiopatias = environment.features.showVisitasFisiopatias;
    public showVisitasCultivos = environment.features.showVisitasCultivos;
    public showVisitasVariedades = environment.features.showVisitasVariedades;
    public isMelia = ((this.appName === 'melia') && (StorageManager.getUser().nombre === 'ALEJANDRO'));
    public showAllCultivosPrecedentes = environment.features.showAllCultivosPrecedentes;
    public showLocationLatLng = environment.features.showLocationLatLng;
    public hiddenVisitasCerezo = environment.features.hiddenVisitasCerezo;
    public hiddenVisitasContador = environment.features.hiddenVisitasContador;
    public showVisitasFormAbonosFolques = environment.features.showVisitasFormAbonosFolques;
    public showVisitasCamposPersonalizados = environment.features.showVisitasCamposPersonalizados;
    public showEstadoFenologicoAlmendro = environment.features.showEstadoFenologicoAlmendro;
    public showEstadoFenologicoVid = environment.features.showEstadoFenologicoVid;

    public override model: VisitasModel = {};
    public formRequest = new AppFormRequest<VisitasModel>();
    public id_finca = '';
    public cita: CitasModel = new CitasModel();

    public plagas = list(['...', null],
        'HONGOS DEL SUELO',
        'HONGOS ENDÓFITOS',
        'INSECTOS DEL SUELO',
        'NEMATODOS',
        'MALAS HIERBAS',
        'TOPOS',
        'BABOSAS',
        'BACTERIOSIS',
        'BARRENETA',
        'CARACOLES',
        'PREVENIR DESECACIÓN PLANTAS',
        'RETRASAR ENVEJECIMIENTO',
        'ÁCAROS',
        'ÁCARO ROJO',
        'ÁCAROS TETRANÍQUIDOS',
        'ARAÑA ROJA',
        'ARAÑA AMARILLA',
        'AGRIOTES',
        'AGUADO',
        'ARMILARIA',
        'CACOECIA',
        'CAPARRETA',
        'CERATITIS',
        'CERCÓPIDO',
        'CHANCRO',
        'CHINCHES',
        'ERIÓFIDOS',
        'ESCARABAJO',
        'EVITAR CAIDA FRUTO',
        'EVITAR CHOQUE TRANSPLANTE',
        'FITOPHTORA',
        'FOMOPSIS',
        'GOMOSIS',
        'GORGOJOS',
        'GRAMINEAS',
        'GRAMINEAS ANUALES',
        'GRAMINEAS VIVACES',
        'OIDIOPSIS',
        'GUSANOS BLANCOS',
        'GUSANOS DE ALAMBRE',
        'GUSANOS GRISES',
        'INDUCIR CUAJADO',
        'INDUCIR CUAJADO FLORES',
        'LARVAS DE DÍPTEROS',
        'LARVAS DE DIABROTICA',
        'LARVAS DE LEPIDÓPTEROS',
        'LAGARTA',
        'RIZOCTONIA',
        'SERPETA',
        'MINADORES DE HOJAS',
        'MOSCA BLANCA',
        'PULGONES',
        'ESTIMULAR ACTIVIDAD VEGETATIVA',
        'COLEÓPTEROS',
        'HORMIGAS',
        'INSECTOS',
        'AUMENTAR TAMAÑO FRUTO',
        'MOSQUITO VERDE',
        'PODREDUMBRE',
        'VERTICILIUM',
        'MALAS HIERBAS VIVACES',
        'MALAS HIERBAS ANUALES',
        'DICOTILEDONEAS ANUALES',
        'DICOTILEDONEAS',
        'ANTRACNOSIS',
        'ROYA',
        'STEMPHYLIUM',
        'ALTERNARIA',
        'BOTRITIS',
        'ESCLEROTINIA',
        'PHOMA',
        'TOPILLOS',
        'TIZON',
        'TETRANYCHUS URTICAE',
        'ALGAS',
        'MUSGOS',
        'MALAS HIERBAS MONOCOTILEDONEAS Y DICOTILEDONEAS',
        ('Micosphaerella').toUpperCase(),
        ('Septoria').toUpperCase(),
        'BROTACIÓN',
        'TOCONES',
        'TRIPS',
        'TORTRIX',
        'TUBERCULOSIS',
        'XANTHOMONAS',
        'POLILLAS',
        'PRAYS',
        'PROCESIONARIA',
        'PSEUDOMONAS',
        'PSILA',
        'PSILIDOS',
        'REBROTES DE CEREALES',
        'PHYLLOCNISTIS',
        'PHYTOPHTORA',
        'PIOJO BLANCO',
        'PIOJO GRIS',
        'PIOJO ROJO',
        'PIOJO ROJO CALIFORNIA',
        'PIOJOS',
        'PITIUM',
        'PLAGAS EN ESTADO HIBERNANTE',
        'PLUTELLA',
        'NEOPHILAENUS CAMPESTRIS',
        'NOCTUIDOS',
        'OIDIO',
        'ORTÓPTEROS',
        'ORUGAS',
        'PANONICHUS CITRI',
        'PARLATORIA',
        'PENICILIUM',
        'PHILAENUS SPUMARIUS',
        'CICADÉLIDOS',
        'CIPERACEAS',
        'COADYUVANTE',
        'COLETOTRICUM',
        'COTONET',
        'DÍPTEROS',
        'EOTETRANYCHUS CARPINI',
        'LEPIDÓPTEROS',
        'MAL SECO',
        'MALAS HIERBAS DE HOJA ANCHA',
        'MILDIU',
        'MINADORES',
        'MOSCA DE LA CEREZA',
        'MOSCA DE LA FRUTA',
    );

    public plagasFast = list(['...', null], 
        ['----- PLAGAS -----', null],
        'Cochinilla',
        'Lagarta',
        'Mosca Blanca',
        'Araña Roja',
        'Thrips',
        'Picuda',
        ['----- ENFERMEDADES -----', null],
        'Fusarium',
        ['----- OTROS -----', null],
        'Malas Hierbas',
        'Nematodos',
        ['----- AUXILIARES -----', null],
        'Crisopas',
        'Sírfidos',
        'Fitoseidos',
        'Trichogramma',
        'Aves'
    );

    public plagasFolques = list(
        ['...', null],
        'Fauna salvaje',
        'Cotonet de Sudáfrica',
        'Trip orquidea',
        'Minador',
        'Prays',
        'Empoasca',
        'Cochinilla café',
        'Caparreta negra',
        'Serpeta',
        'Poll gris',
        'Mosca fruta',
        'Caracoles - Babosas',
        'Pezotrip',
        'Mosca blanca Paraleirodes',
        'Cotonet citricos',
        'Cochinilla acanalada',
        'Caparreta blanca',
        'Poll roch',
        'Mosca blanca algodonosa',
        'Pulgon algonodero',
        'Pulgon primavera',
        'Ácaro Maravillas',
        'Ácaro oriental',
        'Araña roja',
        'Ácaro rojo',
        'Lepra',
        'Alternaria',
        'Antracnosis',
        'Descamado patron',
        'Mancha grasienta',
        'Negrilla',
        'Fitophtora',
        'Melanosis',
        'Tristeza'
    );
    
    public estadoFast = list(
        ['...', null], 
        ['Nivel Bajo', 'Bajo'], 
        ['Vigilancia', 'Vigilancia'], 
        ['Aplicar Control', 'Control'],
        ['Hay (AUXILIARES)', 'Hay'],
        ['No Hay (AUXILIARES)', 'No Hay']
    );

    public estadoPascualCabedo = list(
        ['...', null],
        'Mucho',
        'Regular',
        'Poco',
        'Muy poco',
        'Huevos',
        'Larvas',
        'Ninfas',
        'Adultas',
        'Inicio Brotación',
        'Brotación (+5cm)',
        'Brotación con Flores'
    );

    public estadosOrtizLavado = list(['...', null], 'Fitotoxicidad', 'Deficiente', 'Bueno');

    public concertadaValue = '';
    public concertada = list(['...', null], 'Si', 'No');
    public estado = list(['...', null], 'Mucho', 'Regular', 'Poco', ((this.appName === 'agromainsa') ? 'Presencia' : 'Muy Poco'));
    public umbral = list(
        ['...', null],
        '% Formas móviles',
        '% Brotes afectados',
        '% Hojas ocupadas',
        '% Fruta atacada',
        'Moscas/Mosquero/Día',
        'Condiciones meteorológicas favorables',
    );
    public parcelas = list();
    public sectores = list();
    public cultivos = list();
    public cultivosPrecedentes = list();
    public variedades = list();
    public estadoArbolado = list(['...', null], 'Bien', 'Regular', 'Mal');
    public moviendo = list(['...', null], 'Si', 'Poco', 'No');
    public cosecha = list(['...', null], 'Bien', 'Regular', 'Mal');
    public tamano = list(['...', null], 'Bien', 'Regular', 'Mal');
    public tamanoEspGranada = list(['...', null], 'Fino', 'Medio', 'Grueso');
    public humedad = list(['...', null], 'Subir', 'Bien', 'Bajar');
    public floracion = list(['...', null], 'Mucha', 'Regular', 'Poca');
    public tamanosFruta = list(['...', null], '0-40', '40-60', '+60');
    public listaCarencias = list(['...', null], 'Ca', 'Fe', 'Mg', 'Mn', 'N', 'Zn');
    public listaPorcentajes = list(['...', null], '10', '20', '30', '40', '50', '60', '70', '80', '90', '100');


    public estadoAranaRoja = list(
        ['...', null],
        'Huevos',
        'Larvas',
        'Ninfas',
        'Adultas'
    );

    public estadoBrotacion = list(
        ['...', null],
        'Inicio Brotación',
        'Brotación (+5cm)',
        'Brotación con Flores'
    );

    public estadoMalasHierbas = list(
        ['...', null],
        'Serreig',
        'Puntxo',
        'Juncia',
        'Heteranthera'
    );

    public cosechaAgromainsa = list(['...', null], 'Mucha', 'Normal', 'Regular', 'Poca');
    public aclareo = list(['...', null], 'Trasplantar', 'Birbar');
    public fisiopatias = list(
        'Repón o floración extratemporánea',
        'Creasing o Clareta',
        'Rajado fruta',
        'Pateta rata',
        'Retencion savia',
        'Viento',
        'Golpe sol',
        'Pedrisco',
        'Pulgón',
        'Pyricularia',
        'Helminthosporium',
        'Anoxia',
        'Salinidad'
    );

    public estadios = list(
        ['...', null],
        'Larva',
        'Ninfa',
        'Huevo',
        'Ciclo completo',
        'Adulto'
    );

    public estadoFisiologico = list(
        ['...', null],
        '1 hoja',
        '2 hojas',
        '1 hijo',
        'Más de 2 hijos',
        'Encañado',
        'Inicio de espiga',
        'Espiga'
    );
    
    public estadoFisiologicoCebolla = list(
        ['...', null],
        'Gancho',
        'Bandera',
        '1ª hoja verdadera',
        '2 hojas',
        '4 hojas',
        '5 hojas',
        '6 hojas',
        '7 hojas',
        '8 hojas',
        '9 hojas',
        '10 hojas',
        '11 hojas',
        '12 hojas'
    );
    
    public estadoBulboCebolla = list(
        ['...', null],
        'Inicio del Bulbado',
        'Bulbando',
        'Fin del Bulbado'
    );

    public estadoFenologicoAlmendro = list(
        ['...', null],
        'Yemas de Invierno',
        'Yemas Hinchadas',
        'Cáliz Visible',
        'Corola Visible',
        'Estambres Visibles',
        'Flor Abierta',
        'Caída de Pétalos',
        'Fruto Cuajado',
        'Fruto Joven',
        'Fruto en Evolución hacia la Madurez'
    );
    public estadoFenologicoVid = list(
        ['...', null],
        'Brotación',
        'Desarrollo de las Hojas',
        'Aparición del Organo Floral',
        'Floración',
        'Formación del Fruto',
        'Maduración de Frutos',
        'Comienzo del Reposo Vegetativo'
    );

    public plagasFormFields: ItemInterface<object>[] = [];

    public formFieldsCarencias: ItemInterface<object>[] = [
        {
            label: 'Carencia 1',
            field: 'carencia1',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.listaCarencias))
        },
        {
            label: 'Porcentaje de carencia 1',
            field: 'porcentaje_carencia1',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.listaPorcentajes))
        },
        {
            label: 'Carencia 2',
            field: 'carencia2',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.listaCarencias))
        },
        {
            label: 'Porcentaje de carencia 2',
            field: 'porcentaje_carencia2',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.listaPorcentajes))
        },
        {
            label: 'Carencia 3',  
            field: 'carencia3',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.listaCarencias))
        },
        {
            label: 'Porcentaje de carencia 3',  
            field: 'porcentaje_carencia3',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.listaPorcentajes))
        }
    
    ];

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Estado del arbolado',
            field: 'estado_arbolado',
            inputType: {type: InputType.DROPDOWN},
            values: this.estadoArbolado,
            visible: this.appName !== 'agropecuariaramirogruas' && this.appName !== 'pedrosaez'
        },
        {
            label: 'Moviendo',
            field: 'moviendo',
            inputType: {type: InputType.DROPDOWN},
            values: this.moviendo,
            visible: this.showMoviendo
        },
        {
            label: 'Cosecha',
            field: 'estado_cosecha',
            inputType: {type: InputType.DROPDOWN},
            values: this.cosecha,
            visible: this.showCosecha
        },
        {
            label: 'Nº Tallos',
            field: 'num_tallos',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.showNumTallos
        },
        {
            label: (this.appName === 'esparragosgranada') ? 'Calibre' : 'Tamaño',
            field: 'tamanyo',
            inputType: {type: InputType.DROPDOWN},
            values: (this.appName === 'esparragosgranada') ? this.tamanoEspGranada : this.tamano,
            visible: this.appName !== 'agropecuariaramirogruas' && this.appName !== 'pedrosaez'
        },
        {
            label: 'Calibre de la fruta',
            field: 'tamano_fruta',
            inputType: {type: InputType.DROPDOWN},
            values: this.tamanosFruta,
            visible: (this.appName === 'pascualcabedo')
        },
        {
            label: 'Humedad',
            field: 'humedad',
            inputType: {type: InputType.DROPDOWN},
            values: this.humedad,
            visible: this.appName !== 'agropecuariaramirogruas' && this.appName !== 'pedrosaez'
        },
        {
            label: 'Floración',
            field: 'estado_floracion',
            inputType: {type: InputType.DROPDOWN},
            values: this.floracion,
            visible: this.appName !== 'agropecuariaramirogruas' && this.appName !== 'pedrosaez'
        },
        {
            label: 'Cultivo',
            field: 'id_cultivo',
            inputType: {type: InputType.DROPDOWN},
            values: this.cultivos,
            valuePrimaryKey: 'id',
            visible: this.showVisitasCultivos,
            filter: (it: { value: VisitasVariedadMesModel; }) => (this.parcelas.values || [])
                .filter(p => p && p.value)
                .map(p => p.value)
                .filter(p => p.id_finca === this.id_finca)
                .some(p => {
                    const cultivo = it.value;
                    if (p.id_cultivo === cultivo.id) {
                        cultivo.id_parcela = p.id;
                    }
                    return p.id_cultivo === cultivo.id;
                })
        },
        {
            label: 'Variedad/es',
            field: 'id_variedad',
            inputType: {type: InputType.DROPDOWN},
            values: this.variedades,
            valuePrimaryKey: 'id',
            visible: this.showVisitasVariedades,
            filter: (it: { value: VisitasVariedadMesModel; }) => (this.parcelas.values || [])
                .filter(p => p && p.value)
                .map(p => p.value)
                .filter(p => p.id_finca === this.id_finca)
                .some(p => {
                    const variedad = it.value;

                    if (p.id_variedad === variedad.id) {
                        variedad.id_parcela = p.id;
                    }
                    return p.id_variedad === variedad.id;
                })
        },
        {
            label: 'Calidad Agua',
            field: 'calidad_agua',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName !== 'agropecuariaramirogruas' && this.appName !== 'pedrosaez'
        },
        {
            label: this.appName === 'pedrosaez' ? 'Estado Fisiológico del Cereal' : 'Estado Fisiológico',
            field: 'estado_fisiologico',
            inputType: {type: InputType.DROPDOWN},
            values: this.estadoFisiologico,
            visible: this.appName === 'agropecuariaramirogruas' || this.appName === 'pedrosaez'
        },
        {
            label: 'Estado Fisiológico de la Cebolla',
            field: 'estado_fisiologico_cebolla',
            inputType: {type: InputType.DROPDOWN},
            values: this.estadoFisiologicoCebolla,
            visible: this.appName === 'pedrosaez'
        },
        {
            label: 'Estado del Bulbo de la Cebolla',
            field: 'estado_bulbo_cebolla',
            inputType: {type: InputType.DROPDOWN},
            values: this.estadoBulboCebolla,
            visible: this.appName === 'pedrosaez'
        },
        {
            label: 'Observaciones',
            field: 'observaciones',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
        {
            label: 'Aclareo',
            field: 'aclareo',
            inputType: {type: InputType.DROPDOWN},
            values: this.aclareo,
            visible: this.applicationType === 'cropgest'
        },
        {
            label: 'Fisiopatías',
            field: 'fisiopatias',
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true,
            values: this.fisiopatias,
            visible: this.showVisitasFisiopatias
        },
        {
            label: 'Concertada',
            field: 'concertada',
            inputType: {type: InputType.DROPDOWN},
            values: this.concertada,
            visible: this.appName !== 'agropecuariaramirogruas' && this.appName !== 'pedrosaez'
        }
    ];

    
    public formFieldsArmentia: ItemInterface<object>[] = [
        {
            label: 'Cultivo',
            field: 'id_cultivo',
            inputType: {type: InputType.DROPDOWN},
            values: this.cultivos,
            valuePrimaryKey: 'id',
            filter: (it: { value: VisitasVariedadMesModel; }) => (this.parcelas.values || [])
                .filter(p => p && p.value)
                .map(p => p.value)
                .filter(p => p.id_finca === this.id_finca)
                .some(p => {
                    const cultivo = it.value as VisitasVariedadMesModel;

                    if (p.id_cultivo === cultivo.id) {
                        cultivo.id_parcela = p.id;
                    }
                    return p.id_cultivo === cultivo.id;
                })
        },
        {
            label: 'Estado desarrollo cultivo',
            field: 'estado_desarrollo_cultivo',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Fecha de siembra',
            field: 'fecha_siembra',
            inputType: {type: InputType.CALENDAR}
        },
        {
            label: 'Dosis de siembra',
            field: 'dosis_siembra',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Cultivo precedente',
            field: 'id_cultivo_precedente',
            inputType: {type: InputType.DROPDOWN},
            values: this.cultivosPrecedentes,
            valuePrimaryKey: 'id',
            visible: !this.showAllCultivosPrecedentes,
            filter: (it: { value: VisitasVariedadMesModel; }) => (this.parcelas.values || [])
                .filter(p => p && p.value)
                .map(p => p.value)
                .filter(p => p.id_finca === this.id_finca)
                .some(p => {
                    const cultivo = it.value;
                    if (p.id_cultivo === cultivo.id) {
                        cultivo.id_parcela = p.id;
                    }
                    return p.id_cultivo === cultivo.id;
                })
        },
        {
            label: 'Cultivo precedente',
            field: 'id_cultivo_precedente',
            inputType: {type: InputType.DROPDOWN},
            values: this.cultivosPrecedentes,
            visible: this.showAllCultivosPrecedentes,
            valuePrimaryKey: 'id',
            filter: () => {
                Filtering.distinctBy.bind({field: 'label'});
                return true;
            }
        },
        {
            label: 'Observaciones',
            field: this.appName === 'armentia' ? 'observaciones' : 'Observaciones',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
    ];

    
    public formFieldsFast: ItemInterface<object>[] = [
        {
            label: 'Variedad/es',
            field: 'id_variedad',
            inputType: {type: InputType.DROPDOWN},
            values: this.variedades,
            valuePrimaryKey: 'id',
            filter: (it: { value: VisitasVariedadMesModel; }) => (this.parcelas.values || [])
                .filter(p => p && p.value)
                .map(p => p.value)
                .filter(p => p.id_finca === this.id_finca)
                .some(p => {
                    const variedad = it.value;

                    if (p.id_variedad === variedad.id) {
                        variedad.id_parcela = p.id;
                    }

                    return p.id_variedad === variedad.id;
                })
        }
    ];

    public formFieldsPrevilsa: ItemInterface<object>[] = [
        {
            label: 'Tipo de visita',
            field: 'tipo_visita',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null],
                'Primera visita',
                'Visita a obra',
                'Visita de cortesía',
                'Visita por reclamación',
                'Entrega de tarifas',
                'Negociación de condiciones')
        },
        {
            label: 'Notas',
            field: 'observaciones',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
    ];

    public formFieldsFolques: ItemInterface<object>[] = [
        {
            label: 'Variedad/es',
            field: 'id_variedad',
            inputType: {type: InputType.DROPDOWN},
            values: this.variedades,
            valuePrimaryKey: 'id',
            visible: this.showVisitasVariedades,
            filter: (it: { value: VisitasVariedadMesModel; }) => {
                const variedad = it.value as VisitasVariedadMesModel;

                if ( this.parcelas.selected == null ) {
                    return this.variedades.selected.id === variedad.id;
                } 
                return (this.parcelas.values || [])
                    .filter(p => p && p.value)
                    .map(p => p.value)
                    .filter(p => p.id_finca === this.id_finca)
                    .some(p => {
                        if (p.id_variedad === variedad.id) {
                            variedad.id_parcela = p.id;
                        }
                        return p.id_variedad === variedad.id;
                    });
            }
        },
        {
            label: 'Previsión de aforo',
            field: 'kg_recolectados',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Calibre medio fruto',
            field: 'calibre_medio_fruto',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Calibre aclareo',
            field: 'calibre_aclareo',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Caída hojas suelo',
            field: 'caida_hojas_suelo',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Estado Malas Hierbas',
            field: 'estado_malas_hierbas',
            inputType: {type: InputType.DROPDOWN},
            values: this.estadoMalasHierbas
        },
        {
            label: 'Aplicar nutrientes general',
            field: 'aplicar_nutrientes_general',
            inputType: {type: InputType.SWITCH}
        },
        {
            label: 'Estado Fenológico',
            field: 'estado_fenologico',
            inputType: {type: InputType.DROPDOWN},
            values: list(
                ['...', null],
                'Fruto maduro 89',
                'Fruto cuajado 71-H',
                'Crecimiento del fruto 73-I',
                'Comienzo de la floración 60-E',
                'Primeras hojas totalmente expandidas 19',
                'Caida petalos CI 69-72',
                'Desarrollo fruto CI 71-79',
                'Floración botón CI 31-59'),
        },
        {
            label: 'Estado Fenológico Almendro',
            field: 'estado_fenologico_almendro',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null],
                'A Yemas de Invierno',
                'B Yemas Hinchadas',
                'C Cáliz Visible',
                'D Corola Visible',
                'E Estambres Visibles',
                'F Flor Abierta',
                'G Caída de Pétalos',
                'H Fruto Cuajado',
                'I Fruto Joven',
                'J Fruto en Evolución Hacia la Madurez',
            ),
            visible: this.showEstadoFenologicoAlmendro
        },
        {
            label: 'Estado Fenológico Vid',
            field: 'estado_fenologico_vid',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null],
                `00 Letargo: las yemas de invierno, de puntiagudas a redondeadas, marrón brillante u oscuro según la variedad; 
                 escamas de las yemas cerradas, de acuerdo con la variedad`,
                '01 Comienzo del hinchado de las yemas; los botones empiezan a hincharse dentro de las escamas',
                '03 Fin del hinchado de las yemas: yemas, hinchadas, pero no verdes',
                '05 "Estadio lanoso"; lana marrón, claramente visible',
                '07 Comienzo de la apertura de las yemas: ápices foliares verdes, apenas visibles',
                '09 Apertura de las yemas: ápices foliares claramente visibles',
                '10 Desarrollo de las Hojas',
                '53 Inflorescencias, claramente visibles',
                '55 Inflorescencias hinchándose; las flores apretadas entre sí',
                '57 Inflorescencias, desarrolladas completamente; flores separándose',
                '61 Comienzo de la floración: alrededor de 10 % de capuchones caídos',
                '69 Fin de la floración',
                '71 Cuajado de frutos; los frutos jóvenes comienzan a hincharse; los restos florales perdidos',
                '73 Bayas del tamaño de un perdigón, los racimos comienzan a pender',
                '75 Bayas de tamaño guisante (arvejas); los racimos, colgantes',
                '77 Las bayas comienzan a tocarse',
                '79 Todas las bayas de un racimo se tocan',
                '81 Comienzo de la maduración; las bayas comienzan a brillar',
                '83 Las bayas, brillantes',
                '85 Ablandamiento de las bayas',
                '89 Bayas, listas para recolectarse',
                '91 Después de la vendimia; fin de la maduración de la madera',
                '92 Comienzo de la decoloración foliar',
                '93 Comienzo de la caída de las hojas',
                '95 Alrededor de 50 % de las hojas caídas',
                '97 Fin de la caída de las hojas',
                '99 Partes cosechadas'
            ),
            visible: this.showEstadoFenologicoVid
        },
        {
            label: 'Cerezos',
            field: 'cerezos',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null],
                'Ápices florales verdes D 10',
                'Cuajado engorde I 73',
                'Cuajado inicio H 71',
                'Flor abierta F 85',
                'Hinchado yemas B 03',
                'Letargo A 00',
                'Maduración J 87',
                'Yemas florales hinchadas D 55',
            ),
            visible: (this.appName === 'ava' ? false : true) && !this.hiddenVisitasCerezo
        },
        {
            label: 'Fisiopatías',
            field: 'fisiopatias',
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true,
            values: this.fisiopatias,
            style: {maxHeight: '200px'}
        },
        {
            label: 'Lectura contador',
            field: 'lectura_contador',
            inputType: {type: InputType.EDIT_NUMERIC},
        },        
        {
            label: 'Carencias',
            field: 'carencias',
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true,
            values: list('Ca', 'Fe', 'Mg', 'Mn', 'N', 'Zn'),
            style: {maxHeight: '200px'},
            visible: !this.showVisitaCarenciasMultiple
        },
        {
            label: 'Porcentaje de carencia',
            field: 'porcentaje_carencia',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'),
            visible: !this.showVisitaCarenciasMultiple
        },
        {
            label: 'Observaciones',
            field: 'observaciones',
            inputType: {type: InputType.EDIT_TEXTAREA}
        }
    ];

    public formFieldsMelia: ItemInterface<object>[] = [
        {
            label: 'Poda',
            field: 'poda',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Pendiente', 'Realizada', 'No podar')
        },
        {
            label: 'Estado fenológico',
            field: 'estado_fenologico',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Parada vegetativa', 'Brotación', 'Floración', 'Cuajado', 'Caída fisiológica',
                'Crecimiento Fruto', 'Maduración', 'Recolección')
        },
        {
            label: 'Cosecha',
            field: 'cosecha',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Alta', 'Media', 'Baja')
        },
        {
            label: 'Tamaño (mm)',
            field: 'tamanyo',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Humedad',
            field: 'humedad',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Subir', 'Óptima', 'Bajar')
        },
        {
            label: 'Floración',
            field: 'estado_floracion',
            inputType: {type: InputType.DROPDOWN},
            values: this.floracion
        }
    ];


    constructor(
        public override route: ActivatedRoute,
        public override router: Router,
        public visitasApi: VisitasApiService,
        public citasApi: CitasApiService,
        public fincasApi: FincasApiService,
        public formApi: FormCommonApiService,
        public siexApi: SiexApiService,
        public override dashboard: DashboardService
    ) {
        super(
            route,
            router,
            dashboard,
            visitasApi.visitasPUT,
            visitasApi.visitasPOST,
            'visitas',
            'Editar Visita',
            'Crear Visita',
            'Duplicar Visita'
        );
    }

    ngOnInit() {
        
        if (this.appName === 'armentia') {
            this.formFields = this.formFieldsArmentia;
        }
        if (this.appName === 'lafast') {
            this.plagas = this.plagasFast;
            this.estado = this.estadoFast;
            this.formFields = this.formFieldsFast;
        }
        if (this.appName === 'ortizlavado') {
            this.estado = this.estadosOrtizLavado;
        }
        if (this.appName === 'pascualcabedo') {
            this.estado = this.estadoPascualCabedo;
        }
        if (this.appName === 'previlsa') {
            this.formFields = this.formFieldsPrevilsa;
        }
        if (this.showVisitasCamposPersonalizados || this.showVisitasFormAbonosFolques ) {
            if ( this.appName !== 'lluchychulbi' ) {
                this.plagas = this.plagasFolques;
            }
            
            this.formFields = this.formFieldsFolques;
        }
        if (this.appName === 'agromainsa') {
            const indexEstadoCosecha = this.formFields.map(it => it.field).indexOf('estado_cosecha') ?? '';
            (this.formFields[indexEstadoCosecha] as ItemInterface<object>).values = this.cosechaAgromainsa;
        }
        if (this.isMelia) {
            this.formFields = this.formFieldsMelia;
            this.estado = this.umbral;
        }

        this.initFields();

        if (this.route.snapshot.queryParams['cita']) {
            this.cita = JSON.parse(this.route.snapshot.queryParams['cita']);
            this.id_finca = this.cita.id_finca;
        }
        const fieldsToSend = (['id', 'id_usuario'] as string[] )
            .concat(
                this.formFields.map(it => it.field ?? ''),
                this.plagasFormFields.map(it => it.field ?? '')
            ).filter(field => field !== 'concertada');
        
        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        if ( this.showVisitaCarenciasMultiple ) {
            fieldsToSend.push(
                'carencia1', 
                'porcentaje_carencia1', 
                'carencia2', 
                'porcentaje_carencia2', 
                'carencia3', 
                'porcentaje_carencia3'
            );
        }
      

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .isGeneric(true)
            .setFormFields(Array.prototype.concat(
                this.formFields,
                this.plagasFormFields
            ))
            .setModel(this.model)
            .setGetRequest(this.visitasApi.visitasGET)
            .setPostRequest(this.visitasApi.visitasPOST)
            .setPutRequest(this.visitasApi.visitasPUT)
            .setFieldsToSend(fieldsToSend);

        this.initFormData();
        this.expandFormRequest();

        this.formRequest.load();

        this.softInit(this.getType());
        
    }

    public submit() {
        if (this.appName === 'agropecuariaramirogruas'){
            delete this.model.estado_fisiologico;
        }// if();
        
        this.formRequest.send();
    }

    public formChanges(tag: string) {
        if (tag === 'concertada') {
            this.concertadaValue = this.model[tag] ?? '';
        }
    }
    
    private initFields(): void {
        this.plagas.filtered.sort(
            Filtering.sort('label', 1)
        );
        
        for (let i = 0; i < 4; i++) {
            if (environment.features.plagasInput === true) {
                this.plagasFormFields.push({
                    label: (i === 0) ? 'Plagas' : '',
                    field: 'plaga' + (i + 1),
                    inputType: {type: InputType.EDIT_TEXT}
                });
            } else {
                this.plagasFormFields.push({
                    label: (i === 0) ? 'Listado de plagas' : '',
                    field: 'plaga' + (i + 1),
                    inputType: {type: InputType.DROPDOWN},
                    values: JSON.parse(JSON.stringify(this.plagas))
                });
            }

            this.plagasFormFields.push({
                label: (i === 0) ? 'Incidencia' : '',
                field: 'estado_plaga' + (i + 1),
                inputType: {type: InputType.DROPDOWN},
                values: JSON.parse(JSON.stringify(this.estado)),
                visible: !this.isMelia
            });
            this.plagasFormFields.push({
                label: (i === 0) ? 'Estadio' : '',
                field: 'estadio' + (i + 1),
                inputType: {type: InputType.DROPDOWN},
                values: JSON.parse(JSON.stringify(this.estadios)),
                visible: !this.isMelia
            });


            if (this.isMelia) {
                this.plagasFormFields.push({
                    label: (i === 0) ? 'Umbral' : '',
                    field: 'estado_plaga' + (i + 1),
                    inputType: {type: InputType.DROPDOWN},
                    values: JSON.parse(JSON.stringify(this.estado))
                });
                this.plagasFormFields.push({
                    label: 'Cantidad',
                    field: 'cantidad' + (i + 1),
                    inputType: {type: InputType.EDIT_NUMERIC}
                });
            }
        }
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            if (this.model.aplicar_nutrientes_general) {
                this.model.aplicar_nutrientes_general = [1, '1', true, 'true'].includes(this.model.aplicar_nutrientes_general);
            }
            resolve(true);
        });
        this.formRequest.beforeSend(resolve => {
            if ( (this.appName === 'armentia') ){
                if ( this.model.fecha_siembra == null ||  (this.model.fecha_siembra as string).length === 0  ){
                    this.model.fecha_siembra = null;
                } else if (this.model.fecha_siembra.toString().length > 10) {
                    this.model.fecha_siembra = moment(this.model.fecha_siembra).format('DD/MM/YYYY');
                }
            }
            
            resolve(true);
        });
        this.formRequest.afterSend(resolve => {
            this.model.concertada = this.concertadaValue;
            this.citasApi.citas.PUT.response(() => {
                this.citasApi.citas.PUT.unsuscribe();
            });

            this.citasApi.citas.PUT.perform({
                id: this.cita.id,
                concertada: this.concertadaValue,
                id_parcela: (this.variedades.selected || {}).id_parcela || (this.cultivos.selected || {}).id_cultivo || ''
            } as never);

            resolve(true);
        });
    }

    private initFormData() {
        const params = this.hasClients ? {id_cliente: this.cita.id_cliente} : null;
        stack([
            buildParams(this.fincasApi.parcelasGET, params, this.parcelas, 'id_variedad', true),
            buildParams(
                environment.features.activateSIEX ? 
                    this.siexApi.cultivosGET : 
                    this.formApi.cultivosGET, 
                params, this.cultivos, 
                'cultivo', 
                true
            ),
            buildParams(
                environment.features.activateSIEX ? 
                    this.siexApi.cultivosGET : 
                    this.formApi.cultivosGET, 
                params, 
                this.cultivosPrecedentes, 
                'cultivo', 
                true
            ),
            buildParams(
                environment.features.activateSIEX ? 
                    this.siexApi.variedadesGET : 
                    this.formApi.variedadesGET, 
                params, 
                this.variedades, 
                'variedad', 
                true
            ),
        ]).then(() => {
            this.model.concertada = this.cita.concertada ?? '';
            this.formRequest.update();
            return;
        }).catch (e => {
            console.log('catch en stack: ' + e);
        }
        );
    }
}

