import {Component} from '@angular/core';
import {DashboardService} from '../../../service/events/dashboard.service';
import {StockApiService} from '../../../service/api/stock-api.service';
import {Router} from '@angular/router';
import {RequestHandler} from '../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../common/components/request-button/request-button.component';
import {InputType} from '../../../common/components/form-builder/form-builder.component';
import {BaseView} from '../../base-view';
import { environment } from '../../../../environments/environment';
import { Utils } from '../../../common/utils';
import { Filtering } from '../../../service/filtering/filtering';
import { createCsv } from '../../../common/createCsv';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { StockageModel } from 'src/app/models/stock/stokage.model';
@Component({
    selector: 'app-entradas',
    templateUrl: './entradas.component.html',
    styleUrls: ['./entradas.component.scss']
})
export class EntradasComponent extends BaseView<StockageModel> implements OnInit {

    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showStockMarcaComercial = environment.features.showStockMarcaComercial;
    public showStockTipoDeDato = environment.features.showStockTipoDeDato;
    public showStockProveedor = environment.features.showStockProveedor;
    public showStockEnvase = environment.features.showStockEnvase;
    public showStockReto = environment.features.showStockReto;
    public showStockCifProveedor = environment.features.showStockCifProveedor;
    public showStockUbicacionDestino = environment.features.showStockUbicacionDestino;

    public getRequest: RequestHandler<StockageModel[]> = this.stockApi.entradas.GET;
    public deleteRequest: RequestHandler<StockageModel> = this.stockApi.entradas.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public filter: Filtering<Record<string,string>> = new Filtering<Record<string,string>>();

    public formFieldsEntrada: ItemInterface<object>[] = [
        {
            field: 'finca_nombre',
            label: this.fincaName,
            name: this.fincaName, 
            inputType: {type: InputType.TEXT}, 
            visible: !environment.features.showTareasMto, 
        },
        {
            field: 'producto_nombre', 
            label: this.appName === 'vginer' ? 'Fertilizante' : 'Producto/Marca comercial',
            name: this.appName === 'vginer' ? 'Fertilizante' : 'Producto/Marca comercial',
            inputType: {type: InputType.TEXT}, 
            visible: !this.showStockTipoDeDato
        },
        {
            field: 'producto_nombre', 
            label: 'Producto o semilla',
            name: 'Producto o semilla',
            inputType: {type: InputType.TEXT}, 
            visible: this.showStockTipoDeDato 
        },
        {
            field: 'marca_comercial', 
            label: 'Marca comercial',
            name: 'Marca comercial',
            inputType: {type: InputType.TEXT}, 
            visible: this.showStockMarcaComercial && this.appName !== 'bravosol'
        },
        {
            field: 'fecha', 
            label: 'Fecha',
            name: 'Fecha',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha_injerto', 
            label: 'Fecha injerto', 
            name: 'Fecha injerto', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'viverosblanco'
        },
        {
            field: 'tipo', 
            label: 'Tipo', 
            name: 'Tipo', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'tipo_dato', 
            label: 'Tipo de producto', 
            name: 'Tipo de producto', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showStockTipoDeDato
        },
        {
            field: 'proveedor',
            label: 'Proveedor', 
            name: 'Proveedor', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showStockProveedor
        },
        { 
            field: 'cif_proveedor',
            label: 'NIF/CIF', 
            name: 'NIF/CIF', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showStockCifProveedor
        },
        {
            field: 'albaran', 
            label: 'Albarán/Factura', 
            name: 'Albarán/Factura', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'ubicacion',
            label: 'Ubicación', 
            name: 'Ubicación', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'covidai' || this.appName === 'bravosol'
        },
        { 
            field: 'ubicacion_destino',
            label: 'Ubicación de destino', 
            name: 'Ubicación de destino', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'covidai' || this.appName === 'bravosol' || this.appName === 'viverosblanco' 
        },
        {
            field: 'unidades', 
            label: 'Unidades',
            name: 'Unidades',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'envase', 
            label: 'Envase', 
            name: 'Envase', 
            inputType: {type: InputType.TEXT},
            visible: this.showStockEnvase
        },
        {
            field: 'lote', 
            label: 'Lote', 
            name: 'Lote', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'lote1_conselleria', 
            label: 'Lote conselleria', 
            name: 'Lote conselleria', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'covidai' 
        },
        {
            field: 'lote_conselleria', 
            label: 'Lote conselleria', 
            name: 'Lote conselleria', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'bravosol' 
        },
        {
            field: 'articulo', 
            label: 'Artículo', 
            name: 'Artículo', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'bravosol'
        },
        {
            field: 'observaciones', 
            label: 'Observaciones', 
            name: 'Observaciones', 
            inputType: {type: InputType.TEXT}
        }
    ];

    public formFieldsSemillas: ItemInterface<object>[] = [
        {
            field: this.appName === 'pyf' ? 'nombre_variedad' : 'id_variedad',
            label: 'Variedad',
            name: 'Variedad',
            visible: this.appName !== 'viverosblanco' && this.appName !== 'covidai',
            inputType: {type: InputType.TEXT},
        },
        {
            field: this.appName === 'covidai' ? 'variedad' : 'ids_productos',
            label: 'Variedad',
            name: 'Variedad',
            visible: this.appName === 'viverosblanco' || this.appName === 'covidai', 
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'tipo_semilla',
            label: 'Categoría',
            name: 'Categoría',
            inputType: {type: InputType.TEXT},
        },
    ];

    public formFieldsUnidadesMedida: ItemInterface<object>[] = [
        {
            field: 'unidades_medida',
            label: 'Unidades de medida',
            name: 'Unidades de medida',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'pyf' ? false : true 
        },
    ];

    public cols: ColInterface[] = [
        {field: 'finca_nombre', header: this.fincaName, visible: this.appName !== 'viverosblanco' && this.appName !== 'covidai'},
        {field: 'proveedor', header: 'Proveedor', visible: this.appName === 'covidai'},
        {field: 'cliente', header: 'Cliente', visible: this.appName === 'covidai'},
        {field: 'ubicacion', header: 'Ubicación', width: '75px', visible: this.appName === 'viverosblanco'},
        {field: 'ubicacion_destino', header: 'Ubicación destino', width: '75px', visible: this.appName === 'viverosblanco'},
        {field: 'parcela', header: 'Ubicación', width: '75px', visible:  this.appName === 'covidai' },
        {field: 'parcela_destino', header: 'Ubicación destino', width: '75px', visible:  this.appName === 'covidai'},
        {field: 'producto_nombre', header: 'Producto', visible: !this.showStockTipoDeDato},
        {
            field: 'producto_nombre', 
            header: this.appName === 'viverosblanco' ? 'Producto' : 'Producto o semilla', 
            visible: this.showStockTipoDeDato
        },
        {field: 'marca_comercial', header: 'Marca\xa0comercial', visible: this.showStockMarcaComercial && this.appName !== 'bravosol'},
        {field: 'fecha', header: 'Fecha', width: '90px'},
        {field: 'fecha_injerto', header: 'Fecha\xa0injerto', width: '90px', visible: this.appName === 'viverosblanco' },
        {field: 'albaran', header: 'Albarán/Factura'},
        {field: 'tipo', header: 'Tipo', width: '90px'},
        {field: 'unidades', header: 'Unidades', footer: ''},
        {
            field: 'tipo_dato', 
            header: 'Tipo de producto', 
            visible: this.showStockTipoDeDato && this.appName !== 'viverosblanco' && this.appName !== 'covidai'
        },
        {field: 'variedad', header: 'Variedad', width: '220px', visible: this.appName === 'viverosblanco' || this.appName === 'covidai'},
    ];
    public meses: {label: string; value: string | null}[] = [
        {label: 'Seleccione mes', value: null},
        {label: 'Enero', value: 'Enero'},
        {label: 'Febrero', value: 'Febrero'},
        {label: 'Marzo', value: 'Marzo'},
        {label: 'Abril', value: 'Abril'},
        {label: 'Mayo', value: 'Mayo'},
        {label: 'Junio', value: 'Junioo'},
        {label: 'Julio', value: 'Julio'},
        {label: 'Agosto', value: 'Agosto'},
        {label: 'Septiembre', value: 'Septiembre'},
        {label: 'Octubre', value: 'Octubre'},
        {label: 'Noviembre', value: 'Noviembre'},
        {label: 'Diciembre', value: 'Diciembre'}
    ];
    public years: {label: string; value: string | null}[] = [
        {label: 'Seleccione año', value: null},
        {label: '2020', value: '2020'},
        {label: '2021', value: '2021'},
        {label: '2022', value: '2022'},
    ];

    public mes = '';
    public year = '';
    public displayReto = false;

    constructor(public stockApi: StockApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(dashboardEvents, stockApi.entradas.GET, stockApi.entradas.DELETE);
    }

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem('stock-entradas');
        this.getRequest.response(() => {
            this.updateFooter(this.filter, this.cols);
        });
        setTimeout(() => {
            this.updateFooter(this.filter, this.cols);
        }, 1000);
    }

    public override show(modal: string, data: StockageModel) {
        super.show(modal, data);

        this.model.tipo = ['E', 'Entrada'].includes(this.model.tipo ?? '')
            ? 'Entrada'
            : ['S', 'Salida'].includes(this.model.tipo ?? '')
                ? 'Salida'
                : ['P', 'Producción'].includes(this.model.tipo ?? '')
                    ? 'Producción'
                    : 'Desconocido';
    }

    public add() {
        this.router.navigate(['dashboard', 'stock-crear-entrada']);
    }

    public edit(data: StockageModel) {
        this.router.navigate(['dashboard', 'stock-editar-entrada', data.id]);
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (col.field === 'unidades' &&  (+value) < 0  ) {
            return {
                textAlign: 'center',
                color: 'red'
            };
        } 
        else if (col.field === 'unidades') {
            return {
                textAlign: 'center'
            };
        }
        return {};
    }

    public globalVisualTransform(value: string , col: ColInterface) {
        if (col.field === 'unidades') {
            return Number(value) >= 0 ? Utils.decimalFormat(value, 2) : '-' + Utils.decimalFormat(Math.abs(+value), 2) ;
        }
        if (col.field === 'tipo') {
            switch (value) {
            case 'E':           return 'Entrada';
            case 'Entrada':     return 'Entrada';
            case 'S':           return 'Salida';
            case 'Salida':      return 'Salida';
            case 'P':           return 'Producción';
            case 'Producción':  return 'Producción';
            default:            return 'Desconocido';
            }
        }
        return value;
    }

    exportRetoClick() {
        this.displayReto = true;
    }

    public duplicate(data: StockageModel) {
    
        this.router.navigate(['dashboard', 'stock-duplicar-entrada', data.id]);
    }

    public async downloadCsv() {

        const arrayFormFields = [
            this.formFieldsEntrada,
            this.formFieldsSemillas,
            this.formFieldsUnidadesMedida
        ];
  
    
        const csvContent = createCsv(arrayFormFields, [this.filter.value], 'unidades_medida' );

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Stock.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }// ();
}
