import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { CrmModel } from 'src/app/models/crm/crm.model';

@Injectable({
    providedIn: 'root'
})
export class CRMApiService {

    public CRM = {
        GET: new RequestHandler<CrmModel[]>(),
        POST: new RequestHandler<CrmModel[]>(),
        PUT: new RequestHandler<CrmModel[]>(),
        DELETE: new RequestHandler<CrmModel>()
    };


    constructor(
        private syncService: SyncService<CrmModel[]>,
        private syncServiceDelete: SyncService<CrmModel>,
        private api: ApiRequestService<CrmModel[]>,
        private apiDelete: ApiRequestService<CrmModel>
    ) {

        this.defineCrm();

    }

    private defineCrm() {
        this.CRM.GET.fetch(HttpRequestType.GET, 'crm/get');
        this.CRM.POST.fetch(HttpRequestType.POST, 'crm/post');
        this.CRM.PUT.fetch(HttpRequestType.PUT, 'crm/put');
        this.CRM.DELETE.fetch(HttpRequestType.DELETE, 'generic/crm');

        this.api.registerRequest(this.CRM.GET);
        this.api.registerRequest(this.CRM.POST);
        this.api.registerRequest(this.CRM.PUT);
        this.apiDelete.registerRequest(this.CRM.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.CRM.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.CRM.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.CRM.PUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.CRM.DELETE));
    }
}
