import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FincasService {

    constructor( private http: HttpClient ) {
        
    }

    verificaGML(id: string){
        const url = environment.serverUrl;        
        return this.http.get<{success: string; mensaje: string}>(url + '/ws/gml/verificaGML.php?id_finca=' + id);
    }

    
    generaGML(id: string ) {

        const url = environment.serverUrl;
        window.open(url + '/ws/gml/genParcela.php?id_finca=' + id );
    
    }

}