<br>

<div id="html2canvasDiv" class="w-100 mb-5 p-2 bg-white" style="overflow: auto;">
    <div class="mb-4 d-flex flex-wrap justify-content-between">
        <!-- Parte de arriba -->
        <div>
            <table class="tabla-abonado-sup">
                <tr>
                    <td class="ta-g" style="width: 160px">
                        CLIENTE
                        <i *ngIf="hasSelectedClient"
                            class="fa fa-question text-info"
                            style="cursor: help;"
                            data-toggle="tooltip"
                            title='Ya tiene un cliente seleccionado. Para cambiar de cliente debe salir del Plan de Abonado y seleccionar la opción "TODOS LOS CLIENTES" en el desplegable de Clientes'>
                        </i>
                    </td>
                    <td>
                        <div style="height: 21px; width: 160px;">
                            <div *ngIf="hasSelectedClient">
                                {{client?.nombre}}
                            </div>
                            <div *ngIf="!hasSelectedClient" style="z-index: 3; position: relative; top: -19px;">
                                <app-form-builder
                                    [model]="model"
                                    [distributionColumns]="1"
                                    [fields]="formFieldsCliente"
                                    (formChanges)="formChanges($event)">
                                </app-form-builder>
                            </div>
                        </div>
                    </td>
                    <td rowspan="2" colspan="2" class="ta-no-b" data-html2canvas-ignore="true">
                        <div class="d-flex justify-content-center">
                            <button class="mx-1 btn btn-primary"
                                style="font-size: .7rem; padding: .25rem .6rem;"
                                (click)="guardarAbonado()"
                                [disabled]="isSaving || hasSaved || isPrinting || !model.id_cliente || !model.id_finca">
                                {{isSaving ? 'Guardando...' : hasSaved ? '¡Guardado!' : 'Guardar'}}
                                <i class="fa fa-save"></i>
                            </button>
                            <button class="mx-1 btn btn-primary"
                                style="font-size: .7rem; padding: .25rem .6rem;"
                                (click)="imprimirPlan()"
                                [disabled]="isSaving || isPrinting || !model.id_cliente || !model.id_finca">
                                Imprimir
                                <i class="fa fa-print"></i>
                            </button>
                            <button class="mx-1 btn btn-primary"
                                style="font-size: .7rem; padding: .25rem .6rem;"
                                (click)="show('duplicar')"
                                [disabled]="isSaving || isPrinting || !model.id_cliente || !model.id_finca">
                                Duplicar
                                <i class="fa fa-copy"></i>
                            </button>
                            <button class="mx-1 btn"
                                style="font-size: .7rem; padding: .25rem .6rem;"
                                (click)="enviarCorreo()"
                                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isPrinting || 
                                    !(fincas.selected && clientes.selected)"
                                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">
            
                            <ng-container *ngIf="!tareasApi?.enviarRecetaCaptura?.isLoading && !tareasApi?.enviarRecetaCaptura?.hasError"
                                tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                                <i class="fa fa-envelope"></i>
                                <span *ngIf="isPrinting">
                                    Generando pdf...
                                </span>
                                <span *ngIf="!isPrinting && (fincas?.selected?.email || clientes?.selected?.email)">
                                    Enviar a {{fincas.selected?.email ? fincas.selected?.email : clientes.selected?.email}}
                                </span>
                                <span *ngIf="!isPrinting && !(fincas?.selected?.email || clientes?.selected?.email)">
                                    Enviar email
                                </span>
                            </ng-container>
                            <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.isLoading && !tareasApi?.enviarRecetaCaptura?.hasError">
                                <i class="fa fa-envelope"></i>
                                Enviando...
                            </ng-container>
                            <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                                <i class="fa fa-exclamation-triangle"></i>
                                {{tareasApi.enviarRecetaCaptura.error}}
                            </ng-container>
                        </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="ta-g">FINCA</td>
                    <td>
                        <div style="height: 21px;">
                            <div style="z-index: 1; position: relative; top: -19px;">
                                <app-form-builder
                                    [model]="model"
                                    [distributionColumns]="1"
                                    [fields]="formFieldsFinca"
                                    (formChanges)="formChanges($event)">
                                </app-form-builder>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="ta-g">
                        Nº ÁRBOLES
                        <i class="fa fa-question text-info"
                            style="cursor: help;"
                            data-toggle="tooltip"
                            title="Obtenido de Parcelas"></i>
                    </td>
                    <td class="ta-t-c">
                        {{model.plantas}}
                    </td>
                    <td class="ta-g" style="width: 160px;">
                        EDAD
                    </td>
                    <td class="ta-t-c" style="width: 100px; border-top: none;">
                        <input *ngIf="!isPrinting"
                            type="text"
                            style="width: 100%;"
                            [(ngModel)]="model.edad" />
                        <span *ngIf="isPrinting">
                            {{model.edad}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="ta-g">
                        CAUDAL DEL GOTERO
                        <i class="fa fa-question text-info"
                            style="cursor: help;"
                            data-toggle="tooltip"
                            title="Obtenido de Sectores"></i>
                    </td>
                    <td class="ta-t-c">
                        {{model.caudal_gotero}}
                    </td>
                    <td class="ta-g">
                        Nº GOTEROS/ÁRBOL
                        <i class="fa fa-question text-info"
                            style="cursor: help;"
                            data-toggle="tooltip"
                            title="Obtenido de Sectores"></i>
                    </td>
                    <td class="ta-t-c">
                        {{model.goteros}}
                    </td>
                </tr>
                <tr>
                    <td class="ta-g" style="width: 160px">TÉCNICO</td>
                    <td>
                        {{user.nombre}}
                    </td>
                    <td class="ta-g" style="width: 160px">ROPO</td>
                    <td>
                        {{user.ropo}}
                    </td>
                </tr>
            </table>
            <div class="mb-2 d-flex justify-content-between align-items-baseline">
                <div class="mx-1" style="flex: 5;">
                    <label class="mb-1" for="observaciones">OBSERVACIONES:</label>
                    <br>
                    <textarea *ngIf="!isPrinting" name="observaciones" id="observaciones" cols="55" rows="10" style="font-size: 8pt;" [(ngModel)]="model.observaciones"></textarea>
                    <div *ngIf="isPrinting" style="width: 300px;">
                        {{model.observaciones}}
                    </div>
                </div>
                <div class="mx-1" style="flex: 3;" data-html2canvas-ignore="true">
                    <app-form-builder
                        [model]="modelMes"
                        [distributionColumns]="1"
                        [fields]="formFieldsMes"
                        (formChanges)="formChanges($event)">
                    </app-form-builder>
                </div>
                <div class="mx-1" style="flex: 1;" data-html2canvas-ignore="true">
                    <app-form-builder
                        [model]="model"
                        [distributionColumns]="1"
                        [fields]="formFieldsAnyo"
                        (formChanges)="formChanges($event)">
                    </app-form-builder>
                </div>
            </div>
        </div>

        <!-- Logo -->
        <div class="m-4">
            <img [src]="logoUrl" alt="Savia Tropical" style="max-width: 150px;" />
        </div>
    </div>

    <!-- Abonado aguacates -->
    <table class="tabla-abonado">
        <tr>
            <td class="ta-no-b">
                <div class="d-flex flex-column justify-content-center align-items-center">

                </div>
            </td>
            <td class="ta-no-b"
                style="
                padding: 1rem;
                font-weight: bold;
                font-size: 16pt;
                text-align: center;"
                colspan="41">
                ABONADO {{fincas.selected?.nombre}} {{model.anyo}}
            </td>
        </tr>
        <tr>
            <td class="ta-no-b"></td>
            <ng-container *ngFor="let mes of meses">
                <td *ngIf="!mesesOcultos.includes(+(mes[1] || 0))" class="ta-g ta-t-c" colspan="4">
                    {{mes[0]}}
                </td>
            </ng-container>
            <td class="ta-g ta-t-c" style="width: 70px;">TOTAL (kg/l)</td>
        </tr>
        <tr>
            <td class="ta-y ta-t-c">ABONO</td>
            <ng-container *ngFor="let sh of semanasHead; index as i">
                <td *ngIf="!semanasOcultas?.includes(i + 1)" class="ta-g pr-1 text-right">{{sh}}</td>
            </ng-container>
            <td class="ta-g"></td>
        </tr>

        <tr *ngFor="let producto of productosTablaFiltered">
            <td [ngClass]="producto?.subtipo === '1' ? 'ta-gr' :
                        producto?.subtipo === '2' ? 'ta-r' :
                        producto?.subtipo === '3' ? 'ta-b' : ''">
                    {{producto?.nombre}} 
                    <i *ngIf="producto?.unidades">
                        ({{producto?.unidades}})
                    </i>
            </td>
            <ng-container *ngFor="let s of semanas; index as i">
                <td *ngIf="!semanasOcultas?.includes(i + 1)" class="ta-t-r">
                    <div *ngIf="abonadosTable[+(producto?.id || 0)] && abonadosTable[+(producto?.id || 0)]![s]">
                        {{
                            abonadosTable[+(producto?.id || 0)] && model.plantas
                                ? decimalFormat(+((replaceComma(abonadosTable[+(producto?.id || 0)]![s]?.toString() ?? '')) * (+(model.plantas || 0)) / 1000).toFixed(2))
                                : ''
                        }}
                    </div>
                </td>
            </ng-container>

            <td class="ta-t-c">
                <span *ngIf="totalKg[+(producto?.id || 0)]">
                    {{
                        decimalFormat(((totalKg[+(producto?.id || 0)] || 0)  * (model.plantas || 0) / 1000), 2)
                    }}
                </span>
            </td>
        </tr>
    </table>


    <!-- Consumos planta / año -->
    <table class="tabla-abonado" data-html2canvas-ignore="true">
        <tr>
            <td class="ta-no-b"></td>
            <td class="ta-no-b"
                style="
                padding: 1rem;
                font-weight: bold;
                font-size: 14pt;
                text-align: center;"
                colspan="41">
                Consumos planta / año
            </td>
        </tr>
        <tr>
            <td class="ta-no-b"></td>
            <ng-container *ngFor="let mes of meses">
                <td *ngIf="!mesesOcultos.includes(+(mes[1] || 0))" class="ta-g ta-t-c" colspan="4">
                    {{mes[0]}}
                </td>
            </ng-container>
            <td class="ta-g ta-t-c" style="width: 70px;">TOTAL (gr)</td>
        </tr>
        <tr>
            <td class="ta-y ta-t-c">ABONO</td>
            <ng-container *ngFor="let sh of semanasHead; index as i">
                <td *ngIf="!semanasOcultas?.includes(i + 1)" class="ta-g pr-1 text-right">{{sh}}</td>
            </ng-container>
            <td class="ta-g"></td>
        </tr>

        <tr *ngFor="let producto of productosTablaFiltered || []">
            <td class="ta-y">{{producto?.nombre}}</td>
            <ng-container *ngFor="let s of semanas; index as i">
                <td *ngIf="!semanasOcultas?.includes(i + 1)" class="ta-t-r">
                    <div *ngIf="abonadosTable[+(producto?.id || 0)]">
                        <input 
                            type="text"
                            maxlength="8"
                            lang="es-ES"
                            (blur)="checkNumberFormat(abonadosTable[+(producto?.id || 0)]![s]?.toString() ?? '')"
                            style="width: 100%; border: none; outline: none; text-align: right; font-size: .55rem;"
                            (input)="giveValue()"
                            [(ngModel)]="abonadosTable[+(producto?.id || 0)]![s]"
                            [disabled]="!model.id_finca" />
                    </div>
                </td>
            </ng-container>
            <td class="ta-t-c">
                <span *ngIf="totalKg[+(producto?.id || 0)]">
                    {{decimalFormat(totalKg[+(producto?.id || 0)], 2)}}
                </span>
            </td>
        </tr>
    </table>
</div>

<p-dialog
    *ngIf="canShow['duplicar']"
    header="Duplicar plan de abonado"
    [(visible)]="canShow['duplicar']"
    (onShow)="scrollToTop()"
>
    <div style="max-width: 40vw; width: 100%;">
        <div>
            <p>Seleccione el cliente, finca y año al que desea copiar los datos del plan de abonado.</p>
            <p *ngIf="hasSelectedClient" class="small">
                Cliente seleccionado: <b>{{client?.nombre}}</b>
                <i *ngIf="hasSelectedClient"
                    class="fa fa-question text-info"
                    style="cursor: help;"
                    data-toggle="tooltip"
                    title='Ya tiene un cliente seleccionado. Para cambiar de cliente debe salir del Plan de Abonado y seleccionar la opción "TODOS LOS CLIENTES" en el desplegable de Clientes'>
                </i>
            </p>
            <app-form-builder
                [model]="modelDup"
                [distributionColumns]="1"
                [fields]="formFieldsDuplicar">
            </app-form-builder>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="canShow['duplicar'] = false" [disabled]="isDuplicating">
                Cerrar
            </button>
            <button class="btn btn-primary mx-2" (click)="duplicarPlan()" [disabled]="isDuplicating">
                Duplicar
            </button>
        </div>
    </div>
    <br>

</p-dialog>

<a href="#" id="downloadReceta" style="visibility: hidden; position: fixed;"></a>
