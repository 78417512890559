
import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { InformeInterface, InformeREGEPA, InformeROPO } from 'src/app/view/main/4_trabajadores/consultas/informe.interface';


@Injectable({
    providedIn: 'root'
})
export class RopoApiService {

    public ropo = {
        GET: new RequestHandler(),
    };    

    constructor(private syncService: SyncService<unknown>,
        private api: ApiRequestService<unknown>,
        private http: HttpClient
    ) {

        this.defineRopo();
    }



    getDatosDni(dni: string): Observable<InformeInterface[]> {

        const url = 'https://locatec.es/proyectos/fitogest/ws/index.php?p1=ropo&p2=getDni&dni=';
        return this.http.get<InformeInterface[]>(url + dni).pipe(map((resp: InformeInterface[]) => resp)
        );
    }  
    
    getROPO(dni: string): Observable<{ropo: InformeROPO}>{
      
        const url = 'https://locatec.es/proyectos/fitogest/ws/index.php?p1=ropo&p2=getRopo&dni=';
        return this.http.get<{ropo: InformeROPO}>(url + dni).pipe(map((resp: {ropo: InformeROPO}) => resp)
        );
    };   

    getREGEPA(dni: string): Observable<{regepa: InformeREGEPA}>{
      
        const url = 'https://locatec.es/proyectos/fitogest/ws/index.php?p1=ropo&p2=getRegepa&dni=';
        return this.http.get<{regepa: InformeREGEPA}>(url + dni).pipe(map((resp: {regepa: InformeREGEPA}) => resp)
        );
    };   
    
    imprimirConsulta(dni: string): Observable<{csv: string[]}> {
        return this.http.get<{csv: string[]}>
        ('https://locatec.es/proyectos/fitogest/ws/index.php?p1=ropo&p2=genListaDniCsvDown&dni=' + dni);
    }    

    private defineRopo() {
        this.ropo.GET.fetch(HttpRequestType.GET, 'ropo/getDni');

    
        this.api.registerRequest(this.ropo.GET);

    
        this.syncService.register(this.syncService.baseSyncPolicy(this.ropo.GET));

    }
}
