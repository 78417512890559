import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import type { UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowProductosSimpleGuardService {

    constructor(public router: Router) {}

    canActivate(): boolean | UrlTree{

        if (!environment.features.showProductosSimple) {
            this.router.parseUrl('/login');
            return false;
        }

        return true;
    }
}
