
<p-panel>
    <div class="d-flex justify-content-left align-content-center">
        <div>
            <h4 *ngIf="flag === 'edit'">Actualizar {{clienteName}}</h4>
            <h4 *ngIf="flag === 'new'">Crear {{clienteName}}</h4>
        </div>
    </div>

    <div *ngIf="flag === 'edit' && appName === 'ebro'" class="alert alert-info p-2 small">
        <b><i class="fa fa-exclamation-circle"></i> Atención:</b>
        La sincronización con el sistema Sage sobreescribirá toda la
        información editada aquí, excepto el campo <code>observaciones</code>.
    </div>

    <app-form-builder
            [model]="model"
            [undoEnabled]="false"
            [distributionColumns]="3"
            [fields]="formFields"
            (formChanges)="formChanges($event)">
    </app-form-builder>
    <br>

    <div *ngIf="showFieldsClientesEstadoReglas">
        <app-form-builder
                [model]="model"
                [undoEnabled]="false"
                [distributionColumns]="3"
                [fields]="formFieldsEstadoReglas"
                (formChanges)="formChanges($event)">
        </app-form-builder>
        <br>
    </div>
    <div *ngIf="appName === 'previlsa'" class="d-flex justify-content-between">
        <div style="flex: 1; margin-right: 50px;">
            <app-form-builder
                    [model]="model"
                    [undoEnabled]="false"
                    [distributionColumns]="2"
                    [fields]="formFieldsPrevilsa1"
                    (formChanges)="formChanges($event)">
            </app-form-builder>
        </div>
        <div style="flex: 1; margin-left: 50px;">
            <app-form-builder
                    [model]="model"
                    [undoEnabled]="false"
                    [distributionColumns]="2"
                    [fields]="formFieldsPrevilsa2"
                    (formChanges)="formChanges($event)">
            </app-form-builder>
        </div>
    </div>
    <br>
    <div *ngIf="showClientesFirma" class="w-100 mb-4 d-flex justify-content-center">
        <div>
            <h6>Firma</h6>
            <div class="canvas-wrap">
                <button data-html2canvas-ignore="true" class="p-0 m-0 btn btn-link" (click)="clearSigPad(signaturePadElement)">Borrar</button>
                <app-signature #sp (onEndEvent)="drawComplete(signaturePadElement)"></app-signature>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                [lockOnSuccess]="true"
                [error]="hasClienteError"
                (click)="submit()"
                [mode]="requestButtonType"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>
