<div *ngIf="mode === REQUEST_BUTTON_TYPES.NEW">

    <button class="btn btn-danger mx-2"
            *ngIf="error"
            (click)="requestClick()">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span> {{error}}</span>
    </button>

    <button class="btn btn-warning mx-2"
            *ngIf="warning"
            (click)="requestClick()">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span> {{warning}}</span>
    </button>

    <button class="btn btn-primary mx-2"
            *ngIf="!request.isLoading
                 && request.hasError === undefined  && !error && !warning"
            (click)="requestClick()">
        <i class="fa fa-save" aria-hidden="true"></i>
        <span> Guardar</span>
    </button>
    <button class="btn btn-secondary mx-2"
            disabled
            *ngIf="request.isLoading">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        Guardando...
    </button>
    <div *ngIf="!request.isLoading
          && request.hasError">
        <button class="btn btn-warning mx-2" (click)="requestClick()">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{failMessage[0]}}
            <span style="font-size: 1em; font-weight: bold">{{failMessage[1]}}</span>
        </button>
    </div>
    <div *ngIf="!request.isLoading
          && request.hasErrorCliExists">
        <button class="btn btn-warning mx-2" (click)="requestClick()">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            Cliente ya existe...
            <span style="font-size: 1em; font-weight: bold">{{failMessage[1]}}</span>
        </button>
    </div>
    <div *ngIf="!request.isLoading
          && request.hasError === false && !error && !warning">
        <button class="btn btn-success mx-2"
                (click)="requestClick()"
                [disabled]="lockOnSuccess">
            <i class="fa fa-check" aria-hidden="true"></i>
            Guardado
            <span *ngIf="!lockOnSuccess" style="font-size: 1em; font-weight: bold">Enviar otro</span>
        </button>
    </div>
</div>

<div *ngIf="mode === REQUEST_BUTTON_TYPES.EDIT">

    <button class="btn btn-danger mx-2"
            *ngIf="error"
            (click)="requestClick()">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span> {{error}}</span>
    </button>

    <button class="btn btn-warning mx-2"
            *ngIf="warning"
            (click)="requestClick()">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span> {{warning}}</span>
    </button>

    <button class="btn btn-primary mx-2"
            *ngIf="!request.isLoading
                 && request.hasError === undefined && !error && !warning"
            (click)="requestClick()">
        <i class="fa fa-pencil" aria-hidden="true"></i>
        <span> Actualizar</span>
    </button>
    <button class="btn btn-secondary mx-2"
            disabled
            *ngIf="request.isLoading">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        Actualizando...
    </button>
    <div *ngIf="!request.isLoading
          && request.hasError">
        <button class="btn btn-warning mx-2" (click)="requestClick()">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{failMessage[0]}}
            <span style="font-size: 1em; font-weight: bold">{{failMessage[1]}}</span>
        </button>
    </div>
    <div *ngIf="!request.isLoading
          && request.hasError === false && !error && !warning">
        <button class="btn btn-success mx-2"
                (click)="requestClick()"
                [disabled]="lockOnSuccess">
            <i class="fa fa-check" aria-hidden="true"></i>
            Actualizado
            <span *ngIf="!lockOnSuccess" style="font-size: 1em; font-weight: bold">Enviar otro</span>
        </button>
    </div>
    <!-- <div *ngIf="!request.isLoading
          && request.hasError === false && !error && !warning">
           error: {{error | json}} , warning: {{warning | json}}
        <button class="btn btn-warning mx-2"
                (click)="requestClick()"
                [disabled]="lockOnSuccess">
            <i class="fa fa-cog fa-spin fa-fw" aria-hidden="true"></i>
            Procesando
            <span *ngIf="!lockOnSuccess" style="font-size: 1em; font-weight: bold">Enviar otro</span>
        </button>
    </div>-->
</div>

<div *ngIf="mode === REQUEST_BUTTON_TYPES.DELETE">

    <button class="btn btn-danger mx-2"
            *ngIf="error"
            (click)="requestClick()">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span> {{error}}</span>
    </button>

    <button class="btn btn-warning mx-2"
            *ngIf="warning"
            (click)="requestClick()">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span> {{warning}}</span>
    </button>

    <button class="btn btn-danger mx-2"
            *ngIf="!request.isLoading
             && request.hasError === undefined && !error && !warning"
            (click)="requestClick()">
        <i class="fa fa-trash" aria-hidden="true"></i>
        <span> Eliminar</span>
    </button>
    <button class="btn btn-secondary mx-2"
            disabled
            *ngIf="request.isLoading">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        Eliminando...
    </button>
    <div *ngIf="!request.isLoading
      && request.hasError">
        <button class="btn btn-warning mx-2" (click)="requestClick()">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{failMessage[0]}}
            <span style="font-size: 1em; font-weight: bold">{{failMessage[1]}}</span>
        </button>
    </div>
    <div *ngIf="!request.isLoading
      && request.hasError === false && !error && !warning">
        <button
                disabled
                class="btn btn-success mx-2">
            <i class="fa fa-check" aria-hidden="true"></i>
            Eliminado
        </button>
    </div>
</div>
