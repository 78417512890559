<br>

<!-- FORM FIELDS -->
<p-panel>
    <h4>Datos del bio</h4>
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="3" 
            [distributionOrder]="[this.getType() !== 2 ? 3 : 4, 2, 4, 3, 3]"
            [fields]="form.formFields">
        </app-form-builder>
    <br>

    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div *ngIf="showGenerateRecetaBio">
            <button class="btn btn-warning mx-2" (click)="generarReceta()">Generar Receta</button>
        </div>
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>
<p-dialog header="Generar receta" [(visible)]="showRecetaTareaBio">

        <div id="html2canvasDiv" class="registroDiv" >

            <div class="d-flex mb-5" style="font-size: 0.7rem; height: 100px;">
                <div style="flex: 1" class="d-flex flex-column justify-content-between">
                    <span style="font-weight: bold; text-decoration: underline; font-size: 0.85rem;">
                        TAREA BIO
                        <img [src]="logoUrl" style="width: 80px; height: auto; display: block;"
                            alt="" />
                    </span>
                    <span>CENTRO: {{receta.finca_nombre}}</span>
                    <span>SUBPARCELA(S):
                        <span> {{ receta.parcela }}</span>
                    </span>
                    <span>PH Caldo: {{receta.ph_caldo}}  -  PH Agua: {{receta.ph_agua}}  -  PH Final: {{receta.ph_final}}</span>
                    <span>
                        {{receta.fecha_receta}}
                    </span>
                </div>
                               
            </div>

            <div class="ml-2 mb-2" *ngIf="this.appName !== 'hernandorena'">
                Nivel de peligrosidad: <b>{{receta.nivel_peligrosidad}}</b>
            </div>

            <table class="w-100 table-bordered table-hernandorena">
                <thead class="text-center" style="font-size: 0.7rem;">
                    <th class="p-1">MARCA COMERCIAL</th>
                    <th class="p-1">DOSIS (cc o g/100L)</th>
                    <th class="p-1">ORG. COMBATIR</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="p-3">{{ receta.producto }}</td>
                        <td class="p-3">{{ receta.dosis }}</td>
                        <td class="p-3">{{ receta.tipo_plaga }}</td>
                    </tr>
                    <!-- <tr>
                        <td colspan="4" style="height: 100px">
                            <span style="font-size: 0.7rem;
                                  font-weight: bold;
                                  position: relative;
                                  top: calc(-50% + 0.35rem);">
                                Observaciones OPERARIO:
                            </span>
                            {{receta?.observaciones}}
                        </td>
                    </tr> -->
                </tbody>
            </table>

            <div class="d-flex justify-content-between mt-2" style="font-size: 0.7rem;">
                <span style="flex: 3">Ordenante: firma y fecha</span>
                <span style="flex: 2">Realizador: firma y fecha</span>
            </div>


        </div>

        <div class="mt-4 d-flex justify-content-center">
            <button class="btn btn-dark mx-2" [disabled]="isGeneratingCanvas" (click)="showRecetaTareaBio = false">Cerrar</button>
            <button class="btn btn-primary mx-2" [disabled]="isGeneratingCanvas" (click)="imprimirReceta();">
                {{isGeneratingCanvas ? 'Generando PDF...' : 'Imprimir'}}
            </button>
        </div>
    <br>

</p-dialog>