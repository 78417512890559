<br>

<div style="width: auto; overflow: auto">
  <app-common-list-table
    [request]="stockApi.balances"
    [minWidth]="800"
    [minHeight]="400"
    [cols]="cols"
    [actionButtonText]="actionButtonText ?? ''"
    (actionButtonClick)="show('stockage', model)"
    [filtering]="filter"
    (emitSearchText)="updateFooter(filter, cols)"
    [globalConditionalStyle]="globalConditionalStyle"
    [globalVisualTransform]="globalVisualTransform">

    <ng-template #footer let-col>
      <div style="font-size: 0.75em; text-align: left" *ngIf="(['finca_nombre'].includes(col.field))">
        TOTAL:
      </div>
      <div *ngIf="(['unidades_quedan'].includes(col.field))"
        style="font-size: 0.75em; text-align: center; font-weight: bold">
        {{col?.footer}}
      </div>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog 
  *ngIf="canShow['stockage']"
  header="Hoja de Stockage" 
  [(visible)]="canShow['stockage']" 
  (onShow)="scrollToTop()"
>
  <div style="width: 80vw !important; height: 70vh">
    <div class="d-flex justify-content-between align-items-center">
      <div class="mr-1" style="flex: 1;">
        <app-form-builder
          [model]="model"
          [fields]="formFieldsStockage"
          [distributionColumns]="2"
          (formChanges)="formChanges()">
        </app-form-builder>
      </div>
  
      <div class="ml-1 d-flex justify-content-end" style="flex: 1;">
        <button class="btn btn-dark mr-2" style="padding: 5px 15px; font-size: .8em" (click)="hide('stockage')" [disabled]="isPrinting">
          Cerrar
        </button>
        <button class="btn btn-primary mx-2" style="padding: 5px 15px; font-size: .8em" (click)="generarStockage();" [disabled]="isPrinting">
          Generar
        </button>
        <button class="btn btn-primary ml-2" style="padding: 5px 15px; font-size: .8em" (click)="imprimirReceta();" [disabled]="isPrinting || !isShowingStockage">
          <i class="fa fa-print"></i>
          Imprimir
        </button>
      </div>
    </div>

    <br>

    <!-- Hoja de Stockage -->
    <div *ngIf="isShowingStockage" id="html2canvasDiv" class="mb-5 p-5 bg-white small">
      <div>
        <div style="font-weight: bold; text-decoration: underline;" class="text-center">
          HOJA DE STOCKAGE
        </div>
        <p class="mb-1 font-weight-bold">
          Stockage de {{fincas.selected.nombre}}
          a {{fechaStockage['dia'] || '___'}} de {{fechaStockage['mes'] || '____________'}} de {{fechaStockage['anyo'] || '_____'}}
        </p>
      </div>
      <table class="table table-bordered">
          <thead class="text-center">
            <th>FERTILIZANTE</th>
            <th>MARCA COMERCIAL</th>
            <th>STOCK (Kg/Lt)</th>
            <th>STOCK</th>
          </thead>
          <ng-container *ngFor="let producto of (arrayStockageWithMarcas || [])">
            <tr *ngIf="producto?.nombre && ((producto?.stockage_real !== 0) && (producto?.stockage_teorico !== 0))" class="text-center">
              <td>{{producto?.nombre}}</td>
              <td>{{producto?.marca || 'Sin marca'}}</td>
              <td>{{producto?.stockage_teorico}}</td>
              <td>{{producto?.stockage_real}}</td>
            </tr>
          </ng-container>
      </table>
      <div class="mb-4 text-center" style="font-weight: bold; text-decoration: underline;">
        HOJA DE FERTILIZANTES
      </div>
      <table class="table table-bordered">
        <thead class="text-center">
          <th>Día</th>
          <!-- <th>PROVEEDOR</th> -->
          <th>Nº&nbsp;ALBARÁN</th>
          <th>Kgs/Lts</th>
          <th>MARCA COMERCIAL</th>
          <th>FERTILIZANTE</th>
          <!-- <th>Firma Operario</th> -->
        </thead>
        <tr *ngFor="let entrada of (tablaEntradas || [])">
          <td>{{entrada['fecha']}}</td>
          <!-- <td>{{entrada?.proveedor}}</td> -->
          <td>{{entrada['albaran']}}</td>
          <td>{{entrada['unidades']}}</td>
          <td>{{entrada['marca_comercial']}}</td>
          <td>{{entrada['fertilizante']}}</td>
          <!-- <td></td> -->
        </tr>
      </table>
    </div>

  </div>
</p-dialog>
