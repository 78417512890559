import { Injectable } from '@angular/core';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import { ProductosModel } from 'src/app/models/productos/productos.model';

@Injectable({
    providedIn: 'root'
})
export class ExcepcionalesApiService {

    public excepcionalesGET = new RequestHandler<ProductosModel>();

    constructor(private syncService: SyncService<ProductosModel>,
        private api: ApiRequestService<ProductosModel>) {

        this.defineExcepcionalesGET();
    }

    public defineExcepcionalesGET() {
        this.excepcionalesGET
            .fetch(HttpRequestType.GET, 'mg/excepcionales');

        this.api
            .registerRequest(this.excepcionalesGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.excepcionalesGET));
    }
}
