import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

import type { UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {

    constructor(
        public auth: AuthService,
        public router: Router) {}

    canActivate(): boolean| UrlTree {

        if (!this.auth.isAuthenticated()) {
            return this.router.parseUrl('login');
        }

        return true;
    }
}
