import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


declare const webkitSpeechRecognition: any;

@Injectable({
    providedIn: 'root'
})
export class VoiceRecognitionService {

    public recognition = new webkitSpeechRecognition();
    isStoppedSpeechRecog = false;
    public text = '';
    public tempWords = '';
    public auxTemps = new Subject<string>();

    constructor() { 
   
    }

    init() {
        this.recognition.interimResults = true;
        this.recognition.lang = 'es-ES';

        this.recognition.addEventListener('result', (e: { results: Iterable<unknown> | ArrayLike<unknown>; }) => {
            const transcript = Array.from(e.results)
                .map((result: any) => result[0])
                .map((result) => result.transcript)
                .join('');
            this.tempWords = transcript;
            this.auxTemps.next( transcript );
        });
    }

    start() {
        this.isStoppedSpeechRecog = false;
        this.recognition.start();

        try {
            this.recognition.addEventListener('end', () => {
                if (this.isStoppedSpeechRecog) {
                    this.recognition.stop();
                    // console.log('End speech recognition');
                } else {
                    this.wordConcat();
                    this.recognition.start();
                }
            }); 
        } catch (error) {
            // console.log(error)
        }
        // this.recognition.addEventListener('end', (condition) => {
        //   if (this.isStoppedSpeechRecog) {
        //     this.recognition.stop();
        //     this.recognition.removeEventListener('end', () => {});
        //     // console.log('End speech recognition');
        //   } else {
        //     this.wordConcat();
        //     this.recognition.start();
        //   }
        // }); 

    }

    stop() {
        this.isStoppedSpeechRecog = true;
        this.wordConcat();
        this.recognition.stop();
    // console.log('End speech recognition');
    }

    wordConcat() {
        this.text = this.text + ' ' + this.tempWords + '.';
        this.tempWords = '';
    }

    emptyRecognition(){
        this.recognition = null;
    }
}
