import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { environment } from 'src/environments/environment';

import type { ItemInterface, TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ProductosModel } from 'src/app/models/productos/productos.model';

export class ProductosGanaderiaFormDefinition {

    public appName = environment.appName;
    public showPrecio = environment.features.showPrecio;

    public fincas: TypeAheadInterface<FincasModel> = {
        selected: null,
        filtered: [{label: 'Cargando...', value: null}],
        values: []
    };

    public productos: TypeAheadInterface<ProductosModel> = {
        selected: null,
        filtered: [{label: 'Cargando...', value: null}],
        values: []
    };

    public estado: TypeAheadInterface<string> = {
        selected: null,
        filtered: [
            {label: '...', value: null},
            {label: 'Activo', value: 'Activo'},
            {label: 'No Activo', value: 'No Activo'},
        ],
        values: []
    };

    public unidades: TypeAheadInterface<string> = {
        selected: null,
        filtered: [
            {label: '...', value: null},
            {label: 'unidades', value: 'unidades'},
            {label: 'sobres', value: 'sobres'},
            {label: 'sacos', value: 'sacos'},
            {label: 'toneladas', value: 'toneladas'},
            {label: 'gr', value: 'gr'},
            {label: 'kilos', value: 'kilos'},
            {label: 'litros', value: 'litros'},
            {label: 'c.c.', value: 'c.c.'},
        ],
        values: []
    };


    public topFormFields: ItemInterface<object>[] = [
        {
            label: 'Nombre', 
            field: 'nombre', 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: true
        },
    ];

    public bottomFormFieldsLeft: ItemInterface<object>[] = [
        {
            label: 'Precio', 
            field: 'precio', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.showPrecio
        },
        {
            label: 'Unidades medida', 
            field: 'unidades', 
            inputType: {type: InputType.DROPDOWN}, 
            values: this.unidades
        },
    ];

    public bottomFormFieldsRight: ItemInterface<object>[] = [
    ];

    public baselineFormFields: ItemInterface<object>[] = [
        {
            label: 'Observaciones', 
            field: 'observaciones', 
            inputType: {type: InputType.EDIT_TEXTAREA}
        }
    ];

}