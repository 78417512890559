import {BaseResponseModel} from '../../models/base-response.model';
import {Utils} from '../../common/utils';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ParamsInterface } from '../OffService/params.interface';

export enum HttpRequestType {
    GET, POST, PUT, DELETE
}

export class BaseApi<T> {
    public inject: {
        params: {
            id_cliente?: (endpoint: string, params: ParamsInterface) => string | null,
            id_usuario?: (endpoint: string, params: ParamsInterface) => string | null,
            tipo_usuario?: (endpoint: string, params: ParamsInterface) => string | null
        }
        payload: {
            id_cliente?: (endpoint: string, data: ParamsInterface) => string | null,
            id_usuario?: (endpoint: string, data: ParamsInterface) => string | null,
        },
    } = {
            params: {},
            payload: {}
        };
        
    private BASE_URL: string = environment.serverUrl + 'ws/';
    private BASE_URL_SIEX = 'https://locatec.es/proyectos/SIEX/ws/';
    private BASE_URL_AUX: string = environment.serverUrl + 'ws/';
    private __: HttpClient;

    constructor(http: HttpClient) {
        this.__ = http;
    }


    public proxyCheck: ((type: HttpRequestType, endpoint: string, data: ParamsInterface | undefined, ) => boolean) = () => true;


    
    protected get(endpoint: string, params?: ParamsInterface): Promise<BaseResponseModel<T>> {
        switch (endpoint) {
        case 'cultivo_variedad/get_cultivos':
            this.BASE_URL = this.BASE_URL_SIEX;
            break;
        case 'cultivo_variedad/get_variedades':
            this.BASE_URL = this.BASE_URL_SIEX;
            break;
        case 'generic/vista_cultivo_variedad':
            this.BASE_URL = this.BASE_URL_SIEX;
            break;
        case 'parcelas/get_portainjertos':
            this.BASE_URL = this.BASE_URL_SIEX;
            break;
        case 'parcelas/get_sistemas_riego':
            this.BASE_URL = this.BASE_URL_SIEX;
            break;
        case 'tareas/justificaciones_get':
            this.BASE_URL = this.BASE_URL_SIEX;
            break;
        case 'tareas/actividades_sobre_cubierta_get':
            this.BASE_URL = this.BASE_URL_SIEX;
            break;
        default:
            this.BASE_URL = this.BASE_URL_AUX;
            break;
        }
        return this.proxy(HttpRequestType.GET, endpoint, params, () => this.__
            .get(this.BASE_URL + this.injectParams(this.changeURL(endpoint), params), {
                params: ((p) => {
                    const filteredParams: Record<string,string> = {};

                    for (const i in p) {
                        if (p[i] !== undefined && p[i] !== null) {
                            filteredParams[i] = p[i] ?? '';
                        }
                    }

                    return filteredParams;
                })(params)
            })
            .toPromise()
            .then<BaseResponseModel<T>, never>(
                this.handleResponse,
                this.handleError));
    }

    protected put(endpoint: string, data: ParamsInterface | undefined): Promise<BaseResponseModel<T>> {
        this.BASE_URL = this.BASE_URL_AUX;
        return this.proxy(HttpRequestType.PUT, endpoint, data, () => this.__
            .put(
                this.BASE_URL + this.injectParams(this.changeURL(endpoint), data), JSON.stringify(this.injectPayload(data ?? {}, endpoint))
            )
            .toPromise()
            .then<BaseResponseModel<T>, never>(
                this.handleResponse,
                this.handleError));
    }

    protected delete(endpoint: string, data: ParamsInterface | undefined): Promise<BaseResponseModel<T>> {
        this.BASE_URL = this.BASE_URL_AUX;
        return this.proxy(HttpRequestType.DELETE, endpoint, data, () => this.__
            .delete(this.BASE_URL + this.injectParams(this.changeURL(endpoint), data), data as Record<string,string>)
            .toPromise()
            .then<BaseResponseModel<T>, never>(
                this.handleResponse,
                this.handleError));
    }

    protected post(endpoint: string, data?: ParamsInterface): Promise<BaseResponseModel<T>> {
        this.BASE_URL = this.BASE_URL_AUX;
        return this.proxy(HttpRequestType.POST, endpoint, data, () => this.__
            .post(
                this.BASE_URL + this.injectParams(this.changeURL(endpoint), data), JSON.stringify(this.injectPayload(data ?? {}, endpoint))
            )
            .toPromise()
            .then<BaseResponseModel<T>, never>(
                this.handleResponse,
                this.handleError));
    }

    protected handleResponse(response: object | undefined): BaseResponseModel<T> {
        if (Utils.isSet(response)) {
            return response as BaseResponseModel<T>;
        } else {
            return ({}) as BaseResponseModel<T>;
        }
    }

    protected handleError(error: unknown): PromiseLike<never> {
        throw error;
    }

    protected getEntityData(baseResponse: BaseResponseModel<T>): T {
        return baseResponse ?
            baseResponse.data ?
                baseResponse.data.length > 0 ?
                    baseResponse.data[0] as T
                    : null as T
                : null as T
            : null as T;
    }

    protected getEntityDataList(baseResponse: BaseResponseModel<T>): T[] {
        return baseResponse ?
            baseResponse.data ?
                baseResponse.data as T[]
                : [] as T[]
            : [] as T[];
    }


    private changeURL(endpoint: string): string {
        const route = endpoint.split('/');
        endpoint = 'index.php?';

        for (let i = 0; i < route.length; i++) {
            const element = route[i];

            endpoint += 'p' + (i + 1) + '=' + element;

            if (i < route.length - 1) {
                endpoint += '&';
            }
        }

        return endpoint;
    }

    private injectParams(endpoint: string, data?: ParamsInterface) {
        for (const x in this.inject.params) {
            if (Object.prototype.hasOwnProperty.call(this.inject.params, x)) {
                const value = this.inject.params[x as keyof typeof this.inject.params]?.(endpoint, data ?? {});

                if (value !== undefined && value !== null) {
                    if (['string', 'number', 'boolean'].indexOf(typeof value) !== -1) {
                        endpoint += '&' + x + '=' + value;
                    } else {
                        console.warn('BaseApi @ Inyect Params: UNSUPORTED TYPE > '
                            + value.constructor.name
                            + ' FOR ' + x + ' < supported types: [string | number | boolean]'
                            + ' request: ' + endpoint
                        );
                    }
                } else {
                    console.warn('BaseApi @ Inyect Params: UNDEFINED OR NULL VALUE FOR > '
                        + x + ' < request: '
                        + endpoint
                    );
                }
            }
        }

        return endpoint;
    }

    private proxy(
        type: HttpRequestType, 
        endpoint: string, 
        data: ParamsInterface | undefined, 
        callback: { 
            (): Promise<BaseResponseModel<T>>; 
            (): Promise<BaseResponseModel<T>>; 
            (): Promise<BaseResponseModel<T>>; 
            (): Promise<BaseResponseModel<T>>; 
            (): Promise<BaseResponseModel<T>>; 
        }): Promise<BaseResponseModel<T>> {
        if (this.proxyCheck(type, endpoint, data)) {
            return callback();
        } else {
            return new Promise((_resolve, reject) => reject());
        }
    }

    private injectPayload(data: Record<string,string>, endpoint: string) {
        if (data) {
            for (const x in this.inject.payload) {
                if (Object.prototype.hasOwnProperty.call(this.inject.payload, x)) {
                    const value = this.inject.payload[x as keyof typeof this.inject.payload]?.(endpoint, data);

                    if (value !== undefined && value !== null) {
                        data[x] = value;
                    } else {
                        console.warn('BaseApi @ Inyect Payload: UNDEFINED OR NULL VALUE FOR > '
                            + x + ' < request: '
                            + endpoint
                        );
                    }
                }
            }
        }

        return data;
    }
}

