<p-panel>
    <div class="d-flex justify-content-left align-content-center">
        <div>
            <h4 *ngIf="flag === 'edit'">Actualizar Control de Tratamientos</h4>
            <h4 *ngIf="flag === 'new'">Crear Control de Tratamientos</h4>
        </div>
    </div>

    <div style="width: 100%;" class="mb-3">
        <app-form-builder [model]="model" [undoEnabled]="undoEnabled" [distributionColumns]="3"
            [distributionOrder]="[3,3,3,3]" [fields]="formFields" (formChanges)="formChanges($event)">
        </app-form-builder>
        <div class="d-flex justify-content-between">
            <div style="flex: 1; padding-left: 5px;">
                <app-form-builder [model]="model" [undoEnabled]="undoEnabled" [distributionColumns]="7"
                    [distributionOrder]="[3,2,2,1,3,2,2,1,3,2,2,1]" [fields]="formFieldsDosis" (formChanges)="formChanges($event)">
                </app-form-builder>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center mb-1">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <div>
            <button class="btn btn-warning mx-2" (click)="generarReceta()">Generar Receta</button>
        </div>
        <div>
            <button *ngIf="formType === 1" class="btn btn-danger mx-2" (click)="showEliminarControl = true">
                <i class="fa fa-exclamation-triangle"></i>
                Eliminar
            </button>
        </div>
        <app-request-button (click)="submit()" [mode]="requestButtonType" [lockOnSuccess]="true" [request]="request">
        </app-request-button>
    </div>
</p-panel>

<p-dialog header="Eliminar Control" [(visible)]="showEliminarControl">
    <div class="container" style="max-width: 480px; width: auto; overflow: auto">
        ¿Desea eliminar este control de tratamientos?
    </div>
    <div class="container" style="text-align:center; max-width: 480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showEliminarControl = false;">No</button>
        <button class="btn btn-danger" style="margin: 10px;" (click)="showEliminar();">Sí</button>
    </div>
</p-dialog>



<!-- VERSIÓN "Hernandorena" -->
<p-dialog header="Generar receta" [(visible)]="showReceta">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv" class="registroDiv">

            <div class="d-flex mb-5" style="font-size: 0.7rem; height: 100px;">
                <div style="flex: 1" class="d-flex flex-column justify-content-between">
                    <span style="font-weight: bold; text-decoration: underline; font-size: 0.85rem;">
                        TRATAMIENTO FITOSANITARIO
                        <img src="./assets/logo_hernandorena.png" style="width: 80px; height: auto; display: block;"
                            alt="" />
                    </span>
                    <span>CENTRO: {{receta.finca_nombre}}</span>
                    <span>SUBPARCELA(S):
                        <span *ngFor="let parcela of (parcelas?.selected || []); let i = index">
                            {{parcela?.nombre}}<span *ngIf="i < (parcelas?.selected || []).length - 1">, </span>
                        </span>
                    </span>
                    <span>EPI: {{receta.epi}}</span>
                    <span>PH Caldo: {{receta.ph_caldo}}  -  PH Agua: {{receta.ph_agua}}  -  PH Final: {{receta.ph_final}}</span>
                    <span>
                        {{receta.fecha}}
                    </span>
                </div>
                <div class="d-flex justify-content-around" style="flex: 1">
                    <span style="font-weight: bold; text-decoration: underline; font-size: 0.85rem;">
                        {{(receta.plagas || '').toUpperCase()}}
                    </span>
                    <div class="d-flex" style="height: 2rem;">
                        <input type="text" size="1" maxlength="1" class="text-center mr-1" style="width: 3rem !important;
                              font-size: 1.2rem !important;
                              text-transform: uppercase" />
                        <div style="font-size: 0.7rem; width: 2.5rem;">
                            I, P, A
                        </div>
                    </div>
                </div>
                <div style="flex: 1">
                    <table class="w-100 table-bordered table-hernandorena" style="font-size: 0.7rem;">
                        <thead class="text-center">
                            <th>CENTRO</th>
                            <th>GASTO CALDO (L)</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of [1, 2, 3, 4, 5]">
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td colspan="2" style="border: 1px solid transparent !important;">
                                    <i>* Tachar en centro que no se haga</i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ml-2 mb-2" *ngIf="this.appName !== 'hernandorena'">
                Nivel de peligrosidad: <b>{{receta.nivel_peligrosidad}}</b>
            </div>

            <table class="w-100 table-bordered table-hernandorena">
                <thead class="text-center" style="font-size: 0.7rem;">
                    <th class="p-1">MARCA COMERCIAL</th>
                    <th class="p-1">MATERIA ACTIVA</th>
                    <th class="p-1">DOSIS (cc o g/100L)</th>
                    <th class="p-1">ORG. COMBATIR</th>
                </thead>
                <tbody>
                    <tr *ngFor="let i of [1, 2, 3]">
                        <td class="p-3">{{receta['producto' + i]}}</td>
                        <td class="p-3">{{receta['materia_activa' + i]}}</td>
                        <td class="p-3">{{receta['dosis' + i]}}</td>
                        <td class="p-3">{{receta['plaga' + i]}}</td>
                    </tr>
                    <tr>
                        <td colspan="4" style="height: 100px">
                            <span style="font-size: 0.7rem;
                                  font-weight: bold;
                                  position: relative;
                                  top: calc(-50% + 0.35rem);">
                                Observaciones OPERARIO:
                            </span>
                            {{receta.observaciones}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-between mt-2" style="font-size: 0.7rem;">
                <span style="flex: 3">Ordenante: firma y fecha</span>
                <span style="flex: 2">Realizador: firma y fecha</span>
            </div>

        </div>

        <div class="mt-4 d-flex justify-content-center">
            <button class="btn btn-dark mx-2" [disabled]="isGeneratingCanvas" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" [disabled]="isGeneratingCanvas" (click)="imprimirReceta();">
                {{isGeneratingCanvas ? 'Generando PDF...' : 'Imprimir'}}
            </button>
        </div>
    </div>
    <br>

</p-dialog>

<a href="#" id="downloadReceta" style="visibility: hidden; position: fixed;"></a>