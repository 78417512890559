import { checkIfRolAdmin } from 'src/app/common/check-role';
import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {list} from '../../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import { environment } from 'src/environments/environment';

import type { ItemInterface, TypeAheadInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ClienteModel } from 'src/app/models/cliente.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';

export class TareasEdificiosFormDefinition {

    public lastFieldChanged = '';

    public fincas: TypeAheadInterface<FincasModel> = list();  
    public clientes: TypeAheadInterface<ClienteModel> = list();      
    public trabajadores: TypeAheadInterface<MantenimientoModel> = list();      
    public prioridades: TypeAheadInterface<string> = list(['...', null], 'Alta', 'Realizada');
    
    public requestType: FormRequestTypes = 0;

    public model = {};
    
    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;    

    public fechaActual = Date.now();

    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.CALENDAR}, 
                required: true
            },
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.fincas,
                valuePrimaryKey: 'id',
                filter: (it: { value: FincasModel }) => {
                    if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return true;
                    }
                },
                required: true
            },
            { 
                field: 'prioridad', 
                label: 'Prioridad/Realizada', 
                inputType: { type: InputType.DROPDOWN }, 
                values: this.prioridades 
            },
        ];
    
    public formFieldsJardin: ItemInterface<object>[] = 
        [
            {
                field: 'hora_inicio_jardin', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin()
            },
            {
                field: 'hora_fin_jardin', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin()
            },
            {
                field: 'horas_jardin', 
                label: 'Horas totales', 
                inputType: {type: InputType.ONLY_TEXT} 
            },
        ];

    public formFieldsPiscinaHoras: ItemInterface<object>[] = 
        [
            {
                field: 'hora_inicio_piscina', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin()
            },
            {
                field: 'hora_fin_piscina', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin()
            },
            {
                field: 'horas_piscina', 
                label: 'Horas totales', 
                inputType: {type: InputType.ONLY_TEXT} 
            },
        ];

    public formFieldsPiscinaTareas01: ItemInterface<object>[] = 
        [
            {
                field: 'barredera', 
                label: 'Barredera', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'cepillado', 
                label: 'Cepillado Paredes/Fondo', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'limpieza_red', 
                label: 'Limpieza con Red', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'rascado_linea_flotacion', 
                label: 'Rascado Línea Flotación', 
                inputType: {type: InputType.SWITCH}
            },
        ];

    public formFieldsPiscinaTareas02: ItemInterface<object>[] =
        [
            {
                field: 'comprobar_parametros_ph', 
                label: 'Ph', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'comprobar_parametros_cloro', 
                label: 'Cloro', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'comprobar_parametros_acido_isocianurico', 
                label: 'Acido Isocianurico', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'comprobar_parametros_alcalinidad', 
                label: 'Alcalinidad', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'comprobar_parametros_fosfatos', 
                label: 'Fosfatos', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'comprobar_parametros_observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ];

    public formFieldsPiscinaTareas03: ItemInterface<object>[] =
        [
            {
                field: 'lavado_enjuague_cesti', 
                label: 'Lavado/Enjuague/Cesto Bomba', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'limpieza_duchas', 
                label: 'Limpieza Duchas', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'limpieza_skimmer', 
                label: 'Limpieza Skimmer', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'nivel_vaso', 
                label: 'Nivel del Vaso', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'limpieza_cuarto_depuradora', 
                label: 'Limpieza Cuarto Depuradora', 
                inputType: {type: InputType.SWITCH}
            },
        ];
  
    public formFieldsPiscinaProductos01: ItemInterface<object>[] = 
        [ 
            {
                field: 'cloro_liquido', 
                label: 'Líquido (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'cloro_granulado', 
                label: 'Granulado (kilos)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'cloro_pastillas', 
                label: 'Pastillas', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ]; 

    public formFieldsPiscinaProductos02: ItemInterface<object>[] = 
        [ 
            {
                field: 'floculante_liquido', 
                label: 'Líquido (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'floculante_pastillas', 
                label: 'Pastillas', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ]; 

    public formFieldsPiscinaProductos03: ItemInterface<object>[] = 
        [ 
            {
                field: 'coagulante', 
                label: 'Coagulante (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'algicida', 
                label: 'Algicida (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'alcalinidad', 
                label: 'Alcalinidad (kg)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'ph_minus', 
                label: 'PH- (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'ph_plus', 
                label: 'PH+ (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'activador', 
                label: 'Activador (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'enzimatic', 
                label: 'Enzimatic (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'desincrustante', 
                label: 'Desincrustante (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'eliminador_fosfatos', 
                label: 'Eliminador Fosfatos (kg)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ]; 
    
    public formFieldsPiscinaRecambios: ItemInterface<object>[] =
        [
            {
                field: 'arena_silice', 
                label: 'Arena Sílice (25kg)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'limpiador_silex', 
                label: 'Limpiador Silex (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'vidrio', 
                label: 'Vidrio (20kg)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'sacos_sal', 
                label: 'Sacos Sal (25kg)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'limpiador_fotocelulas', 
                label: 'Limpiador Fotocelulas (litros)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'cesto_skimmer', 
                label: 'Cesto Skimmer', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'tapas_skimmer', 
                label: 'Tapas Skimmer', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'rejillas_sumidero', 
                label: 'Rejillas Sumidero', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'foco_luz', 
                label: 'Foco Luz', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'alcachofa_ducha', 
                label: 'Alcachofa Ducha', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'pomo_sencilla', 
                label: 'Pomo Sencilla', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'pomo_completo', 
                label: 'Pomo Completo', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'red_piscina', 
                label: 'Red Piscina', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'pertiga', 
                label: 'Pertiga', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'bomba', 
                label: 'Bomba', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'cesto_bomba', 
                label: 'Cesto Bomba', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'escalera', 
                label: 'Escalera', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'rejillas_transversales', 
                label: 'Rejillas Transversales', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'impulsores_boquilla', 
                label: 'Impulsores/Boquilla', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'neat_clean', 
                label: 'Neat Clean', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'dosificadores_orp', 
                label: 'Dosificadores ORP', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'dosificadores_ph', 
                label: 'Dosificadores PH', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'sonda_orp', 
                label: 'Sonda ORP', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'sonda_ph', 
                label: 'Sonda PH', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'dosificador_pastillas', 
                label: 'Dosificador Pastillas', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'manometro', 
                label: 'Manómetro', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ];
  
    public formFieldsLimpiezaGeneral: ItemInterface<object>[] = 
        [
            {
                field: 'hora_inicio_limpieza_general', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}
            },
            {
                field: 'hora_fin_limpieza_general', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}
            },
            {
                field: 'horas_limpieza_general', 
                label: 'Horas totales', 
                inputType: {type: InputType.ONLY_TEXT} 
            },
        ];
  
    public formFieldsRecambiosLuces: ItemInterface<object>[] =
        [
            {
                field: 'luces_R90', 
                label: 'R90 LED', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'luces_11W', 
                label: '11W Bajo Consumo', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'luces_18ww', 
                label: '18ww LED (Tubo Fluoresc.)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'luces_26W', 
                label: '26W Bajo Consumo (Tubo Fluoresc.)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'luces_14w', 
                label: '14w Tubo Fluorescente', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'luces_22w', 
                label: '22w LED (Tubo Fluoresc.)', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ];
  
    public formFieldsRecambiosPapelHigienico: ItemInterface<object>[] =
        [
            {
                field: 'papel_higienico', 
                label: '', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ];
    
    public formFieldsRecambiosRejillas: ItemInterface<object>[] =
        [
            {
                field: 'rejillas_200x500', 
                label: 'Desagües 200x500', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'rejillas_130x500', 
                label: 'Desagües 130x500', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'rejillas_transversales_curva_alta', 
                label: 'Transversales P. Curva Alta 22', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ];
  
    public formFieldsRecambiosProductosLimpieza: ItemInterface<object>[] =
        [
            {
                field: 'fregasuelos_5l', 
                label: 'Fregasuelos 5l', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'escoba_interior', 
                label: 'Escoba Interior', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'escoba_exterior', 
                label: 'Escoba Exterior', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'recogedor', 
                label: 'Recogedor', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'palo_escoba', 
                label: 'Palo Escoba', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'fregona_algodon', 
                label: 'Fregona Algodón', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'alcohol_limpieza', 
                label: 'Alcohol Limpieza', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'recogedor_palo', 
                label: 'Recogedor Palo', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'lejia_5l', 
                label: 'Lejia 5l', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'lejia_1l', 
                label: 'Lejia 1l', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'salfuman_5l', 
                label: 'Salfuman 5l', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'salfuman_1l', 
                label: 'Salfuman 1l', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'amoniaco', 
                label: 'Amoniaco', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'limpiacristales', 
                label: 'Limpiacristales', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'antical', 
                label: 'Antical', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'abrillantador', 
                label: 'Abrillantador', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'saca_de_trapos', 
                label: 'Saca de Trapos', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'lana_de_roca', 
                label: 'Lana de Roca', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'cristalizador', 
                label: 'Cristalizador', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ];
    
    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Profesionales',
            inputType: { type: InputType.MULTISELECT },
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
    ];

    public formFieldsOrdenTrabajo: ItemInterface<object>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA },
        },
     
    ];
    public formFieldsObservaciones: ItemInterface<object>[] = [
        { 
            field: 'observaciones', 
            label: 'Observaciones', 
            inputType: { type: InputType.EDIT_TEXTAREA } 
        },
    ];



    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }

}