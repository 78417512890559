import { environment } from 'src/environments/environment';
import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { BoquillaModel } from 'src/app/models/trabajadores/boquillas.model';
import { Fichaje } from 'projects/fichajes/src/app/models/Fichajes.model';
import { PartesInterface } from 'src/app/view/main/4_trabajadores/partes/partes.interface';
import { CargoModel } from 'src/app/models/trabajadores/cargos.model';

@Injectable({
    providedIn: 'root'
})
export class TrabajadoresApiService {

    public mantenimiento = new RequestHandler<MantenimientoModel[]>();

    public trabajadoresGET = new RequestHandler<MantenimientoModel[]>();
    public trabajadoresPOST = new RequestHandler<MantenimientoModel[]>();
    public trabajadoresPUT = new RequestHandler<MantenimientoModel[]>();
    public trabajadoresDELETE = new RequestHandler<MantenimientoModel>();

    public maquinariaGET = new RequestHandler<MaquinariaModel[]>();
    public maquinariaPOST = new RequestHandler<MaquinariaModel[]>();
    public maquinariaPUT = new RequestHandler<MaquinariaModel[]>();
    public maquinariaDELETE = new RequestHandler<MaquinariaModel>();

    public boquillas = {
        GET: new RequestHandler<BoquillaModel[]>(),
        POST: new RequestHandler<BoquillaModel[]>(),
        PUT: new RequestHandler<BoquillaModel[]>(),
        DELETE: new RequestHandler<BoquillaModel>()
    };

    public fichajesGET = new RequestHandler<Fichaje[]>();
    public fichajesPOST = new RequestHandler<Fichaje[]>();
    public fichajesPUT = new RequestHandler<Fichaje[]>();
    public fichajesDELETE = new RequestHandler<Fichaje>();

    public cargosGET = new RequestHandler<CargoModel[]>();
    public cargosPOST = new RequestHandler<CargoModel[]>();
    public cargosPUT = new RequestHandler<CargoModel[]>();
    public cargosDELETE = new RequestHandler<CargoModel>();


    // OLD VERSION
    public fichajes = new RequestHandler<Fichaje[]>();
    public fichajesAll = {
        POST: new RequestHandler<Fichaje[]>(),
        PUT: new RequestHandler<Fichaje[]>(),
        DELETE: new RequestHandler<Fichaje>()
    };

    public partes = new RequestHandler<PartesInterface[]>();

    public cuadrillas = {
        GET: new RequestHandler<MantenimientoModel[]>(),
        POST: new RequestHandler<MantenimientoModel[]>(),
        PUT: new RequestHandler<MantenimientoModel[]>(),
        DELETE: new RequestHandler<MantenimientoModel>()
    };

    constructor(
        private syncServiceMantenimiento: SyncService<MantenimientoModel[]>,
        private syncServiceMantenimientoDelete: SyncService<MantenimientoModel>,
        private syncServiceMaquinaria: SyncService<MaquinariaModel[]>,
        private syncServiceMaquinariaDelete: SyncService<MaquinariaModel>,
        private syncServiceFichaje: SyncService<Fichaje[]>,
        private syncServiceFichajeDelete: SyncService<Fichaje>,
        private syncServiceBoquilla: SyncService<BoquillaModel[]>,
        private syncServiceBoquillaDelete: SyncService<BoquillaModel>,
        private syncServiceCargo: SyncService<CargoModel[]>,
        private syncServiceCargoDelete: SyncService<CargoModel>,
        private syncServiceParte: SyncService<PartesInterface[]>,
        private apiMantenimiento: ApiRequestService<MantenimientoModel[]>,
        private apiMantenimientoDelete: ApiRequestService<MantenimientoModel>,
        private apiMaquinaria: ApiRequestService<MaquinariaModel[]>,
        private apiMaquinariaDelete: ApiRequestService<MaquinariaModel>,
        private apiFichaje: ApiRequestService<Fichaje[]>,
        private apiFichajeDelete: ApiRequestService<Fichaje>,
        private apiBoquilla: ApiRequestService<BoquillaModel[]>,
        private apiBoquillaDelete: ApiRequestService<BoquillaModel>,
        private apiCargo: ApiRequestService<CargoModel[]>,
        private apiCargoDelete: ApiRequestService<CargoModel>,
        private apiParte: ApiRequestService<PartesInterface[]>) {


        // OLD VERSION
        this.defineFichajes();
        
        this.definePartes();

        // NEW VERSION
        this.defineFichajesAllPOST();
        this.defineFichajesAllPUT();
        this.defineFichajesAllDELETE();
        
        this.defineTrabajadoresGET();
        this.defineTrabajadoresPOST();
        this.defineTrabajadoresPUT();
        this.defineTrabajadoresDELETE();

        this.defineMaquinasGET();
        this.defineMaquinasPOST();
        this.defineMaquinasPUT();
        this.defineMaquinasDELETE();

        this.defineBoquillas();

        this.defineFichajesGET();
        this.defineFichajesPOST();
        this.defineFichajesPUT();
        this.defineFichajesDELETE();

        this.defineCargosGET();
        this.defineCargosPOST();
        this.defineCargosPUT();
        this.defineCargosDELETE();

        this.defineCuadrillasGET();
    }

    // OLD VERSION
    private defineFichajes() {
        this.fichajes
            .fetch(HttpRequestType.GET, 'trabajadores/fichajes');

        this.apiFichaje
            .registerRequest(this.fichajes);

        this.syncServiceFichaje
            .register(this.syncServiceFichaje.baseSyncPolicy(this.fichajes));
    }
    

    private defineFichajesGET() {
        if ( localStorage.getItem('sm') != null && environment.appName === 'covidai') {
            this.fichajesGET
                .fetch(HttpRequestType.GET, 'trabajadores/fichajes_get');
        } else {
            if ( environment.appName === 'covidai' ) {
                this.fichajesGET
                    .fetch(HttpRequestType.GET, 'trabajadores/fichajes_get_b');
            } else {
                this.fichajesGET
                    .fetch(HttpRequestType.GET, 'trabajadores/fichajes_get');
            }
           
        }
        

        this.apiFichaje
            .registerRequest(this.fichajesGET);

        this.syncServiceFichaje
            .register(this.syncServiceFichaje.baseSyncPolicy(this.fichajesGET));
    }

    private defineFichajesPOST() {
        this.fichajesPOST
            .fetch(HttpRequestType.POST, 'trabajadores/fichajes_post');

        this.apiFichaje
            .registerRequest(this.fichajesPOST);

        this.syncServiceFichaje
            .register(this.syncServiceFichaje.baseSyncPolicy(this.fichajesPOST));
    }

    private defineFichajesPUT() {
        this.fichajesPUT
            .fetch(HttpRequestType.PUT, 'generic/fichajes');

        this.apiFichaje
            .registerRequest(this.fichajesPUT);

        this.syncServiceFichaje
            .register(this.syncServiceFichaje.baseSyncPolicy(this.fichajesPUT));
    }

    private defineFichajesDELETE() {
        this.fichajesDELETE
            .fetch(HttpRequestType.DELETE, 'generic/fichajes');

        this.apiFichajeDelete
            .registerRequest(this.fichajesDELETE);

        this.syncServiceFichajeDelete
            .register(this.syncServiceFichajeDelete.baseSyncPolicy(this.fichajesDELETE));
    }

    // OLD VERSION
    private defineFichajesAllPOST() {
        this.fichajesAll.POST
            .fetch(HttpRequestType.POST, 'generic/fichajes');

        this.apiFichaje
            .registerRequest(this.fichajesAll.POST);

        this.syncServiceFichaje
            .register(this.syncServiceFichaje.baseSyncPolicy(this.fichajesAll.POST));
    }

    private defineFichajesAllPUT() {
        this.fichajesAll.PUT
            .fetch(HttpRequestType.PUT, 'generic/fichajes');

        this.apiFichaje
            .registerRequest(this.fichajesAll.PUT);

        this.syncServiceFichaje
            .register(this.syncServiceFichaje.baseSyncPolicy(this.fichajesAll.PUT));
    }

    private defineFichajesAllDELETE() {
        this.fichajesAll.DELETE
            .fetch(HttpRequestType.DELETE, 'generic/fichajes');

        this.apiFichajeDelete
            .registerRequest(this.fichajesAll.DELETE);

        this.syncServiceFichajeDelete
            .register(this.syncServiceFichajeDelete.baseSyncPolicy(this.fichajesAll.DELETE));
    }
    

    private definePartes() {
        if ( environment.appName === 'covidai' && localStorage.getItem('sm') != null ){
            this.partes
                .fetch(HttpRequestType.GET, 'trabajadores/partesB');
        } else {
            this.partes
                .fetch(HttpRequestType.GET, 'trabajadores/partes');
        }
        
        this.apiParte
            .registerRequest(this.partes);

        this.syncServiceParte
            .register(this.syncServiceParte.baseSyncPolicy(this.partes));
    }
    
    private defineTrabajadoresGET() {
        this.trabajadoresGET
            .fetch(HttpRequestType.GET, 'trabajadores/trabajadores');

        this.apiMantenimiento
            .registerRequest(this.trabajadoresGET);

        this.syncServiceMantenimiento
            .register(this.syncServiceMantenimiento.baseSyncPolicy(this.trabajadoresGET));
    }

    private defineTrabajadoresPOST() {
        this.trabajadoresPOST
            .fetch(HttpRequestType.POST, 'generic/trabajadores');

        this.apiMantenimiento
            .registerRequest(this.trabajadoresPOST);

        this.syncServiceMantenimiento
            .register(this.syncServiceMantenimiento.baseSyncPolicy(this.trabajadoresPOST));
    }

    private defineTrabajadoresPUT() {
        this.trabajadoresPUT
            .fetch(HttpRequestType.PUT, 'generic/trabajadores');

        this.apiMantenimiento
            .registerRequest(this.trabajadoresPUT);

        this.syncServiceMantenimiento
            .register(this.syncServiceMantenimiento.baseSyncPolicy(this.trabajadoresPUT));
    }

    private defineTrabajadoresDELETE() {
        this.trabajadoresDELETE
            .fetch(HttpRequestType.DELETE, 'generic/trabajadores');

        this.apiMantenimientoDelete
            .registerRequest(this.trabajadoresDELETE);

        this.syncServiceMantenimientoDelete
            .register(this.syncServiceMantenimientoDelete.baseSyncPolicy(this.trabajadoresDELETE));
    }

    private defineMaquinasGET() {
        this.maquinariaGET
            .fetch(HttpRequestType.GET, 'trabajadores/maquinarias');

        this.apiMaquinaria
            .registerRequest(this.maquinariaGET);

        this.syncServiceMaquinaria
            .register(this.syncServiceMaquinaria.baseSyncPolicy(this.maquinariaGET));
    }

    private defineMaquinasPOST() {
        this.maquinariaPOST
            .fetch(HttpRequestType.POST, 'generic/maquinarias');

        this.apiMaquinaria
            .registerRequest(this.maquinariaPOST);

        this.syncServiceMaquinaria
            .register(this.syncServiceMaquinaria.baseSyncPolicy(this.maquinariaPOST));
    }

    private defineMaquinasPUT() {
        this.maquinariaPUT
            .fetch(HttpRequestType.PUT, 'generic/maquinarias');

        this.apiMaquinaria
            .registerRequest(this.maquinariaPUT);

        this.syncServiceMaquinaria
            .register(this.syncServiceMaquinaria.baseSyncPolicy(this.maquinariaPUT));
    }

    private defineMaquinasDELETE() {
        this.maquinariaDELETE
            .fetch(HttpRequestType.DELETE, 'generic/maquinarias');

        this.apiMaquinariaDelete
            .registerRequest(this.maquinariaDELETE);

        this.syncServiceMaquinariaDelete
            .register(this.syncServiceMaquinariaDelete.baseSyncPolicy(this.maquinariaDELETE));
    }

    private defineBoquillas() {
        this.boquillas.GET.fetch(HttpRequestType.GET, 'trabajadores/boquillas_get');
        this.boquillas.POST.fetch(HttpRequestType.POST, 'generic/boquillas');
        this.boquillas.PUT.fetch(HttpRequestType.PUT, 'generic/boquillas');
        this.boquillas.DELETE.fetch(HttpRequestType.DELETE, 'generic/boquillas');

        this.apiBoquilla.registerRequest(this.boquillas.GET);
        this.apiBoquilla.registerRequest(this.boquillas.POST);
        this.apiBoquilla.registerRequest(this.boquillas.PUT);
        this.apiBoquillaDelete.registerRequest(this.boquillas.DELETE);

        this.syncServiceBoquilla.register(this.syncServiceBoquilla.baseSyncPolicy(this.boquillas.GET));
        this.syncServiceBoquilla.register(this.syncServiceBoquilla.baseSyncPolicy(this.boquillas.POST));
        this.syncServiceBoquilla.register(this.syncServiceBoquilla.baseSyncPolicy(this.boquillas.PUT));
        this.syncServiceBoquillaDelete.register(this.syncServiceBoquillaDelete.baseSyncPolicy(this.boquillas.DELETE));
    }


    private defineCargosGET() {
        this.cargosGET
            .fetch(HttpRequestType.GET, 'trabajadores/cargos');
        /* this.cargosGET
            .fetch(HttpRequestType.GET, 'generic/cargos'); */

        this.apiCargo
            .registerRequest(this.cargosGET);

        this.syncServiceCargo
            .register(this.syncServiceCargo.baseSyncPolicy(this.cargosGET));
    }

    private defineCargosPOST() {
        this.cargosPOST
            .fetch(HttpRequestType.POST, 'generic/cargos');

        this.apiCargo
            .registerRequest(this.cargosPOST);

        this.syncServiceCargo
            .register(this.syncServiceCargo.baseSyncPolicy(this.cargosPOST));
    }

    private defineCargosPUT() {
        this.cargosPUT
            .fetch(HttpRequestType.PUT, 'generic/cargos');

        this.apiCargo
            .registerRequest(this.cargosPUT);

        this.syncServiceCargo
            .register(this.syncServiceCargo.baseSyncPolicy(this.cargosPUT));
    }

    private defineCargosDELETE() {
        this.cargosDELETE
            .fetch(HttpRequestType.DELETE, 'generic/cargos');

        this.apiCargoDelete
            .registerRequest(this.cargosDELETE);

        this.syncServiceCargoDelete
            .register(this.syncServiceCargoDelete.baseSyncPolicy(this.cargosDELETE));
    }
    
    private defineCuadrillasGET() {
        this.cuadrillas.GET.fetch(HttpRequestType.GET, 'trabajadores/cuadrillas_get');
        this.apiMantenimiento.registerRequest(this.cuadrillas.GET);
        this.syncServiceMantenimiento.register(this.syncServiceMantenimiento.baseSyncPolicy(this.cuadrillas.GET));
    }
}
