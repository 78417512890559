import { FormCommonApiService } from '../../../../../service/api/formcommon-api.service';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { BaseForm } from '../../../../base-form';
import { AppFormRequest } from '../../../../app-common/form-request/app-form-request';
import { ProductosApiService } from '../../../../../service/api/productos-api';
import { ProductosFitosanitariosFormDefinition } from './form-control/form-definition';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { FileUploadAnswer, Utils } from '../../../../../common/utils';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import type { OnDestroy, OnInit } from '@angular/core';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { DetalleProducto } from 'src/app/models/productos/detalle-producto.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { catchError, map, of } from 'rxjs';
import { Uso } from 'src/app/models/productos/uso.model';
import { TreeNode } from 'primeng/api';
@Component({
    selector: 'app-stock-formulario-productos',
    templateUrl: './productos-form.component.html',
    styleUrls: ['./productos-form.component.scss']
})
export class ProductosFormComponent extends BaseForm<ProductosModel> implements OnInit, OnDestroy {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<ProductosModel>();

    public appName = environment.appName;
    public serverUrl: string = environment.serverUrl;
    public showInfoCambios = environment.features.showInfoCambios;
    public showNPK = environment.features.showNPK;
    public showReto = environment.features.showReto;
    public showProductosMaxAplicacion = environment.features.showProductosMaxAplicacion;

    public override model: ProductosModel = {nombre: '', materia_activa: ''};
    public form: ProductosFitosanitariosFormDefinition = new ProductosFitosanitariosFormDefinition();

    public formRequest = new AppFormRequest<ProductosModel>();
    public isProductSelected = false;
    public hasntRegisterNumber = false;
    public fecha_caducidad = '';

    public adjunto?: string | ArrayBuffer | null | undefined;


    constructor(public dashboardEvents: DashboardService,
        public formApi: FormCommonApiService,
        public productosApi: ProductosApiService,
        public override router: Router,
        public override route: ActivatedRoute,
        public http: HttpClient) {
        super(
            route,
            router,
            dashboardEvents,
            productosApi.productos.PUT,
            productosApi.productos.POST,
            'productos',
            'Actualizar Fitosanitario',
            'Alta de Producto Fitosanitario',
        );
    }

    ngOnInit() {

        this.getAllProducts();

        /* Input nº registro ocultado para crear con Registro */
        if (this.form.topFormFields[1]) {
            this.form.topFormFields[1].visible = false;
        }

        /* Input nº registro ocultado para crear sin Registro */
        if (this.form.topFormFieldsNoReg[1]) {
            this.form.topFormFieldsNoReg[1].visible = false;
        }

        if (this.getType() === FormRequestTypes.EDIT) {

            /* Input nº registro visible para editar */
            if (this.form.topFormFields[1]) {
                this.form.topFormFields[1].visible = true;
            }

            if (this.form.topFormFieldsNoReg[1]) {
                this.form.topFormFieldsNoReg[1].visible = true;
            }

            /* Toogle CON/SIN registro ocultado para editar */
            if (this.form.topFormFieldsNoReg[0]) {
                this.form.topFormFieldsNoReg[0].visible = false;
            }

            if (this.form.topFormFields[0]) {
                this.form.topFormFields[0].visible = false;
            }
        }

        this.initFormFiltering();
        this.expandFormRequest();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.productosApi.productos.POST)
            .setPutRequest(this.productosApi.productos.PUT)
            .setGetRequest(this.productosApi.productos.GET)
            .setFormFields(Array.prototype
                .concat(
                    this.form.topFormFields,
                    this.form.topFormFieldsNoReg,
                    this.form.bottomFormFieldsLeft
                )
            )
            .setFieldsToSend((['id', 'num_registro', 'tipo'])
                .concat(
                    this.form.topFormFields.map(it => it.field ?? ''),
                    this.form.topFormFieldsNoReg.map(it => it.field ?? ''),
                    this.form.bottomFormFieldsLeft.map(it => it.field)
                ).filter((it: string) => it !== 'hasntRegisterNumber')
            );

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public applyFilters() {
        if (this.form.agentesFilter) {
            this.form.agentesFilter.filter();
        }
    }

    public formChanges(tag: string) {
        this.requestButton.error = '';

        switch (tag) {
        case 'nombre':
            if (!this.hasntRegisterNumber) {
                if (this.model.nombre) {
                    this.formApi.magramaProducto.cancelablePerform({ p3: this.model.nombre } as never);
                } else {
                    this.getAllProducts();
                }
            }
            break;
        case 'uso':
            this.form.agentes.selected = null;
            this.applyFilters();
            break;
        case 'productos':
            this.form.usos.selected = null;
            this.isProductSelected = false;
            this.form.productos.selected = null;
            break;
        }

        this.hasntRegisterNumber = this.model.hasntRegisterNumber ?? false;
    }

    public productSelected(product: ProductosModel) {
        this.applyFilters();

        this.model.nombre = product.nombre_comercial;
        this.model.num_registro = product.referencia;
        this.model.materia_activa = product.composicion;

        this.form.productos.selected = product;
        this.form.agentes.selected = null;
        this.form.usos.selected = null;

        this.form.usos.values = [{
            label: 'Cargando...', value: null
        }];

        this.searchProduct(this.form.productos.selected.referencia);
    }

    public searchProduct(referencia: string): Promise<boolean> {
        return new Promise((resolve) => {
            this.formApi.magramaDetalleProducto
                .safePerform({
                    p3: '"' + (referencia) + '"'
                } as never)
                .response((data: Uso[]) => {
                    const usosAux: string[] = [];
                    this.form.usos.filtered = [];

                    (data || []).forEach(uso => {
                        if (!usosAux.includes(uso.uso)) {
                            this.form.usos.filtered.push(
                                { label: uso.uso, value: uso }
                            );

                            usosAux.push(uso.uso);
                        }
                    });

                    this.form.usos.filtered.unshift({
                        label: 'Usos...',
                        value: null
                    });

                    this.form.agentes.values = (data || []).map(it => (
                        { label: it.agente, value: it })
                    );

                    this.form.usosFilter.setValue(this.form.usos.filtered);
                    this.form.agentesFilter.setValue(this.form.agentes.values);

                    if ((data || []).length > 0) {
                        this.model.materia_activa = data[0]?.composicion;
                        this.fecha_caducidad = data[0]?.fecha_caducidad ?? '';
                    }

                    this.applyFilters();

                    this.isProductSelected = true;
                    this.formApi.magramaDetalleProducto.unsuscribe();
                    this.form.agentes.selected = null;
                    resolve(true);
                });
        });
    }

    public submit() {
        delete this.model.hasntRegisterNumber;
        
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        if(this.appName === 'amoros') {
            this.model.ultima_fecha_compra = moment(this.model.ultima_fecha_compra).format('DD/MM/YYYY');
        }else {
            delete this.model['ultima_fecha_compra'];
        }

        this.formRequest.send();
    }

    public globalConditionalStyle(
        _cellValue: string, 
        colDefinition: ColInterface, 
        _colGroup: ColInterface[], 
        rowValue: TreeNode<ProductosModel>
    ): ColStylesInterface {
        let estiloVademecum: ColStylesInterface = {};
        if (colDefinition.field === 'composicion') {
            estiloVademecum = {
                rowStyle: {
                    color: 'black',
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid #dddddd'
                }
            };

            const diffInsc = moment(new Date()).diff(Utils.toDate(rowValue.data?.fecha_inscripcion ?? ''), 'months');
            if (diffInsc < 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#cbdecb',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            } else {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffffff',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            }


            const diff = moment(new Date()).diff(Utils.toDate(rowValue.data?.fecha_caducidad ?? ''), 'months');

            if (diff > 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'white',
                        backgroundColor: '#222222',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            } else if (diff > 6) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ff7f7f',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            } else if (diff >= 0) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffcf78',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            }
        }


        return estiloVademecum;
    }

    public getObservaciones(event: string) {
        this.model.observaciones = event;
    }

    public goToVademecum(num_reg: number) {
        this.router.navigate(['dashboard', 'consultas', num_reg.toString()]);
    }

    public override goBack() {
        this.router.navigate(['dashboard', 'productos-fitosanitarios']);
    }

    public override ngOnDestroy() {
        Utils.unsuscribe([
            this.formApi.magramaDetalleProducto,
        ]);
        Utils.unsuscribe([
            this.formApi.magramaProducto
        ]);
    }

    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.adjunto = e.target?.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(environment.serverUrl + 'ws/trabajadores/upload_file.php', this.adjunto).subscribe(res => {
                const file = res as FileUploadAnswer;
                this.model.adjunto = file.fileName;
                r.unsubscribe();
            });
        }
    }

    public fileChange(event: Event ) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const fileList: FileList = input.files;
            if (fileList.length > 0) {
                const file: File = fileList[0] ?? new File([],'');
                const formData: FormData = new FormData();
                formData.append('uploadFile', file, file.name);
                const headers = new HttpHeaders();
                headers.append('Content-Type', 'multipart/form-data');
                headers.append('Accept', 'application/json');

                const name: string[] = file.name.split('.');
                const ext = name[name.length - 1];
                const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
                let cond = false;

                for (const e of accepted) {
                    if (e === ext) {
                        cond = true;
                    }
                }
                
                if (cond) {
                    this.http.post<FileUploadAnswer>(
                        `${environment.serverUrl}ws/productos/upload_file.php`, 
                        formData, 
                        {headers: headers}
                    ).pipe(
                        map(data => {
                            const res = data || {};
                            this.model.adjunto = res.fileName;
                        }),
                        catchError(error => {
                            console.log(error);
                            return of(null);
                        })
                    ).subscribe();
                } else {
                    alert('Formato de imágen no válido.');
                    this.model.adjunto = '';
                }
            }
        }
    }


    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            this.formApi.magramaProducto.cancelablePerform({ p3: 'a' } as never);
            if (this.showReto) {
                if (this.getType() === FormRequestTypes.EDIT) {
                    if (!this.model.otra_denominacion_comun) {
                        this.model.otra_denominacion_comun = false;
                    }
                }
            }

            if (this.getType() === FormRequestTypes.EDIT
                || this.getType() === FormRequestTypes.DUPLICATE) {

                this.model.hasntRegisterNumber = (this.model.num_registro?.toString() || '').length === 0;
                this.hasntRegisterNumber = this.model.hasntRegisterNumber;

                this.form.productos.selected = {
                    referencia: this.model.num_registro
                };

                this.form.agentes.selected = {
                    composicion: this.model.materia_activa,
                    num_registro: this.model.num_registro,
                    plazo_seguridad: this.model.ps
                };
            }

            resolve(true);
        });

        this.formRequest.afterLoad(resolve => {
            this.searchProduct(this.model.num_registro?.toString() ?? '')
                .then(() => {
                    this.form.agentesFilter.filter();
                    this.form.usosFilter.filter();
                    return;
                }).catch (e => {
                    console.log('catch en searchProduct: ' + e);
                }
                );
            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            if (this.model.hasntRegisterNumber) {
                delete this.model.num_registro;
            }

            this.model.materia_activa = this.model.materia_activa
                ? this.model.materia_activa
                : (this.form.productos.selected || {} as ProductosModel).composicion;

            this.model.ps = this.model.ps
                ? (this.form.usos.selected || {} as ProductosModel).plazo_seguridad
                : this.model.ps;


            delete this.model.uso;
            delete this.model.agente;

            this.model.tipo = 'fitosanitario';

            resolve(true);
        });
    }

    private initFormFiltering() {
        this.form.agentesFilter.addFilter(() => {
            if (this.form.usos.selected) {
                this.model.ps = this.model.hasntRegisterNumber
                    ? this.form.usos.selected.plazo_seguridad
                    : this.model.ps
                        ? this.model.ps
                        : this.form.usos.selected.plazo_seguridad;

                const copy = this.form.agentes.values
                    .filter(agente => agente.value.uso === this.form.usos.selected.uso
                        && agente.value.num_registro === this.form.usos.selected.num_registro);

                this.form.agentes.filtered = [];

                const agentesAux: DetalleProducto[] = [];

                copy.forEach(agente => {
                    agente = agente.value;
                    if (!agentesAux.includes(agente.agente)) {
                        this.form.agentes.filtered.push(
                            { label: agente.agente, value: agente }
                        );
                        agentesAux.push(agente.agente);
                    }
                });

                this.form.agentes.filtered.unshift({ label: 'Agente...', value: null });
            } else {
                this.form.agentes.filtered = [{ label: 'Debe seleccionar un uso...', value: null }];
            }
        });
    }

    private getAllProducts() {
        this.formApi.magramaProducto.response(productos => {
            for (const producto of [productos]) {
                const repes = [productos].filter((it: ProductosModel) => it.referencia === producto.referencia) || [];
                if (repes.length > 1) {
                    const index = [productos].map((it: ProductosModel) => it.referencia).indexOf(repes[repes.length - 1]?.referencia);
                    [productos].splice(index, (repes.length - 1));
                }
            }
        });
    }
}
