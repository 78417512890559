import {Component} from '@angular/core';
import {BaseView} from '../../../base-view';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Filtering} from '../../../../service/filtering/filtering';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Utils} from '../../../../common/utils';
import { environment } from 'src/environments/environment';
import { CostesProductosApiService } from 'src/app/service/api/costes.productos.service';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { CosteModel } from 'src/app/models/coste.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-costes-productos',
    templateUrl: './costes-productos.component.html',
    styleUrls: ['./costes-productos.component.scss']
})
export class CostesProductosComponent extends BaseView<CosteModel> implements OnInit, OnDestroy {
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public appName = environment.appName;
    public showCostesTareasFertiCsv = environment.features.showCostesTareasFertiCsv;


    public getRequest: RequestHandler<CosteModel[]> = this.costesProductosApi.costesProductos.GET;
    public deleteRequest: RequestHandler<CosteModel> = new RequestHandler<CosteModel>();
    public menuItemId = 'costes-productos';

    public override canShow: Record<string, boolean> = {};
    public override model: CosteModel = {};
    public ps = 0;

    public mostrarTratamientosAplicados = true;
    public override filtering: Filtering<CosteModel> = new Filtering<CosteModel>();

    public distributionOrder = [4];

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca_nombre', 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'nombre', 
            label: 'Producto', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'unidades', 
            label: 'Cantidad', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'precio', 
            label: 'Precio', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'resultado', 
            label: 'Resultado', 
            inputType: {type: InputType.TEXT}
        }
    ];

    public cols: ColInterface[] = [
        {field: 'fecha', header: 'Fecha', filter: ''},
        {field: 'finca_nombre', header: this.fincaName, filter: ''},
        {field: 'parcela_nombre', header: this.parcelaName, filter: ''},
        {field: 'tipo_dato', header: 'Tipo', filter: ''},
        {field: 'nombre', header: 'Producto', filter: ''},
        {field: 'unidades', header: 'Cantidad', filter: ''},
        {field: 'precio', header: 'Precio', filter: ''},
        {field: 'resultado', header: 'Resultado', filter: '', footer: '0'},
    ];

    constructor(public costesProductosApi: CostesProductosApiService,
        private dashboard: DashboardService) {
        super(dashboard, costesProductosApi.costesProductos.GET, undefined);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);

        this.filtering.addFilter((data: TreeNode<CosteModel>[] | void) => {
            this.updateFooter();
            return (data as TreeNode<CosteModel>[]).sort((a: TreeNode<CosteModel>, b: TreeNode<CosteModel>) =>
                parseInt(a.data?.id ?? '', 10) > parseInt(b.data?.id ?? '', 10) ? -1 : 1
            );
        });
    }

    public filtrarTratamientos() {
        this.filtering.filter();
    }

    public moneyFormat(input: number) {
        return Utils.decimalFormat(input, 2);
    }

    public cuantityFormat(input: number) {
        return Utils.decimalFormat(input, 1);
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filtering.value || []).forEach(it => {
            sum += (it as CosteModel)[field] as number;
        });

        this.cols.forEach(it => {
            if (it.field === field) {
                it['footer'] = sum + '';
            }
        });
    }

    public override updateFooter() {
        ['resultado'].forEach(it => this.sum(it));
    }

    public globalConditionalStyle(value: string): ColStylesInterface {
        if (Utils.isNumber(value)) {
            return {
                textAlign: 'right',
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: { field: string; }) {
        if (Utils.isNumber(value) && !['litros', 'sector'].includes(col.field)) {
            return Utils.decimalFormat(value, 2);
        } else if (Utils.isNumber(value) && col.field === 'litros') {
            return Utils.decimalFormat(value, 0, '.', ',');
        } else {
            return value;
        }
    }

    public calcularTratamiento( tratamiento: TratamientosModel[] ) {
        let suma = 0;
        if ( tratamiento == null || tratamiento.length === 0 ){
            return 0;
        } else {
            for (let index = 0; index < tratamiento.length; index++) {
                const precio: number = (tratamiento[index]?.precio ? tratamiento[index]?.precio : 0) as number;
                suma += ( precio * (this.model['dosis' + ( 1 + index )] as number));
                if ( (index + 1) >= tratamiento.length ) {
                    return suma;
                }
            }

        }
        return 0;
    }
}