import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import type { HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BaseResponseModel } from 'src/base-response.model';

@Injectable()
export class DecodeInterceptor<T> implements HttpInterceptor {

    private urlsToDecode = [
        environment.serverUrl + 'ws/index.php?p1=productos&p2=productos_get',
        environment.serverUrl + 'ws/index.php?p1=mg&p2=excepcionales',
        environment.serverUrl + 'ws/index.php?p1=mg&p2=otrasdenominaciones',
        environment.serverUrl + 'ws/index.php?p1=mg&p2=all',
        environment.serverUrl + 'ws/index.php?p1=mg&p2=usos',
        environment.serverUrl + 'ws/index.php?p1=mg&p2=productos',
        environment.serverUrl + 'ws/index.php?p1=mg&p2=listado_vademecum'
    ];

    constructor() {}

    intercept(request: HttpRequest<BaseResponseModel<T>>, next: HttpHandler): Observable<HttpEvent<BaseResponseModel<T>>> {

        return next.handle(request).pipe(
            map((event: HttpEvent<BaseResponseModel<T>>) => {
                if (event instanceof HttpResponse) {
                    for (const url of this.urlsToDecode) {
                        if (event.url?.indexOf(url) !== -1 && event.body) {
                            event.body.data = JSON.parse(window.atob(event.body?.data.toString() ?? ''));
                        }
                    }
                }
                return event;
            }));
    }

}