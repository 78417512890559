import { ClienteModel } from '../cliente.model';


export class CrmModel {
    id = '';
    fecha = '';
    estado?: string;
    archivo?: string;
    realizado?: string;
    tarea_comercial?: string;
    estado_presupuesto?: string;
    observaciones?: string;
    cliente = {} as ClienteModel;
    fecha_proxima_accion_comercial?: string;
}
