import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { SeguimientoModel } from 'src/app/models/visitas/seguimiento.model';
import { CitasModel } from 'src/app/models/citas/citas.model';
import { TrasiegoModel } from 'src/app/models/visitas/trasiego.model';
import { VisitasSemanaModel } from 'src/app/models/visitas/visitas-semana.model';
import { VisitasModel } from 'src/app/models/visitas/visitas.model';

@Injectable({
    providedIn: 'root'
})
export class VisitasApiService {
    public visitasGET = new RequestHandler<VisitasModel[]>();
    public visitasPOST = new RequestHandler<VisitasModel[]>();
    public visitasPUT = new RequestHandler<VisitasModel[]>();
    public visitasDELETE = new RequestHandler<VisitasModel>();

    public visitasSemanaGET = new RequestHandler<VisitasSemanaModel[]>();
    public visitasSemanaPOST = new RequestHandler<VisitasSemanaModel[]>();
    public visitasSemanaPUT = new RequestHandler<VisitasSemanaModel[]>();   
    public visitasSemanaDELETE = new RequestHandler<VisitasSemanaModel>();

    public visitasPLAGAS_GET = new RequestHandler<VisitasSemanaModel[]>();
    public visitasPLAGAS_TRASIEGO_GET = new RequestHandler<TrasiegoModel[]>();
    public visitasESTADISTICAS_GET = new RequestHandler<CitasModel[]>();

    public visitasTrasiego = {
        GET: new RequestHandler<TrasiegoModel[]>(),
        POST: new RequestHandler<TrasiegoModel[]>(),
        PUT: new RequestHandler<TrasiegoModel[]>(),
        DELETE: new RequestHandler<TrasiegoModel>()
    };

    public visitasPereira = {
        GET: new RequestHandler<SeguimientoModel[]>(),
        POST: new RequestHandler<SeguimientoModel[]>(),
        PUT: new RequestHandler<SeguimientoModel[]>(),
        DELETE: new RequestHandler<SeguimientoModel>()
    };

    constructor(
        private syncServiceVisitas: SyncService<VisitasModel[]>,
        private syncServiceVisitasDelete: SyncService<VisitasModel>,
        private syncServiceSemana: SyncService<VisitasSemanaModel[]>,
        private syncServiceTrasiego: SyncService<TrasiegoModel[]>,
        private syncServiceTrasiegoDelete: SyncService<TrasiegoModel>,
        private syncServiceSeguimiento: SyncService<SeguimientoModel[]>,
        private syncServiceSeguimientoDelete: SyncService<SeguimientoModel>,
        private syncServiceCitas: SyncService<CitasModel[]>,
        private apiVisitas: ApiRequestService<VisitasModel[]>,
        private apiVisitasDelete: ApiRequestService<VisitasModel>,
        private apiSemana: ApiRequestService<VisitasSemanaModel[]>,
        private apiTrasiego: ApiRequestService<TrasiegoModel[]>,
        private apiTrasiegoDelete: ApiRequestService<TrasiegoModel>,
        private apiSeguimiento: ApiRequestService<SeguimientoModel[]>,
        private apiSeguimientoDelete: ApiRequestService<SeguimientoModel>,
        private apiCitas: ApiRequestService<CitasModel[]>) {

        this.defineVisitasGET();
        this.defineVisitasPOST();
        this.defineVisitasPUT();
        this.defineVisitasDELETE();
        this.defineVisitasPLAGAS_GET();
        this.defineVisitasPLAGAS_TRASIEGO_GET();
        this.defineVisitasESTADISTICAS_GET();

        this.defineVisitasTrasiego();
        this.defineVisitasPereira();
    }

    private defineVisitasGET() {
        this.visitasGET
            .fetch(HttpRequestType.GET, 'generic/visitas');

        this.apiVisitas
            .registerRequest(this.visitasGET);

        this.syncServiceVisitas
            .register(this.syncServiceVisitas.baseSyncPolicy(this.visitasGET));
    }

    private defineVisitasPOST() {
        this.visitasPOST
            .fetch(HttpRequestType.POST, 'generic/visitas');

        this.apiVisitas
            .registerRequest(this.visitasPOST);

        this.syncServiceVisitas
            .register(this.syncServiceVisitas.baseSyncPolicy(this.visitasPOST));
    }

    private defineVisitasPUT() {
        this.visitasPUT
            .fetch(HttpRequestType.PUT, 'generic/visitas');

        this.apiVisitas
            .registerRequest(this.visitasPUT);

        this.syncServiceVisitas
            .register(this.syncServiceVisitas.baseSyncPolicy(this.visitasPUT));
    }

    private defineVisitasDELETE() {
        this.visitasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/visitas');

        this.apiVisitasDelete
            .registerRequest(this.visitasDELETE);

        this.syncServiceVisitasDelete
            .register(this.syncServiceVisitasDelete.baseSyncPolicy(this.visitasDELETE));
    }

    
    private defineVisitasPLAGAS_GET() {
        this.visitasPLAGAS_GET.fetch(HttpRequestType.GET, 'visitas/visitas_distinct_plagas_get');
        this.apiSemana.registerRequest(this.visitasPLAGAS_GET);
        this.syncServiceSemana.register(this.syncServiceSemana.baseSyncPolicy(this.visitasPLAGAS_GET));
    }

    private defineVisitasPLAGAS_TRASIEGO_GET() {
        this.visitasPLAGAS_TRASIEGO_GET.fetch(HttpRequestType.GET, 'visitas/visitas_distinct_plagas_trasiego_get');
        this.apiSemana.registerRequest(this.visitasPLAGAS_TRASIEGO_GET);
        this.syncServiceTrasiego.register(this.syncServiceTrasiego.baseSyncPolicy(this.visitasPLAGAS_TRASIEGO_GET));
    }

    private defineVisitasESTADISTICAS_GET() {
        this.visitasESTADISTICAS_GET.fetch(HttpRequestType.GET, 'visitas/estadisticas_visitas');
  
        this.apiCitas
            .registerRequest(this.visitasESTADISTICAS_GET);
  
        this.syncServiceCitas
            .register(this.syncServiceCitas.baseSyncPolicy(this.visitasESTADISTICAS_GET));
    }
      
    private defineVisitasTrasiego() {
        this.visitasTrasiego.GET.fetch(HttpRequestType.GET, 'visitas/trasiego_get');
        this.visitasTrasiego.POST.fetch(HttpRequestType.POST, 'generic/visitas_trasiego');
        this.visitasTrasiego.PUT.fetch(HttpRequestType.PUT, 'generic/visitas_trasiego');
        this.visitasTrasiego.DELETE.fetch(HttpRequestType.DELETE, 'generic/visitas_trasiego');

        this.apiTrasiego.registerRequest(this.visitasTrasiego.GET);
        this.apiTrasiego.registerRequest(this.visitasTrasiego.POST);
        this.apiTrasiego.registerRequest(this.visitasTrasiego.PUT);
        this.apiTrasiegoDelete.registerRequest(this.visitasTrasiego.DELETE);

        this.syncServiceTrasiego.register(this.syncServiceTrasiego.baseSyncPolicy(this.visitasTrasiego.GET));
        this.syncServiceTrasiego.register(this.syncServiceTrasiego.baseSyncPolicy(this.visitasTrasiego.POST));
        this.syncServiceTrasiego.register(this.syncServiceTrasiego.baseSyncPolicy(this.visitasTrasiego.PUT));
        this.syncServiceTrasiegoDelete.register(this.syncServiceTrasiegoDelete.baseSyncPolicy(this.visitasTrasiego.DELETE));
    }

    private defineVisitasPereira() {
        this.visitasPereira.GET.fetch(HttpRequestType.GET, 'visitas/visitas_pereira_get');
        this.visitasPereira.POST.fetch(HttpRequestType.POST, 'generic/seguimiento');
        this.visitasPereira.PUT.fetch(HttpRequestType.PUT, 'generic/seguimiento');
        this.visitasPereira.DELETE.fetch(HttpRequestType.DELETE, 'generic/seguimiento');

        this.apiSeguimiento.registerRequest(this.visitasPereira.GET);
        this.apiSeguimiento.registerRequest(this.visitasPereira.POST);
        this.apiSeguimiento.registerRequest(this.visitasPereira.PUT);
        this.apiSeguimientoDelete.registerRequest(this.visitasPereira.DELETE);
        
        this.syncServiceSeguimiento.register(this.syncServiceSeguimiento.baseSyncPolicy(this.visitasPereira.GET));
        this.syncServiceSeguimiento.register(this.syncServiceSeguimiento.baseSyncPolicy(this.visitasPereira.POST));
        this.syncServiceSeguimiento.register(this.syncServiceSeguimiento.baseSyncPolicy(this.visitasPereira.PUT));
        this.syncServiceSeguimientoDelete.register(this.syncServiceSeguimientoDelete.baseSyncPolicy(this.visitasPereira.DELETE));
    }
}
