import {AppFormRequest} from '../app-form-request';

export class AppFormRequestDuplicate<T> {

    private form: AppFormRequest<T>;

    constructor(form: AppFormRequest<T>) {
        this.form = form;
        this.init();
    }

    private init() {
        this.form.onLoad(resolve => {
            const params: {p3?: string; id?: string} = {};

            if (this.form.generic) {
                params['p3'] = this.form.registerId;
            } else {
                params['id'] = this.form.registerId;
            }

            this.form.requestGET
                .safePerform(params as T[]);

            this.form.requestGET
                .response((data) => {
                    if (data[0]) {
                        for (const x in data[0]) {
                            if (data[0][x]) {
                                (this.form.model as Record<string,string>)[x] = data[0][x] as string;
                            }
                        }
                    }
                    resolve(true);
                });
        });

        this.form.afterLoad(resolve => {
            delete (this.form.model as Record<string,string>)['id'];
            this.form.requestGET.unsuscribe();
            resolve(true);
        });

        this.form.onUpdate(resolve => {
            if (this.form.model) {
                for (const x in this.form.model) {
                    if (this.form.model[x]) {
                        (this.form.model as Record<string,string>)[x] = (this.form.model as Record<string,string>)[x] ?? '';
                    }
                }
            }
            resolve(true);
        });

        this.form.beforeSend(resolve => {
            if (this.form.fieldsToSend) {
                for (const x in this.form.model) {
                    if (this.form.model[x]) {
                        let founded = false;

                        this.form.fieldsToSend.forEach(field => {
                            if (field === x) {
                                founded = true;
                            }
                        });

                        if (!founded) {
                            delete this.form.model[x];
                        }
                    }
                }
            }

            resolve(true);
        });

        this.form.onSend(resolve => {
            this.form.requestPOST.safePerform(this.form.model as T[]);
            this.form.requestPOST.response(() => {
                resolve(true);
            });
        });

        /*this.form.afterSend(resolve => {
            if (!this.form.requestPOST.hasError) {
                this.form.modelOld = JSON.parse(JSON.stringify(this.form.model));
            }
            resolve(true);
        });*/

        this.form.onFinish(resolve => {
            setTimeout(() => {
                this.form.requestPOST.unsuscribe();
            }, 1000);
            resolve(true);
        });
    }
}
